import { spring as r } from "../generators/spring/index.js";
import { createAnimationsFromSequence as c } from "../sequence/create.js";
import { animateSubject as s } from "./subject.js";
function h(i, o, t) {
  const n = [];
  return c(i, o, t, { spring: r }).forEach(({ keyframes: e, transition: a }, m) => {
    n.push(...s(m, e, a));
  }), n;
}
export {
  h as animateSequence
};
