import * as React from 'react';
import { useCacheAndNetworkQuery } from './Overview/ChannelSelection/useCacheAndNetworkQuery';
import { ChannelGroupFragment, GetDashboardChannelGroups } from '@generated/graphql';
import { getPixelRatio } from '@shared/components/helper/getPixelRatio';
import { Flex, FlexCol, Text } from '@knuddels/component-library';
import { ChannelListGroupItems } from './shared/ChannelList/ChannelListGroupItems';
import { ChannelGroupExpandedContextDefaultProvider } from './ChannelGroupExpandedContext';
import { CondensedButton, equalUpToDepth } from '@shared/components';
import { useService } from '@knuddels-app/DependencyInjection';
import { $ViewService } from '@knuddels-app/layout';
import { channelListViewId } from '@knuddelsModules/ChannelList';
import { $I18n, declareFormat } from '@knuddels-app/i18n';
import { mergePreviewMembers } from './Overview/ChannelSelection/mergePreviewMembers';
import { useChannelGroupUpdater } from './Overview/ChannelSelection/useChannelGroupUpdater';
import { useLogEvent } from '@knuddels-app/analytics/firebase/useLogEvent';
import { useReactiveState } from '@knuddels-app/tools/useReactiveState';
export const DashboardChannelListElement: React.FC<{
  layout: 'desktop' | 'mobile';
}> = ({
  layout
}) => {
  const {
    data,
    setData
  } = useCacheAndNetworkQuery(GetDashboardChannelGroups, {
    pixelDensity: getPixelRatio()
  });
  const setVisibleChannels = useUpdater(setData);
  React.useEffect(() => {
    if (data && data.length > 0) {
      const visibleChannels = data.map(it => it.id);
      setVisibleChannels(visibleChannels);
    }
  }, [data]);
  const viewService = useService($ViewService);
  const i18n = useService($I18n);
  const track = useLogEvent();
  if (!data || data.length === 0) {
    return null;
  }
  return <div className={_c0}>
			<div className={_c1}>
				<Text type={'body2'} state={'tertiary'} bold={true} className={_c2}>
					{declareFormat({
          id: 'dashboard.channellist.title',
          defaultFormat: 'Channels for you'
        }).format(i18n).toUpperCase()}
				</Text>
			</div>
			<div className={_c3 + (layout === 'mobile' ? _c4 : _c5)}>
				<ChannelGroupExpandedContextDefaultProvider>
					{data.map((group, index) => <Group key={group.id} group={group} isLast={index === data.length - 1} />)}
				</ChannelGroupExpandedContextDefaultProvider>
			</div>
			<div className={_c6}>
				<CondensedButton onPress={() => {
        viewService.openView(channelListViewId, {
          openContext: 'discover'
        });
        track('Dashboard_Channel', 'OpenChannelselection_Clicked');
      }} text={declareFormat({
        id: 'dashboard.channellist.button',
        defaultFormat: 'Open channel selection'
      }).format(i18n)} />
			</div>
		</div>;
};
const Group: React.FC<{
  group: ChannelGroupFragment;
  isLast: boolean;
}> = ({
  group,
  isLast
}) => {
  const track = useLogEvent();
  const userCount = group.channels.reduce((previousValue, currentValue) => previousValue + currentValue.onlineUserCount, 0);
  return <ChannelListGroupItems expandingKey={group.id} channelGroup={group} userCount={userCount} size={'default'} isLast={isLast} shouldUseShortDescription={false} trackingContentType={'Dashboard_Channel'} onChannelSelected={channelName => {
    track('Dashboard_Channel', channelName + '_Clicked');
  }} />;
};
type QueryResult = typeof GetDashboardChannelGroups.TPrimaryResult;
type DataUpdater = (prevData: QueryResult) => QueryResult;
const useUpdater = (setData: (updater: DataUpdater) => void) => {
  const updateChannelData = React.useCallback((newChannel: ChannelGroupFragment) => {
    const groupId = newChannel.id;
    setData(prevData => {
      const oldChannel = prevData.find(it => it.id === groupId);
      if (!oldChannel) {
        return prevData;
      }
      if (equalUpToDepth(oldChannel, newChannel, undefined, 10)) {
        return prevData;
      }
      const channelToInsert = mergePreviewMembers(oldChannel, newChannel);
      return prevData.map(group => {
        if (group.id === groupId) {
          return channelToInsert;
        }
        return group;
      });
    });
  }, [setData]);
  const overlayOpen = isOverlayOpen();
  return useChannelGroupUpdater(!overlayOpen, updateChannelData);
};
const isOverlayOpen = () => {
  const viewService = useService($ViewService);
  return useReactiveState(() => {
    return viewService.layout.additionalOverlays.length > 0;
  }, [viewService]);
};
const _c0 = " Knu-FlexCol ";
const _c1 = " Knu-Flex ml-base mb-minor mt-base ";
const _c2 = "  ";
const _c3 = " Knu-FlexCol ";
const _c4 = " px-base ";
const _c5 = " px-none ";
const _c6 = " Knu-Flex alignSelf-center mt-minor ";