import { memoSupports as e } from "./memo-supports.js";
const a = /* @__PURE__ */ e(() => {
  try {
    document.createElement("div").animate({ opacity: 0 }, { easing: "linear(0, 1)" });
  } catch {
    return !1;
  }
  return !0;
}, "linearEasing");
export {
  a as supportsLinearEasing
};
