const s = (e) => ({
  test: (t) => typeof t == "string" && t.endsWith(e) && t.split(" ").length === 1,
  parse: parseFloat,
  transform: (t) => `${t}${e}`
}), n = /* @__PURE__ */ s("deg"), r = /* @__PURE__ */ s("%"), o = /* @__PURE__ */ s("px"), p = /* @__PURE__ */ s("vh"), a = /* @__PURE__ */ s("vw"), c = {
  ...r,
  parse: (e) => r.parse(e) / 100,
  transform: (e) => r.transform(e * 100)
};
export {
  n as degrees,
  r as percent,
  c as progressPercentage,
  o as px,
  p as vh,
  a as vw
};
