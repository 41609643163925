import * as React from 'react';
import { useEffect, useState } from 'react';
import { useService } from '@knuddels-app/DependencyInjection';
import { declareFormat } from '@knuddels/i18n';
import { $I18n } from '@knuddels-app/i18n';
import { SwitchListItem } from '@knuddels/component-library';
import { TanEnabled } from '@generated/graphql';
import { observer } from '@knuddels-app/mobx';
import { $TanSystemService } from '../../../../providedServiceIds';
import { TanSystemSettings } from './TanSystemSettings';
import { TanSystemInfo } from './TanSystemInfo';
import { useTrackTanSettings } from './useTrackTanSettings';
import { $GenericUserEventService } from '@knuddels-app/analytics/generic';
export const TanSystemOverview: React.FC = observer('TanSystemOverview', () => {
  const i18n = useService($I18n);
  const tanService = useService($TanSystemService);
  const tanEnabled = tanService.status?.__typename === 'TanEnabled';
  const [tanSystemActive, setTanSystemActive] = useState<boolean>(tanEnabled);
  const track = useTrackTanSettings();
  const genericUserEventService = useService($GenericUserEventService);
  useEffect(() => {
    setTanSystemActive(tanService.status?.__typename === 'TanEnabled');
  }, [tanService.status]);
  const toggleTanSystem = async (active: boolean): Promise<void> => {
    const oldActiveState = tanSystemActive;
    setTanSystemActive(active);
    const ok = active ? await tanService.enable() : await tanService.disable();
    if (!ok) {
      setTanSystemActive(oldActiveState);
      return;
    }
    track(active ? 'Tansystem_Activate' : 'Tansystem_Deactivate');
    genericUserEventService.reportEvent({
      type: 'Settings_Changed',
      field: 'TanSystemEnabled'
    });
  };
  if (!tanService.status) {
    return null;
  }
  return <>
			<SwitchListItem caption={declareFormat({
      id: 'settings.tanSettings.activateTan',
      defaultFormat: 'Activate TAN system'
    }).format(i18n)} checked={tanSystemActive} onChange={() => toggleTanSystem(!tanSystemActive)} separator={tanSystemActive ? 'equalPadding' : 'none'} />
			{tanSystemActive && tanEnabled ? <TanSystemSettings status={(tanService.status as TanEnabled)} /> : <TanSystemInfo />}
		</>;
});