import { memo as e } from "../../../../utils/memo.js";
import { supportsFlags as p } from "./supports-flags.js";
function i(r, m) {
  const t = e(r);
  return () => {
    var o;
    return (o = p[m]) !== null && o !== void 0 ? o : t();
  };
}
export {
  i as memoSupports
};
