import { FullConversationFragment, IgnoreState, MenteeStatus, Scalars, User } from '@generated/graphql';
import { declareProps, inject, injectProps, injectable, injectedComponent } from '@knuddels-app/DependencyInjection';
import { $ScreenService } from '@knuddels-app/Screen';
import { computed, reactionOnceWhen } from '@knuddels-app/mobx';
import { getMessageDayText } from '@knuddels-app/tools/getMessageDayText';
import { FlexCol, Z_INDEX, resolveThemingValue, useTheme } from '@knuddels/component-library';
import { Disposable, expectUnreachable, last } from '@knuddels/std';
import { $FriendRequestsService } from '@knuddelsModules/Contacts';
import { LazyConversationMentorStatusBar } from '@knuddelsModules/MentorSystem';
import { $MessengerConversationService } from '@knuddelsModules/Messenger/providedServices';
import { $PushNotificationsService } from '@knuddelsModules/Notifications';
import { $ProfileNavigationService } from '@knuddelsModules/Profile';
import { $UserService } from '@knuddelsModules/UserData';
import { InfiniteScrollView, Spacer, SpacerSizes } from '@shared/components';
import * as React from 'react';
import { conversationOpenedTracker } from '../../../analytics';
import { isPhotoCommentMessage } from '../../../isPhotoCommentMessage';
import { ClientConversationState, SendingImage, SuggestionsType } from '../../../services/conversationServices/MessengerConversationService';
import { useNavigate } from '../../MessengerRouter';
import { MiniChatContext } from '../../MiniChat/MiniChatContext';
import { MessageLightboxSource } from '../Lightbox/Lightbox';
import { CommunicationRequestBox } from './CommunicationRequestBox/CommunicationRequestBox';
import { ConversationItemData, SendImageBubbleDataToSendImageBubbleProps, Spinner, TimeDivider, UnreadDivider, generateSets, messageDataToMessengerChatMessageProps } from './ConversationContent/Components';
import { CongratulateReactions } from './ConversationContent/CongratulateReactions';
import { ConversationStarterSuggestions } from './ConversationContent/ConversationStarterSuggestions';
import { FriendRecommendation } from './ConversationContent/FriendRecommendation';
import { IgnoreKind, IgnorePanel } from './ConversationContent/IgnorePanel';
import { MentorSystemHint } from './ConversationContent/MentorSystemHint';
import { MessengerChatMessage } from './ConversationContent/MessengerChatMessage';
import { MessengerSendingMessage } from './ConversationContent/MessengerSendingMessage';
import { ReceivedFriendRequest } from './ConversationContent/ReceivedFriendRequest';
import { SendImageBubble } from './ConversationContent/SendImageBubble';
import { SendingMessageRetry } from './ConversationContent/SendingMessageRetry';
import { ThankSuggestions } from './ConversationContent/ThankSuggestions';
import { ImageGroupClickOnceIdContext } from '@shared/components/molecules/FormattedText/ImageGroup';
interface Props {
  conversation: FullConversationFragment;
  scrollToMessageId: Scalars['ID'];
  lastReadIndex?: Scalars['ID'];
  loading?: boolean;
  initialLoading?: boolean;
  sendingImage?: SendingImage;
  wasMessageSentRecently?: boolean;
  setLightboxSrc?: (src: MessageLightboxSource | string) => void;
  scrollViewRef: React.RefObject<InfiniteScrollView<ConversationItemData>>;
  onScrollToBottomStateChange?(isAtBottom: boolean): void;
  onMessageSent(): void;
  fetchMoreMessages(): Promise<void>;
}
const SUGGESTION_HEIGHT = 56; // 40 + 2 * 8 (padding)

@injectable()
class ConversationHistoryModel {
  public readonly dispose = Disposable.fn();
  public initialRenderDone = false;
  public readonly clientConversationState: ClientConversationState;
  constructor(@injectProps()
  private readonly props: Props, @inject($UserService)
  private readonly userService: typeof $UserService.T, @inject($ScreenService)
  private readonly screenService: typeof $ScreenService.T, @inject($MessengerConversationService)
  private readonly messengerConversationService: typeof $MessengerConversationService.T, @inject.lazy($ProfileNavigationService)
  private readonly getProfileNavigationService: typeof $ProfileNavigationService.TLazy, @inject($FriendRequestsService)
  private readonly friendRequestsService: typeof $FriendRequestsService.T, @inject.lazy($PushNotificationsService)
  private readonly getPushNotificationsService: typeof $PushNotificationsService.TLazy) {
    this.clientConversationState = messengerConversationService.getOrCreateClientConversationState(props.conversation.id);
    this.dispose.track(reactionOnceWhen({
      name: 'Track conversation visible',
      fireImmediately: true
    }, () => !!this.props.conversation.conversationMessages, () => {
      conversationOpenedTracker.stop();
    }));
    this.getPushNotificationsService().then(service => {
      service.clearNotificationsWithTag();
    });
  }
  public get showOtherSuggestions(): boolean {
    const state = this.clientConversationState;
    const activeType = state.activeSuggestionsType;
    return !!state.selectedMessageIdForSuggestions && activeType !== undefined;
  }
  @computed
  public get conversationItems(): ConversationItemData[] {
    const activeUser = this.userService.currentUser;
    if (!activeUser) {
      return [];
    }
    const conversation = this.props.conversation;
    const otherParticipant = this.props.conversation.otherParticipants[0];
    const sets = generateSets(this.props.lastReadIndex, conversation.conversationMessages.messages, this.clientConversationState.sendingMessages.filter(it => it.conversationId === conversation.id));
    const items: ConversationItemData[] = [];
    const pushItemAndSpacer = (item: ConversationItemData, size: SpacerSizes | 'none' | number = 'small') => {
      items.push(item);
      if (size !== 'none') {
        items.push({
          type: 'spacer',
          uniqueKey: item.uniqueKey + '-spacer',
          size
        });
      }
    };
    if (this.props.loading) {
      items.push({
        type: 'spinner',
        uniqueKey: 'messages-loading-spinner'
      });
    }
    let lastTime = '';
    let nextIsFirstUnread = false;
    sets.forEach((set, setIndex) => {
      const time = getMessageDayText(+set[0].timestamp);
      if (nextIsFirstUnread) {
        pushItemAndSpacer({
          type: 'unreadDivider',
          uniqueKey: 'unread-divider'
        });
      }
      if (lastTime !== time) {
        pushItemAndSpacer({
          type: 'timeDivider',
          uniqueKey: 'time-divider-' + time,
          time
        });
      }
      set.forEach((message, index) => {
        if (!('retry' in message)) {
          pushItemAndSpacer({
            type: 'message',
            uniqueKey: message.id,
            message,
            conversation,
            activeUserId: activeUser.id,
            activeUserNick: activeUser.nick,
            isStackedLayout: this.screenService.isStackedLayout,
            setLightboxSrc: this.props.setLightboxSrc,
            openProfile: this.showProfile,
            showUserImage: index === 0 && message.sender.id !== activeUser.id,
            hasArrow: index === 0 && !isPhotoCommentMessage(message)
          }, index === set.length - 1 && setIndex === sets.length - 1 ? 'none' : 2);
        } else {
          pushItemAndSpacer({
            type: 'sendingMessage',
            uniqueKey: message.id,
            message,
            receiverId: otherParticipant.id,
            activeUserId: activeUser.id,
            isStackedLayout: this.screenService.isStackedLayout,
            hasArrow: index === 0,
            animate: index === set.length - 1 && setIndex === sets.length - 1 && this.initialRenderDone,
            showLoadingIndicator: !message.canRetry
          }, message.canRetry ? 'tiny' : index === set.length - 1 ? 'small' : 2);
          if (message.canRetry) {
            pushItemAndSpacer({
              type: 'retryMessage',
              uniqueKey: message.id + '-retry',
              isStackedLayout: this.screenService.isStackedLayout,
              retry: message.retry
            }, 'base');
          }
        }
      });
      lastTime = time;
      nextIsFirstUnread = this.props.lastReadIndex && set[set.length - 1].id === this.props.lastReadIndex;
    });
    if (this.props.sendingImage) {
      const hasArrow = sets.length === 0 || last(sets)[0].sender.id !== activeUser.id;
      items.push({
        type: 'sendImageBubble',
        uniqueKey: 'send-image-bubble',
        isStackedLayout: false,
        withArrow: hasArrow,
        sendingImage: this.props.sendingImage
      });
    }
    insertElements(items, this.clientConversationState.friendRecommendationsState.recommendations, (recommendation, index) => ({
      type: 'friendRecommendation',
      uniqueKey: 'friend-recommendation-' + recommendation.timestamp,
      recommendation,
      user: otherParticipant,
      isNewestMessage: !areThereRealItemsAfterIndex(index, items)
    }));
    insertElements(items, this.friendRequestsService.friendRequestsForConversation.filter(r => r.user.id === otherParticipant.id), r => {
      const setAccepted = (isAccepted: boolean) => {
        this.friendRequestsService.setRequestForConversationAccepted(r.user.id, isAccepted);
      };
      return {
        type: 'receivedFriendRequest',
        uniqueKey: 'received-friend-request-' + r.user.id,
        request: r,
        onAccepted: () => setAccepted(true),
        onAcceptError: () => setAccepted(false)
      };
    });

    // Show mentor hints only for (new potential) mentee
    if (this.hasNoMessages && otherParticipant.menteeStatus === MenteeStatus.PotentialMentee || !this.props.wasMessageSentRecently && otherParticipant.menteeStatus === MenteeStatus.Mentee) {
      items.push({
        type: 'mentorSystemHint',
        uniqueKey: 'mentor-system-first-message-tip',
        hasNoMessages: this.hasNoMessages,
        menteeNick: otherParticipant.nick
      });
    }
    return items;
  }
  public get hasNoMessages(): boolean {
    return this.props.conversation.conversationMessages.messages.length === 0;
  }
  public get showStarterSuggestions(): boolean {
    return this.hasNoMessages && this.props.conversation.otherParticipants[0].isAllowedByContactFilter;
  }
  public get hasMenteeStatus(): boolean {
    return this.props.conversation.otherParticipants[0].menteeStatus && this.props.conversation.otherParticipants[0].menteeStatus !== MenteeStatus.None;
  }
  @computed
  public get initialScrollLocationInfo(): {
    initialKey: string | undefined;
    initialTarget: 'top' | 'center';
  } {
    let initialKey: string | undefined = undefined;
    let initialTarget: 'top' | 'center' = 'top';
    if (!this.props.initialLoading) {
      return {
        initialKey: undefined,
        initialTarget
      };
    }
    for (const item of this.conversationItems) {
      if (this.props.scrollToMessageId && item.type === 'message' && item.message.id === this.props.scrollToMessageId) {
        initialKey = item.uniqueKey;
        initialTarget = 'center';
        break;
      }
      if (initialKey === undefined && item.type === 'unreadDivider') {
        initialKey = item.uniqueKey;
        initialTarget = 'top';
      }
    }
    return {
      initialKey,
      initialTarget
    };
  }
  public get ignoreType(): IgnoreKind {
    const otherParticipant = this.props.conversation.otherParticipants[0];
    if (otherParticipant.isLockedByAutomaticComplaint) {
      return otherParticipant.automaticComplaintCommand ? 'automaticComplaintWithButton' : 'automaticComplaintWithoutButton';
    }
    const isBlockSender = otherParticipant.ignoreState === IgnoreState.Block;
    const isIgnoreSender = otherParticipant.ignoreState === IgnoreState.Ignore;
    const isPrivateIgnoreSender = otherParticipant.ignoreState === IgnoreState.PrivateIgnore;
    if (isBlockSender) {
      return 'block';
    }
    if (isIgnoreSender) {
      return 'ignore';
    }
    if (isPrivateIgnoreSender) {
      return 'privateIgnore';
    }
    if (otherParticipant.isIgnoring) {
      return 'receiver';
    }
    if (!otherParticipant.isAllowedByContactFilter) {
      return 'contactFilter';
    }
    return undefined;
  }
  public onEndReached = (): void => {
    this.props.fetchMoreMessages();
  };
  private readonly showProfile = (userId: User['id']): void => {
    this.getProfileNavigationService().then(service => service.showProfile(userId, 'Conversation'));
  };
}
export const ConversationHistory = injectedComponent({
  name: 'ConversationHistory',
  model: ConversationHistoryModel,
  props: declareProps<Props>()
}, ({
  conversation,
  model,
  setLightboxSrc,
  scrollViewRef,
  loading,
  onMessageSent,
  onScrollToBottomStateChange
}) => {
  const isMiniChatContext = React.useContext(MiniChatContext);
  if (!conversation) {
    return null;
  }
  const otherParticipant = conversation.otherParticipants[0];
  const {
    initialKey,
    initialTarget
  } = model.initialScrollLocationInfo;

  // Show suggestions if you haven't had a conversation yet
  const showStarterSuggestions = model.showStarterSuggestions;
  const showOtherSuggestions = model.showOtherSuggestions;

  // This must be set after model.conversationItems is called for the first time.
  // Currently this happens inside of model.initialScrollLocationInfo
  model.initialRenderDone = true;
  return <div className={_c0}>
				{model.hasMenteeStatus && !isMiniChatContext && <MenteeStatusBar hasNoMessages={model.hasNoMessages} />}
				<InfiniteScrollView estimatedItemSize={80} ref={scrollViewRef} renderItem={renderItem} items={model.conversationItems} onScrollToBottomStateChange={onScrollToBottomStateChange} initialScrollToUniqueKey={initialKey} initialScrollTarget={initialTarget} onEndReached={model.onEndReached} disableHorizontalScroll wrapperStyle={{
      marginBottom: showOtherSuggestions || showStarterSuggestions ? SUGGESTION_HEIGHT : 0
    }} header={!loading && !conversation.conversationMessages.hasMore && <div className={_c1}>
								<CommunicationRequestBox conversation={conversation} setLightBoxSource={setLightboxSrc} />
							</div>} footer={<div className={_c2}>
							{!!model.ignoreType && <IgnorePanel key={'ignore'} conversation={conversation} kind={model.ignoreType} />}
						</div>} />

				{renderSuggestions(model.clientConversationState, otherParticipant.nick, onMessageSent)}

				{showStarterSuggestions && <ConversationStarterSuggestions onMessageSent={onMessageSent} />}
			</div>;
});
const renderSuggestions = (clientConversationState: ClientConversationState, otherParticipantNick: string, onMessageSent: () => void): JSX.Element => {
  if (!clientConversationState.selectedMessageIdForSuggestions) {
    return null;
  }
  switch (clientConversationState.activeSuggestionsType) {
    case SuggestionsType.CongratulateReactions:
      {
        return <CongratulateReactions onMessageSent={onMessageSent} />;
      }
    case SuggestionsType.ThankSuggestions:
      {
        return <ThankSuggestions otherParticipantNick={otherParticipantNick} onMessageSent={onMessageSent} />;
      }
    default:
      return null;
  }
};
const renderItem = (item: ConversationItemData) => {
  if (item.type === 'timeDivider') {
    return <TimeDivider key={item.uniqueKey} {...item} />;
  } else if (item.type === 'unreadDivider') {
    return <UnreadDivider key={item.uniqueKey} {...item} />;
  } else if (item.type === 'spinner') {
    return <Spinner key={item.uniqueKey} {...item} />;
  } else if (item.type === 'sendImageBubble') {
    return <SendImageBubble key={item.uniqueKey} {...SendImageBubbleDataToSendImageBubbleProps(item)} />;
  } else if (item.type === 'spacer') {
    if (typeof item.size === 'number') {
      return <div key={item.uniqueKey} style={{
        height: resolveThemingValue(item.size, "sizes", useTheme())
      }} className={_c3} />;
    } else {
      return <Spacer key={item.uniqueKey} size={item.size} />;
    }
  } else if (item.type === 'message') {
    return <ImageGroupClickOnceIdContext.Provider value={'messenger_' + item.uniqueKey}>
				<MessengerChatMessage key={item.uniqueKey} {...messageDataToMessengerChatMessageProps(item)} />
			</ImageGroupClickOnceIdContext.Provider>;
  } else if (item.type === 'sendingMessage') {
    return <MessengerSendingMessage key={item.uniqueKey} message={item.message} receiverId={item.receiverId} isStackedLayout={item.isStackedLayout} hasArrow={item.hasArrow} animated={item.animate} showLoadingIndicator={item.showLoadingIndicator} />;
  } else if (item.type === 'retryMessage') {
    return <SendingMessageRetry key={item.uniqueKey} isStackedLayout={item.isStackedLayout} retry={item.retry} />;
  } else if (item.type === 'friendRecommendation') {
    return <FriendRecommendation key={item.uniqueKey} isNewestMessage={item.isNewestMessage} user={item.user} recommendationData={item.recommendation} />;
  } else if (item.type === 'receivedFriendRequest') {
    return <ReceivedFriendRequest request={item.request} onAccepted={item.onAccepted} onAcceptError={item.onAcceptError} />;
  } else if (item.type === 'mentorSystemHint') {
    return <MentorSystemHint hasNoMessages={item.hasNoMessages} menteeNick={item.menteeNick} />;
  } else {
    expectUnreachable(item);
  }
};
function insertElements<T extends {
  timestamp: number;
}>(into: ConversationItemData[], elements: readonly T[], factory: (element: T, index: number) => ConversationItemData): void {
  elements.forEach(r => {
    const nextOlderMessageIndex = findIndexWithNextSmallerTimestamp(into, r.timestamp);
    if (nextOlderMessageIndex === -1) {
      // ignore elements if there are no messages above them
      return;
    }
    const newIndex = nextOlderMessageIndex + 1;
    const newItem = factory(r, newIndex);
    into.splice(newIndex, 0, newItem);
  });
}

// Finds the index of a message with the next smallest timestamp.
// Returns -1 if no index was found (given timestamp is too old).
function findIndexWithNextSmallerTimestamp(items: ConversationItemData[], timestamp: number): number {
  // searching backwards because
  // 1. we can return early without checking the next item in the list
  // 2. most of the time we want to insert at newer elements (towards the end of the list)
  for (let i = items.length - 1; i >= 0; i--) {
    const item = items[i];
    const itemTimestamp = getTimestamp(item);
    if (typeof itemTimestamp === 'number' && itemTimestamp < timestamp) {
      return i;
    }
  }
  return -1;
}

// 'real' item is seen as an item with a timestamp.
function areThereRealItemsAfterIndex(startIndex: number, items: ConversationItemData[]): boolean {
  for (let i = startIndex + 1; i < items.length; i++) {
    const itemTimestamp = getTimestamp(items[i]);
    if (typeof itemTimestamp === 'number' && itemTimestamp > 0) {
      return true;
    }
  }
  return false;
}
function getTimestamp(item: ConversationItemData): number | undefined {
  if ('sendingImage' in item) {
    return item.sendingImage.timestamp;
  }
  if ('message' in item) {
    return +item.message.timestamp;
  }
  if ('recommendation' in item) {
    return item.recommendation.timestamp;
  }
  if ('request' in item) {
    return item.request.timestamp;
  }
  return undefined;
}
const AbsoluteTop: React.FC = props => {
  return <div style={{
    zIndex: resolveThemingValue(Z_INDEX.BELOW_TITLE_BAR, "theme", useTheme())
  }} className={_c4}>
			{props.children}
		</div>;
};
const MenteeStatusBar: React.FC<{
  hasNoMessages: boolean;
}> = ({
  hasNoMessages
}) => {
  const navigate = useNavigate();
  return <AbsoluteTop>
			<LazyConversationMentorStatusBar onPress={() => navigate('mentorsystem-rewards')} hasNoMessagesInConversation={hasNoMessages} />
		</AbsoluteTop>;
};
// tslint:disable-next-line: max-file-line-count
const _c0 = " Knu-FlexCol flex-1 position-relative height-full overflow-hidden ";
const _c1 = " Knu-FlexCol py-base position-relative ";
const _c2 = " Knu-FlexCol position-relative overflow-hidden ";
const _c3 = " Knu-FlexCol ";
const _c4 = " Knu-FlexCol position-absolute top-none left-none right-none ";