import {
	ConversationMessage,
	MessengerMessageReceivedFragment,
} from '@generated/graphql';

export interface ClientMessengerMessage {
	__typename: typeof MessengerMessageType;
	id: ConversationMessage['id'];
	message: MessengerMessageReceivedFragment['conversationMessage'];
	conversation: MessengerMessageReceivedFragment['conversation'];
}

export const MessengerMessageType = 'CLIENT_MESSENGER_MESSAGE';
