import { createBox as o } from "../../projection/geometry/models.js";
import { VisualElement as s } from "../VisualElement.js";
function u(n, e) {
  return n in e;
}
class c extends s {
  constructor() {
    super(...arguments), this.type = "object";
  }
  readValueFromInstance(e, t) {
    if (u(t, e)) {
      const r = e[t];
      if (typeof r == "string" || typeof r == "number")
        return r;
    }
  }
  getBaseTargetFromProps() {
  }
  removeValueFromRenderState(e, t) {
    delete t.output[e];
  }
  measureInstanceViewportBox() {
    return o();
  }
  build(e, t) {
    Object.assign(e.output, t);
  }
  renderInstance(e, { output: t }) {
    Object.assign(e, t);
  }
  sortInstanceNodePosition() {
    return 0;
  }
}
export {
  c as ObjectVisualElement
};
