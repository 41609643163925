import * as React from 'react';
import { declareFormat, FormattedMessage } from '@knuddels-app/i18n';
import { AlbumDetailsUserContext } from '../AlbumDetailsUserContext';
import { Flex, FlexCol, LineDivider, Text } from '@knuddels/component-library';
import { PhotoCommentsInput } from '@knuddelsModules/Profile/bundle/components/AlbumDetails/PhotoComments/PhotoCommentsInput';
export const PhotoCommentsInputBar: React.FC = () => {
  const {
    canReceivePhotoComments,
    isAllowedByContactFilter,
    nick,
    gender
  } = React.useContext(AlbumDetailsUserContext);
  if (!isAllowedByContactFilter) {
    return <CommentingUnavailable>
				<FormattedMessage id={declareFormat({
        id: 'album.details.sendComment.contactfilter',
        defaultFormat: 'We are sorry, but currently you are not able to add comments to photos of {nick} because you do not meet all the criteria of their contact filter.'
      })} values={{
        nick,
        gender: gender?.toLowerCase?.()
      }} />
			</CommentingUnavailable>;
  }
  if (!canReceivePhotoComments) {
    return <CommentingUnavailable>
				<FormattedMessage id={declareFormat({
        id: 'album.details.sendComment.unavailable',
        defaultFormat: "Unfortunately, you can't leave a comment"
      })} />
			</CommentingUnavailable>;
  }
  return <div className={_c0}>
			<PhotoCommentsInput />
		</div>;
};
const CommentingUnavailable: React.FC = ({
  children
}) => {
  return <div className={_c1}>
			<div className={_c2}>
				<LineDivider className={_c3} />
			</div>
			<div className={_c4}>
				<Text type={'body2'} state={'disabled'} className={_c5}>
					{children}
				</Text>
			</div>
		</div>;
};
const _c0 = " Knu-Flex ";
const _c1 = " Knu-FlexCol minHeight-48px ";
const _c2 = " Knu-Flex alignSelf-stretch ";
const _c3 = "  ";
const _c4 = " Knu-FlexCol placeItems-center flex-1 p-small ";
const _c5 = " textAlign-center ";