import { secondsToMilliseconds as p } from "../../utils/time-conversion.js";
import { getDefaultTransition as c } from "../utils/default-transitions.js";
import { getValueTransition as A } from "../utils/get-value-transition.js";
import { MotionGlobalConfig as k } from "../../utils/GlobalConfig.js";
import { instantAnimationState as C } from "../../utils/use-instant-transition-state.js";
import { getFinalKeyframe as D } from "../animators/waapi/utils/get-final-keyframe.js";
import { frame as T } from "../../frameloop/frame.js";
import { AcceleratedAnimation as d } from "../animators/AcceleratedAnimation.js";
import { MainThreadAnimation as g } from "../animators/MainThreadAnimation.js";
import { GroupPlaybackControls as M } from "../GroupPlaybackControls.js";
import { isTransitionDefined as U } from "../utils/is-transition-defined.js";
const j = (r, i, n, a = {}, s, m) => (u) => {
  const o = A(a, r) || {}, y = o.delay || a.delay || 0;
  let { elapsed: l = 0 } = a;
  l = l - p(y);
  let e = {
    keyframes: Array.isArray(n) ? n : [null, n],
    ease: "easeOut",
    velocity: i.getVelocity(),
    ...o,
    delay: -l,
    onUpdate: (t) => {
      i.set(t), o.onUpdate && o.onUpdate(t);
    },
    onComplete: () => {
      u(), o.onComplete && o.onComplete();
    },
    name: r,
    motionValue: i,
    element: m ? void 0 : s
  };
  U(o) || (e = {
    ...e,
    ...c(r, e)
  }), e.duration && (e.duration = p(e.duration)), e.repeatDelay && (e.repeatDelay = p(e.repeatDelay)), e.from !== void 0 && (e.keyframes[0] = e.from);
  let f = !1;
  if ((e.type === !1 || e.duration === 0 && !e.repeatDelay) && (e.duration = 0, e.delay === 0 && (f = !0)), (C.current || k.skipAnimations) && (f = !0, e.duration = 0, e.delay = 0), f && !m && i.get() !== void 0) {
    const t = D(e.keyframes, o);
    if (t !== void 0)
      return T.update(() => {
        e.onUpdate(t), e.onComplete();
      }), new M([]);
  }
  return !m && d.supports(e) ? new d(e) : new g(e);
};
export {
  j as animateMotionValue
};
