import {
	AppControlPlaneEventInput,
	AppDataEventInput,
	AppEvents,
} from '@generated/graphql';
import { $ClientInfoStore } from '@knuddels-app/Connection/serviceIds';
import { action, observable, runInAction } from '@knuddels-app/mobx';
import { shouldLogAppEvents } from './shouldLogAppEvents';
import { EventType } from '../../../SystemApps';
import { APPS_TO_FORCE_CLOSE } from '@shared/constants';
import { $EvergreenDataService } from '@knuddels-app/evergreenData';
import { $SystemAppPerformanceService } from '@knuddelsModules/Apps/providedServices';

type Writeable<T> = { -readonly [P in keyof T]: T[P] };
export type AppInstanceData = Writeable<
	Extract<
		(typeof AppEvents)['TPrimaryResult'],
		{ readonly __typename?: 'AppOpenEvent' }
	>
>;
type ClientData = {
	clientVersion: typeof $ClientInfoStore.T.clientVersion;
	currentUserNick: string;
};

export interface AppWebviewApi {
	appId: string;
	securityIdFromClient: string;
	data: AppInstanceData;
	initWebview(ref: WebviewRef, context: any): void;
	handleMessageEvent(dataRaw: string): void;
}

export interface AppCallbacks {
	onAppClose(appId: string): void;
	sendAppEvent(event: AppDataEventInput): void;
	sendControlPlaneEvent(event: AppControlPlaneEventInput): void;
	executeSlashCommand(cmd: string, appId: string): void;
	logFirebaseEvent(name: string, jsonParams: string): void;
	handleClientEvent(appInstance: AppInstance, event: any): void;
	setFirebaseUserProperty(name: string, value: string): void;
	playSound(appId: string, url: string): void;
}

type WebviewRef = {
	postMessage(message: string): void;
};

export class AppInstance implements AppWebviewApi {
	/* this is needed in order to rerender iframe if app ids are equal  */
	get uniqueId(): string {
		return this._appData.appId + '_' + this.securityIdFromClient;
	}

	public readonly isUserApp: boolean;

	public readonly openedInsideGlobalAppContext: boolean;

	@observable
	public title: string;

	@observable
	public isResponsive: boolean;

	@observable
	public nonChannelApp: boolean;

	public readonly channelName?: string;

	@observable
	public appReady = false;
	public readonly securityIdFromClient: string;
	public readonly isGlobalApp: boolean;
	public readonly globalAppKey: string | undefined;
	private securityIdFromApp: string | undefined;

	private readonly eventQueue: { message: any; timestamp: number }[] = [];
	private webviewRef: WebviewRef | undefined;
	@observable
	private readonly _appData: AppInstanceData;
	private readonly parsedPageData: Record<string, any>;
	private readonly backendSessionId: number;
	private readonly clientData: ClientData;
	private readonly callbacks: AppCallbacks;

	private onOpenListeners: Set<Function> | undefined;

	private currentAppState: 'active' | 'background' = 'active';

	private systemAppPerformanceService: typeof $SystemAppPerformanceService.T;

	constructor(
		config: {
			initAppData: AppInstanceData & {
				isGlobalApp: boolean;
				openedInsideGlobalAppContext: boolean;
			};
			clientVersion: typeof $ClientInfoStore.T.clientVersion;
			currentUserNick: string;
			callbacks: AppCallbacks;
		},
		systemAppPerformanceService: typeof $SystemAppPerformanceService.T
	) {
		this.systemAppPerformanceService = systemAppPerformanceService;
		this.openedInsideGlobalAppContext =
			config.initAppData.openedInsideGlobalAppContext;
		this.isUserApp = config.initAppData.isUserApp;
		this.clientData = {
			clientVersion: config.clientVersion,
			currentUserNick: config.currentUserNick,
		};
		this.callbacks = config.callbacks;
		this.securityIdFromClient = encodeURIComponent(getRandomId()); // generate
		this._appData = {
			...config.initAppData,
			loaderUrl: config.initAppData.loaderUrl,
		};
		this.parsedPageData = safeParse(config.initAppData.pageData) || {};
		this.backendSessionId =
			(this.parsedPageData.config &&
				(this.parsedPageData.config.sessionId as number)) ||
			0;
		this.isGlobalApp =
			this.parsedPageData.config?.globalApp ||
			config.initAppData.isGlobalApp;
		this.globalAppKey =
			(this.parsedPageData.config &&
				this.parsedPageData.config.globalAppBar &&
				this.parsedPageData.config.globalAppBar.appKey) ||
			undefined;
		this.channelName = this.data?.channelName;
	}

	public getLoaderUrl(bgColor: string): string {
		return (
			this.data.loaderUrl +
			`&bg=${bgColor}` +
			'#sessionId=' +
			this.securityIdFromClient
		);
	}

	public onOpen(): Promise<void> {
		if (!this.onOpenListeners) {
			this.onOpenListeners = new Set();
		}
		return new Promise(resolve => {
			this.onOpenListeners.add(resolve);
		});
	}

	public get data(): Readonly<AppInstanceData> {
		return this._appData;
	}
	public get appId(): string {
		return this._appData.appId;
	}

	public get appIdWithoutMeta(): string {
		return this._appData.appId.split(':')[0];
	}

	public get displayWidth(): number {
		if ('width' in this.data.display) {
			return this.data.display.width;
		} else {
			return 0;
		}
	}

	public get displayHeight(): number {
		if ('height' in this.data.display) {
			return this.data.display.height;
		} else {
			return 0;
		}
	}

	public get displayType(): 'fill' | 'scaled' {
		const preferredAppWidth = this.displayWidth;
		const preferredAppHeight = this.displayHeight;

		return !preferredAppWidth ||
			!preferredAppHeight ||
			this.data.display.__typename === 'AppDisplayHeaderbar' ||
			this.data.display.__typename === 'AppDisplayPopup'
			? 'fill'
			: 'scaled';
	}

	// We need to register/init the webviewRef.
	// We can't do it like HTMLChat (creating the iframe with code) because for native this doesn't work.
	initWebview(ref: WebviewRef, context: any): void {
		this.webviewRef = ref;
		const { globalAppName, appTitle, responsive, nonChannelApp } =
			JSON.parse(this.data?.pageData).config;

		runInAction('updateAppConfig', () => {
			this.title = globalAppName || appTitle || 'App';
			this.isResponsive = responsive;
			this.nonChannelApp = nonChannelApp || false;
		});

		this.webviewRef.postMessage(
			JSON.stringify({
				command: 'initBridge',
				appId: this.data.appId,
				// todo: rename key in our bridge
				securityIdFromClient: this.securityIdFromClient,
				title: this.title,
				responsive: this.isResponsive,
				nick: this.clientData.currentUserNick,
				channel: this.data.channelName,
				version: this.clientData.clientVersion.toString(),
				pageData: this.data.pageData,
				url: this.data.contentUrl,
				context: context,
			})
		);
	}

	public sendEventToWebview(key: string, data: any): void {
		const event = {
			message: {
				command: 'sendEvent',
				securityIdFromClient: this.securityIdFromClient,
				key,
				data,
			},
			timestamp: Date.now(),
		};
		this.eventQueue.push(event);
		this.processEvents();
	}

	private processEvents(): void {
		if (this.appReady && this.webviewRef) {
			while (this.eventQueue.length > 0) {
				const entry = this.eventQueue.shift();
				// throw away events older than 60sec
				if (entry && Date.now() - entry.timestamp < 60000) {
					this.webviewRef.postMessage(JSON.stringify(entry.message));
				}
			}
		}
	}

	@action.bound
	public close(): void {
		this.callbacks.onAppClose(this.appId);
		this.callbacks.sendControlPlaneEvent({
			appId: this.appId,
			channelName: this.data.channelName,
			eventKey: 'closed',
			eventValue: `${this.backendSessionId}`,
		});
	}

	updateEvergreenData(data: (typeof $EvergreenDataService.T)['data']): void {
		this.sendEventToWebview('EVERGREEN_DATA_CHANGE', {
			type: 'EVERGREEN_DATA_CHANGE',
			data: data,
		});
	}

	updateVisibleStatus(appState: 'active' | 'background'): void {
		if (this.currentAppState === appState) {
			return;
		}
		this.currentAppState = appState;
		this.sendEventToWebview('APP_STATE_CHANGE', {
			type: 'APP_STATE_CHANGE',
			isActive: appState,
		});
	}

	updateOrientation(orientation: 'portrait' | 'landscape'): void {
		this.sendEventToWebview('ORIENTATION_CHANGE', {
			type: 'ORIENTATION_CHANGE',
			orientation: orientation,
		});
	}

	@action.bound
	public handleMessageEvent(dataRaw: string): void {
		const data = safeParse(dataRaw);
		if (!data) {
			console.error('Invalid event', dataRaw);
			return;
		}

		if (shouldLogAppEvents) {
			console.log('Event from webview:', data.command, data);
		}

		if (
			data.command === 'onAppInit' &&
			data.securityIdFromClient &&
			data.securityIdFromClient === this.securityIdFromClient
		) {
			this.securityIdFromApp = data.securityIdFromApp;
			return;
		} else if (
			data.securityIdFromApp &&
			this.securityIdFromApp &&
			data.securityIdFromApp === this.securityIdFromApp
		) {
			switch (data.command) {
				case 'onAppReady': {
					this.systemAppPerformanceService.recordAppReady(this.appId);
					this.appReady = true;
					this.callbacks.handleClientEvent(this, {
						appId: this.appId,
						type: 'onAppReady',
					});
					this.processEvents();
					this.onOpenListeners?.forEach(l => l());
					this.onOpenListeners = undefined;
					return;
				}
				case 'setTitle': {
					runInAction('setTitle', () => (this.title = data.title));
					return;
				}
				case 'setResizable': {
					runInAction(
						'setResizable',
						() => (this.isResponsive = data.resizable)
					);
					return;
				}
				case 'setFrameVisible': {
					return;
				}
				case 'setSize': {
					this._appData.display = {
						...this._appData.display,
						...data,
					};
					return;
				}
				case 'sendEventToServer': {
					console.log('sendEventToServer', data.key, data.data);
					this.callbacks.sendAppEvent({
						appId: this._appData.appId,
						channelName: this._appData.channelName,
						eventKey: data.key,
						eventValue: data.data,
					});
					return;
				}
				case 'sendChatServerEvent': {
					this.callbacks.sendControlPlaneEvent({
						appId: this._appData.appId,
						channelName: this._appData.channelName,
						eventKey: data.key,
						eventValue: data.data,
					});
					return;
				}
				case 'executeSlashCommand': {
					this.callbacks.executeSlashCommand(data.cmd, this.appId);
					return;
				}
				case 'sendEventToClient': {
					if (
						data.key === EventType.CloseApp &&
						APPS_TO_FORCE_CLOSE.includes(this.appId)
					) {
						this.callbacks.onAppClose(this.appId);
					}
					const parsedData = safeParse(data.data);
					this.callbacks.handleClientEvent(this, {
						...parsedData,
						name: data.key,
						appId: this.appId,
					});
					return;
				}
				case 'playSound': {
					const url = data.url;
					if (url) {
						this.callbacks.playSound(this.appId, url);
					}
					return;
				}
				case 'close': {
					this.close();
					return;
				}
				case 'logFirebaseEvent': {
					this.callbacks.logFirebaseEvent(data.name, data.jsonParams);
					return;
				}
				case 'setFirebaseUserProperty': {
					this.callbacks.setFirebaseUserProperty(
						data.name,
						data.value
					);
					return;
				}
				default:
					console.log('unknown command', data.command, data);
			}
		}
	}
}

function safeParse(data: string): any | undefined {
	try {
		return JSON.parse(data);
	} catch (e) {
		return undefined;
	}
}

function getRandomId(): string {
	return Math.random().toString(36).substr(2, 9);
}
