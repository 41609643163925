import * as React from 'react';
import { useEffect } from 'react';
import { Gender, PotentialMenteeAddedEventFragment } from '@generated/graphql';
import { Flex, FlexCol, Smiley, Text, toPointerHandler, resolveThemingValue, resolveIsDarkColor, useTheme } from '@knuddels/component-library';
import { Spacer } from '@shared/components';
import { useLazyService } from '@knuddels-app/ModuleSystem';
import { $MessengerNavigationService } from '../../../Messenger';
import { FormattedMessage } from '@knuddels-app/i18n';
import { declareFormat } from '@knuddels/i18n';
import { GenderIcon } from '@knuddels-app/shared-components/GenderIcon';
import { useTrackInAppNotification } from './useTrackInAppNotification';
import { $AppService } from '@knuddelsModules/Apps';
export const NEW_MENTEE_FOUND_FORMAT = declareFormat({
  id: 'mentorsystem.potential-mentee-added.notification.title',
  defaultFormat: 'New mentee found!'
});
export const WRITE_MESSAGE_FORMAT = declareFormat({
  id: 'mentorsystem.potential-mentee-added.notification.action',
  defaultFormat: 'Send message now!'
});
export const handlePress = (appService: typeof $AppService.T, messengerNavigationService: typeof $MessengerNavigationService.T, conversationId: string) => {
  messengerNavigationService.openConversation(conversationId, 'MenteeAddedNotification');
  appService.closeApp('MenteeNotificationsApp');
};
export const PotentialMenteeAddedNotificationContent: React.FC<{
  user: PotentialMenteeAddedEventFragment['user'];
  close: () => void;
}> = ({
  user,
  close
}) => {
  const track = useTrackInAppNotification();
  const getMessengerNavigationService = useLazyService($MessengerNavigationService);
  const getAppService = useLazyService($AppService);
  useEffect(() => {
    track('PotentialMenteeAvailable_Shown');
  }, []);
  const openConversation = async () => {
    track('PotentialMenteeAvailable_Clicked');
    close();
    handlePress(await getAppService(), await getMessengerNavigationService(), user.conversationId);
  };
  return <div onClick={toPointerHandler(openConversation)} className={_c0}>
			<Smiley width={40} height={35} src={require('@shared/images/sm_abo_15-05_schulabschluss.gif')} />
			<Spacer size={'minor'} />
			<div className={_c1}>
				<Text bold={true} className={_c2}>
					<FormattedMessage id={NEW_MENTEE_FOUND_FORMAT} />
				</Text>
				<div className={_c3}>
					<NickAgeAndGender nick={user.nick} gender={user.gender} age={user.age} />
					{user.isOnline && <div className={_c4}>
							<div className={_c5 + ("green-500" ? resolveIsDarkColor("green-500", useTheme()) ? " content-is-dark" : " content-is-light" : "")} />
							<Text state={'tertiary'} className={_c6}>
								{`Online${user.currentOnlineChannelName ? ` in ${user.currentOnlineChannelName}` : ''}`}
							</Text>
						</div>}
				</div>
				<Text state={'highlighted'} bold={true} className={_c7}>
					<FormattedMessage id={WRITE_MESSAGE_FORMAT} />
				</Text>
			</div>
		</div>;
};
const NickAgeAndGender: React.FC<{
  nick: string;
  gender: Gender;
  age?: number;
}> = props => {
  if (props.gender === Gender.Unknown && !props.age) {
    return <Text state={'tertiary'} className={_c8}>{props.nick}</Text>;
  }
  return <div className={_c9}>
			<Text state={'tertiary'} className={_c10}>{props.nick}</Text>
			<div className={_c11}>
				<Text state={'tertiary'} numberOfLines={1} className={_c12}>
					{' ('}
					{!!props.age && '' + props.age}
				</Text>
				{props.gender !== Gender.Unknown && <GenderIcon gender={props.gender} />}
				<Text state={'tertiary'} className={_c13}>{')'}</Text>
			</div>
		</div>;
};
const _c0 = " Knu-Flex px-minor py-minor alignItems-center cursor-pointer ";
const _c1 = " Knu-FlexCol ";
const _c2 = "  ";
const _c3 = " Knu-Flex alignItems-center flexWrap-wrap ";
const _c4 = " Knu-Flex alignItems-center flexShrink-0 ";
const _c5 = " Knu-Flex mx-tiny width-10px height-10px borderRadius-circle bg-green-500 ";
const _c6 = "  ";
const _c7 = "  ";
const _c8 = "  ";
const _c9 = " Knu-Flex flexShrink-0 ";
const _c10 = "  ";
const _c11 = " Knu-Flex alignItems-center ";
const _c12 = "  ";
const _c13 = "  ";