import * as React from 'react';
import { $ViewService, LayoutPosition, ViewId } from '@knuddels-app/layout';
import { inject, injectable } from '@knuddels-app/DependencyInjection';
import { $NavBarItemProvider } from '../../extensionPoints';
import { Disposable, expectUnreachable } from '@knuddels/std';
import { declareFormat } from '@knuddels/i18n';
import { DYNAMIC_SLOT_TYPE, navigationViewId } from '../../constants';
import { $FirebaseAnalyticsService } from '@knuddels-app/analytics/firebase';
import { $PromotionService } from '@knuddelsModules/Promotions';
import { colorToRgbaString } from '@knuddels-app/tools/colorToRgbaString';
import { IconMenu } from '@knuddels/component-library';
import { $AppService, $GlobalAppsAppViewer } from '@knuddelsModules/Apps';
import { NavBarItemData } from './NavBarItemData';
import { formatMessage } from '@knuddels-app/i18n';
import { getBadgeCount, getTotalBadgeCount } from '@shared/helper/getBadgeCount';
import { action, autorun, computed, observable, runInAction } from '@knuddels-app/mobx';
import { $LocalStorage, LocalStorageEntry, LocalStorageKey } from '@knuddels-app/local-storage';
import { $ScreenService } from '@knuddels-app/Screen';
import { globalAppViewId, viewIdForSystemApp } from '@knuddelsModules/SystemApps';
import { $ClientSettingsService } from '@knuddelsModules/Settings';
import { $EvergreenDataService } from '@knuddels-app/evergreenData';
import { associate } from '@knuddels-app/tools/associate';
import { K3_SIDEBAR_SID } from '@shared/constants';
import { $CommandService, $CommandWithoutChannelService } from '@knuddels-app/Commands';
import { $GenericUserEventService } from '@knuddels-app/analytics/generic';
export const lastVisitedSlotKey = LocalStorageKey.withJsonSerializer<string>({
  name: 'lastVisitedSlot',
  cookieExpires: {
    inDays: Number.MAX_SAFE_INTEGER
  }
});

/**
 * TODO Extract Slot behavior to strategies
 */

@injectable()
export class NavBarItemRegistry {
  public readonly dispose = Disposable.fn();
  private lastViewFromMoreMenu: ViewId;
  private lastOpenedView: ViewId;
  private lastVisitedSlotEntry: LocalStorageEntry<string> = this.localStorage.getEntry(lastVisitedSlotKey);
  @observable
  private dynamicItemId: string | null = null;
  private globalAppsAppViewer: typeof $GlobalAppsAppViewer.T | null = null;
  constructor(@inject.many($NavBarItemProvider)
  private readonly providers: (typeof $NavBarItemProvider.T)[], @inject($ViewService)
  private readonly viewService: typeof $ViewService.T, @inject($FirebaseAnalyticsService)
  private readonly firebaseAnalyticsService: typeof $FirebaseAnalyticsService.T, @inject($PromotionService)
  private readonly promotionService: typeof $PromotionService.T, @inject.lazy($GlobalAppsAppViewer)
  private readonly getGlobalAppsAppViewer: typeof $GlobalAppsAppViewer.TLazy, @inject($LocalStorage)
  private readonly localStorage: typeof $LocalStorage.T, @inject($ScreenService)
  private readonly screenService: typeof $ScreenService.T, @inject($ClientSettingsService)
  private readonly clientSettingsService: typeof $ClientSettingsService.T, @inject($EvergreenDataService)
  private readonly evergreenDataService: typeof $EvergreenDataService.T, @inject.lazy($AppService)
  private readonly getAppService: typeof $AppService.TLazy, @inject($CommandService)
  private readonly commandService: typeof $CommandService.T, @inject($CommandWithoutChannelService)
  private readonly commandWithoutChannelService: typeof $CommandWithoutChannelService.T, @inject($GenericUserEventService)
  private readonly genericUserEventService: typeof $GenericUserEventService.T) {
    this.initMenuItemsFromLocalStorage();
    this.getGlobalAppsAppViewer().then(globalAppsAppViewer => {
      this.globalAppsAppViewer = globalAppsAppViewer;
    });
    this.lastOpenedView = this.viewService.visibleViews.some(view => view.viewConfig.viewId === navigationViewId) ? navigationViewId : null;
    this.dispose.track(autorun({
      name: 'update slot type on menu change'
    }, () => {
      const menuEntries = this.evergreenDataService.data.menu.categories.flatMap(it => it.entries);
      if (menuEntries.length > 0 && this.getSelectedSlotType() !== DYNAMIC_SLOT_TYPE && !menuEntries.some(it => this.isSelectedSlotType(it.id, it.name))) {
        this.setSelectedSlotType('fotomeet');
      }
    }));
  }
  public viewInsideBottomNav(viewId: ViewId, includeFavorites = false): boolean {
    /**
     * there is no bottom nav on non-stacked layouts
     */
    if (!this.screenService.isStackedLayout) {
      return false;
    }
    return this.bottomItems.some(i => {
      return i.kind === 'view' && i.view === viewId || includeFavorites && i.kind === 'favorite';
    });
  }
  public getItems(navBarLocation: NavBarLocation): CombinedNavBarItemData[] {
    return this.getCategories(navBarLocation).flatMap(it => it.entries);
  }
  private trackItem(id: string, location: 'left' | 'bottom' | 'menu'): void {
    if (id === 'Logout') {
      this.genericUserEventService.reportEvent({
        type: 'Logout',
        source: 'Navigation_' + location
      });
    }
    if (location === 'left') {
      this.firebaseAnalyticsService.logEvent('Navigation_SideNavigation', `${id}_Clicked`);
    } else if (location === 'bottom') {
      this.firebaseAnalyticsService.logEvent('Navigation_BottomNavigation', `${id}_Clicked`);
    } else {
      this.firebaseAnalyticsService.logEvent('Navigation_Menu', `${id}_Clicked`);
    }
  }
  private isViewActive(viewId: ViewId, location: 'left' | 'bottom' | 'menu'): boolean {
    if (location === 'menu') {
      return false;
    }
    const activeViews = [...this.viewService.visibleViews];
    if (activeViews.some(view => view.viewConfig.viewId === viewId)) {
      if (viewId === this.globalAppsAppViewer?.globalAppViewId) {
        return !this.globalAppsAppViewer?.app || this.viewInsideBottomNav(viewId, false);
      }
      return true;
    }
    if (location === 'left') {
      return false;
    }
    if (this.getSelectedSlotType() === 'dynamic' && viewId.equals(navigationViewId)) {
      return false;
    }
    return activeViews.some(view => !!view.viewConfig.getParentViewId && view.viewConfig.getParentViewId(view.state) === viewId && !this.bottomItems.some(bottom => bottom.kind === 'view' && bottom.view === view.viewConfig.viewId));
  }
  private isAppActive(title: string): boolean {
    const activeViews = [...this.viewService.visibleViews];
    if (!this.globalAppsAppViewer?.app?.title || !activeViews.some(view => view.viewConfig.viewId === this.globalAppsAppViewer?.globalAppViewId)) {
      return false;
    }
    return this.globalAppsAppViewer?.app.title.includes(title);
  }
  private openView(viewId: ViewId, location: 'menu' | 'bottom' | 'left', overlay: boolean): void {
    if (overlay) {
      this.viewService.openViewAsOverlayWithContext(viewId, 'Navigation_' + location);
      return;
    }
    const doubleClickOnMoreMenu = this.lastOpenedView === navigationViewId && viewId === navigationViewId;
    if (viewId === navigationViewId || this.lastViewFromMoreMenu && !viewId.equals(this.lastViewFromMoreMenu)) {
      this.lastOpenedView = viewId;
    }
    const existing = this.viewService.findView(viewId);
    if (!existing) {
      // Open last visited view from more menu

      if (doubleClickOnMoreMenu) {
        this.lastViewFromMoreMenu = null;
      }
      if (viewId === navigationViewId && this.lastViewFromMoreMenu) {
        this.viewService.openView(this.lastViewFromMoreMenu);
      } else {
        const cb = location === 'menu' ? 'onOpenByMenu' : 'onOpenByNav';
        this.viewService.openView(viewId.with(s => s[cb] ? s[cb]() : s), {
          locationUpdateMode: 'push',
          openContext: 'Navigation_' + location
        });
      }
    } else {
      // If a view is in side position, we have at least two columns,
      // which means we're closing the view.
      if (existing.laidoutViews.some(v => v.position === LayoutPosition.Side)) {
        this.viewService.closeView(viewId);
      } else {
        this.viewService.openView(viewId.with(s => {
          let ns = s;
          if (ns.onOpenByNav) {
            ns = ns.onOpenByNav();
          }
          if (ns.withOnlyRootViewOpened) {
            ns = ns.withOnlyRootViewOpened();
          }
          return ns;
        }), {
          openContext: 'Navigation_' + location
        });
      }
    }
  }

  /**
   * Gets the items displayed in the bottom navigation bar.
   */
  @computed
  private get bottomItems(): CombinedNavBarItemData[] {
    const items = this.getItems('menu');
    const MAX_BOTTOM_ITEMS = 5;
    if (items.length < MAX_BOTTOM_ITEMS) {
      return items;
    }
    const bottomItems: CombinedNavBarItemData[] = items.slice(0, MAX_BOTTOM_ITEMS - 1);
    if (this.getSelectedSlotType() === DYNAMIC_SLOT_TYPE && this.dynamicItemId && !bottomItems.find(i => i.id === this.dynamicItemId) && items.find(i => i.id === this.dynamicItemId)) {
      const actualItem = items.find(i => i.id === this.dynamicItemId);
      bottomItems.pop();
      bottomItems.push(actualItem);
    }
    if (this.getSelectedSlotType() !== DYNAMIC_SLOT_TYPE) {
      bottomItems.pop();
      const selectedSlotItem = items.find(i => this.isSelectedSlotType(i.id, i.title));
      if (selectedSlotItem) {
        bottomItems.push(selectedSlotItem);
      } else {
        const fallback = this.getFallbackBottomItem(items);
        if (fallback) {
          bottomItems.push(fallback);
        }
      }
    }
    const bottomItemTitles = bottomItems.map(i => i.id);
    const badgeItems = items.filter(i => !bottomItemTitles.includes(i.id));
    bottomItems.push({
      id: 'menu',
      kind: 'view',
      disableForDynamicSlot: true,
      title: formatMessage(declareFormat({
        id: 'NAVBAR_ITEM_MENU',
        defaultFormat: 'Menu'
      })),
      icon: IconMenu,
      view: navigationViewId,
      trackingId: 'Menu',
      ...this.getMoreBadge(badgeItems)
    });
    return bottomItems;
  }
  public isSelectedSlotType(id: string, title: string): boolean {
    return this.getSelectedSlotType().toLowerCase() === id.toLowerCase() || this.getSelectedSlotType().toLowerCase() === title.toLowerCase();
  }
  public getFallbackBottomItem<T extends {
    id?: string;
  }>(items: T[]): T {
    const fotomeet = items.find(i => i.id === 'fotomeet');
    if (fotomeet) {
      return fotomeet;
    }
    return items.find(i => i.id === 'engagementSystem');
  }
  private getMoreBadge(remainingItems: CombinedNavBarItemData[]): {
    badge: string | undefined;
    badgeColor?: string;
  } {
    const happyHour = this.promotionService.activeHappyHour;
    if (happyHour) {
      return {
        badge: '%',
        badgeColor: colorToRgbaString(happyHour.color)
      };
    } else {
      return {
        badge: getTotalBadgeCount(remainingItems.map(i => i.badge))
      };
    }
  }
  private async initMenuItemsFromLocalStorage(): Promise<void> {
    let lastItemCount = 0;
    const dispose = autorun({
      name: 'Check stored items'
    }, async () => {
      const items = this.getItems('menu');
      if (items.length === lastItemCount) {
        return;
      }
      lastItemCount = items.length;
      if (this.getSelectedSlotType() !== DYNAMIC_SLOT_TYPE) {
        return;
      }
      const slot = this.lastVisitedSlotEntry.get();
      if (slot) {
        const item = items.find(i => i.id === slot || i.title === slot);
        if (item) {
          runInAction('set dynamicItem', () => {
            this.dynamicItemId = item.id;
            dispose();
          });
        }
      }
    });
  }
  @action
  private updateDynamicItem(item: CombinedNavBarItemData): void {
    if (this.getSelectedSlotType() !== DYNAMIC_SLOT_TYPE || item.disableForDynamicSlot) {
      return;
    }
    const fixedItems = this.getBottomItems().slice(0, 3);
    if (fixedItems.find(i => i.id === item.id)) {
      return;
    }
    this.dynamicItemId = item.id;
    this.lastVisitedSlotEntry.set(item.id);
  }
  public setLastViewFromMoreMenu(view?: ViewId): void {
    this.lastViewFromMoreMenu = view;
  }
  public getBottomItems(): NormalizedNavBarItem[] {
    return this.bottomItems.map(i => this.createNormalizedItem(i, 'bottom'));
  }
  private getCategories(location: NavBarLocation): NavBarCategory[] {
    const menu = this.evergreenDataService.data.menu;
    const itemData = this.providers.flatMap(it => it.getItems(location)).filter(it => !!it.id);
    const byId = associate(itemData, it => it.id, it => it);
    return menu.categories.map(category => {
      return ({
        id: category.id,
        name: category.name,
        entries: category.entries.filter(entry => entry.type !== 'frontendProvided' || !!byId[entry.id]).map(entry => {
          if (entry.type === 'frontendProvided' && byId[entry.id]) {
            return {
              title: entry.name,
              ...byId[entry.id],
              icon: entry.icon
            };
          }
          const commonData = ({
            id: entry.id,
            title: entry.name,
            icon: entry.icon,
            badge: menu.badges[entry.id]
          } satisfies Partial<CombinedNavBarItemData>);
          if (entry.type === 'systemApp') {
            return ({
              ...commonData,
              kind: 'view',
              view: viewIdForSystemApp(entry.appId),
              trackingId: entry.appId
            } satisfies CombinedNavBarItemData);
          }
          if (entry.type === 'link') {
            return ({
              ...commonData,
              kind: 'link',
              trackingId: entry.name,
              url: entry.url
            } satisfies CombinedNavBarItemData);
          }
          if (entry.type === 'slashCommand') {
            if (entry.id.startsWith('globalAppsFav')) {
              return ({
                ...commonData,
                kind: 'favorite',
                trackingId: entry.name,
                parentView: globalAppViewId,
                onClick: async () => {
                  const appService = await this.getAppService();
                  await appService.executeSlashCommand(entry.command?.replace(/\$SID/g, K3_SIDEBAR_SID), 'nav-favorite-apps');
                }
              } satisfies CombinedNavBarItemData);
            }
            return ({
              ...commonData,
              kind: 'custom',
              trackingId: entry.id,
              onClick: () => {
                const call = this.commandService.tryParseCommandCall(entry.command);
                if (!call) {
                  return;
                }
                this.commandWithoutChannelService.invokeCommand(call.commandName, call.parameter);
              }
            } satisfies CombinedNavBarItemData);
          }
        }).filter(it => !!it)
      } satisfies NavBarCategory);
    });
  }
  public getNormalizedCategories(location: NavBarLocation): NormalizedNavBarCategory[] {
    return this.getCategories(location).map(category => {
      return ({
        id: category.id,
        name: category.name,
        entries: category.entries.map(entry => {
          return this.createNormalizedItem(entry, location);
        })
      } satisfies NormalizedNavBarCategory);
    });
  }
  private createNormalizedItem(item: CombinedNavBarItemData, location: 'left' | 'bottom' | 'menu'): NormalizedNavBarItem | null {
    switch (item.kind) {
      case 'custom':
        return {
          id: item.id,
          active: false,
          badge: getBadgeCount(item.badge),
          badgeColor: item.badgeColor,
          icon: item.icon,
          onClick: () => {
            this.trackItem(item.trackingId, location);
            item.onClick();
          },
          title: item.title,
          menuTitle: item.menuTitle
        };
      case 'view':
        return {
          id: item.id,
          active: this.isViewActive(item.view, location),
          badge: getBadgeCount(item.badge),
          badgeColor: item.badgeColor,
          icon: item.icon,
          onClick: () => {
            if (location === 'menu') {
              this.updateDynamicItem(item);
            }
            if (item.traceId) {
              this.firebaseAnalyticsService.startTrace(item.traceId);
            }
            this.trackItem(item.trackingId, location);
            if (item.onClick) {
              item.onClick();
            }
            this.openView(item.view, location, item.openAsOverlay);
          },
          title: item.title,
          menuTitle: item.menuTitle
        };
      case 'link':
        return {
          id: item.id,
          active: false,
          badge: getBadgeCount(item.badge),
          badgeColor: item.badgeColor,
          icon: item.icon,
          onClick: () => {
            this.trackItem(item.trackingId, location);
            if (item.onClick) {
              item.onClick();
            }
          },
          title: item.title,
          menuTitle: item.menuTitle,
          url: item.url
        };
      case 'favorite':
        return {
          id: item.id,
          active: typeof item.title === 'string' && this.isAppActive(item.title),
          badge: getBadgeCount(item.badge),
          badgeColor: item.badgeColor,
          icon: item.icon,
          onClick: () => {
            if (location === 'menu') {
              this.updateDynamicItem(item);
            }

            /**
             * if global app is already open, just switch to it,
             * without triggering the click event (which would cause a reload)
             */
            if (this.globalAppsAppViewer?.app?.title === item.title) {
              this.viewService.openView(this.globalAppsAppViewer.globalAppViewId);
              return;
            }
            this.trackItem(item.trackingId, location);
            if (item.onClick) {
              item.onClick();
            }
          },
          title: item.title,
          menuTitle: item.menuTitle
        };
      default:
        {
          expectUnreachable(item);
        }
    }
  }
  public getSelectedSlotType(): string {
    return this.clientSettingsService.navIconSlot ?? 'fotomeet';
  }
  @action
  public async setSelectedSlotType(slotType: string): Promise<void> {
    this.clientSettingsService.setNavIconSlot(slotType);
  }
}
export interface NavBarItemProvider {
  getItems(navBarLocation: NavBarLocation): NavBarItemData[];
}
export type NavBarLocation = 'left' | 'menu';

/**
 * A normalized description of a nav bar item.
 */
export interface NormalizedNavBarItem {
  id: string;
  active: boolean;
  onClick: () => void;
  url?: string;
  title: string | JSX.Element;
  menuTitle?: string;
  badgeColor?: string;
  badge?: string;
  icon: string | React.ComponentType<any>;
}
export type CombinedNavBarItemData = NavBarItemData & {
  title: string;
  icon: string | React.ComponentType<any>;
};
export type NavBarCategory = {
  id: string;
  name: string;
  entries: CombinedNavBarItemData[];
};
export type NormalizedNavBarCategory = {
  id: string;
  name: string;
  entries: NormalizedNavBarItem[];
};
// tslint:disable-next-line: max-file-line-count