import { declareProps, injectedComponent, useService } from '@knuddels-app/DependencyInjection';
import { $KeyboardService } from '@knuddels-app/Keyboard';
import { observer } from '@knuddels-app/mobx';
import { useSafeAreaPaddingBottom } from '@knuddels-app/overlays/InsideModalContext';
import { $ScreenService } from '@knuddels-app/Screen';
import { Flex, FlexCol, IconCloseCircular, TitleBar, resolveThemingValue, useTheme, resolveIsDarkColor } from '@knuddels/component-library';
import { $ModalAppViewer } from '@knuddelsModules/Apps';
import { ModalWrapper, ModalWrapperProps } from '@shared/components';
import { KeyboardAvoidanceView } from '@shared/components/KeyboardAvoidanceView';
import * as React from 'react';
import { AppInstance } from '../services/AppInstance';
import { AppsWebview } from './AppsWebview';
const ModalAppWrapper: React.FC<React.PropsWithChildren<{
  stacked: boolean;
  app: AppInstance;
  onClose: () => void;
}>> = observer('ModalAppWrapper', ({
  app,
  onClose,
  children,
  stacked
}) => {
  const safeAreaPaddingBottom = useSafeAreaPaddingBottom();
  const wrapperProps: ModalWrapperProps = stacked ? {
    fillHeight: true,
    renderNativeModal: false,
    disablePadding: true,
    maxWidth: '100%',
    cancelModal: onClose
  } : {
    fillHeight: true,
    maxWidth: '100%',
    cancelModal: onClose
  };
  return <ModalWrapper {...wrapperProps}>
			<div style={{
      width: resolveThemingValue(stacked ? '100%' : undefined, "sizes", useTheme()),
      alignSelf: resolveThemingValue(!stacked ? 'center' : undefined, "theme", useTheme()),
      maxWidth: resolveThemingValue(!stacked ? '90%' : undefined, "sizes", useTheme()),
      maxHeight: resolveThemingValue(!stacked ? '90%' : undefined, "sizes", useTheme())
    }} className={_c0 + ((stacked ? "contentBg" : "transparent") ? resolveIsDarkColor(stacked ? "contentBg" : "transparent", useTheme()) ? " content-is-dark" : " content-is-light" : "") + (stacked ? _c1 : _c2) + (!stacked ? _c3 : _c4)}>
				<div style={{
        width: resolveThemingValue(stacked ? '100%' : app.displayWidth, "sizes", useTheme()),
        height: resolveThemingValue(stacked ? '100%' : undefined, "sizes", useTheme()),
        maxHeight: resolveThemingValue(stacked ? '100%' : app.displayHeight, "sizes", useTheme())
      }} className={_c5 + (!stacked ? _c6 : _c7) + (stacked ? _c8 : _c9)}>
					{children}
				</div>
			</div>
			<div style={{
      height: resolveThemingValue(safeAreaPaddingBottom, "sizes", useTheme())
    }} className={_c10} />
		</ModalWrapper>;
});
export const ModalAppView = injectedComponent({
  name: 'ModalAppView',
  props: declareProps<{
    appId: string;
    uniqueId: string;
    showTitleBar: boolean;
  }>(),
  inject: {
    screenService: $ScreenService,
    modalAppViewer: $ModalAppViewer
  }
}, ({
  screenService,
  modalAppViewer,
  showTitleBar,
  appId
}) => {
  const keyboardService = useService($KeyboardService);
  React.useLayoutEffect(() => {
    keyboardService.closeAll();
  }, []);
  const app = modalAppViewer.getApp(appId);
  React.useEffect(() => {
    if (!app) {
      modalAppViewer.closeApp(appId);
    }
  }, [app]);
  if (!app) {
    return null;
  }
  const closeApp = () => {
    modalAppViewer.closeApp(appId);
  };
  return <ModalAppWrapper app={app} onClose={closeApp} stacked={screenService.isStackedLayout}>
				{showTitleBar && <TitleBar title={app.title} navIcon={<IconCloseCircular size={'large'} onPress={closeApp} />} fillBackground />}
				<KeyboardAvoidanceView type={'shrink'}>
					<AppsWebview appInstance={app} />
				</KeyboardAvoidanceView>
			</ModalAppWrapper>;
});
const _c0 = " Knu-FlexCol zIndex-1 position-relative height-100-percent justifyContent-center overflow-hidden pointerEvents-none ";
const _c1 = " bg-contentBg ";
const _c2 = " bg-transparent ";
const _c3 = " borderRadius-base ";
const _c4 = " borderRadius-none ";
const _c5 = " Knu-FlexCol position-relative overflow-hidden flex-1 pointerEvents-all ";
const _c6 = " borderRadius-base ";
const _c7 = " borderRadius-none ";
const _c8 = " shadow-none ";
const _c9 = " shadow-Shadow4 ";
const _c10 = " Knu-Flex ";