import * as React from 'react';
import { AcceptFriendRequest, DeclineFriendRequest, FriendRequestUserFragment, Gender } from '@generated/graphql';
import { UserImage, UserImageType } from '@knuddelsModules/UserData';
import { Spacer } from '@shared/components';
import { createAccessiblePointerEventHandler, Fade, Flex, FlexCol, Image, Text, TranslateX, resolveThemingValue, useTheme, FallbackBox, createNativeAccessiblePointerEventHandler, resolveIsDarkColor } from '@knuddels/component-library';
import { GenderIcon } from '@knuddels-app/shared-components/GenderIcon';
import { declareFormat, FormattedMessage } from '@knuddels-app/i18n';
import { useMutation } from '@knuddels-app/Connection';
import { useService } from '@knuddels-app/DependencyInjection';
import { $SnackbarService } from '@knuddels-app/SnackbarManager';
import { AcceptRequestButton } from './AcceptRequestButton';
import { DeclineRequestButton } from './DeclineRequestButton';
import { Confettis } from './Confettis';
import { ExpandingBackgroundCircle } from './ExpandingBackgroundCircle';
import { useOpenProfile } from '@knuddelsModules/Profile';
import { $SoundService, SoundEvent } from '@knuddelsModules/Sound';
import { useTrackFriendRequestsView } from './useTrackFriendRequestsView';
const GREEN_COLOR = ('rgb(71,209,179)' as any);
export const ReceivedFriendRequest: React.FC<{
  user: FriendRequestUserFragment;
  isAccepted: boolean;
  onAccepted: (user: FriendRequestUserFragment) => void;
  onAcceptError: (user: FriendRequestUserFragment) => void;
  hideDeclineButton?: boolean;
  onDeclined?: () => void;
}> = ({
  user,
  isAccepted,
  onAccepted,
  onAcceptError,
  hideDeclineButton,
  onDeclined
}) => {
  const [{
    width,
    height
  }, setSize] = React.useState({
    width: 0,
    height: 0
  });
  const mountedAccepted = React.useRef(isAccepted);
  const track = useTrackFriendRequestsView();
  const openProfile = useOpenProfile(user, () => track('ReceivedRequest_ProfileOpened'));
  return <div className={_c0}>
			<div style={{
      background: resolveThemingValue(mountedAccepted.current ? GREEN_COLOR : 'contentLightBg', "colors", useTheme())
    }} className={_c1 + ((mountedAccepted.current ? GREEN_COLOR : 'contentLightBg') ? resolveIsDarkColor(mountedAccepted.current ? GREEN_COLOR : 'contentLightBg', useTheme()) ? " content-is-dark" : " content-is-light" : "")}>
				<FallbackBox onLayout={e => setSize(e)} className={_c2}>
					<ExpandingBackgroundCircle active={isAccepted} size={Math.max(width, height)} color={GREEN_COLOR} />
					<div className={_c3}>
						<Fade visible={!isAccepted} delay={250} className={_c4}>
							<NotAcceptedTextContent user={user} />
						</Fade>
						<Spacer size={'large'} />
						{!mountedAccepted.current && <Buttons width={width} user={user} isAccepted={isAccepted} onAccepted={() => onAccepted(user)} onError={() => onAcceptError(user)} onDeclined={onDeclined} hideDeclineButton={hideDeclineButton} />}
					</div>
				</FallbackBox>
				<div className={_c5}>
					<div {...createNativeAccessiblePointerEventHandler({
          pointerCallback: () => openProfile('ReceivedFriendRequest'),
          disabled: isAccepted
        })} className={_c6}>
						<Fade visible={!isAccepted} delay={250} className={_c7}>
							<UserImage type={UserImageType.RECEIVED_FRIEND_REQUEST} userId={user.id} />
						</Fade>
					</div>
				</div>
				<div className={_c8}>
					<Fade visible={isAccepted} delay={150} className={_c9}>
						<AcceptedTextContent user={user} />
					</Fade>
				</div>
				<Confettis active={isAccepted} />
			</div>
		</div>;
};
const useDeclineRequest = (userId: string, onDecline?: () => void) => {
  const [decline] = useMutation(DeclineFriendRequest);
  const snackbarService = useService($SnackbarService);
  return () => {
    if (onDecline) {
      onDecline();
    }
    decline({
      userId
    }).then(result => {
      if (result.data?.__typename !== 'Success') {
        snackbarService.showGenericError();
      }
    });
  };
};
const useAcceptRequest = (userId: string, onAccepted: () => void, onFail: () => void) => {
  const [accept] = useMutation(AcceptFriendRequest);
  const snackbarService = useService($SnackbarService);
  const soundService = useService($SoundService);
  return () => {
    onAccepted();
    soundService.playSound(SoundEvent.FriendRequestAccepted);
    accept({
      userId
    }).then(result => {
      if (result.data?.__typename !== 'Success') {
        snackbarService.showGenericError();
        onFail();
      }
    });
  };
};
const Buttons: React.FC<{
  isAccepted: boolean;
  width: number;
  user: FriendRequestUserFragment;
  hideDeclineButton: boolean;
  onAccepted: () => void;
  onDeclined?: () => void;
  onError: () => void;
}> = ({
  isAccepted,
  width,
  user,
  onAccepted,
  onError,
  onDeclined,
  hideDeclineButton
}) => {
  const acceptRequest = useAcceptRequest(user.id, onAccepted, onError);
  const declineRequest = useDeclineRequest(user.id, onDeclined);
  return <div className={_c10}>
			{!hideDeclineButton && <>
					<TranslateX translated={isAccepted} from={0} to={-width / 2} delay={250}>
						<DeclineRequestButton onPress={declineRequest} />
					</TranslateX>
					<Spacer size={'small'} />
				</>}
			<Fade visible={!isAccepted} delay={250} className={_c11}>
				<AcceptRequestButton disabled={isAccepted} onPress={acceptRequest} />
			</Fade>
		</div>;
};
const NotAcceptedTextContent: React.FC<{
  user: FriendRequestUserFragment;
}> = ({
  user
}) => {
  return <div className={_c12}>
			<div className={_c13}>
				<Text type={'title'} bold={true} state={'primary'} numberOfLines={1} className={_c14}>
					{user.nick}
				</Text>
				<Spacer size={'small'} />
				{user.gender !== Gender.Unknown && <GenderIcon gender={user.gender} />}
				<Spacer size={'tiny'} />
				{typeof user.age === 'number' && <Text type={'body1'} state={'tertiary'} className={_c15}>
						{user.age}
					</Text>}
			</div>
			<Spacer size={'tiny'} />
			<Text state={'tertiary'} numberOfLines={1} className={_c16}>
				<FormattedMessage id={declareFormat({
        id: 'friendrequests.received.subtext',
        defaultFormat: 'would like to be your friend.'
      })} />
			</Text>
		</div>;
};
const AcceptedTextContent: React.FC<{
  user: FriendRequestUserFragment;
}> = ({
  user
}) => {
  return <div className={_c17}>
			<Image src={require('@shared/images/sm_xmas_17_knallbonbon-ani.gif')} alt={''} className={_c18} />
			<Spacer size={'small'} />
			{/* Color with 0 opacity is there to force the text to have the correct color */}
			<div className={_c19 + ("rgba(71,209,179,0.0)" ? resolveIsDarkColor("rgba(71,209,179,0.0)", useTheme()) ? " content-is-dark" : " content-is-light" : "")}>
				<Text type={'title'} bold={true} state={'primary'} numberOfLines={1} className={_c20}>
					<FormattedMessage id={declareFormat({
          id: 'friendrequests.accepted.title',
          defaultFormat: "Here's to your friendship!"
        })} />
				</Text>
				<Spacer size={'tiny'} />
				<Text state={'primary'} className={_c21}>
					<FormattedMessage id={declareFormat({
          id: 'friendrequests.accepted.subtext',
          defaultFormat: '{nick} and you are now befriended.'
        })} values={{
          nick: user.nick
        }} />
				</Text>
			</div>
		</div>;
};
const _c0 = " Knu-FlexCol alignItems-center ";
const _c1 = " Knu-FlexCol position-relative overflow-visible shadow-Shadow2 borderRadius-large mt-28px mx-xlarge maxWidth-288px minWidth-260px height-188px ";
const _c2 = " Knu-FlexCol px-xlarge overflow-hidden position-relative borderRadius-large ";
const _c3 = " Knu-FlexCol mt-xxxlarge mb-base position-relative flex-1 alignItems-center ";
const _c4 = "  ";
const _c5 = " Knu-Flex position-absolute left-none right-none justifyContent-center zIndex-1 ";
const _c6 = " Knu-FlexCol cursor-pointer mt--28px ";
const _c7 = "  ";
const _c8 = " Knu-FlexCol position-absolute left-none right-none placeItems-center pt-large pointerEvents-none ";
const _c9 = "  ";
const _c10 = " Knu-Flex alignSelf-stretch justifyContent-center ";
const _c11 = "  ";
const _c12 = " Knu-FlexCol alignItems-center textAlign-center ";
const _c13 = " Knu-Flex alignItems-center ";
const _c14 = "  ";
const _c15 = "  ";
const _c16 = "  ";
const _c17 = " Knu-FlexCol placeItems-center textAlign-center ";
const _c18 = " width-91px height-38px ";
const _c19 = " Knu-FlexCol px-xlarge bg-rgba-71-209-179-0-0- ";
const _c20 = "  ";
const _c21 = " textAlign-center ";