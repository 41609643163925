import { color as u } from "../color/index.js";
import { colorRegex as g } from "../utils/color-regex.js";
import { floatRegex as N } from "../utils/float-regex.js";
import { sanitize as b } from "../utils/sanitize.js";
function x(t) {
  var e, o;
  return isNaN(t) && typeof t == "string" && (((e = t.match(N)) === null || e === void 0 ? void 0 : e.length) || 0) + (((o = t.match(g)) === null || o === void 0 ? void 0 : o.length) || 0) > 0;
}
const p = "number", m = "color", T = "var", O = "var(", a = "${}", _ = /var\s*\(\s*--(?:[\w-]+\s*|[\w-]+\s*,(?:\s*[^)(\s]|\s*\((?:[^)(]|\([^)(]*\))*\))+\s*)\)|#[\da-f]{3,8}|(?:rgb|hsl)a?\((?:-?[\d.]+%?[,\s]+){2}-?[\d.]+%?\s*(?:[,/]\s*)?(?:\b\d+(?:\.\d+)?|\.\d+)?%?\)|-?(?:\d+(?:\.\d+)?|\.\d+)/giu;
function f(t) {
  const e = t.toString(), o = [], i = {
    color: [],
    number: [],
    var: []
  }, n = [];
  let s = 0;
  const l = e.replace(_, (c) => (u.test(c) ? (i.color.push(s), n.push(m), o.push(u.parse(c))) : c.startsWith(O) ? (i.var.push(s), n.push(T), o.push(c)) : (i.number.push(s), n.push(p), o.push(parseFloat(c))), ++s, a)).split(a);
  return { values: o, split: l, indexes: i, types: n };
}
function h(t) {
  return f(t).values;
}
function d(t) {
  const { split: e, types: o } = f(t), i = e.length;
  return (n) => {
    let s = "";
    for (let r = 0; r < i; r++)
      if (s += e[r], n[r] !== void 0) {
        const l = o[r];
        l === p ? s += b(n[r]) : l === m ? s += u.transform(n[r]) : s += n[r];
      }
    return s;
  };
}
const R = (t) => typeof t == "number" ? 0 : t;
function y(t) {
  const e = h(t);
  return d(t)(e.map(R));
}
const A = {
  test: x,
  parse: h,
  createTransformer: d,
  getAnimatableNone: y
};
export {
  f as analyseComplexValue,
  A as complex
};
