import * as React from 'react';
import { useService } from '@knuddels-app/DependencyInjection';
import { $I18n, declareFormat } from '@knuddels-app/i18n';
import { PanelsList, Tab, Tabs, TabsList, TabText } from '@knuddels/component-library';
import { useLogEvent } from '@knuddels-app/analytics/firebase/useLogEvent';
import { $GenericUserEventService } from '@knuddels-app/analytics/generic';
const ActiveTabContext = React.createContext(0);
export const useActiveTabIndex = () => React.useContext(ActiveTabContext);
export const ChannelSelectionTabs: React.FC<{
  children?: React.ReactNode;
}> = ({
  children
}) => {
  const {
    activeIndex,
    onIndexChange
  } = useActiveTab();
  const i18n = useService($I18n);
  return <ActiveTabContext.Provider value={activeIndex}>
			<Tabs value={activeIndex} onChange={onIndexChange} mountingType={'keepInactive'}>
				<TabsList>
					<Tab className={_c0}>
						<TabText>
							{declareFormat({
              id: 'channellist.tabs.recommended',
              defaultFormat: 'Recommended'
            }).format(i18n)}
						</TabText>
					</Tab>
					<Tab className={_c1}>
						<TabText>
							{declareFormat({
              id: 'channellist.tabs.games',
              defaultFormat: 'Games'
            }).format(i18n)}
						</TabText>
					</Tab>
					<Tab className={_c2}>
						<TabText>
							{declareFormat({
              id: 'channellist.tabs.categories',
              defaultFormat: 'Categories'
            }).format(i18n)}
						</TabText>
					</Tab>
				</TabsList>
				<PanelsList>{children}</PanelsList>
			</Tabs>
		</ActiveTabContext.Provider>;
};
let initialIndex = 0;
const useActiveTab = () => {
  const [activeIndex, setActiveIndex] = React.useState(initialIndex);
  const genericUserEventService = useService($GenericUserEventService);
  const track = useLogEvent();
  return {
    activeIndex,
    onIndexChange: (index: number) => {
      let tabName = '';
      switch (index) {
        case 0:
          tabName = 'recommended';
          break;
        case 1:
          tabName = 'games';
          break;
        case 2:
          tabName = 'categories';
          break;
        default:
          throw new Error(`Unknown tab index: ${index}`);
      }
      initialIndex = index;
      setActiveIndex(index);
      genericUserEventService.reportEvent({
        type: 'ChannelOverview_TabChanged',
        tab: tabName
      });
      track('ChannelSelection_Navigation', `Tabbar_SwitchTo_${tabName}`);
    }
  };
};
const _c0 = "  ";
const _c1 = "  ";
const _c2 = "  ";