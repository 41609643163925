import { Flex, FlexCol, resolveThemingValue, useTheme } from '@knuddels/component-library';
import * as React from 'react';
import { FormattedTextDisplay, FormattedTextDisplayProps, FormattedTextTable } from '@shared/components';
export const Table: React.FC<{
  passProps: FormattedTextDisplayProps;
  table: FormattedTextTable;
}> = ({
  passProps,
  table
}) => {
  return <div className={_c0}>
			{table.rows.map((row, rowIndex) => <div key={rowIndex} className={_c1}>
					{table.columns.map((column, index) => <div key={index} style={{
        width: resolveThemingValue('width' in column ? column.width : undefined, "sizes", useTheme()),
        flex: resolveThemingValue('weight' in column ? column.weight : undefined, "theme", useTheme()),
        minWidth: resolveThemingValue(column.minWidth, "sizes", useTheme()),
        maxWidth: resolveThemingValue(column.maxWidth, "sizes", useTheme())
      }} className={_c2}>
							{row[index] && <FormattedTextDisplay {...passProps} prefix={undefined} suffix={undefined} placeholderTime={undefined} containerProps={undefined} isParentImageBox={false} text={row[index]} />}
						</div>)}
				</div>)}
		</div>;
};
const _c0 = " Knu-FlexCol ";
const _c1 = " Knu-Flex ";
const _c2 = " Knu-FlexCol position-relative ";