import { Keyboard } from '@capacitor/keyboard';
import { IModel, inject, injectable, injectedComponent, injectProps, useService } from '@knuddels-app/DependencyInjection';
import { $Environment } from '@knuddels-app/Environment';
import { $KeyboardService } from '@knuddels-app/Keyboard';
import { LayoutView } from '@knuddels-app/layout';
import { LoadService } from '@knuddels-app/ModuleSystem';
import { IPhoneXWrapper } from '@knuddels-app/NativeBackground';
import { OverlayPresenter } from '@knuddels-app/overlays/OverlayPresenter';
import { isNative } from '@knuddels-app/tools/isNative';
import { $AppService, SystemAppOverlayPresenter } from '@knuddelsModules/Apps';
import { NotificationBarProvider } from '@knuddelsModules/Notifications';
import { dismissKeyboard } from '@shared/helper/dismissKeyboard';
import * as React from 'react';
import { Nav } from './NavBar';
import { useTrackNetworkCondition } from './useTrackNetworkCondition';
@injectable()
class LoggedInViewModel implements IModel {
  constructor(@injectProps()
  private readonly props: unknown, @inject($Environment)
  private readonly environment: typeof $Environment.T) {}
  componentDidMount(): void {
    const stappmessenger = this.environment.messengerSystemAppInterface;
    if (stappmessenger) {
      // this is necessary for the system app to remove the loading screen.
      stappmessenger.client.triggerAppReadiness();
    }
  }
}
export const LoggedInView = injectedComponent({
  name: 'LoggedInView',
  model: LoggedInViewModel
}, () => {
  useTrackNetworkCondition();
  const keyboardService = useService($KeyboardService);
  React.useEffect(() => {
    keyboardService.setViewNotResizing();
    /**
     * dismiss any pending keyboard state when the app is opened
     */
    if (isNative()) {
      Keyboard.hide();
    }
    dismissKeyboard();
  }, []);
  return <IPhoneXWrapper>
				<NotificationBarProvider>
					{globalEnv.product === 'stapp-messenger' ? <LayoutView /> : <Nav>
							<LayoutView />
						</Nav>}
					{/*
      It is important that the `OverlayPresenter` for the logged in view is within the `IPhoneXWrapper`!
      The logged out view has no `IPhoneXWrapper` (for UI reasons).
      This is why we cannot put the `OverlayPresenter` in the `App` component.
      */}
					<OverlayPresenter />
					{globalEnv.product !== 'stapp-messenger' ? <LoadService service={$AppService}>
							{() => <SystemAppOverlayPresenter />}
						</LoadService> : null}
				</NotificationBarProvider>
			</IPhoneXWrapper>;
});