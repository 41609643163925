import {
	NativeFilePicker,
	requestPicturePermissions,
	SelectFileResult,
} from '@knuddels-app/tools/filePicking';
import { sendImageEvent } from '../../../analytics';
import { UploadImageLightboxSource } from '../Lightbox/Lightbox';
import { $MessengerImageService } from '@knuddelsModules/Messenger/providedServices';
import { User } from '@generated/graphql';
import { ClientConversationState } from '../../../services/conversationServices/MessengerConversationService';
import { $SnackbarService } from '@knuddels-app/SnackbarManager';
import { declareFormat } from '@knuddels/i18n';
import { $I18n } from '@knuddels-app/i18n';
import { $GenericUserEventService } from '@knuddels-app/analytics/generic';

export class InputBarImageSenderHelper {
	private filePicker = new NativeFilePicker();

	constructor(
		private readonly messengerImageService: typeof $MessengerImageService.T,
		private readonly clientConversationState: ClientConversationState,
		private readonly snackbarService: typeof $SnackbarService.T,
		private readonly i18n: typeof $I18n.T,
		private readonly setLightboxSrc: (
			src: UploadImageLightboxSource
		) => void,
		private readonly otherParticipants: () => ReadonlyArray<
			Pick<User, 'nick'>
		>,
		private readonly genericUserEventService: typeof $GenericUserEventService.T
	) {}

	public readonly selectPicture = (
		context: 'messenger' | 'mini-messenger'
	): void => {
		requestPicturePermissions().then(result => {
			if (result === 'yes') {
				this.filePicker.select(fileResults => {
					sendImageEvent.track('SendImage_ImageSelected');

					this.setLightboxSrc({
						type: 'upload',
						imageUrl: fileResults[0].image,
						sendImage: (expirationInSeconds: number) =>
							this.sendImage(
								fileResults[0],
								context,
								expirationInSeconds
							),
						onClose: () => sendImageEvent.track('SendImage_Cancel'),
					});
				});
			} else {
				this.snackbarService.showErrorSnackbarWithDefaults({
					text: declareFormat({
						id: 'messenger.send-image.no-permission',
						defaultFormat:
							'You have to grant the permission for your camera to send images.',
					}).format(this.i18n),
					subtext: undefined,
				});
			}
		});
	};

	private readonly sendImage = (
		result: SelectFileResult,
		context: 'messenger' | 'mini-messenger',
		expirationInSeconds?: number
	): void => {
		if (!expirationInSeconds) {
			sendImageEvent.track('SendImage_SendAsImage');
		} else if (expirationInSeconds === 3) {
			sendImageEvent.track('SendImage_SendAsSnap3Seconds');
		} else if (expirationInSeconds === 10) {
			sendImageEvent.track('SendImage_SendAsSnap10Seconds');
		} else if (expirationInSeconds === 60) {
			sendImageEvent.track('SendImage_SendAsSnap60Seconds');
		}

		this.setLightboxSrc(undefined);
		this.clientConversationState.setSendingImage({
			image: result.image,
			timestamp: Date.now(),
		});
		this.messengerImageService
			.sendImage(
				result.uploadObject,
				this.clientConversationState.conversationId,
				this.otherParticipants()[0],
				expirationInSeconds
			)
			.then(r => {
				if (r) {
					this.genericUserEventService.reportEvent({
						type: 'Sent_Image',
						recipient: this.otherParticipants()[0].nick,
						context,
					});
				}
				if (!r) {
					this.clientConversationState.setSendingImage(undefined);
				}
			});
	};
}
