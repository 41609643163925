import { parseImageGroup } from './parseImageGroup';
import { shallowEquals } from '../../tools';
import { TextStates } from '../../atoms/Texts/TextProps';
import { ImageGroupData } from './ImageGroupData';
import { rgb } from '@knuddels/component-library';
import { parseImageBox } from '@shared/components/molecules/FormattedText/parseImageBox';

export type FormattedText =
	| FormattedTextText
	| FormattedTextLink
	| FormattedTextList
	| FormattedTextSmiley
	| FormattedTextTable
	| FormattedTextImageBox;

export type Alignment = 'LEFT' | 'RIGHT' | 'CENTER' | 'JUSTIFY';

interface FormattedTextCommon {
	readonly containsImages: boolean;
}

export class FormattedTextText implements FormattedTextCommon {
	readonly kind = 'Text';
	readonly containsImages = false;

	constructor(
		public readonly text: string,
		// TODO Tech debt: remove state here again if we can improve handling the CommunicationRequestBox text.
		//  (this state was only introduced for this use case)
		public readonly style: {
			bold: boolean;
			italic: boolean;
			state?: TextStates;
			indented?: boolean;
			color?: string;
			alignment?: Alignment;
		} = { bold: false, italic: false }
	) {}
}

export class FormattedTextLink implements FormattedTextCommon {
	readonly kind = 'Link';
	readonly containsImages = false;

	constructor(
		public readonly href: string,
		public readonly text: string,
		public readonly style: {
			bold: boolean;
			italic: boolean;
			indented?: boolean;
			color?: string;
			alignment?: Alignment;
		} = {
			bold: false,
			italic: false,
		}
	) {}
}

export class FormattedTextList implements FormattedTextCommon {
	readonly kind = 'List';
	readonly containsImages: boolean;

	constructor(public items: FormattedText[]) {
		this.containsImages = items.some(item => item.containsImages);
	}
}

export class FormattedTextSmiley implements FormattedTextCommon {
	readonly kind = 'Smiley';
	readonly containsImages = true;

	constructor(
		public data: ImageGroupData,
		public indented = false,
		public alignment: Alignment | undefined = undefined
	) {}
}

export class FormattedTextTable implements FormattedTextCommon {
	readonly kind = 'Table';
	readonly containsImages: boolean;
	constructor(
		public columns: {
			width?: number;
			weight?: number;
			minWidth?: number;
			maxWidth?: number;
		}[],
		public rows: FormattedText[][]
	) {
		this.containsImages = rows.some(row =>
			row.some(cell => cell.containsImages)
		);
	}
}

export class FormattedTextImageBox implements FormattedTextCommon {
	readonly kind = 'ImageBox';
	readonly containsImages: boolean;

	constructor(
		public data: ImageBoxData,
		public content: FormattedText
	) {
		this.containsImages = content.containsImages;
	}
}

export type ImageBoxSizes = {
	topCenter?: { width: number; height: number };
	topLeft?: { width: number; height: number };
	topRight?: { width: number; height: number };
	centerRight?: { width: number; height: number };
	centerCenter?: { width: number; height: number };
	centerLeft?: { width: number; height: number };
	bottomCenter?: { width: number; height: number };
	bottomLeft?: { width: number; height: number };
	bottomRight?: { width: number; height: number };
};

export interface ImageBoxData {
	path?: string;
	urls: {
		topCenter: string;
		topLeft: string;
		topRight: string;
		centerRight: string;
		centerCenter: string;
		centerLeft: string;
		bottomCenter: string;
		bottomLeft: string;
		bottomRight: string;
	};
	imageSizes: Promise<ImageBoxSizes>;
}

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace FormattedText {
	export function isFormattedText(text: any): text is FormattedText {
		if (text instanceof FormattedTextText) {
			return true;
		}
		if (text instanceof FormattedTextLink) {
			return true;
		}
		if (text instanceof FormattedTextList) {
			return true;
		}
		if (text instanceof FormattedTextSmiley) {
			return true;
		}
		if (text instanceof FormattedTextTable) {
			return true;
		}
		if (text instanceof FormattedTextImageBox) {
			return true;
		}
		return false;
	}

	export function asText(
		text: FormattedText,
		includeSmileyPlaceholder = true
	): string {
		if (text.kind === 'Text') {
			return text.text;
		}
		if (text.kind === 'Link') {
			return text.text;
		}
		if (text.kind === 'Smiley') {
			return includeSmileyPlaceholder ? '[Smiley]' : '';
		}
		if (text.kind === 'List') {
			return text.items
				.map(item => asText(item, includeSmileyPlaceholder))
				.join('');
		}
		if (text.kind === 'Table') {
			return text.rows
				.map(row =>
					row
						.map(cell => asText(cell, includeSmileyPlaceholder))
						.join('')
				)
				.join('\n');
		}

		if (text.kind === 'ImageBox') {
			return asText(text.content, includeSmileyPlaceholder);
		}
		return '';
	}

	export function getFirstLine(text: FormattedText): string {
		return asText(text).split('\n')[0];
	}

	export function isEmpty(text: FormattedText): boolean {
		return asText(text).length === 0;
	}

	// eslint-disable-next-line no-inner-declarations
	function haveSameStyle(
		text1: FormattedTextText,
		text2: FormattedTextText
	): boolean {
		return shallowEquals(text1.style, text2.style);
	}

	const cache = new Map<string, FormattedText>();

	export function fromJsonString(jsonString: string): FormattedText {
		if (cache.has(jsonString)) {
			return cache.get(jsonString)!;
		}

		const r = fromJson(JSON.parse(jsonString));
		if (cache.size > 1000) {
			// This can be optimized, e.g. by using an LRU cache.
			// However, this heuristic should do the job.
			cache.clear();
		}

		cache.set(jsonString, r);
		return r;
	}

	export function empty(): FormattedText {
		return new FormattedTextText('');
	}

	export function fromJson(formattedTextJson: {
		[key: string]: any;
	}): FormattedText {
		if (formattedTextJson.text) {
			const text = formattedTextJson.text;
			return new FormattedTextText(text.text, {
				bold: text.bold,
				italic: text.italic,
				indented: !!formattedTextJson.lineIndent,
				color: text.color
					? rgb(text.color.red, text.color.green, text.color.blue)
					: undefined,
				alignment: text.alignment,
			});
		}
		if (formattedTextJson.link) {
			const link = formattedTextJson.link;
			return new FormattedTextLink(link.href, link.text, {
				bold: link.bold,
				italic: link.italic,
				indented: !!formattedTextJson.lineIndent,
				color: link.color
					? rgb(link.color.red, link.color.green, link.color.blue)
					: undefined,
				alignment: link.alignment,
			});
		}
		if (formattedTextJson.list) {
			if (!formattedTextJson.list.items) {
				return new FormattedTextList([]);
			}

			const items = Array<FormattedText>();
			for (const i of formattedTextJson.list.items) {
				const transformed = fromJson(i);

				if (items.length > 0 && transformed.kind === 'Text') {
					const last = items[items.length - 1];
					// merge consecutive text elements that have the same style
					if (
						last.kind === 'Text' &&
						haveSameStyle(transformed, last)
					) {
						items[items.length - 1] = new FormattedTextText(
							last.text + transformed.text,
							last.style
						);
						continue;
					}
				}
				items.push(transformed);
			}

			if (items.length === 1) {
				return items[0];
			}
			return new FormattedTextList(items);
		}
		if (formattedTextJson.smiley) {
			const smiley = formattedTextJson.smiley;
			const imageGroupData = parseImageGroup(smiley.json);
			if (!imageGroupData) {
				return new FormattedTextText('');
			}
			return new FormattedTextSmiley(
				imageGroupData,
				!!formattedTextJson.lineIndent,
				smiley.alignment
			);
		}

		if (formattedTextJson.table) {
			const table = formattedTextJson.table;

			const rows = table.rows.map((row: any) => {
				return row.map((cell: any) => {
					return fromJson(cell);
				});
			});

			return new FormattedTextTable(table.columns, rows);
		}

		if (formattedTextJson.imageBox) {
			const imageBox = formattedTextJson.imageBox;

			return new FormattedTextImageBox(
				parseImageBox(imageBox.imageId, JSON.parse(imageBox.json)),
				fromJson(imageBox.content)
			);
		}

		console.warn('Unknown formatted text', formattedTextJson);
		return new FormattedTextText('');
	}
}
