import * as React from 'react';
import {
	FormattedText,
	FormattedTextList,
	FormattedTextText,
} from '@shared/components';
import { useService } from '@knuddels-app/DependencyInjection';
import { $I18n, declareFormat } from '@knuddels-app/i18n';
import { ConversationVisiblePhotoCommentMessageContent } from '@generated/graphql';
import { $UserService } from '@knuddelsModules/UserData';

export const usePhotoCommentText = (
	content: ConversationVisiblePhotoCommentMessageContent,
	senderId: string,
	otherNick: string
) => {
	const i18n = useService($I18n);
	const userService = useService($UserService);

	const format = userService.isCurrentUser(senderId)
		? declareFormat({
				id: 'channel.message.photocomment.sent',
				defaultFormat: 'You commented on a photo of {nick}: ',
			})
		: declareFormat({
				id: 'channel.message.photocomment.received',
				defaultFormat: '{nick} commented on a photo of yours: ',
			});

	const formattedText = content.formattedText;
	return React.useMemo(() => {
		return new FormattedTextList([
			new FormattedTextText(i18n.format(format, { nick: otherNick })),
			new FormattedTextText('"'),
			FormattedText.fromJsonString(formattedText),
			new FormattedTextText('"'),
		]);
	}, [formattedText]);
};
