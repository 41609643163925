import { isBezierDefinition as u } from "../../../easing/utils/is-bezier-definition.js";
import { generateLinearEasing as p } from "./utils/linear.js";
import { supportsLinearEasing as n } from "./utils/supports-linear-easing.js";
function s(e) {
  return !!(typeof e == "function" && n() || !e || typeof e == "string" && (e in o || n()) || u(e) || Array.isArray(e) && e.every(s));
}
const r = ([e, t, i, a]) => `cubic-bezier(${e}, ${t}, ${i}, ${a})`, o = {
  linear: "linear",
  ease: "ease",
  easeIn: "ease-in",
  easeOut: "ease-out",
  easeInOut: "ease-in-out",
  circIn: /* @__PURE__ */ r([0, 0.65, 0.55, 1]),
  circOut: /* @__PURE__ */ r([0.55, 0, 1, 0.45]),
  backIn: /* @__PURE__ */ r([0.31, 0.01, 0.66, -0.59]),
  backOut: /* @__PURE__ */ r([0.33, 1.53, 0.69, 0.99])
};
function c(e, t) {
  if (e)
    return typeof e == "function" && n() ? p(e, t) : u(e) ? r(e) : Array.isArray(e) ? e.map((i) => c(i, t) || o.easeOut) : o[e];
}
export {
  r as cubicBezierAsString,
  s as isWaapiSupportedEasing,
  c as mapEasingToNativeEasing,
  o as supportedWaapiEasing
};
