import { GroupPlaybackControls as e } from "../GroupPlaybackControls.js";
import { animateSequence as f } from "./sequence.js";
import { animateSubject as u } from "./subject.js";
function A(n) {
  return Array.isArray(n) && Array.isArray(n[0]);
}
function c(n) {
  function o(i, r, m) {
    let t = [];
    A(i) ? t = f(i, r, n) : t = u(i, r, m, n);
    const a = new e(t);
    return n && n.animations.push(a), a;
  }
  return o;
}
const l = c();
export {
  l as animate,
  c as createScopedAnimate
};
