import { animations as o } from "../../../motion/features/animations.js";
import { drag as t } from "../../../motion/features/drag.js";
import { gestureAnimations as r } from "../../../motion/features/gestures.js";
import { layout as m } from "../../../motion/features/layout.js";
import { createMotionComponentFactory as e } from "../create-factory.js";
import { createDomVisualElement as i } from "../../dom/create-visual-element.js";
const l = /* @__PURE__ */ e({
  ...o,
  ...r,
  ...t,
  ...m
}, i);
export {
  l as createMotionComponent
};
