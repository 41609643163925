import { EditProfileEntryFragment } from '@generated/graphql';
import { action, observable, runInAction } from '@knuddels-app/mobx';
import { $SnackbarService } from '@knuddels-app/SnackbarManager';
import { $SaveProfileService } from '@knuddelsModules/Profile/providedServices';
import {
	SnackbarDefinitionProfileSaved,
	SnackbarUnableToSaveProfile,
} from '../../../../../snackbars';
import { $I18n } from '@knuddels-app/i18n';
import { $AuthenticatedClientService } from '@knuddels-app/Connection';
import { DetailsEditor } from './DetailsEditor';
import { AlbumsEditor } from './AlbumsEditor';

export class ProfileEditor {
	public readonly detailsEditor: DetailsEditor;
	public readonly albumsEditor: AlbumsEditor;

	@observable
	private _hasChanges = false;

	private _savedChanges = false;

	constructor(
		private readonly authenticatedClientService: typeof $AuthenticatedClientService.T,
		private readonly saveProfileService: typeof $SaveProfileService.T,
		private readonly snackbarService: typeof $SnackbarService.T,
		private readonly i18n: typeof $I18n.T,
		private readonly _entries: ReadonlyArray<EditProfileEntryFragment>
	) {
		this.detailsEditor = new DetailsEditor(
			saveProfileService,
			snackbarService,
			i18n,
			_entries,
			() => this.markDirty()
		);

		this.albumsEditor = new AlbumsEditor(
			authenticatedClientService,
			() => this.markDirty(),
			snackbarService
		);
	}

	public get selectedPhotoIds(): Set<string> {
		return this.albumsEditor.selectedPhotoIds;
	}

	@action.bound
	private markDirty(): void {
		this._hasChanges = true;
	}

	public get hasChanges(): boolean {
		return this._hasChanges;
	}

	public get savedChanges(): boolean {
		return this._savedChanges;
	}

	public save = async (): Promise<void> => {
		if (!this._hasChanges) {
			return;
		}

		if (!this.detailsEditor.canSave()) {
			return;
		}

		if (!this.albumsEditor.canSave()) {
			return;
		}

		const [detailsResult, albumResult] = await Promise.all([
			this.detailsEditor.save(), // Displays its own error message, no need to display one below again!
			this.albumsEditor.save(),
		]);

		if (detailsResult && albumResult) {
			this.snackbarService.showSnackbar(SnackbarDefinitionProfileSaved);
		} else if (!albumResult) {
			this.snackbarService.showSnackbar(SnackbarUnableToSaveProfile);
		}

		runInAction('Unmark profile dirty', () => {
			this._hasChanges = false;
		});
		this._savedChanges = true;
	};
}
