import * as React from 'react';
import { declareProps, inject, injectable, injectedComponent, injectProps, useService } from '@knuddels-app/DependencyInjection';
import { filterAndSortContacts } from './createItemsForVirtualListView';
import { ContactsViewState } from './ContactsView';
import { Disposable } from '@knuddels/std';
import { action, computed, observable, reaction } from '@knuddels-app/mobx';
import { debounce } from '@knuddels-app/tools/debounce';
import { $ContactsService } from '../../../providedServices';
import { $FirebaseAnalyticsService } from '@knuddels-app/analytics/firebase';
import { PlaceholderContent, TextBadge } from '@shared/components';
import { Flex, FlexCol, Panel, PanelsList, Tab, Tabs, TabsList, TabText } from '@knuddels/component-library';
import { $I18n, declareFormat, FormattedMessage } from '@knuddels-app/i18n';
import { AllContactsTabs, getContactsTabTitleFormat } from './ContactTabs';
import { ContactsTabView } from './ContactsTabView';
import { FriendRequests } from '../FriendRequests/FriendRequests';
import { ContactsUser } from '../../ContactsUser';
import { ContactsVirtualList } from './ContactsVirtualList';
import { $MentorService } from '@knuddelsModules/MentorSystem';
import { MentorSystem } from './MentorSystem';
import { $GenericUserEventService, GenericUserEventService } from '@knuddels-app/analytics/generic';
interface ModelProps extends ViewProps {}
interface ViewProps {
  state: ContactsViewState;
  visibleTabs: AllContactsTabs[];
  onContactClicked: (contact: ContactsUser, index: number) => void;
}
@injectable()
class ContactsViewContentModel {
  public readonly dispose = Disposable.fn();
  @observable
  public exactUserMatch: ContactsUser | undefined = undefined;
  private readonly debouncedExactMatchSearch = debounce(async (searchValue: string): Promise<void> => {
    if (searchValue) {
      await this.contactsService.fetchContactByNick(searchValue);
      this.updateExactUserMatchFromCache();
    }
  }, 400);
  private readonly debouncedSearchResultTracking = debounce(() => {
    if (this.props.state.kind === 'search') {
      const isSearchResultEmpty = this.searchContacts.length === 0;
      this.firebaseAnalyticsService.logEvent('Contacts_SearchResult', 'Shown');
      this.firebaseAnalyticsService.logEvent('Contacts_SearchResult', isSearchResultEmpty ? 'NoUserFound' : 'UserFound');
    }
  }, 1000);
  constructor(@injectProps()
  private readonly props: ModelProps, @inject($ContactsService)
  private readonly contactsService: typeof $ContactsService.T, @inject($FirebaseAnalyticsService)
  private readonly firebaseAnalyticsService: typeof $FirebaseAnalyticsService.T, @inject($I18n)
  private readonly i18n: typeof $I18n.T, @inject($MentorService)
  private readonly mentorService: typeof $MentorService.T) {
    this.dispose.track(reaction({
      name: 'Trigger debounced exact match search'
    }, () => this.searchValue, async searchValue => {
      this.updateExactUserMatchFromCache();
      await Promise.all([this.debouncedExactMatchSearch(searchValue), this.debouncedSearchResultTracking()]);
    }));
  }
  @action.bound
  private updateExactUserMatchFromCache(): void {
    this.exactUserMatch = this.contactsService.getContactByNick(this.searchValue);
  }
  @computed
  private get searchValue(): string {
    return this.props.state.kind === 'search' ? this.props.state.searchValue : '';
  }
  @computed
  public get searchContacts(): readonly ContactsUser[] {
    const state = this.props.state;
    if (state.kind === 'search') {
      const contacts = this.exactUserMatch && !state.searchValueMatchesCurrentUser ? addContactIfNeeded(this.exactUserMatch, state.allContacts) : state.allContacts;
      return filterAndSortContacts(contacts, state.searchValueMatchesCurrentUser ? undefined : this.exactUserMatch, state.searchValue);
    }
    return [];
  }
  public getTabText = (tab: AllContactsTabs): string => {
    return getContactsTabTitleFormat(tab).format(this.i18n);
  };
  public getTabBadge = (tab: AllContactsTabs): string | null => {
    if (tab !== 'mentees') {
      return null;
    }
    return this.mentorService.mentorStatus?.__typename === 'MentorStatusAvailable' ? '1' : null;
  };
}
const reportDebounced = debounce((genericUserEventService: GenericUserEventService, state: ContactsViewState, hitCount: number) => {
  if (state.kind === 'search') {
    genericUserEventService.reportEvent({
      type: 'Contacts_Searched',
      hitCount,
      searchText: state.searchValue
    });
  }
}, 1000);
export const ContactsViewContent = injectedComponent({
  name: 'ContactsViewContent',
  model: ContactsViewContentModel,
  props: declareProps<ViewProps>()
}, ({
  model,
  state,
  onContactClicked,
  visibleTabs
}) => {
  const genericUserEventService = useService($GenericUserEventService);
  React.useEffect(() => {
    reportDebounced(genericUserEventService, state, model.searchContacts.length);
  }, [model.searchContacts]);
  if (state.kind === 'search') {
    return <ContactsVirtualList contacts={model.searchContacts} searchText={state.searchValue} onContactClicked={onContactClicked} />;
  } else if (state.kind === 'allContactsDisabled') {
    return <PlaceholderContent description={<FormattedMessage id={declareFormat({
      id: 'contacts.allTabsDisabled.description',
      defaultFormat: 'Using the settings menu (top right), you can activate some or all contact types again.'
    })} />} title={<FormattedMessage id={declareFormat({
      id: 'contacts.allTabsDisabled.title',
      defaultFormat: "You've disabled all contacts."
    })} />} image={require('@shared/icons/sm_17-04-Columbus_ani.gif')} imageWidth={69} imageHeight={63} />;
  } else if (state.kind === 'categorized') {
    // Makes sure everything is set correctly when tab order changes
    const tabsKey = visibleTabs.reduce((acc, tab) => acc + tab, '');
    return <div className={_c0}>
					<Tabs key={tabsKey} variant="scrollable" value={state.activeTabIndex} onChange={state.setActiveTabIndex}>
						<TabsList>
							{visibleTabs.map(tab => {
            const tabText = model.getTabText(tab);
            const tabBadge = model.getTabBadge(tab);
            return <Tab key={tab} ariaLabel={tabText}>
										<TabText>{tabText}</TabText>
										{tabBadge && <div className={_c1}>
												<TextBadge text={tabBadge} colorPreset={'accent'} />
											</div>}
									</Tab>;
          })}
						</TabsList>
						<PanelsList>
							{visibleTabs.map((tab, index) => <Panel key={tab}>
									<TabContent isActive={state.activeTabIndex === index} tab={tab} onContactClicked={onContactClicked} />
								</Panel>)}
						</PanelsList>
					</Tabs>
				</div>;
  } else {
    return null;
  }
});
const TabContent: React.FC<{
  tab: AllContactsTabs;
  onContactClicked: ViewProps['onContactClicked'];
  isActive: boolean;
}> = ({
  tab,
  onContactClicked,
  isActive
}) => {
  if (tab === 'friendrequests') {
    return <FriendRequests isTabActive={isActive} />;
  }
  if (tab === 'mentees') {
    return <MentorSystem onContactClicked={onContactClicked} />;
  }
  return <ContactsTabView tab={tab} onContactClicked={onContactClicked} />;
};
function addContactIfNeeded(contact: ContactsUser, contacts: readonly ContactsUser[]): readonly ContactsUser[] {
  if (contacts.some(it => it.id === contact.id)) {
    return contacts;
  } else {
    return [contact, ...contacts];
  }
}
const _c0 = " Knu-FlexCol flex-1 minHeight-0-px overflow-hidden ";
const _c1 = " Knu-Flex ml-tiny ";