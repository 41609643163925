import * as React from 'react';
import { ChannelGroupInfoFragment, ChannelPreviewMembersFragment } from '@generated/graphql';
import { useService } from '@knuddels-app/DependencyInjection';
import { $JoinChannelService } from '@knuddelsModules/Channel';
import { useLazyService } from '@knuddels-app/ModuleSystem';
import { $AdsService } from '@knuddelsModules/Ads';
import { SmallChannelListItem } from './SmallChannelListItem';
import { useUpdateRecommendedChannels } from '../RecommendedChannelsContext';
import { useLogEvent } from '@knuddels-app/analytics/firebase/useLogEvent';
export type ChannelGroupData = {
  id: string;
  name: string;
  info: ChannelGroupInfoFragment;
  channels: ReadonlyArray<{
    id: string;
    name: string;
    onlineUserCount: number;
    previewMembers?: ChannelPreviewMembersFragment;
  }>;
  previewMembers?: ChannelPreviewMembersFragment;
};
export function SmallChannelListGroupItems({
  channelGroup,
  userCount,
  isFirst,
  isLast,
  trackingContentType
}: {
  channelGroup: ChannelGroupData;
  userCount: number;
  isFirst: boolean;
  isLast: boolean;
  trackingContentType: string;
}): React.ReactElement {
  const joinChannelGroup = useJoinCallback(channelGroup, trackingContentType);
  const updateLastVisitedChannels = useUpdateRecommendedChannels();
  return <SmallChannelListItem id={channelGroup.id} name={channelGroup.name} userCount={userCount} backgroundColor={channelGroup.info.backgroundColor} channelImage={channelGroup.info.previewImageInfo.url} hasSubChannels={channelGroup.channels.length > 1} previewMembers={channelGroup.previewMembers} onEntryClick={groupId => {
    joinChannelGroup(groupId);
    updateLastVisitedChannels();
  }} isFirst={isFirst} isLast={isLast} />;
}
const useJoinCallback = (channelGroup: ChannelGroupData, trackingContentType: string) => {
  const channelService = useService($JoinChannelService);
  const getAdsService = useLazyService($AdsService);
  const track = useLogEvent();
  return React.useCallback(groupId => {
    track(trackingContentType, 'Channellist_ChannelEntered');
    channelService.joinChannelByGroupId(groupId, channelGroup.name, 'ChannelList');
    getAdsService().then(it => it.showInterstitial());
  }, [channelGroup]);
};