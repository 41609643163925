import { EditorConfig, LexicalNode, TextModeType, TextNode } from 'lexical';
export class AutocompleteNode extends TextNode {
  public className = '';
  static getType(): string {
    return 'autocomplete';
  }
  static clone(node: AutocompleteNode): AutocompleteNode {
    return $createAutocompleteNode({
      text: node.getTextContent(),
      className: node.className,
      key: node.getKey()
    });
  }
  createDOM(config: EditorConfig): HTMLElement {
    const dom = super.createDOM(config);
    dom.classList.add('autocomplete');
    dom.classList.add(this.className);
    return dom;
  }
  canInsertTextAfter(): boolean {
    return true;
  }
  getMode(): TextModeType {
    return 'segmented';
  }
  isDirectionless(): boolean {
    return true;
  }
}
export function $createAutocompleteNode(args: {
  text: string;
  className?: string;
  key?: string;
}): AutocompleteNode {
  const mentionNode = new AutocompleteNode(args.text, args.key);
  mentionNode.className = args.className || '';
  return mentionNode;
}
export function $isAutocompleteNode(node: LexicalNode | null | undefined): node is AutocompleteNode {
  return node instanceof AutocompleteNode;
}