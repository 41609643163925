import { FlexCol, Modal, resolveThemingValue, useTheme } from '@knuddels/component-library';
import { Z_INDEX } from '@shared/components/globalStyleConfiguration/zIndexValues';
import { equalUpToDepth } from '@shared/components/tools';
import * as React from 'react';
import { ReactNode } from 'react';
import { Notification, NotificationProps } from './Notification';
export interface NotificationBarContainerProps extends NotificationDisplayProps {
  notification?: NotificationProps;
  children?: ReactNode;
}
export interface NotificationDisplayProps {
  isTinyScreen?: boolean;
}
export class NotificationBarContainer extends React.Component<NotificationBarContainerProps> {
  shouldComponentUpdate(nextProps: Readonly<{
    children?: ReactNode;
  }> & Readonly<NotificationBarContainerProps>): boolean {
    if (this.props.isTinyScreen !== nextProps.isTinyScreen || this.props.children !== nextProps.children) {
      return true;
    }
    const notification = this.props.notification;
    const nextNotification = nextProps.notification;
    if (!notification) {
      return !!nextNotification;
    }
    if (!nextNotification) {
      return !!notification;
    }
    return !equalUpToDepth(notification, nextNotification, undefined, 2);
  }
  render(): React.ReactNode {
    const notification = this.props.notification;
    const isTinyScreen = this.props.isTinyScreen || false;
    return <NotificationBarDisplayer notification={notification} isTinyScreen={isTinyScreen}>
				{this.props.children}
			</NotificationBarDisplayer>;
  }
}
const NotificationBarDisplayer: React.FC<{
  notification: NotificationProps | undefined;
  isTinyScreen: boolean;
}> = ({
  notification,
  isTinyScreen,
  children
}) => {
  return <div className={_c0}>
			{children}
			{notification && <Modal zIndex={Z_INDEX.ABOVE_MODAL} backgroundStyle={'transparent'} pointerEvents={'none'}>
					<div style={{
        left: resolveThemingValue(isTinyScreen ? 'base' : undefined, "spacing", useTheme())
      }} className={_c1 + (!isTinyScreen ? _c2 : _c3)}>
						<div className={_c4 + (isTinyScreen ? _c5 : _c6)}>
							<Notification key={notification.id} {...notification} />
						</div>
					</div>
				</Modal>}
		</div>;
};
const _c0 = " Knu-FlexCol position-absolute inset-none ";
const _c1 = " Knu-FlexCol position-absolute right-base top-xxlarge pointerEvents-all ";
const _c2 = " alignItems-flex-end ";
const _c3 = " alignItems-stretch ";
const _c4 = " Knu-FlexCol ";
const _c5 = " width-full ";
const _c6 = " width-340px ";