import { useService } from '@knuddels-app/DependencyInjection';
import { $I18n, declareFormat } from '@knuddels-app/i18n';
import { IconMessage } from '@knuddels-app/shared-components/IconMessage';
import { SpeedDial } from '@knuddels/component-library';
import { $MessengerMiniChatService } from '@knuddelsModules/Messenger';
import { userIsJames } from '@shared/helper/user';
import * as React from 'react';
import { ProfileUser } from '../../../profileQueryHelper';
import { useTrackProfileView } from '../../../shared/useTrackProfileView';
import { useLazyService } from '@knuddels-app/ModuleSystem';
export const ConversationFabEntry: React.FC<{
  user: ProfileUser;
  conversationId: string;
  onClose(): void;
}> = props => {
  const getMiniChatService = useLazyService($MessengerMiniChatService);
  const i18n = useService($I18n);
  const track = useTrackProfileView();
  if (userIsJames(props.user) || props.user.isAppBot) {
    return null;
  }
  return <SpeedDial.Option label={i18n.format(declareFormat({
    id: 'profile.fab-menu.send-message',
    defaultFormat: 'Send private message'
  }))} icon={<IconMessage size={'large'} />} onPress={() => {
    track('FAB_SendMessage');
    props.onClose();
    getMiniChatService().then(service => service.openConversation(props.conversationId, 'ProfileConversationFabEntry'));
  }} />;
};