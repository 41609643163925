import { alpha as n } from "../numbers/index.js";
import { percent as t } from "../numbers/units.js";
import { sanitize as r } from "../utils/sanitize.js";
import { isColorString as i, splitColor as e } from "./utils.js";
const u = {
  test: /* @__PURE__ */ i("hsl", "hue"),
  parse: /* @__PURE__ */ e("hue", "saturation", "lightness"),
  transform: ({ hue: o, saturation: s, lightness: a, alpha: m = 1 }) => "hsla(" + Math.round(o) + ", " + t.transform(r(s)) + ", " + t.transform(r(a)) + ", " + r(n.transform(m)) + ")"
};
export {
  u as hsla
};
