import { SubscriptionManager as nt } from "../../utils/subscription-manager.js";
import { mixValues as lt } from "../animation/mix-values.js";
import { copyBoxInto as p, copyAxisDeltaInto as z } from "../geometry/copy.js";
import { translateAxis as w, transformBox as E, applyBoxDelta as ht, applyTreeDeltas as ut } from "../geometry/delta-apply.js";
import { calcRelativePosition as k, calcRelativeBox as ct, calcBoxDelta as L, calcLength as F, isNear as dt } from "../geometry/delta-calc.js";
import { removeBoxTransforms as _ } from "../geometry/delta-remove.js";
import { getValueTransition as ft } from "../../animation/utils/get-value-transition.js";
import { boxEqualsRounded as st, isDeltaZero as ot, axisDeltaEquals as W, aspectRatio as H, boxEquals as mt } from "../geometry/utils.js";
import { NodeStack as pt } from "../shared/stack.js";
import { scaleCorrectors as q } from "../styles/scale-correction.js";
import { buildProjectionTransform as yt } from "../styles/transform.js";
import { eachAxis as G } from "../utils/each-axis.js";
import { hasTransform as B, hasScale as X, has2DTranslate as gt } from "../utils/has-transform.js";
import { FlatTree as vt } from "../../render/utils/flat-tree.js";
import { resolveMotionValue as O } from "../../value/utils/resolve-motion-value.js";
import { globalProjectionState as b } from "./state.js";
import { delay as Tt } from "../../utils/delay.js";
import { mixNumber as C } from "../../utils/mix/number.js";
import { isSVGElement as Dt } from "../../render/dom/utils/is-svg-element.js";
import { animateSingleValue as xt } from "../../animation/animate/single-value.js";
import { clamp as Pt } from "../../utils/clamp.js";
import { cancelFrame as Y, frameData as y, frameSteps as M, frame as U } from "../../frameloop/frame.js";
import { noop as St } from "../../utils/noop.js";
import { time as Bt } from "../../frameloop/sync-time.js";
import { microtask as jt } from "../../frameloop/microtask.js";
import { getOptimisedAppearId as At } from "../../animation/optimized-appear/get-appear-id.js";
import { createBox as f, createDelta as j } from "../geometry/models.js";
const x = {
  type: "projectionFrame",
  totalNodes: 0,
  resolvedTargetDeltas: 0,
  recalculatedProjection: 0
}, V = typeof window < "u" && window.MotionDebug !== void 0, I = ["", "X", "Y", "Z"], Rt = { visibility: "hidden" }, $ = 1e3;
let Vt = 0;
function N(e, l, h, c) {
  const { latestValues: v } = l;
  v[e] && (h[e] = v[e], l.setStaticValue(e, 0), c && (c[e] = 0));
}
function at(e) {
  if (e.hasCheckedOptimisedAppear = !0, e.root === e)
    return;
  const { visualElement: l } = e.options;
  if (!l)
    return;
  const h = At(l);
  if (window.MotionHasOptimisedAnimation(h, "transform")) {
    const { layout: v, layoutId: A } = e.options;
    window.MotionCancelOptimisedAnimation(h, "transform", U, !(v || A));
  }
  const { parent: c } = e;
  c && !c.hasCheckedOptimisedAppear && at(c);
}
function Te({ attachResizeListener: e, defaultParent: l, measureScroll: h, checkIsScrollRoot: c, resetTransform: v }) {
  return class {
    constructor(t = {}, i = l == null ? void 0 : l()) {
      this.id = Vt++, this.animationId = 0, this.children = /* @__PURE__ */ new Set(), this.options = {}, this.isTreeAnimating = !1, this.isAnimationBlocked = !1, this.isLayoutDirty = !1, this.isProjectionDirty = !1, this.isSharedProjectionDirty = !1, this.isTransformDirty = !1, this.updateManuallyBlocked = !1, this.updateBlockedByResize = !1, this.isUpdating = !1, this.isSVG = !1, this.needsReset = !1, this.shouldResetTransform = !1, this.hasCheckedOptimisedAppear = !1, this.treeScale = { x: 1, y: 1 }, this.eventHandlers = /* @__PURE__ */ new Map(), this.hasTreeAnimated = !1, this.updateScheduled = !1, this.scheduleUpdate = () => this.update(), this.projectionUpdateScheduled = !1, this.checkUpdateFailed = () => {
        this.isUpdating && (this.isUpdating = !1, this.clearAllSnapshots());
      }, this.updateProjection = () => {
        this.projectionUpdateScheduled = !1, V && (x.totalNodes = x.resolvedTargetDeltas = x.recalculatedProjection = 0), this.nodes.forEach(wt), this.nodes.forEach(Ot), this.nodes.forEach(bt), this.nodes.forEach(Et), V && window.MotionDebug.record(x);
      }, this.resolvedRelativeTargetAt = 0, this.hasProjected = !1, this.isVisible = !0, this.animationProgress = 0, this.sharedNodes = /* @__PURE__ */ new Map(), this.latestValues = t, this.root = i ? i.root || i : this, this.path = i ? [...i.path, i] : [], this.parent = i, this.depth = i ? i.depth + 1 : 0;
      for (let s = 0; s < this.path.length; s++)
        this.path[s].shouldResetTransform = !0;
      this.root === this && (this.nodes = new vt());
    }
    addEventListener(t, i) {
      return this.eventHandlers.has(t) || this.eventHandlers.set(t, new nt()), this.eventHandlers.get(t).add(i);
    }
    notifyListeners(t, ...i) {
      const s = this.eventHandlers.get(t);
      s && s.notify(...i);
    }
    hasListeners(t) {
      return this.eventHandlers.has(t);
    }
    /**
     * Lifecycles
     */
    mount(t, i = this.root.hasTreeAnimated) {
      if (this.instance)
        return;
      this.isSVG = Dt(t), this.instance = t;
      const { layoutId: s, layout: o, visualElement: a } = this.options;
      if (a && !a.current && a.mount(t), this.root.nodes.add(this), this.parent && this.parent.children.add(this), i && (o || s) && (this.isLayoutDirty = !0), e) {
        let r;
        const n = () => this.root.updateBlockedByResize = !1;
        e(t, () => {
          this.root.updateBlockedByResize = !0, r && r(), r = Tt(n, 250), b.hasAnimatedSinceResize && (b.hasAnimatedSinceResize = !1, this.nodes.forEach(J));
        });
      }
      s && this.root.registerSharedNode(s, this), this.options.animate !== !1 && a && (s || o) && this.addEventListener("didUpdate", ({ delta: r, hasLayoutChanged: n, hasRelativeTargetChanged: u, layout: m }) => {
        if (this.isTreeAnimationBlocked()) {
          this.target = void 0, this.relativeTarget = void 0;
          return;
        }
        const d = this.options.transition || a.getDefaultTransition() || _t, { onLayoutAnimationStart: P, onLayoutAnimationComplete: D } = a.getProps(), S = !this.targetLayout || !st(this.targetLayout, m) || u, g = !n && u;
        if (this.options.layoutRoot || this.resumeFrom && this.resumeFrom.instance || g || n && (S || !this.currentAnimation)) {
          this.resumeFrom && (this.resumingFrom = this.resumeFrom, this.resumingFrom.resumingFrom = void 0), this.setAnimationOrigin(r, g);
          const T = {
            ...ft(d, "layout"),
            onPlay: P,
            onComplete: D
          };
          (a.shouldReduceMotion || this.options.layoutRoot) && (T.delay = 0, T.type = !1), this.startAnimation(T);
        } else
          n || J(this), this.isLead() && this.options.onExitComplete && this.options.onExitComplete();
        this.targetLayout = m;
      });
    }
    unmount() {
      this.options.layoutId && this.willUpdate(), this.root.nodes.remove(this);
      const t = this.getStack();
      t && t.remove(this), this.parent && this.parent.children.delete(this), this.instance = void 0, Y(this.updateProjection);
    }
    // only on the root
    blockUpdate() {
      this.updateManuallyBlocked = !0;
    }
    unblockUpdate() {
      this.updateManuallyBlocked = !1;
    }
    isUpdateBlocked() {
      return this.updateManuallyBlocked || this.updateBlockedByResize;
    }
    isTreeAnimationBlocked() {
      return this.isAnimationBlocked || this.parent && this.parent.isTreeAnimationBlocked() || !1;
    }
    // Note: currently only running on root node
    startUpdate() {
      this.isUpdateBlocked() || (this.isUpdating = !0, this.nodes && this.nodes.forEach(Mt), this.animationId++);
    }
    getTransformTemplate() {
      const { visualElement: t } = this.options;
      return t && t.getProps().transformTemplate;
    }
    willUpdate(t = !0) {
      if (this.root.hasTreeAnimated = !0, this.root.isUpdateBlocked()) {
        this.options.onExitComplete && this.options.onExitComplete();
        return;
      }
      if (window.MotionCancelOptimisedAnimation && !this.hasCheckedOptimisedAppear && at(this), !this.root.isUpdating && this.root.startUpdate(), this.isLayoutDirty)
        return;
      this.isLayoutDirty = !0;
      for (let a = 0; a < this.path.length; a++) {
        const r = this.path[a];
        r.shouldResetTransform = !0, r.updateScroll("snapshot"), r.options.layoutRoot && r.willUpdate(!1);
      }
      const { layoutId: i, layout: s } = this.options;
      if (i === void 0 && !s)
        return;
      const o = this.getTransformTemplate();
      this.prevTransformTemplateValue = o ? o(this.latestValues, "") : void 0, this.updateSnapshot(), t && this.notifyListeners("willUpdate");
    }
    update() {
      if (this.updateScheduled = !1, this.isUpdateBlocked()) {
        this.unblockUpdate(), this.clearAllSnapshots(), this.nodes.forEach(Z);
        return;
      }
      this.isUpdating || this.nodes.forEach(Ft), this.isUpdating = !1, this.nodes.forEach(Ct), this.nodes.forEach(kt), this.nodes.forEach(Lt), this.clearAllSnapshots();
      const i = Bt.now();
      y.delta = Pt(0, 1e3 / 60, i - y.timestamp), y.timestamp = i, y.isProcessing = !0, M.update.process(y), M.preRender.process(y), M.render.process(y), y.isProcessing = !1;
    }
    didUpdate() {
      this.updateScheduled || (this.updateScheduled = !0, jt.read(this.scheduleUpdate));
    }
    clearAllSnapshots() {
      this.nodes.forEach(Ut), this.sharedNodes.forEach(It);
    }
    scheduleUpdateProjection() {
      this.projectionUpdateScheduled || (this.projectionUpdateScheduled = !0, U.preRender(this.updateProjection, !1, !0));
    }
    scheduleCheckAfterUnmount() {
      U.postRender(() => {
        this.isLayoutDirty ? this.root.didUpdate() : this.root.checkUpdateFailed();
      });
    }
    /**
     * Update measurements
     */
    updateSnapshot() {
      this.snapshot || !this.instance || (this.snapshot = this.measure());
    }
    updateLayout() {
      if (!this.instance || (this.updateScroll(), !(this.options.alwaysMeasureLayout && this.isLead()) && !this.isLayoutDirty))
        return;
      if (this.resumeFrom && !this.resumeFrom.instance)
        for (let s = 0; s < this.path.length; s++)
          this.path[s].updateScroll();
      const t = this.layout;
      this.layout = this.measure(!1), this.layoutCorrected = f(), this.isLayoutDirty = !1, this.projectionDelta = void 0, this.notifyListeners("measure", this.layout.layoutBox);
      const { visualElement: i } = this.options;
      i && i.notify("LayoutMeasure", this.layout.layoutBox, t ? t.layoutBox : void 0);
    }
    updateScroll(t = "measure") {
      let i = !!(this.options.layoutScroll && this.instance);
      if (this.scroll && this.scroll.animationId === this.root.animationId && this.scroll.phase === t && (i = !1), i) {
        const s = c(this.instance);
        this.scroll = {
          animationId: this.root.animationId,
          phase: t,
          isRoot: s,
          offset: h(this.instance),
          wasRoot: this.scroll ? this.scroll.isRoot : s
        };
      }
    }
    resetTransform() {
      if (!v)
        return;
      const t = this.isLayoutDirty || this.shouldResetTransform || this.options.alwaysMeasureLayout, i = this.projectionDelta && !ot(this.projectionDelta), s = this.getTransformTemplate(), o = s ? s(this.latestValues, "") : void 0, a = o !== this.prevTransformTemplateValue;
      t && (i || B(this.latestValues) || a) && (v(this.instance, o), this.shouldResetTransform = !1, this.scheduleRender());
    }
    measure(t = !0) {
      const i = this.measurePageBox();
      let s = this.removeElementScroll(i);
      return t && (s = this.removeTransform(s)), Wt(s), {
        animationId: this.root.animationId,
        measuredBox: i,
        layoutBox: s,
        latestValues: {},
        source: this.id
      };
    }
    measurePageBox() {
      var t;
      const { visualElement: i } = this.options;
      if (!i)
        return f();
      const s = i.measureViewportBox();
      if (!(((t = this.scroll) === null || t === void 0 ? void 0 : t.wasRoot) || this.path.some(Ht))) {
        const { scroll: a } = this.root;
        a && (w(s.x, a.offset.x), w(s.y, a.offset.y));
      }
      return s;
    }
    removeElementScroll(t) {
      var i;
      const s = f();
      if (p(s, t), !((i = this.scroll) === null || i === void 0) && i.wasRoot)
        return s;
      for (let o = 0; o < this.path.length; o++) {
        const a = this.path[o], { scroll: r, options: n } = a;
        a !== this.root && r && n.layoutScroll && (r.wasRoot && p(s, t), w(s.x, r.offset.x), w(s.y, r.offset.y));
      }
      return s;
    }
    applyTransform(t, i = !1) {
      const s = f();
      p(s, t);
      for (let o = 0; o < this.path.length; o++) {
        const a = this.path[o];
        !i && a.options.layoutScroll && a.scroll && a !== a.root && E(s, {
          x: -a.scroll.offset.x,
          y: -a.scroll.offset.y
        }), B(a.latestValues) && E(s, a.latestValues);
      }
      return B(this.latestValues) && E(s, this.latestValues), s;
    }
    removeTransform(t) {
      const i = f();
      p(i, t);
      for (let s = 0; s < this.path.length; s++) {
        const o = this.path[s];
        if (!o.instance || !B(o.latestValues))
          continue;
        X(o.latestValues) && o.updateSnapshot();
        const a = f(), r = o.measurePageBox();
        p(a, r), _(i, o.latestValues, o.snapshot ? o.snapshot.layoutBox : void 0, a);
      }
      return B(this.latestValues) && _(i, this.latestValues), i;
    }
    setTargetDelta(t) {
      this.targetDelta = t, this.root.scheduleUpdateProjection(), this.isProjectionDirty = !0;
    }
    setOptions(t) {
      this.options = {
        ...this.options,
        ...t,
        crossfade: t.crossfade !== void 0 ? t.crossfade : !0
      };
    }
    clearMeasurements() {
      this.scroll = void 0, this.layout = void 0, this.snapshot = void 0, this.prevTransformTemplateValue = void 0, this.targetDelta = void 0, this.target = void 0, this.isLayoutDirty = !1;
    }
    forceRelativeParentToResolveTarget() {
      this.relativeParent && this.relativeParent.resolvedRelativeTargetAt !== y.timestamp && this.relativeParent.resolveTargetDelta(!0);
    }
    resolveTargetDelta(t = !1) {
      var i;
      const s = this.getLead();
      this.isProjectionDirty || (this.isProjectionDirty = s.isProjectionDirty), this.isTransformDirty || (this.isTransformDirty = s.isTransformDirty), this.isSharedProjectionDirty || (this.isSharedProjectionDirty = s.isSharedProjectionDirty);
      const o = !!this.resumingFrom || this !== s;
      if (!(t || o && this.isSharedProjectionDirty || this.isProjectionDirty || !((i = this.parent) === null || i === void 0) && i.isProjectionDirty || this.attemptToResolveRelativeTarget || this.root.updateBlockedByResize))
        return;
      const { layout: r, layoutId: n } = this.options;
      if (!(!this.layout || !(r || n))) {
        if (this.resolvedRelativeTargetAt = y.timestamp, !this.targetDelta && !this.relativeTarget) {
          const u = this.getClosestProjectingParent();
          u && u.layout && this.animationProgress !== 1 ? (this.relativeParent = u, this.forceRelativeParentToResolveTarget(), this.relativeTarget = f(), this.relativeTargetOrigin = f(), k(this.relativeTargetOrigin, this.layout.layoutBox, u.layout.layoutBox), p(this.relativeTarget, this.relativeTargetOrigin)) : this.relativeParent = this.relativeTarget = void 0;
        }
        if (!(!this.relativeTarget && !this.targetDelta)) {
          if (this.target || (this.target = f(), this.targetWithTransforms = f()), this.relativeTarget && this.relativeTargetOrigin && this.relativeParent && this.relativeParent.target ? (this.forceRelativeParentToResolveTarget(), ct(this.target, this.relativeTarget, this.relativeParent.target)) : this.targetDelta ? (this.resumingFrom ? this.target = this.applyTransform(this.layout.layoutBox) : p(this.target, this.layout.layoutBox), ht(this.target, this.targetDelta)) : p(this.target, this.layout.layoutBox), this.attemptToResolveRelativeTarget) {
            this.attemptToResolveRelativeTarget = !1;
            const u = this.getClosestProjectingParent();
            u && !!u.resumingFrom == !!this.resumingFrom && !u.options.layoutScroll && u.target && this.animationProgress !== 1 ? (this.relativeParent = u, this.forceRelativeParentToResolveTarget(), this.relativeTarget = f(), this.relativeTargetOrigin = f(), k(this.relativeTargetOrigin, this.target, u.target), p(this.relativeTarget, this.relativeTargetOrigin)) : this.relativeParent = this.relativeTarget = void 0;
          }
          V && x.resolvedTargetDeltas++;
        }
      }
    }
    getClosestProjectingParent() {
      if (!(!this.parent || X(this.parent.latestValues) || gt(this.parent.latestValues)))
        return this.parent.isProjecting() ? this.parent : this.parent.getClosestProjectingParent();
    }
    isProjecting() {
      return !!((this.relativeTarget || this.targetDelta || this.options.layoutRoot) && this.layout);
    }
    calcProjection() {
      var t;
      const i = this.getLead(), s = !!this.resumingFrom || this !== i;
      let o = !0;
      if ((this.isProjectionDirty || !((t = this.parent) === null || t === void 0) && t.isProjectionDirty) && (o = !1), s && (this.isSharedProjectionDirty || this.isTransformDirty) && (o = !1), this.resolvedRelativeTargetAt === y.timestamp && (o = !1), o)
        return;
      const { layout: a, layoutId: r } = this.options;
      if (this.isTreeAnimating = !!(this.parent && this.parent.isTreeAnimating || this.currentAnimation || this.pendingAnimation), this.isTreeAnimating || (this.targetDelta = this.relativeTarget = void 0), !this.layout || !(a || r))
        return;
      p(this.layoutCorrected, this.layout.layoutBox);
      const n = this.treeScale.x, u = this.treeScale.y;
      ut(this.layoutCorrected, this.treeScale, this.path, s), i.layout && !i.target && (this.treeScale.x !== 1 || this.treeScale.y !== 1) && (i.target = i.layout.layoutBox, i.targetWithTransforms = f());
      const { target: m } = i;
      if (!m) {
        this.prevProjectionDelta && (this.createProjectionDeltas(), this.scheduleRender());
        return;
      }
      !this.projectionDelta || !this.prevProjectionDelta ? this.createProjectionDeltas() : (z(this.prevProjectionDelta.x, this.projectionDelta.x), z(this.prevProjectionDelta.y, this.projectionDelta.y)), L(this.projectionDelta, this.layoutCorrected, m, this.latestValues), (this.treeScale.x !== n || this.treeScale.y !== u || !W(this.projectionDelta.x, this.prevProjectionDelta.x) || !W(this.projectionDelta.y, this.prevProjectionDelta.y)) && (this.hasProjected = !0, this.scheduleRender(), this.notifyListeners("projectionUpdate", m)), V && x.recalculatedProjection++;
    }
    hide() {
      this.isVisible = !1;
    }
    show() {
      this.isVisible = !0;
    }
    scheduleRender(t = !0) {
      var i;
      if ((i = this.options.visualElement) === null || i === void 0 || i.scheduleRender(), t) {
        const s = this.getStack();
        s && s.scheduleRender();
      }
      this.resumingFrom && !this.resumingFrom.instance && (this.resumingFrom = void 0);
    }
    createProjectionDeltas() {
      this.prevProjectionDelta = j(), this.projectionDelta = j(), this.projectionDeltaWithTransform = j();
    }
    setAnimationOrigin(t, i = !1) {
      const s = this.snapshot, o = s ? s.latestValues : {}, a = { ...this.latestValues }, r = j();
      (!this.relativeParent || !this.relativeParent.options.layoutRoot) && (this.relativeTarget = this.relativeTargetOrigin = void 0), this.attemptToResolveRelativeTarget = !i;
      const n = f(), u = s ? s.source : void 0, m = this.layout ? this.layout.source : void 0, d = u !== m, P = this.getStack(), D = !P || P.members.length <= 1, S = !!(d && !D && this.options.crossfade === !0 && !this.path.some(zt));
      this.animationProgress = 0;
      let g;
      this.mixTargetDelta = (T) => {
        const R = T / 1e3;
        K(r.x, t.x, R), K(r.y, t.y, R), this.setTargetDelta(r), this.relativeTarget && this.relativeTargetOrigin && this.layout && this.relativeParent && this.relativeParent.layout && (k(n, this.layout.layoutBox, this.relativeParent.layout.layoutBox), Nt(this.relativeTarget, this.relativeTargetOrigin, n, R), g && mt(this.relativeTarget, g) && (this.isProjectionDirty = !1), g || (g = f()), p(g, this.relativeTarget)), d && (this.animationValues = a, lt(a, o, this.latestValues, R, S, D)), this.root.scheduleUpdateProjection(), this.scheduleRender(), this.animationProgress = R;
      }, this.mixTargetDelta(this.options.layoutRoot ? 1e3 : 0);
    }
    startAnimation(t) {
      this.notifyListeners("animationStart"), this.currentAnimation && this.currentAnimation.stop(), this.resumingFrom && this.resumingFrom.currentAnimation && this.resumingFrom.currentAnimation.stop(), this.pendingAnimation && (Y(this.pendingAnimation), this.pendingAnimation = void 0), this.pendingAnimation = U.update(() => {
        b.hasAnimatedSinceResize = !0, this.currentAnimation = xt(0, $, {
          ...t,
          onUpdate: (i) => {
            this.mixTargetDelta(i), t.onUpdate && t.onUpdate(i);
          },
          onComplete: () => {
            t.onComplete && t.onComplete(), this.completeAnimation();
          }
        }), this.resumingFrom && (this.resumingFrom.currentAnimation = this.currentAnimation), this.pendingAnimation = void 0;
      });
    }
    completeAnimation() {
      this.resumingFrom && (this.resumingFrom.currentAnimation = void 0, this.resumingFrom.preserveOpacity = void 0);
      const t = this.getStack();
      t && t.exitAnimationComplete(), this.resumingFrom = this.currentAnimation = this.animationValues = void 0, this.notifyListeners("animationComplete");
    }
    finishAnimation() {
      this.currentAnimation && (this.mixTargetDelta && this.mixTargetDelta($), this.currentAnimation.stop()), this.completeAnimation();
    }
    applyTransformsToTarget() {
      const t = this.getLead();
      let { targetWithTransforms: i, target: s, layout: o, latestValues: a } = t;
      if (!(!i || !s || !o)) {
        if (this !== t && this.layout && o && rt(this.options.animationType, this.layout.layoutBox, o.layoutBox)) {
          s = this.target || f();
          const r = F(this.layout.layoutBox.x);
          s.x.min = t.target.x.min, s.x.max = s.x.min + r;
          const n = F(this.layout.layoutBox.y);
          s.y.min = t.target.y.min, s.y.max = s.y.min + n;
        }
        p(i, s), E(i, a), L(this.projectionDeltaWithTransform, this.layoutCorrected, i, a);
      }
    }
    registerSharedNode(t, i) {
      this.sharedNodes.has(t) || this.sharedNodes.set(t, new pt()), this.sharedNodes.get(t).add(i);
      const o = i.options.initialPromotionConfig;
      i.promote({
        transition: o ? o.transition : void 0,
        preserveFollowOpacity: o && o.shouldPreserveFollowOpacity ? o.shouldPreserveFollowOpacity(i) : void 0
      });
    }
    isLead() {
      const t = this.getStack();
      return t ? t.lead === this : !0;
    }
    getLead() {
      var t;
      const { layoutId: i } = this.options;
      return i ? ((t = this.getStack()) === null || t === void 0 ? void 0 : t.lead) || this : this;
    }
    getPrevLead() {
      var t;
      const { layoutId: i } = this.options;
      return i ? (t = this.getStack()) === null || t === void 0 ? void 0 : t.prevLead : void 0;
    }
    getStack() {
      const { layoutId: t } = this.options;
      if (t)
        return this.root.sharedNodes.get(t);
    }
    promote({ needsReset: t, transition: i, preserveFollowOpacity: s } = {}) {
      const o = this.getStack();
      o && o.promote(this, s), t && (this.projectionDelta = void 0, this.needsReset = !0), i && this.setOptions({ transition: i });
    }
    relegate() {
      const t = this.getStack();
      return t ? t.relegate(this) : !1;
    }
    resetSkewAndRotation() {
      const { visualElement: t } = this.options;
      if (!t)
        return;
      let i = !1;
      const { latestValues: s } = t;
      if ((s.z || s.rotate || s.rotateX || s.rotateY || s.rotateZ || s.skewX || s.skewY) && (i = !0), !i)
        return;
      const o = {};
      s.z && N("z", t, o, this.animationValues);
      for (let a = 0; a < I.length; a++)
        N(`rotate${I[a]}`, t, o, this.animationValues), N(`skew${I[a]}`, t, o, this.animationValues);
      t.render();
      for (const a in o)
        t.setStaticValue(a, o[a]), this.animationValues && (this.animationValues[a] = o[a]);
      t.scheduleRender();
    }
    getProjectionStyles(t) {
      var i, s;
      if (!this.instance || this.isSVG)
        return;
      if (!this.isVisible)
        return Rt;
      const o = {
        visibility: ""
      }, a = this.getTransformTemplate();
      if (this.needsReset)
        return this.needsReset = !1, o.opacity = "", o.pointerEvents = O(t == null ? void 0 : t.pointerEvents) || "", o.transform = a ? a(this.latestValues, "") : "none", o;
      const r = this.getLead();
      if (!this.projectionDelta || !this.layout || !r.target) {
        const d = {};
        return this.options.layoutId && (d.opacity = this.latestValues.opacity !== void 0 ? this.latestValues.opacity : 1, d.pointerEvents = O(t == null ? void 0 : t.pointerEvents) || ""), this.hasProjected && !B(this.latestValues) && (d.transform = a ? a({}, "") : "none", this.hasProjected = !1), d;
      }
      const n = r.animationValues || r.latestValues;
      this.applyTransformsToTarget(), o.transform = yt(this.projectionDeltaWithTransform, this.treeScale, n), a && (o.transform = a(n, o.transform));
      const { x: u, y: m } = this.projectionDelta;
      o.transformOrigin = `${u.origin * 100}% ${m.origin * 100}% 0`, r.animationValues ? o.opacity = r === this ? (s = (i = n.opacity) !== null && i !== void 0 ? i : this.latestValues.opacity) !== null && s !== void 0 ? s : 1 : this.preserveOpacity ? this.latestValues.opacity : n.opacityExit : o.opacity = r === this ? n.opacity !== void 0 ? n.opacity : "" : n.opacityExit !== void 0 ? n.opacityExit : 0;
      for (const d in q) {
        if (n[d] === void 0)
          continue;
        const { correct: P, applyTo: D } = q[d], S = o.transform === "none" ? n[d] : P(n[d], r);
        if (D) {
          const g = D.length;
          for (let T = 0; T < g; T++)
            o[D[T]] = S;
        } else
          o[d] = S;
      }
      return this.options.layoutId && (o.pointerEvents = r === this ? O(t == null ? void 0 : t.pointerEvents) || "" : "none"), o;
    }
    clearSnapshot() {
      this.resumeFrom = this.snapshot = void 0;
    }
    // Only run on root
    resetTree() {
      this.root.nodes.forEach((t) => {
        var i;
        return (i = t.currentAnimation) === null || i === void 0 ? void 0 : i.stop();
      }), this.root.nodes.forEach(Z), this.root.sharedNodes.clear();
    }
  };
}
function kt(e) {
  e.updateLayout();
}
function Lt(e) {
  var l;
  const h = ((l = e.resumeFrom) === null || l === void 0 ? void 0 : l.snapshot) || e.snapshot;
  if (e.isLead() && e.layout && h && e.hasListeners("didUpdate")) {
    const { layoutBox: c, measuredBox: v } = e.layout, { animationType: A } = e.options, t = h.source !== e.layout.source;
    A === "size" ? G((r) => {
      const n = t ? h.measuredBox[r] : h.layoutBox[r], u = F(n);
      n.min = c[r].min, n.max = n.min + u;
    }) : rt(A, h.layoutBox, c) && G((r) => {
      const n = t ? h.measuredBox[r] : h.layoutBox[r], u = F(c[r]);
      n.max = n.min + u, e.relativeTarget && !e.currentAnimation && (e.isProjectionDirty = !0, e.relativeTarget[r].max = e.relativeTarget[r].min + u);
    });
    const i = j();
    L(i, c, h.layoutBox);
    const s = j();
    t ? L(s, e.applyTransform(v, !0), h.measuredBox) : L(s, c, h.layoutBox);
    const o = !ot(i);
    let a = !1;
    if (!e.resumeFrom) {
      const r = e.getClosestProjectingParent();
      if (r && !r.resumeFrom) {
        const { snapshot: n, layout: u } = r;
        if (n && u) {
          const m = f();
          k(m, h.layoutBox, n.layoutBox);
          const d = f();
          k(d, c, u.layoutBox), st(m, d) || (a = !0), r.options.layoutRoot && (e.relativeTarget = d, e.relativeTargetOrigin = m, e.relativeParent = r);
        }
      }
    }
    e.notifyListeners("didUpdate", {
      layout: c,
      snapshot: h,
      delta: s,
      layoutDelta: i,
      hasLayoutChanged: o,
      hasRelativeTargetChanged: a
    });
  } else if (e.isLead()) {
    const { onExitComplete: c } = e.options;
    c && c();
  }
  e.options.transition = void 0;
}
function wt(e) {
  V && x.totalNodes++, e.parent && (e.isProjecting() || (e.isProjectionDirty = e.parent.isProjectionDirty), e.isSharedProjectionDirty || (e.isSharedProjectionDirty = !!(e.isProjectionDirty || e.parent.isProjectionDirty || e.parent.isSharedProjectionDirty)), e.isTransformDirty || (e.isTransformDirty = e.parent.isTransformDirty));
}
function Et(e) {
  e.isProjectionDirty = e.isSharedProjectionDirty = e.isTransformDirty = !1;
}
function Ut(e) {
  e.clearSnapshot();
}
function Z(e) {
  e.clearMeasurements();
}
function Ft(e) {
  e.isLayoutDirty = !1;
}
function Ct(e) {
  const { visualElement: l } = e.options;
  l && l.getProps().onBeforeLayoutMeasure && l.notify("BeforeLayoutMeasure"), e.resetTransform();
}
function J(e) {
  e.finishAnimation(), e.targetDelta = e.relativeTarget = e.target = void 0, e.isProjectionDirty = !0;
}
function Ot(e) {
  e.resolveTargetDelta();
}
function bt(e) {
  e.calcProjection();
}
function Mt(e) {
  e.resetSkewAndRotation();
}
function It(e) {
  e.removeLeadSnapshot();
}
function K(e, l, h) {
  e.translate = C(l.translate, 0, h), e.scale = C(l.scale, 1, h), e.origin = l.origin, e.originPoint = l.originPoint;
}
function Q(e, l, h, c) {
  e.min = C(l.min, h.min, c), e.max = C(l.max, h.max, c);
}
function Nt(e, l, h, c) {
  Q(e.x, l.x, h.x, c), Q(e.y, l.y, h.y, c);
}
function zt(e) {
  return e.animationValues && e.animationValues.opacityExit !== void 0;
}
const _t = {
  duration: 0.45,
  ease: [0.4, 0, 0.1, 1]
}, tt = (e) => typeof navigator < "u" && navigator.userAgent && navigator.userAgent.toLowerCase().includes(e), et = tt("applewebkit/") && !tt("chrome/") ? Math.round : St;
function it(e) {
  e.min = et(e.min), e.max = et(e.max);
}
function Wt(e) {
  it(e.x), it(e.y);
}
function rt(e, l, h) {
  return e === "position" || e === "preserve-aspect" && !dt(H(l), H(h), 0.2);
}
function Ht(e) {
  var l;
  return e !== e.root && ((l = e.scroll) === null || l === void 0 ? void 0 : l.wasRoot);
}
export {
  Et as cleanDirtyNodes,
  Te as createProjectionNode,
  Q as mixAxis,
  K as mixAxisDelta,
  Nt as mixBox,
  wt as propagateDirtyNodes
};
