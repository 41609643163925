import { useContext as f, useRef as C, useInsertionEffect as x, useEffect as j } from "react";
import { PresenceContext as I } from "../../context/PresenceContext.js";
import { MotionContext as h } from "../../context/MotionContext/index.js";
import { useIsomorphicLayoutEffect as A } from "../../utils/use-isomorphic-effect.js";
import { LazyContext as S } from "../../context/LazyContext.js";
import { MotionConfigContext as b } from "../../context/MotionConfigContext.js";
import { optimizedAppearDataAttribute as v } from "../../animation/optimized-appear/data-id.js";
import { microtask as L } from "../../frameloop/microtask.js";
import { isRefObject as k } from "../../utils/is-ref-object.js";
import { SwitchLayoutGroupContext as H } from "../../context/SwitchLayoutGroupContext.js";
function T(o, u, e, i, c) {
  var n, a;
  const { visualElement: d } = f(h), l = f(S), r = f(I), M = f(b).reducedMotion, s = C();
  i = i || l.renderer, !s.current && i && (s.current = i(o, {
    visualState: u,
    parent: d,
    props: e,
    presenceContext: r,
    blockInitialAnimation: r ? r.initial === !1 : !1,
    reducedMotionConfig: M
  }));
  const t = s.current, g = f(H);
  t && !t.projection && c && (t.type === "html" || t.type === "svg") && R(s.current, e, c, g), x(() => {
    t && t.update(e, r);
  });
  const m = e[v], p = C(!!m && !(!((n = window.MotionHandoffIsComplete) === null || n === void 0) && n.call(window, m)) && ((a = window.MotionHasOptimisedAnimation) === null || a === void 0 ? void 0 : a.call(window, m)));
  return A(() => {
    t && (window.MotionIsMounted = !0, t.updateFeatures(), L.render(t.render), p.current && t.animationState && t.animationState.animateChanges());
  }), j(() => {
    t && (!p.current && t.animationState && t.animationState.animateChanges(), p.current && (queueMicrotask(() => {
      var w;
      (w = window.MotionHandoffMarkAsComplete) === null || w === void 0 || w.call(window, m);
    }), p.current = !1));
  }), t;
}
function R(o, u, e, i) {
  const { layoutId: c, layout: n, drag: a, dragConstraints: d, layoutScroll: l, layoutRoot: r } = u;
  o.projection = new e(o.latestValues, u["data-framer-portal-id"] ? void 0 : y(o.parent)), o.projection.setOptions({
    layoutId: c,
    layout: n,
    alwaysMeasureLayout: !!a || d && k(d),
    visualElement: o,
    /**
     * TODO: Update options in an effect. This could be tricky as it'll be too late
     * to update by the time layout animations run.
     * We also need to fix this safeToRemove by linking it up to the one returned by usePresence,
     * ensuring it gets called if there's no potential layout animations.
     *
     */
    animationType: typeof n == "string" ? n : "both",
    initialPromotionConfig: i,
    layoutScroll: l,
    layoutRoot: r
  });
}
function y(o) {
  if (o)
    return o.options.allowProjection !== !1 ? o.projection : y(o.parent);
}
export {
  T as useVisualElement
};
