import { NicklistIconFragment, NicklistIconType, User } from '@generated/graphql';
import { declareProps, inject, injectable, injectedComponent, injectProps, useService } from '@knuddels-app/DependencyInjection';
import { action, computed, fromPromise, observable, observer } from '@knuddels-app/mobx';
import { Container, FormattedText, FormattedTextDisplay, isUsingTouch, Spacer } from '@shared/components';
import { nickListUserEvent } from '@knuddelsModules/Channel/analytics';
import { $ContactsService } from '@knuddelsModules/Contacts';
import { $ProfileNavigationService } from '@knuddelsModules/Profile';
import { $UserService, UserImage, UserImageType } from '@knuddelsModules/UserData';
import * as React from 'react';
import { ActiveChannelData, NickListState } from '../../services';
import { ClickableUser } from '../Chat/ChatContent/ClickableUser';
import { GenderIcon } from '@knuddels-app/shared-components/GenderIcon';
import { NickListText } from './NickListText';
import { ChannelColorMode } from '../../services/channel/calculateChannelColorMode';
import { Box, Flex, FlexCol, Image, PointerEvent, Ripple, ScrollView, Text, ThemeOverride, toPointerHandler, resolveThemingValue, useTheme, resolveIsDarkColor } from '@knuddels/component-library';
import { measureLayoutRelativeToWindow } from '@knuddels-app/tools/measureLayoutRelativeToWindow';
import { setScrollLeft } from './setScrollLeft';
import './NickListEntry.css';
import { $CoreCommands } from '@knuddels-app/Commands';
export interface NickListEntryProps {
  user: ActiveChannelData['participants'][0]['user'];
  isScrolling?: boolean;
  showFullScreen?: boolean;
  nicklistState?: NickListState;
  colorMode: ChannelColorMode;
}
type Props = NickListEntryProps;
const QUICK_WHOIS_DELAY = 50;
@injectable()
class NickListEntryModel {
  private readonly profileNavigationService = fromPromise(this.getProfileNavigationService());
  @observable
  private _hovered = false;
  private _ref = React.createRef<any>();
  private timeoutId: any | undefined = undefined;
  constructor(@injectProps()
  private readonly props: Props, @inject.lazy($ProfileNavigationService)
  private readonly getProfileNavigationService: typeof $ProfileNavigationService.TLazy, @inject($ContactsService)
  private readonly contactsService: typeof $ContactsService.T) {}
  public readonly handleClick = (): void => {
    nickListUserEvent.track('User_Clicked');
    this.cancelOpenQuickWhois();
  };
  @action.bound
  public handleMouseEnter(e: PointerEvent): void {
    if (isUsingTouch()) {
      return;
    }
    e.preventDefault();
    this._hovered = true;
  }
  @action.bound
  public handleMouseMove(): void {
    if (isUsingTouch()) {
      return;
    }
    this.delayOpenQuickWhois();
  }
  private readonly delayOpenQuickWhois = (): void => {
    if (
    // don't trigger `showQuickWhois` while already open or in process of opening it
    this.timeoutId || !!this.openedQuickWhoisUserId ||
    // don't show quickwhois here
    this.props.showFullScreen) {
      return;
    }
    this.timeoutId = setTimeout(() => {
      measureLayoutRelativeToWindow(this.ref.current).then(({
        x,
        y,
        width,
        height
      }) => {
        this.getProfileNavigationService().then(service => service.showQuickWhois(this.props.user.id, x, y, width, height));
      }).catch((err: any): any => {
        console.warn(err);
      }).then(() => this.timeoutId = undefined);
    }, QUICK_WHOIS_DELAY);
  };
  @action.bound
  public handleMouseLeave(): void {
    this._hovered = false;
    this.cancelOpenQuickWhois();
  }
  private readonly cancelOpenQuickWhois = (): void => {
    clearTimeout(this.timeoutId);
    this.timeoutId = undefined;
  };
  @computed
  public get isActive(): boolean {
    const isOtherQuickWhoisOpen = this.openedQuickWhoisUserId && this.openedQuickWhoisUserId !== this.props.user.id;
    return this._hovered && !isOtherQuickWhoisOpen || this.openedQuickWhoisUserId === this.props.user.id;
  }
  private get openedQuickWhoisUserId(): User['id'] | undefined {
    return this.profileNavigationService.case({
      fulfilled: service => {
        const overlay = service.findQuickWhoisOverlay();
        return overlay && overlay.tag.data.userId;
      },
      pending: () => undefined,
      rejected: () => undefined
    });
  }
  public get ref(): React.RefObject<any> {
    return this._ref;
  }
  @computed
  public get isContact(): boolean {
    return this.contactsService.isUserInActiveContactTypes(this.props.user.id);
  }
}
export const NickListEntry = injectedComponent({
  name: 'NickListEntry',
  model: NickListEntryModel,
  props: declareProps<Props>()
}, ({
  model,
  user,
  nicklistState,
  isScrolling,
  colorMode
}) => {
  const [parentIndex, setParentIndex] = React.useState<number | null>(null);
  const ref = React.useRef<HTMLDivElement>(null);
  React.useEffect(() => {
    const getParentDataIndex = (): void => {
      const parentNode = (ref.current?.parentNode as HTMLElement);
      if (!parentNode) {
        return;
      }
      if (parentNode.dataset && parentNode.dataset.index) {
        setParentIndex(parseInt(parentNode.dataset.index, 10));
      }
    };
    getParentDataIndex();
  }, []);
  return <div onMouseEnter={toPointerHandler(model.handleMouseEnter)} onMouseLeave={toPointerHandler(model.handleMouseLeave)} onPointerMove={model.handleMouseMove} ref={ref} className={_c0}>
				<ClickableUser uid={user.id} onClick={model.handleClick} openedFromTrackingLabel="NickListEntry">
					{model.isActive && <NickListHoverBackground />}
					<div ref={model.ref}
      // limit nicklist height (to prevent icons from stretching the entry)
      style={{
        height: resolveThemingValue(nicklistHeight, "sizes", useTheme())
      }} className={_c1}>
						{isScrolling ? <div className={_c2 + ("contentLightBg" ? resolveIsDarkColor("contentLightBg", useTheme()) ? " content-is-dark" : " content-is-light" : "")} /> : <UserImage type={UserImageType.NICKLIST} uid={user.id} />}

						<AnimatedNickAndIcons animate={model.isActive} bold={model.isContact} nick={user.nick} nicklistState={nicklistState} colorMode={colorMode} index={parentIndex} />
						<GenderIcon gender={user.gender} />
						<Spacer size={'tiny'} />
						{!!user.age && <Text className={_c3}>{user.age}</Text>}
					</div>
					<Ripple />
				</ClickableUser>
			</div>;
});
function NickListHoverBackground(): React.ReactElement {
  return <>
			<Container className={'NickListHoverArrow'} />
			<Container className={'NickListHoverBackground'} />
		</>;
}
const AnimatedNickAndIcons = observer('AnimatedNickAndIcons', (props: {
  nick: string;
  bold: boolean;
  nicklistState?: NickListState;
  animate: boolean;
  colorMode: ChannelColorMode;
  index: number | null;
}): React.ReactElement => {
  const scrollViewRef = React.useRef<typeof ScrollView>();
  const currentUserStatus = useService($UserService).currentUser?.status;
  const coreCommands = useService($CoreCommands);
  const disableColors = !currentUserStatus || currentUserStatus === 'NORMAL';
  React.useEffect(() => {
    if (scrollViewRef.current) {
      const newScrollLeft = props.animate ? 'end' : 'start';
      setScrollLeft(scrollViewRef.current, newScrollLeft);
    }
  }, [props.animate]);
  const OldVersion = <div style={{
    height: resolveThemingValue(UserImageType.NICKLIST, "sizes", useTheme())
  }} className={_c4}>
				<div ref={scrollViewRef} className={_c5 + ({
      horizontal: " scroll-direction-horizontal "
    }['horizontal'] ?? "") + (true ? " hide-scroll-indicator " : "")}>
					<div style={{
        height: resolveThemingValue(UserImageType.NICKLIST, "sizes", useTheme())
      }} className={_c6}>
						{props.nicklistState && <IconList icons={props.nicklistState.iconsByType[NicklistIconType.Prefix]} />}
						<NickListText bold={props.bold} color={disableColors ? undefined : props.nicklistState?.color} colorMode={props.colorMode}>
							{props.nick}
						</NickListText>
						{props.nicklistState && <IconList icons={props.nicklistState.iconsByType[NicklistIconType.Suffix]} />}
					</div>
				</div>
			</div>;
  if (!coreCommands.isNicklistEffectTestEnabled || props.index && props.index % coreCommands.nicklistEffectSettings.affectEveryNthUser !== 0) {
    return OldVersion;
  }
  return <div style={{
    height: resolveThemingValue(UserImageType.NICKLIST, "sizes", useTheme())
  }} className={_c7}>
				<div style={{
      top: '50%',
      left: 0,
      transform: 'translateY(-50%)'
    }} className={_c8}>
					{coreCommands.nicklistEffectSettings.backgroundImageEnabled && <Image src={require('../../assets/zug-bg.png')} alt="" className={_c9} />}
				</div>
				<div style={{
      top: '50%',
      left: 0,
      transform: 'translateY(-50%)'
    }} className={_c10}>
					{coreCommands.nicklistEffectSettings.backgroundImageEnabled && (coreCommands.nicklistEffectSettings.backgroundImageType === 'gif' ? <Image src={require('../../assets/zug-ani.gif')} alt="" className={_c11} /> : <Image src={require('../../assets/zug-ani.webp')} alt="" className={_c12} />)}
				</div>
				<div ref={scrollViewRef} className={_c13}>
					<div style={{
        height: resolveThemingValue(UserImageType.NICKLIST, "sizes", useTheme())
      }} className={_c14}>
						{props.nicklistState && <IconList icons={props.nicklistState.iconsByType[NicklistIconType.Prefix]} />}
						<NickListText bold={props.bold} color={disableColors ? undefined : props.nicklistState?.color} colorMode={props.colorMode} enableNicklistEffect nicklistEffectType={coreCommands.nicklistEffectSettings.nickAnimationType && coreCommands.nicklistEffectSettings.nickAnimationEnabled ? coreCommands.nicklistEffectSettings.nickAnimationType : 'disabled'}>
							{props.nick}
						</NickListText>
						{props.nicklistState && <IconList icons={props.nicklistState.iconsByType[NicklistIconType.Suffix]} />}
					</div>
				</div>
				{coreCommands.nicklistEffectSettings.foregroundAnimationEnabled && <Box position="absolute" className="foregroundAnimation" style={{
      top: 'calc(50% - 7px)',
      left: 0,
      transform: 'translateY(-50%)'
    }}>
						<Image src={require('../../assets/knuddel.png')} alt="" className={_c16} />
					</Box>}
			</div>;
});
const IconList = observer('IconList', (props: {
  icons: readonly NicklistIconFragment[];
}): React.ReactElement => {
  return <>
				{props.icons.map(icon => <div key={icon.iconName} style={{
      marginLeft: resolveThemingValue(icon.listType === NicklistIconType.Suffix ? (2 as ThemeOverride) : undefined, "spacing", useTheme()),
      marginRight: resolveThemingValue(icon.listType === NicklistIconType.Prefix ? 'tiny' : undefined, "spacing", useTheme())
    }} className={_c17}>
						<FormattedTextDisplay text={FormattedText.fromJsonString(icon.iconFormattedText)} textProps={{}} />
					</div>)}
			</>;
});
export const nicklistHeight = 44;
const _c0 = " Knu-FlexCol ";
const _c1 = " Knu-Flex px-base py-6px alignItems-center ";
const _c2 = " Knu-FlexCol height-32px width-32px borderRadius-28px mt-18px mb-22px bg-contentLightBg ";
const _c3 = "  ";
const _c4 = " Knu-Flex position-relative overflow-hidden flex-1 ";
const _c5 = " Knu-ScrollView flex-1 pointerEvents-none ";
const _c6 = " Knu-Flex position-relative alignItems-center overflow-visible px-small ";
const _c7 = " Knu-Flex position-relative overflow-visible flex-1 ";
const _c8 = " Knu-Box position-absolute ";
const _c9 = "  ";
const _c10 = " Knu-Box position-absolute ";
const _c11 = "  ";
const _c12 = "  ";
const _c13 = " Knu-Flex flex-1 pointerEvents-none overflow-visible ";
const _c14 = " Knu-Flex position-relative alignItems-center overflow-visible px-small ";
const _c15 = " Knu-Box position-absolute ";
const _c16 = "  ";
const _c17 = " Knu-FlexCol position-relative ";