import * as React from 'react';
import { Flex, Image, ThemeOverride, Text, IconSend, toPointerHandler, resolveThemingValue, resolveIsDarkColor, useTheme } from '@knuddels/component-library';
import { declareFormat, formatMessage } from '@knuddels-app/i18n';
import { $MessengerConversationService, $MessengerMiniChatService, $MessengerNavigationService, $SendMessageService } from '@knuddelsModules/Messenger';
import { ConversationMessageFragment, FullConversationFragment, FullConversationWithoutMessagesFragment, MessengerConversation, User } from '@generated/graphql';
import { IModel, inject, injectable, injectProps } from '@knuddels-app/DependencyInjection';
import { declareProps, injectedComponent } from '@knuddels-app/DependencyInjection/injectedComponent';
import { observable, runInAction } from '@knuddels-app/mobx';
import { RichTextEditor, RichTextEditorRef } from '@shared/components/RichText/RichTextEditor';
interface Props {
  user: Pick<User, 'id'>;
  conversationId: MessengerConversation['id'];
  trackingCallbacks: {
    conversationOpenClicked(): void;
    messageSent(): void;
    enterUsed(): void;
    sendButtonUsed(): void;
  };
  onClose(): void;
}
@injectable()
class ProfileInputBarModel implements IModel {
  private readonly conversation: FullConversationWithoutMessagesFragment | undefined;
  @observable
  private hasSentMessage = false;
  constructor(@injectProps()
  private readonly props: Props, @inject($MessengerConversationService)
  private readonly messengerConversationService: typeof $MessengerConversationService.T, @inject($MessengerNavigationService)
  private readonly messengerNavigationService: typeof $MessengerNavigationService.T, @inject($SendMessageService)
  private readonly sendMessageService: typeof $SendMessageService.T, @inject($MessengerMiniChatService)
  private readonly messengerMiniChatService: typeof $MessengerMiniChatService.T) {
    this.conversation = this.messengerConversationService.getCachedOverviewConversation(this.props.conversationId);
  }
  componentDidMount(): void {}
  public sendMessage = async (originalText: string): Promise<void> => {
    const text = originalText.trim().replace(/\n/g, '#');
    runInAction('Set has sent message', () => this.hasSentMessage = true);
    const r = await this.sendMessageService.sendMessage(this.props.conversationId, text);
    r.match({
      ok: () => this.props.trackingCallbacks.messageSent(),
      error: () => runInAction('Reset has sent message', () => this.hasSentMessage = false)
    });
  };
  public openConversation = (): void => {
    this.props.trackingCallbacks.conversationOpenClicked();
    this.messengerMiniChatService.openConversation(this.props.conversationId, 'ProfileInputBar');
    this.props.onClose();
  };
  public get showInputBar(): boolean {
    if (this.hasSentMessage) {
      return false;
    }
    const lastOwnMessage = this.conversation && getLastOwnMessageWithinTime(this.props.user.id, this.conversation, 12 * 60 * 60 * 1000);
    return lastOwnMessage === undefined;
  }
}
export const ProfileInputBar = injectedComponent({
  name: 'ProfileInputBar',
  model: ProfileInputBarModel,
  props: declareProps<Props>()
}, ({
  model
}) => {
  const editorRef = React.useRef<RichTextEditorRef>(null);
  const [value, setValue] = React.useState('');
  const disabled = !value.trim().length;
  const submit = () => {
    if (disabled) {
      return;
    }
    model.sendMessage(value);
  };
  if (model.showInputBar) {
    return <div className={_c0}>
					<RichTextEditor onEnter={submit} onChange={setValue} ref={editorRef} placeholder={formatMessage(declareFormat({
        id: 'PROFILE_INPUT_BAR_PLACEHOLDER',
        defaultFormat: 'Write a message...'
      }))} />

					<IconSend size={'large'} disabled={disabled} onPress={submit} />
				</div>;
  } else {
    return <div onClick={toPointerHandler(model.openConversation)} className={_c1 + ("contentLightBg" ? resolveIsDarkColor("contentLightBg", useTheme()) ? " content-is-dark" : " content-is-light" : "")}>
					<Image src={require('../../assets/message-sent.gif')} alt={''} className={_c2} />
					<Text type={'body1'} state={'highlighted'} className={_c3}>
						{formatMessage(declareFormat({
          id: 'MESSAGE_SENT',
          defaultFormat: 'Message sent!'
        }))}
					</Text>
				</div>;
  }
});
function getLastOwnMessageWithinTime(otherParticipant: User['id'], conversation: Partial<Omit<FullConversationFragment, 'otherParticipants'>>, maxAge: number): ConversationMessageFragment {
  const msgs = conversation.conversationMessages && conversation.conversationMessages.messages;
  if (!msgs) {
    return undefined;
  }
  const now = Date.now();
  for (let i = msgs.length - 1; i >= 0; i--) {
    const msg = msgs[i];
    if (now - +msg.timestamp > maxAge) {
      break;
    } else if (msg.sender.id !== otherParticipant) {
      return msg;
    }
  }
  return undefined;
}
const _c0 = " Knu-Flex flex-1 alignItems-center p-small gap-tiny maxHeight-200px ";
const _c1 = " Knu-Flex cursor-pointer py-14px px-base bg-contentLightBg placeItems-center ";
const _c2 = " width-24px height-15px ";
const _c3 = " ml-small ";