import * as React from 'react';
import { useService } from '@knuddels-app/DependencyInjection';
import { $CurrentEndpointStore } from '@knuddels-app/Connection';
import { Z_INDEX } from '@shared/components';
import { $OverlayService } from '@knuddels-app/overlays/serviceIds';
import { $AppService } from '../../providedServices';
import { runInAction } from '@knuddels-app/mobx';
import { ModalWrapper } from '@knuddels/component-library';
import { $Environment } from '@knuddels-app/Environment';
import { $SystemAppService } from '../../../SystemApps';
import { useLazyService } from '@knuddels-app/ModuleSystem';
import { $KeyboardService } from '@knuddels-app/Keyboard';
const OVERLAY_SYSTEM_APP_TEST_URL = 'https://chatproxytest.knuddels.de/static/system-app-overlay-presenter.html?v=3';
const OVERLAY_SYSTEM_APP_LIVE_URL = 'https://chatproxy.knuddels.de/static/system-app-overlay-presenter.html?v=3';
export const SystemAppOverlayPresenter = () => {
  const [openerAppId, setOpenerAppId] = React.useState<string | null>(null);
  const endpoint = useService($CurrentEndpointStore);
  const overlayService = useService($OverlayService);
  const keyboardService = useService($KeyboardService);
  const systemAppServiceGetter = useLazyService($SystemAppService);
  const appService = useService($AppService);
  const env = useService($Environment);
  const modalRef = React.useRef<HTMLDivElement | null>(null);
  const isLive = endpoint.currentEndpoint.id === 'DE';
  const ref = React.useRef<HTMLIFrameElement | null>(null);
  const closeOverlay = React.useCallback(() => {
    if (!ref.current) {
      return;
    }
    ref.current.contentWindow.postMessage({
      type: 'HIDE_MODAL'
    }, '*');
    ref.current.style.pointerEvents = 'none';
    ref.current.style.opacity = '0';
    ref.current.style.transform = 'translateY(-100%)';
    setOpenerAppId(null);
    systemAppServiceGetter().then(s => {
      s.setOverlayOpened(false);
    });
  }, []);
  const sendCloseToApp = (appId: string) => {
    const app = appService.appViewers.map(v => v.getApp(appId)).filter(Boolean)[0];
    if (app) {
      app.sendEventToWebview('CLOSE_OVERLAY', {});
      return true;
    } else {
      return false;
    }
  };
  React.useEffect(() => {
    if (openerAppId) {
      const handler = (e: KeyboardEvent) => {
        if (e.key === 'Escape') {
          sendCloseToApp(openerAppId);
          e.stopPropagation();
        }
      };
      window.addEventListener('keydown', handler, true);
      const d = env.nativeInterface?.prependBackHandler(() => {
        return sendCloseToApp(openerAppId);
      });
      return () => {
        d?.dispose();
        window.removeEventListener('keydown', handler, true);
      };
    }
  }, [closeOverlay, env.nativeInterface, openerAppId]);
  React.useEffect(() => {
    runInAction('set isSystemAppOverlayVisible', () => {
      overlayService.isSystemAppOverlayVisible = !!openerAppId;
    });
    if (!openerAppId) {
      return;
    }
    return appService.registerAppCloseListener(openerAppId, () => {
      closeOverlay();
    });
  }, [openerAppId]);
  React.useEffect(() => {
    const listener = (ev: MessageEvent) => {
      if (!ref.current) {
        return;
      }
      if (ev.data?.type === 'PRESENTER_SHOW_OVERLAY') {
        ref.current.style.pointerEvents = 'all';
        ref.current.style.opacity = '1';
        ref.current.style.transform = 'translateY(0)';
        /**
         * overlays are stacked via index.
         * gets the largest index and uses it to ensure the overlay is rendered above
         */
        modalRef.current.style.zIndex = Z_INDEX.OVERLAY + overlayService.overlays.length + (keyboardService.currentlyVisibleCustomKeyboard === 'smiley' || keyboardService.currentlyVisibleCustomKeyboard === 'feature' ? 2 : 1) + '';
        setOpenerAppId(ev.data.appId);
      } else if (ev.data?.type === 'PRESENTER_HIDE_OVERLAY') {
        closeOverlay();
      }
    };
    window.addEventListener('message', listener);
    return () => {
      window.removeEventListener('message', listener);
    };
  }, []);
  return <ModalWrapper innerRef={modalRef} pointerEvents={'none'}>
			<iframe name={'system-app-overlay-presenter-v3'} style={{
      display: 'block',
      position: 'absolute',
      pointerEvents: 'none',
      top: 'env(safe-area-inset-top)',
      left: 0,
      right: 0,
      height: 'calc(100% - env(safe-area-inset-bottom))',
      opacity: 0,
      width: '100%',
      transform: 'translateY(-100%)'
    }} allow={'microphone;camera;clipboard-write'} frameBorder={0} ref={ref} src={isLive ? OVERLAY_SYSTEM_APP_LIVE_URL : OVERLAY_SYSTEM_APP_TEST_URL} />
		</ModalWrapper>;
};