import * as React from 'react';
import { inject, injectable } from '@knuddels-app/DependencyInjection';
import { Disposable } from '@knuddels/std';
import { $Environment } from '@knuddels-app/Environment';
import { autorunWhen } from '@knuddels-app/mobx';
import { $MessengerNavigationService, $MessengerService } from '../../providedServices';
import { $AuthService } from '@knuddels-app/Connection/serviceIds';
import { $OverlayService } from '@knuddels-app/overlays';
import { StAppDisconnectedModal } from '@knuddelsModules/Messenger/bundle/stappmessenger/StAppDisconnectedModal';
import { CONTACT_FILTER_SETTINGS_APP_ID } from '@shared/constants';
@injectable()
export class StappMessengerInitService implements Disposable {
  public readonly dispose = Disposable.fn();
  constructor(@inject($MessengerService)
  messengerService: typeof $MessengerService.T, @inject($MessengerNavigationService)
  private readonly messengerNavigationService: typeof $MessengerNavigationService.T, @inject($Environment)
  private readonly environment: typeof $Environment.T, @inject($AuthService)
  authService: typeof $AuthService.T, @inject($OverlayService)
  private readonly overlayService: typeof $OverlayService.T) {
    const stappMessenger = environment.messengerSystemAppInterface;
    if (stappMessenger.client.pageData.route === 'ContactFilterSettings') {
      this.openContactFilterSettings();
    } else {
      // initially open conversation
      this.openConversation(stappMessenger.client.pageData.conversationId);
    }
    stappMessenger.client.addEventListener('connected', () => {
      const activeConversationId = messengerService.getOpenConversationId();
      // reregister webview in server
      stappMessenger.client.sendEvent('reconnect', {
        activeConversationIdString: activeConversationId
      });
    });
    this.dispose.track(() => stappMessenger.client.removeEventListener('connected'));
    this.dispose.track(autorunWhen({
      name: 'show error modal if logged out'
    }, () => authService.state.kind === 'loggedOut', () => {
      if (authService.state.kind === 'loggedOut') {
        console.error(authService.state.error);
        // TODO fix text? trigger Client.close() + /messenger to restart the messenger?
        overlayService.showTextOverlay({
          text: 'Leider hat der Messenger die Verbindung zum Server verloren. ' + 'Derzeit kann das nur durch einen Neustart der ganzen Desktop App (StApp) repariert werden. ' + 'Wir bitten um euer Verständnis.',
          headline: 'Messenger ist gerade nicht verfügbar.',
          buttonText: 'Ok'
        });
      }
    }));
    stappMessenger.client.addEventListener('switchToConversation', e => {
      this.openConversation(e.data.conversationId);
    });
    this.dispose.track(() => stappMessenger.client.removeEventListener('switchToConversation'));
    stappMessenger.client.addEventListener('openContactFilterSettings', () => {
      this.openContactFilterSettings();
    });
    this.dispose.track(() => stappMessenger.client.removeEventListener('openContactFilterSettings'));
    if (!stappMessenger.client.pageData.isSidebarOverview) {
      stappMessenger.client.addEventListener('disconnected', () => {
        overlayService.showOverlay({
          view: <StAppDisconnectedModal />
        });
      });
      this.dispose.track(() => stappMessenger.client.removeEventListener('disconnected'));
    }
  }
  openConversation(convId: string | undefined): void {
    if (convId) {
      this.messengerNavigationService.openConversation(convId, 'StappMessenger');
    }
  }
  private openContactFilterSettings(): void {
    this.environment.messengerSystemAppInterface.client.executeSlashCommand('/opensystemapp ' + CONTACT_FILTER_SETTINGS_APP_ID);
  }
}