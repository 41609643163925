import { FullConversationFragment } from '@generated/graphql';
import { useService } from '@knuddels-app/DependencyInjection';
import { ScreenWidth, useIsStackedLayout, useScreenWidth } from '@knuddels-app/Screen';
import { InsideOverlayViewContext } from '@knuddels-app/layout';
import { observer } from '@knuddels-app/mobx';
import { Box, Flex, Text, resolveThemingValue, resolveIsDarkColor, useTheme } from '@knuddels/component-library';
import { TypingIndicator } from '@knuddelsModules/Messenger/bundle/components/TypingIndicator';
import { $UserDataComponents } from '@knuddelsModules/UserData';
import { userIsJames } from '@shared/helper/user';
import * as React from 'react';
import { useContext } from 'react';
import { useConversationTitle } from '../../utils/getConversationTitle';
import { useConfigureNavBar, useParams, useRouter } from '../MessengerRouter';
import { ConversationBackground } from './ConversationBackground';
import { ConversationBody } from './ConversationBody/ConversationBody';
import { ConversationOverflowMenu } from './ConversationOverflowMenu';
import { OnlineAndChannelInfo } from './OnlineAndChannelInfo';
import { ReportButton } from './ReportButton';
import { WithConversationWithMessages } from './WithConversationWithMessages';
const useSetupTitleBar = (conversation: FullConversationFragment | undefined) => {
  const overlay = useContext(InsideOverlayViewContext);
  const params = useParams('conversation');
  const userDataComponents = useService($UserDataComponents);
  const router = useRouter();
  const otherParticipant = conversation?.otherParticipants?.[0] || undefined;
  const {
    text
  } = useConversationTitle(otherParticipant);
  const shouldShowReportButton = !!otherParticipant && !!otherParticipant.nick && !userIsJames(otherParticipant);
  const title = <div className={_c0}>
			<div className={_c1}>
				{!text ? <div className={_c2 + ("contentBg" ? resolveIsDarkColor("contentBg", useTheme()) ? " content-is-dark" : " content-is-light" : "")} /> : typeof text === 'string' ? <Text type={'title'} className={_c3}>{text}</Text> : text('title', 'primary', false)}
			</div>
		</div>;
  const user = userDataComponents.useUserOnlineStatusWithChannel(otherParticipant && otherParticipant.id);
  const subTitle = text && user ? <OnlineAndChannelInfo user={{
    ...user,
    ...otherParticipant
  }} /> : <div className={_c4 + ("contentBg" ? resolveIsDarkColor("contentBg", useTheme()) ? " content-is-dark" : " content-is-light" : "")} />;
  const overlayMode = overlay && params.initialView;
  useConfigureNavBar({
    title,
    navIconStyle: overlayMode ? 'close' : 'auto',
    onNavIconPress: overlayMode ? e => {
      e.preventDefault();
      overlay.close();
    } : undefined,
    iconList: [shouldShowReportButton && <ReportButton key="IconReport" otherParticipant={otherParticipant} />, <ConversationOverflowMenu key="overflow" conversation={conversation} onConversationDeleted={router.pop} />],
    subtitleAdornment: subTitle
  }, [text, shouldShowReportButton, otherParticipant, user, conversation?.visibility, conversation?.readState.markedAsUnread, conversation?.readState.unreadMessageCount]);
};
const useTypingIndicator = () => {
  const [isTyping, setIsTyping] = React.useState(false);
  // eslint-disable-next-line @typescript-eslint/ban-types
  const revertNavBarRef = React.useRef<Function | null>();
  const {
    updateNavBar
  } = useRouter();
  React.useEffect(() => {
    if (!isTyping) {
      revertNavBarRef.current?.();
      revertNavBarRef.current = null;
      return;
    }
    if (revertNavBarRef.current) {
      return;
    }
    revertNavBarRef.current = updateNavBar({
      subtitleAdornment: <TypingIndicator />
    });
  }, [isTyping]);
  return setIsTyping;
};
export const Conversation: React.FC = observer('Conversation', () => {
  const isStackedLayout = useIsStackedLayout();
  const screenWidth = useScreenWidth();
  const {
    conversationId,
    scrollToMessageId
  } = useParams('conversation');
  const setIsTyping = useTypingIndicator();
  return <WithConversationWithMessages id={conversationId}>
			{(conversation, loading, fetchMoreMessages, initialLoading) => {
      useSetupTitleBar(conversation);
      return <ConversationBackground>
						{conversation ? <ConversationBody
        // fixes inconsistencies when switching conversations (resets old state).
        // also triggers componentDidMount to tell the MessengerService which conversation was opened.
        key={conversation.id} onOtherParticipantTypingChange={setIsTyping} conversation={conversation} loading={loading} initialLoading={initialLoading} scrollToMessageId={scrollToMessageId} indentInput={!isStackedLayout || screenWidth === ScreenWidth.S} fetchMoreMessages={fetchMoreMessages} /> : null /* could actually display an error? */}
					</ConversationBackground>;
    }}
		</WithConversationWithMessages>;
});
const _c0 = " Knu-Flex alignItems-center ";
const _c1 = " Knu-Flex alignItems-center flex-1 ";
const _c2 = " Knu-Box height-16px width-128px mt-tiny mb-tiny borderRadius-tiny bg-contentBg ";
const _c3 = "  ";
const _c4 = " Knu-Box width-74px height-12px bg-contentBg borderRadius-tiny my-tiny ";