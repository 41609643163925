import { $LocationService, LocationInfo } from '@knuddels-app/location';
import { BugIndicatingError, Disposable } from '@knuddels/std';
import { inject, injectable } from '@knuddels-app/DependencyInjection';
import { action, autorun, computed, observable, runInAction } from '@knuddels-app/mobx';
import { LaidoutView, layout, Layout } from './layout';
import { $ScreenService, ScreenWidth } from '@knuddels-app/Screen';
import { Position, RenderedView, ViewConfig, ViewId, ViewIdWithStateEffect, ViewState } from './ViewId';
import { $LocationToViewsMapping } from './serviceIds';
import { $ViewProvider } from './extensionPoints';
import * as React from 'react';
import { MountingAnimation } from './MountingAnimation';
import { $AppLoadingService } from '@knuddels-app/AppLoadingService';
import { $KeyboardService } from '@knuddels-app/Keyboard';
import { $GenericUserEventService } from '@knuddels-app/analytics/generic';
export const InsideOverlayViewContext = React.createContext<{
  close(): void;
} | null>(null);
export interface ViewRegistry {
  registerView<TState extends ViewState>(view: ViewConfig<TState>): void;
  registerDynamicView<TState extends ViewState>(dynamicViewProvider: DynamicViewProvider<TState>): void;
}
@injectable()
export class ViewService implements ViewRegistry {
  @computed
  public get visibleViews(): ReadonlyArray<VisibleView> {
    return this._layout.visibleViews;
  }
  @computed
  public get persistedViews(): ReadonlyArray<VisibleView> {
    return Array.from(this._persistedViews.values());
  }
  public readonly dispose = Disposable.fn();
  private readonly dynamicViews: DynamicViewProvider<any>[] = [];
  private readonly registeredViews = new Map<string, ViewConfig<any>>();

  // necessary if multiple view operations are queued before the location is updated
  private visibleViewsDuringUpdateState: ReadonlyArray<VisibleView> | undefined = undefined;
  private readonly lastViewStates = new Map<ViewId<any>, ViewState>();
  private readonly lastOverlayStates = new Map<ViewId<any>, ViewState>();
  private lastOpenedViewId: ViewId<any> | undefined = undefined;
  @observable
  private readonly _persistedViews = new Map<ViewId<any>, VisibleView<any>>();
  private pendingViews: VisibleView<any>[] = [];
  @observable.ref
  private _layout: Layout = new Layout({
    views: [],
    overlays: [],
    persistedViewsByPosition: {
      Side: [],
      Main: []
    }
  });
  public get layout(): Layout {
    return this._layout;
  }
  constructor(@inject($LocationService)
  private readonly locationService: typeof $LocationService.T, @inject($ScreenService)
  private readonly screenService: typeof $ScreenService.T, @inject($LocationToViewsMapping)
  private readonly locationToViewsMapping: typeof $LocationToViewsMapping.T, @inject.many($ViewProvider)
  viewProviders: (typeof $ViewProvider.T)[], @inject($AppLoadingService)
  private readonly appLoadingService: typeof $AppLoadingService.T, @inject($KeyboardService)
  private readonly keyboardService: typeof $KeyboardService.T, @inject($GenericUserEventService)
  private readonly genericUserEventService: typeof $GenericUserEventService.T) {
    for (const provider of viewProviders) {
      provider.registerViews(this);
    }
    this.autostartBackgroundViews();
    this.dispose.track(autorun({
      name: 'Update layout from screenWidth and currentLocation'
    }, () => {
      if (this.appLoadingService.isReady) {
        this.visibleViewsDuringUpdateState = undefined;
        this.updateLayout(this.locationService.currentLocation, this.screenService.screenWidth);
      }
    }));
  }
  @action
  private updateLayout(location: LocationInfo, screenWidth: ScreenWidth): void {
    const visibleViews = this.locationToViewsMapping.getViewsFromLocation(location, id => id ? this.findViewConfig(id) : undefined, (config, state) => new VisibleView(config, state, this));
    for (const v of visibleViews) {
      // we need to update the last view states with the states from the history
      this.lastViewStates.set(v.viewConfig.viewId, v.state);
      runInAction('UpdatePersistedViews', () => {
        if (typeof v.viewConfig.persistIndex === 'number') {
          this._persistedViews.set(v.viewConfig.viewId, v);
        }
      });
    }
    const newLayout = layout({
      visibleViews,
      persistedViews: this.persistedViews,
      screenWidth,
      views: [...this.registeredViews.values()],
      createVisibleView: view => {
        const state = this.getLastOrDefaultViewState(view);
        return new VisibleView(view, state, this);
      }
    });
    const additionalOverlaysInLocation: {
      state: unknown;
      viewId: string;
    }[] = (location.state as any)?.additionalOverlays ?? [];
    newLayout.additionalOverlays = (additionalOverlaysInLocation.map((o: any) => {
      console.log(o.id, o.viewId.id);
      const config = this.findViewConfig(o.viewId);
      if (!config) {
        return null;
      }
      return this.getVisibleViewForOverlay(config.viewId, o.state);
    }).filter(Boolean) as VisibleView[]);
    if (!newLayout.equals(this._layout)) {
      this._layout = newLayout;
    }

    // Fix the location because sometimes screenWidth closes views
    this.updateLocation(newLayout.visibleViews, 'replace');
  }
  @action
  private updateLocation(visibleViews: ReadonlyArray<VisibleView>, locationUpdateMode: 'push' | 'replace'): void {
    const prevLocation = this.locationService.currentLocation;
    const loc = this.locationToViewsMapping.getLocationFromVisibleViews(visibleViews, this.locationService.currentLocation);
    if (!loc.state) {
      (loc as any).state = {};
    }
    (loc.state as any).additionalOverlays = (prevLocation.state as any)?.additionalOverlays ?? [];
    if (loc.equals(prevLocation)) {
      // Don't update if they are equal to prevent endless loop
      return;
    }
    this.visibleViewsDuringUpdateState = visibleViews;
    if (locationUpdateMode === 'replace') {
      this.locationService.replace(loc);
    } else {
      this.locationService.push(loc);
    }
  }
  private autostartBackgroundViews(): void {
    this.registeredViews.forEach(viewConfig => {
      if (typeof viewConfig.autostartInBackground === 'function') {
        viewConfig.autostartInBackground().then(it => {
          if (it) {
            this.autostartBackgroundView(viewConfig);
          }
        });
      } else if (viewConfig.autostartInBackground) {
        this.autostartBackgroundView(viewConfig);
      }
    });
  }
  private autostartBackgroundView(viewConfig: ViewConfig<any>) {
    const state = this.getLastOrDefaultViewState(viewConfig);
    const viewImpl = new VisibleView(viewConfig, state, this);
    this._persistedViews.set(viewConfig.viewId, viewImpl);
  }
  public registerView<TState extends ViewState>(view: ViewConfig<TState>): void {
    this.registeredViews.set(view.viewId.id, view);
    for (const aliasId of view.viewId.aliasIds) {
      this.registeredViews.set(aliasId, view);
    }
  }
  public registerDynamicView<TState extends ViewState>(dynamicViewProvider: DynamicViewProvider<TState>) {
    this.dynamicViews.push(dynamicViewProvider);
  }
  @action
  public openViewInBackground<TState extends ViewState>(viewId: ViewId<TState>): void {
    const config = (this.findViewConfig(viewId) as ViewConfig<any>);
    if (!config) {
      throw new Error(`View with id ${viewId.id} not found`);
    }
    const state = this.getLastOrDefaultViewState(config);
    const viewImpl = new VisibleView(config, state, this);
    this.layout.addPersistedView(viewImpl);
    this._persistedViews.set(viewId, viewImpl);
  }
  public openView<TState extends ViewState>(viewIdWithOptionalStateEffect: ViewIdWithStateEffect<TState> | ViewId<TState>, options: {
    locationUpdateMode?: 'push' | 'replace';
    closeOtherOverlays?: boolean;
    keepInBackground?: boolean;
    dismissKeyboard?: boolean;
    openContext?: string;
  } = {
    locationUpdateMode: 'push',
    closeOtherOverlays: false,
    keepInBackground: false,
    dismissKeyboard: false,
    openContext: undefined
  }): VisibleView<any> {
    let currentVisibleViews = this.visibleViewsDuringUpdateState ?? this.visibleViews;
    if (options.closeOtherOverlays) {
      currentVisibleViews = currentVisibleViews.filter(v => v.viewConfig.position !== Position.Overlay);
    }
    const {
      effect,
      viewId
    } = normalizeViewIdWithOptionalStateEffect(viewIdWithOptionalStateEffect);
    const viewConfig = this.getRegisteredViewConfig(viewId);
    const existing = this.findView(viewId);
    const prevState = existing ? existing.visibleView.state : this.getLastOrDefaultViewState(viewConfig);
    const state = effect(prevState);
    if (existing && prevState === state) {
      return existing.visibleView;
    }
    this.lastViewStates.set(viewId, state);
    const view = new VisibleView(viewConfig, state, this);
    if (this.isViewVisible(viewId) || !options.keepInBackground) {
      if (options.openContext) {
        this.genericUserEventService.reportEvent({
          type: 'View_Opened',
          item: viewId.id,
          source: options.openContext
        });
      }
      this.openVisibleView(view, currentVisibleViews, options.locationUpdateMode ?? 'push');
      // TODO: see if there is a better place to handle keyboard dissmissal
      if (this.screenService.isStackedLayout && options.dismissKeyboard) {
        setTimeout(() => {
          this.keyboardService.dismissKeyboard();
        });
      }
    }
    return view;
  }
  public openViewAsOverlayWithContext<TState extends ViewState>(viewIdWithOptionalStateEffect: ViewIdWithStateEffect<TState> | ViewId<TState>, openContext: string, method: 'replace' | 'push' = 'push', forceOnDesktop = false): VisibleView<any> {
    return this.openViewAsOverlay(viewIdWithOptionalStateEffect, method, forceOnDesktop, openContext);
  }
  public openViewAsOverlay<TState extends ViewState>(viewIdWithOptionalStateEffect: ViewIdWithStateEffect<TState> | ViewId<TState>, method: 'replace' | 'push' = 'push', forceOnDesktop = false, openContext: string | undefined = undefined): VisibleView<any> {
    if (!this.screenService.isStackedLayout && !forceOnDesktop) {
      return this.openView(viewIdWithOptionalStateEffect, {
        locationUpdateMode: method,
        openContext
      });
    }
    const {
      effect,
      viewId
    } = normalizeViewIdWithOptionalStateEffect(viewIdWithOptionalStateEffect);
    if (openContext) {
      this.genericUserEventService.reportEvent({
        type: 'View_Opened',
        item: viewId.id,
        source: openContext
      });
    }
    const viewConfig = this.getRegisteredViewConfig(viewId);
    const isOpen = this.isViewInOverlay(viewId);
    const storedState = isOpen && this.lastOverlayStates.has((viewId as any)) ? this.lastOverlayStates.get(viewId) : viewConfig.loadState({
      state: undefined,
      pathItems: []
    });
    const state = effect((storedState as TState));
    this.lastOverlayStates.set(viewId, state);
    const currentLocation = this.locationService.currentLocation;
    const additionalOverlays = (currentLocation.state as any)?.additionalOverlays?.map((o: any) => ({
      ...o
    })) ?? [];
    if (!isOpen) {
      additionalOverlays.push({
        state,
        viewId: viewId.id
      });
    } else {
      const overlay = additionalOverlays.find((o: any) => {
        return o.viewId === viewId.id;
      });
      overlay.state = state;
    }
    const nL = new LocationInfo(currentLocation.path, currentLocation.search, currentLocation.hash, {
      ...currentLocation.state,
      additionalOverlays
    });
    this.locationService[method](nL);
    return this.getVisibleViewForOverlay(viewId, state);
  }
  private getVisibleViewForOverlay<TState extends ViewState>(viewIdWithOptionalStateEffect: ViewIdWithStateEffect<TState> | ViewId<TState>, initialState: TState): VisibleView<TState> {
    const {
      viewId
    } = normalizeViewIdWithOptionalStateEffect(viewIdWithOptionalStateEffect);
    const viewConfig = this.getRegisteredViewConfig(viewId);
    const state = viewConfig.loadState({
      state: initialState,
      pathItems: []
    });
    this.lastOverlayStates.set(viewId, state);
    const visibleView = new VisibleView({
      ...viewConfig,
      render: (): ReturnType<ViewConfig['render']> => {
        return {
          mainView: <InsideOverlayViewContext.Provider value={{
            close: () => {
              visibleView.close();
            }
          }}>
								{viewConfig.render(state).mainView}
							</InsideOverlayViewContext.Provider>
        };
      }
    }, state, this);
    visibleView.close = () => {
      const currentLocation = this.locationService.currentLocation;
      const additionalOverlays = (currentLocation.state as any).additionalOverlays.filter((o: any) => o.viewId !== viewId.id);
      const nL = new LocationInfo(currentLocation.path, currentLocation.search, currentLocation.hash, {
        ...currentLocation.state,
        additionalOverlays
      });
      this.lastOverlayStates.delete(viewId);
      this.locationService.push(nL);
    };
    return visibleView;
  }
  public getStateForOverlay<TState extends ViewState>(viewIdWithOptionalStateEffect: ViewIdWithStateEffect<TState> | ViewId<TState>): TState {
    return (this.lastOverlayStates.get((viewIdWithOptionalStateEffect as any)) as TState);
  }
  private getLastOrDefaultViewState<TState extends ViewState>(viewConfig: ViewConfig<TState>): TState {
    if (this.lastViewStates.has(viewConfig.viewId)) {
      return (this.lastViewStates.get(viewConfig.viewId) as TState);
    } else {
      return viewConfig.loadState({
        state: undefined,
        pathItems: []
      });
    }
  }
  public updateLatestViewState<TState extends ViewState>(viewId: ViewId<TState>, updater: (state: TState) => TState): void {
    const config = this.getRegisteredViewConfig(viewId);
    const state = this.getLastOrDefaultViewState(config);
    this.lastViewStates.set(viewId, updater(state));
  }
  public navigateBack(): void {
    if (!this.lastOpenedViewId) {
      return;
    }
    this.openView(this.lastOpenedViewId, {
      locationUpdateMode: 'replace'
    });
  }
  private findViewConfig<TState extends ViewState>(viewId: string | ViewId<TState>): ViewConfig<TState> | undefined {
    const idString = viewId instanceof ViewId ? viewId.id : viewId;
    let viewInfo = this.registeredViews.get(idString);
    if (!viewInfo) {
      for (const dynamicView of this.dynamicViews) {
        const idObject = typeof viewId === 'string' ? dynamicView.getViewId(idString) : viewId;
        if (!idObject) {
          continue;
        }
        viewInfo = dynamicView.getViewConfig(idObject);
        if (viewInfo) {
          this.registerView(viewInfo);
          break;
        }
      }
    }
    return (viewInfo as ViewConfig<TState>);
  }
  public getRegisteredViewConfig<TState extends ViewState>(viewId: ViewId<TState>): ViewConfig<TState> {
    const viewInfo = this.findViewConfig(viewId);
    if (!viewInfo) {
      throw new BugIndicatingError(`There is no view registered with id "${viewId.id}"!`);
    }
    return (viewInfo as ViewConfig<TState>);
  }
  public findView<TState extends ViewState>(view: ViewId<TState>): {
    visibleView: VisibleView<TState>;
    laidoutViews: LaidoutView[];
  } | undefined {
    const visibleView = ((this.visibleViews.find(v => v.viewConfig.viewId.equals((view as ViewId<any>))) as unknown) as VisibleView<TState>);
    if (!visibleView) {
      return undefined;
    }
    const laidoutViews = this.layout.find(visibleView);
    return {
      visibleView,
      laidoutViews
    };
  }
  public findViewInOverlay<TState extends ViewState>(view: ViewId<TState>): VisibleView<TState> | undefined {
    return this._layout.additionalOverlays.find(v => v.viewConfig.viewId.equals((view as ViewId<any>)));
  }
  public isViewInBackground(viewId: ViewId<any>): boolean {
    return this._persistedViews.has(viewId) && !this.isViewVisible(viewId);
  }
  public isViewVisible<TState extends ViewState>(view: ViewId<TState>): boolean {
    return !!this.findView(view) || this.isViewInOverlay(view);
  }
  public isViewVisibleOrPending<TState extends ViewState>(view: ViewId<TState>): boolean {
    return this.isViewVisible(view) || this.pendingViews.some(v => v.viewConfig.viewId.equals((view as ViewId<any>)));
  }
  public isViewInOverlay(view: ViewId<any>): boolean {
    return !!this._layout.additionalOverlays.find(v => v.viewConfig.viewId.equals(view));
  }
  @action
  public closeView(view: ViewId<any> | VisibleView<any>): void {
    const currentVisibleViews = this.visibleViewsDuringUpdateState ?? this.visibleViews;
    const newViews = currentVisibleViews.filter(v => view instanceof ViewId ? !v.viewConfig.viewId.equals(view) : !v.equals(view));
    this.updateLocation(newViews, 'push');
  }

  /**
   * If the given viewId has a parent view and that view can be opened, this will open the parent view. Otherwise
   * it will close the given view.
   */
  public closeViewAndTryOpenParent<TState extends ViewState>(viewId: ViewId<TState>): void {
    const parentViewId = this.findAvailableParentView(viewId);
    if (parentViewId) {
      this.openView(parentViewId);
    } else {
      this.closeView(viewId);
    }
  }

  /**
   * Checks if viewId has a parent view that can be opened.
   */
  public canOpenParentView<TState extends ViewState>(viewId: ViewId<TState>): boolean {
    return !!this.findAvailableParentView(viewId);
  }
  private openVisibleView(view: VisibleView<any>, oldVisibleViews: readonly VisibleView<any>[], locationUpdateMode: 'push' | 'replace'): void {
    this.lastViewStates.set(view.viewConfig.viewId, view.state);

    // close all views that have the same position
    const oldViews = oldVisibleViews.filter(v => !shouldReplaceView(view, v));

    // this is a simple stable sort.
    const newViews: VisibleView<any>[] = oldViews.filter(v => v.viewConfig.position === Position.Main).concat(oldViews.filter(v => v.viewConfig.position === Position.Side)).concat(oldViews.filter(v => v.viewConfig.position === Position.Overlay));
    // `view` must be pushed last so that it has the highest priority when rendering.
    // It might push out other views from the layout.
    newViews.push(view);

    // get removed views
    const removedViews = oldVisibleViews.filter(v => !newViews.some(v2 => v2.equals(v)) || !this.isViewVisible((v as any)) && view.viewConfig.position !== Position.Overlay);
    if (removedViews.length > 0 && !removedViews[0].viewConfig.viewId.equals(view.viewConfig.viewId)) {
      this.pendingViews.push(...newViews);
      removedViews[0].animateOut(() => {
        runInAction('openView', () => {
          this.updateLocation(newViews, locationUpdateMode);
          this.pendingViews = this.pendingViews.filter(v => !newViews.some(v2 => v2.equals(v)));
        });
      });
    } else {
      runInAction('openView', () => {
        this.updateLocation(newViews, locationUpdateMode);
      });
    }
  }
  private findAvailableParentView<TState extends ViewState>(viewId: ViewId<TState>): ViewId | undefined {
    const config = this.getRegisteredViewConfig(viewId);
    const state = this.getLastOrDefaultViewState(config);
    const parentConfig = config.getParentViewId && this.getRegisteredViewConfig(config.getParentViewId(state));
    return parentConfig && (!parentConfig.requiredScreenWidths || parentConfig.requiredScreenWidths.some(width => this.screenService.screenWidth === width)) ? parentConfig.viewId : undefined;
  }
}
export class VisibleView<TState extends ViewState = ViewState> {
  public readonly renderedView: RenderedView;
  private ref: React.RefObject<any> = React.createRef();
  constructor(public readonly viewConfig: ViewConfig<TState>, public readonly state: TState, private readonly viewService: ViewService) {
    const r = viewConfig.render(state);
    this.renderedView = {
      mainView: <MountingAnimation config={viewConfig} ref={this.ref}>
					{r.mainView}
				</MountingAnimation>
    };
  }
  animateOut = (cb: () => void): void => {
    if (!this.ref?.current) {
      return cb();
    }
    return this.ref.current.animateOut().then(cb);
  };
  animateIn = (): void => {
    if (!this.ref?.current) {
      return;
    }
    this.ref?.current?.animateIn?.();
  };

  /**
   * Closes the view.
   */
  close(): void {
    this.viewService.closeView(this);
  }
  update(effect: (oldState: TState) => TState, options?: {
    locationUpdateMode: 'push' | 'replace';
  }): void {
    this.viewService.openView(this.viewConfig.viewId.with(effect), options);
  }
  equals(other: VisibleView): boolean {
    if (this.viewConfig.viewId.id !== other.viewConfig.viewId.id) {
      return false;
    }
    return JSON.stringify(this.state.persist()) === JSON.stringify(other.state.persist());
  }
}
function normalizeViewIdWithOptionalStateEffect<TState extends ViewState>(viewWithOptionalStateUpdater: ViewIdWithStateEffect<TState> | ViewId<TState>): ViewIdWithStateEffect<TState> {
  if (viewWithOptionalStateUpdater instanceof ViewId) {
    return viewWithOptionalStateUpdater.with(s => s);
  } else {
    return viewWithOptionalStateUpdater;
  }
}
function shouldReplaceView(newVisibleView: VisibleView<any>, oldVisibleView: VisibleView<any>): boolean {
  const newPosition = newVisibleView.viewConfig.position;
  const oldPosition = oldVisibleView.viewConfig.position;
  if (newPosition === Position.Overlay && oldPosition === Position.Overlay) {
    if (newVisibleView.viewConfig.allowMultipleOverlays) {
      return newVisibleView.equals(oldVisibleView);
    }
    return newVisibleView.viewConfig.viewId.equals(oldVisibleView.viewConfig.viewId);
  }
  return newPosition === oldPosition;
}
interface DynamicViewProvider<TState extends ViewState> {
  /**
   * Get the view id for the given id string. If this provider does not handle the id,
   * returns null.
   */
  getViewId(id: string): ViewId<TState> | null;

  /**
   * Get the view config for the given view id. If this provider does not handle the id,
   * returns null.
   */
  getViewConfig(viewId: ViewId<TState>): ViewConfig<TState> | undefined;
}
// tslint:disable-next-line: max-file-line-count