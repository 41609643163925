import * as React from 'react';
import { observer } from '@knuddels-app/mobx';
import { useService } from '@knuddels-app/DependencyInjection';
import { $I18n, declareFormat } from '@knuddels-app/i18n';
import { $ClientInfoStore } from '@knuddels-app/Connection/serviceIds';
import { getAppDownloadLinkForCurrentPlatform } from '@knuddels-app/tools/getAppDownloadLinkForCurrentPlatform';
import { Box, Button, Flex, FlexCol, Image, Smiley, Text, ThemeOverride, resolveThemingValue, resolveIsDarkColor, useTheme } from '@knuddels/component-library';
import { getAppBenefits, getAppBenefitsTitle } from './appBenefitsData';
import { INSTALL_APP } from '../../i18n/shared-formats';
import { openInNewTab } from '@shared/helper/openUrl';
import { useLogEvent } from '@knuddels-app/analytics/firebase/useLogEvent';
import { $ScreenService, ScreenWidth } from '@knuddels-app/Screen';
import headerSmall from '../assets/install-app-header-small.png';
import headerLarge from '../assets/install-app-header-large.png';
import { $GenericUserEventService } from '@knuddels-app/analytics/generic';
const SRC_SET: Record<ScreenWidth, string> = {
  [ScreenWidth.XS]: headerSmall,
  [ScreenWidth.S]: headerLarge,
  [ScreenWidth.M]: headerSmall,
  [ScreenWidth.L]: headerSmall,
  [ScreenWidth.XL]: headerSmall
};
export const AppBenefits: React.FC = observer('AppBenefits', () => {
  const i18n = useService($I18n);
  const clientInfoStore = useService($ClientInfoStore);
  const screenService = useService($ScreenService);
  const genericUserEventService = useService($GenericUserEventService);
  const track = useLogEvent();
  const osInfo = clientInfoStore.clientInfo?.osInfo?.value;
  if (!osInfo) {
    return null;
  }
  const link = clientInfoStore.clientInfo ? getAppDownloadLinkForCurrentPlatform(clientInfoStore.clientInfo) : undefined;
  const appBenefits = getAppBenefits(osInfo);
  const appBenefitsTitle = getAppBenefitsTitle(osInfo);
  const handleDownload = (): void => {
    track('InstallApp_AdScreen', 'DownloadApp_Clicked');
    genericUserEventService.reportEvent({
      type: 'InstallApp_Clicked'
    });
    openInNewTab(link);
  };
  return <div className={_c0 + ("contentBg" ? resolveIsDarkColor("contentBg", useTheme()) ? " content-is-dark" : " content-is-light" : "")}>
			<Image loading={'eager'} resizeMode={'cover'} alt={INSTALL_APP.format(i18n)} src={SRC_SET[screenService.screenWidth]} className={_c1} />

			<div className={_c2}>
				<Smiley height={87} loading={'eager'} src={require('../assets/knuddels-to-go.png')} />
			</div>

			<div className={_c3}>
				<Text type="headline" className={_c4}>{appBenefitsTitle.format(i18n)}</Text>
			</div>

			<div className={_c5}>
				{appBenefits.map((benefit, index) => <div key={`benefit-${index}`} className={_c6}>
						<div className={_c7}>
							<Smiley src={benefit.icon} />
						</div>
						<Text className={_c8}>{benefit.textFormatId.format(i18n)}</Text>
					</div>)}

				<div className={_c9}>
					<Button onPress={handleDownload}>
						{declareFormat({
            id: 'download.button',
            defaultFormat: 'Download now'
          }).format(i18n)}
					</Button>
				</div>
			</div>
		</div>;
});
const _c0 = " Knu-FlexCol bg-contentBg height-100-percent alignItems-center overflow-auto ";
const _c1 = " width-100-percent height-142px ";
const _c2 = " Knu-Box mb-small mt--44px ";
const _c3 = " Knu-FlexCol py-small ";
const _c4 = "  ";
const _c5 = " Knu-FlexCol py-small px-large maxWidth-360px ";
const _c6 = " Knu-Flex py-minor ";
const _c7 = " Knu-Box pr-base ";
const _c8 = "  ";
const _c9 = " Knu-Box mx-base my-large ";