import { action, observable } from '@knuddels-app/mobx';
import { $AuthenticatedClientService } from '@knuddels-app/Connection';
import { EditAlbumTitle } from '@generated/graphql';
import { $SnackbarService } from '@knuddels-app/SnackbarManager';
import { SnackbarEmptyAlbumTitle } from '@knuddelsModules/Profile/bundle/snackbars';

type EntryData = {
	albumId: string;
	newTitle: string;
};
export class AlbumsEditor {
	@observable
	public selectedPhotoIds: Set<string> = new Set();

	@observable
	public albumEntries: Record<string, EntryData> = {};

	constructor(
		private readonly authenticatedClientService: typeof $AuthenticatedClientService.T,
		private readonly markProfileDirty: () => void,
		private readonly snackbarService: typeof $SnackbarService.T
	) {}

	public canSave(): boolean {
		const invalidField = Object.values(this.albumEntries).find(
			entry => entry.newTitle === ''
		);

		if (invalidField) {
			this.snackbarService.showSnackbar(SnackbarEmptyAlbumTitle);
			return false;
		}

		return true;
	}

	private getOrCreateEntry = (albumId: string) => {
		if (this.albumEntries[albumId]) {
			return this.albumEntries[albumId];
		} else {
			const entry = {
				albumId,
				newTitle: '',
			};
			this.albumEntries[albumId] = entry;
			return entry;
		}
	};

	@action.bound
	public changeTitle(albumId: string, newTitle: string): void {
		const entryData = this.getOrCreateEntry(albumId);

		if (entryData.newTitle === newTitle && newTitle !== '') {
			return;
		}

		this.albumEntries[albumId] = { ...entryData, newTitle };
		this.markProfileDirty();
	}

	public save = async (): Promise<boolean> => {
		const promises = Object.values(this.albumEntries).map(entry => {
			return this.saveAlbum(entry);
		});

		const results = await Promise.all(promises);

		return results.every(it => it === true);
	};

	private saveAlbum = async (entry: EntryData): Promise<boolean> => {
		const result = await this.authenticatedClientService.currentK3Client
			.mutate(EditAlbumTitle, {
				id: entry.albumId,
				title: entry.newTitle,
			})
			.then(it =>
				it.match({
					ok: data =>
						data.primaryData?.__typename ===
						'ChangeAlbumTitleSuccess',
					error: () => false,
				})
			);

		return result;
	};
}
