import { isSVGElement as r } from "../../render/dom/utils/is-svg-element.js";
import { SVGVisualElement as s } from "../../render/svg/SVGVisualElement.js";
import { HTMLVisualElement as a } from "../../render/html/HTMLVisualElement.js";
import { visualElementStore as o } from "../../render/store.js";
import { ObjectVisualElement as l } from "../../render/object/ObjectVisualElement.js";
function f(t) {
  const n = {
    presenceContext: null,
    props: {},
    visualState: {
      renderState: {
        transform: {},
        transformOrigin: {},
        style: {},
        vars: {},
        attrs: {}
      },
      latestValues: {}
    }
  }, e = r(t) ? new s(n) : new a(n);
  e.mount(t), o.set(t, e);
}
function V(t) {
  const n = {
    presenceContext: null,
    props: {},
    visualState: {
      renderState: {
        output: {}
      },
      latestValues: {}
    }
  }, e = new l(n);
  e.mount(t), o.set(t, e);
}
export {
  f as createDOMVisualElement,
  V as createObjectVisualElement
};
