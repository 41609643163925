import { User } from '@generated/graphql';
import { useService } from '@knuddels-app/DependencyInjection';
import { useLazyService } from '@knuddels-app/ModuleSystem';
import { useLogEvent } from '@knuddels-app/analytics/firebase/useLogEvent';
import { declareFormat, FormattedMessage } from '@knuddels-app/i18n';
import { GenderIcon } from '@knuddels-app/shared-components/GenderIcon';
import { NickWithIcons } from '@knuddels-app/shared-components/NickWithIcons';
import { Box, Button, createAccessiblePointerEventHandler, Flex, FlexCol, IconClose, LineDivider, Text, ThemeOverride, useTheme, createNativeAccessiblePointerEventHandler, resolveThemingValue, resolveIsDarkColor } from '@knuddels/component-library';
import { $ProfileNavigationService } from '@knuddelsModules/Profile';
import { UserImage, UserImageType } from '@knuddelsModules/UserData';
import * as React from 'react';
import { $MessengerMiniChatService } from '../../../../Messenger';
import { MacroBoxDataTypes } from '@knuddelsModules/Channel/bundle/components/Macrobox/MacroBoxDataTypes';
import { $KeyboardService } from '@knuddels-app/Keyboard';
import { observer } from '@knuddels-app/mobx';
import { AnimatePresence, motion } from 'framer-motion';
import { MacroBoxInput } from '@knuddelsModules/Channel/bundle/components/Macrobox/MacroBoxInput';
import { InsideModalContext } from '@knuddels-app/overlays/InsideModalContext';
export const MacroBoxHeader: React.FC<{
  userId: User['id'];
  user: MacroBoxDataTypes;
  isSmallHeight: boolean;
  close: () => void;
}> = observer('MacroBoxHeader', ({
  userId,
  isSmallHeight,
  user,
  close
}) => {
  const profileNavigationService = useLazyService($ProfileNavigationService);
  const miniChatService = useService($MessengerMiniChatService);
  const keyboardService = useService($KeyboardService);
  const track = useLogEvent();
  const ref = React.useRef<HTMLDivElement>(null);
  const openProfile = async () => {
    close();
    const service = await profileNavigationService();
    service.showProfileForUser({
      id: userId,
      nick: user.nick
    }, {
      openedFromTrackingLabel: 'MacroBoxHeader'
    });
  };
  const handleProfilePicClick = async () => {
    await openProfile();
    track('Chat_MacroQuickAccess', 'ProfileImage_Clicked');
  };
  const openConversation = () => {
    close();
    miniChatService.openConversationByUserId(user?.id, 'MacroBoxHeader');
    track('Chat_MacroQuickAccess', 'Conversation_Clicked');
  };
  const openAuthenticity = async () => {
    const service = await profileNavigationService();
    service.showAuthenticityForUser(user);
  };
  return <div className={_c0 + ("contentLightBg" ? resolveIsDarkColor("contentLightBg", useTheme()) ? " content-is-dark" : " content-is-light" : "") + (isSmallHeight ? _c1 : _c2)}>
			<div className={_c3}>
				<IconClose size={'base'} onPress={() => close()} />
			</div>

			<div className={_c4}>
				<div {...createNativeAccessiblePointerEventHandler({
        pointerCallback: handleProfilePicClick
      })} className={_c5}>
					<UserImage showSkeletonWhileLoading={user.profilePicture?.urlCustomSizeSquare ?? true} type={UserImageType.COMMUNICATION_REQUEST_BOX} userId={userId} />
				</div>
				<div className={_c6}>
					{user ? <div className={_c7}>
							<NickWithIcons type={'title'} bold user={user} onNickPress={openProfile} onAuthenticityIconPress={openAuthenticity} />
						</div> : <Text type={'title'} bold={true} className={_c8}>
							{user.nick}
						</Text>}
					{user && <GenderAgeCity {...user} />}
				</div>
			</div>
			<div ref={ref} className={_c9}>
				<AnimatePresence mode={'popLayout'} initial={false}>
					<div style={{
          display: 'grid',
          alignItems: 'center',
          width: '100%',
          position: 'relative'
        }}>
						<AnimatePresence mode={'popLayout'}>
							<motion.div layoutId={'input-container'} layout={'position'} initial={{
              opacity: 0
            }} animate={{
              opacity: 1,
              y: 0
            }} exit={{
              opacity: 0,
              height: 0,
              position: 'absolute',
              inset: 0
            }} transition={{
              duration: 0.1,
              ease: [0.05, 0.7, 0.1, 1]
            }} key={keyboardService.anyKeyboardVisible ? '1' : '2'} style={{
              display: 'grid',
              gridArea: '1/1/2/2'
            }}>
								{!keyboardService.anyKeyboardVisible || !keyboardService.supportsVirtualKeyboard ? <div className={_c10}>
										<NewMessageButton openConversation={openConversation} disabled={!user} />
										<OpenProfileButton userId={user.id} nick={user.nick} close={close} />
									</div> : <InsideModalContext.Provider value={false}>
										<InputContainer anchor={ref}>
											<MacroBoxInput inHeader user={(user as any)} autoFocus />
										</InputContainer>
									</InsideModalContext.Provider>}
							</motion.div>
						</AnimatePresence>
					</div>
				</AnimatePresence>
			</div>

			<LineDivider className={_c11} />
		</div>;
});
const GenderAgeCity: React.FC<Pick<User, 'age' | 'gender' | 'city'>> = ({
  gender,
  age,
  city
}) => {
  return <div className={_c12}>
			<GenderIcon gender={gender} />
			<Text state={'tertiary'} className={_c13}>
				{age ? age : ''}
				{age && city ? ' · ' : ''}
				{city ? city : ''}
			</Text>
		</div>;
};
const NewMessageButton: React.FC<{
  openConversation: () => void;
  disabled?: boolean;
}> = ({
  openConversation,
  disabled
}) => {
  return <div className={_c14}>
			<Button onPress={openConversation} type={'secondary'} disabled={disabled}>
				<Text type={'body2'} state={'secondary'} bold={true} className={_c15}>
					<FormattedMessage id={declareFormat({
          id: 'channel.macrobox.conversation',
          defaultFormat: 'Write message'
        })} />
				</Text>
			</Button>
		</div>;
};
const OpenProfileButton: React.FC<{
  userId: User['id'];
  nick: string;
  close: () => void;
}> = ({
  userId,
  nick,
  close
}) => {
  const profileNavigationService = useLazyService($ProfileNavigationService);
  const track = useLogEvent();
  const openProfile = async () => {
    close();
    const service = await profileNavigationService();
    service.showProfileForUser({
      id: userId,
      nick
    }, {
      openedFromTrackingLabel: 'MacroBoxHeader'
    });
    track('Chat_MacroQuickAccess', 'OpenProfile_Clicked');
  };
  return <div className={_c16}>
			<Button onPress={openProfile} type={'secondary'}>
				<div className={_c17}>
					<Text type={'body2'} state={'secondary'} bold={true} className={_c18}>
						<FormattedMessage id={declareFormat({
            id: 'channel.macrobox.openProfile',
            defaultFormat: 'Open profile'
          })} />
					</Text>
				</div>
			</Button>
		</div>;
};
const InputContainer: React.FC<{
  anchor: React.RefObject<HTMLDivElement>;
}> = ({
  anchor,
  children
}) => {
  const [render, setRender] = React.useState(false);
  React.useLayoutEffect(() => {
    return setRender(!!anchor.current);
  }, [anchor.current]);
  const keyboardService = useService($KeyboardService);
  if (!render) {
    return null;
  }
  const height = window.innerHeight - ((anchor.current?.getBoundingClientRect().top ?? 0) + keyboardService.keyboardHeight + useTheme().spacings.base);
  return <div style={{
    maxHeight: resolveThemingValue(height, "sizes", useTheme())
  }} className={_c19}>
			<div style={({
      display: 'contents',
      '--editor-min-height': `${height}px`
    } as any)}>
				{children}
			</div>
		</div>;
};
const _c0 = " Knu-FlexCol position-relative pt-base bg-contentLightBg ";
const _c1 = " borderTopRadius-none ";
const _c2 = " borderTopRadius-xlarge ";
const _c3 = " Knu-Box position-absolute right-base top-small ";
const _c4 = " Knu-Flex px-large ";
const _c5 = " Knu-Box cursor-pointer ";
const _c6 = " Knu-FlexCol flex-1 ml-base pt-6px mr-large ";
const _c7 = " Knu-Flex mb-tiny ";
const _c8 = " mb-tiny ";
const _c9 = " Knu-Flex my-base ";
const _c10 = " Knu-Flex gap-base px-large ";
const _c11 = "  ";
const _c12 = " Knu-Flex alignItems-center ";
const _c13 = "  ";
const _c14 = " Knu-Flex flex-1 ";
const _c15 = "  ";
const _c16 = " Knu-Flex flex-1 ";
const _c17 = " Knu-Flex alignItems-center ";
const _c18 = " ml-tiny ";
const _c19 = " Knu-Flex width-100-percent gap-small pl-large pr-base alignItems-center minHeight-0-px ";