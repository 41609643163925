import { supportsScrollTimeline as n } from "../render/dom/scroll/supports.js";
class o {
  constructor(t) {
    this.stop = () => this.runAll("stop"), this.animations = t.filter(Boolean);
  }
  then(t, e) {
    return Promise.all(this.animations).then(t).catch(e);
  }
  /**
   * TODO: Filter out cancelled or stopped animations before returning
   */
  getAll(t) {
    return this.animations[0][t];
  }
  setAll(t, e) {
    for (let s = 0; s < this.animations.length; s++)
      this.animations[s][t] = e;
  }
  attachTimeline(t, e) {
    const s = this.animations.map((i) => n() && i.attachTimeline ? i.attachTimeline(t) : e(i));
    return () => {
      s.forEach((i, l) => {
        i && i(), this.animations[l].stop();
      });
    };
  }
  get time() {
    return this.getAll("time");
  }
  set time(t) {
    this.setAll("time", t);
  }
  get speed() {
    return this.getAll("speed");
  }
  set speed(t) {
    this.setAll("speed", t);
  }
  get startTime() {
    return this.getAll("startTime");
  }
  get duration() {
    let t = 0;
    for (let e = 0; e < this.animations.length; e++)
      t = Math.max(t, this.animations[e].duration);
    return t;
  }
  runAll(t) {
    this.animations.forEach((e) => e[t]());
  }
  play() {
    this.runAll("play");
  }
  pause() {
    this.runAll("pause");
  }
  cancel() {
    this.runAll("cancel");
  }
  complete() {
    this.runAll("complete");
  }
}
export {
  o as GroupPlaybackControls
};
