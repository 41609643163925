import { useService } from '@knuddels-app/DependencyInjection';
import { $I18n } from '@knuddels-app/i18n';
import { $ViewService, InsideOverlayViewContext } from '@knuddels-app/layout';
import { observer } from '@knuddels-app/mobx';
import { Flex, FlexCol, PatternBackground, FallbackBox, resolveThemingValue, useTheme } from '@knuddels/component-library';
import { declareFormat } from '@knuddels/i18n';
import { $GlobalAppsAppViewer, AppsWebview } from '@knuddelsModules/Apps';
import { $NavBarRegistry } from '@knuddelsModules/LoggedInArea';
import { GenericSystemApp } from '@knuddelsModules/SystemApps';
import { globalAppViewId } from '@knuddelsModules/SystemApps/bundle/components/GlobalApp/viewId';
import { GLOBAL_APP_ID, GLOBAL_APP_SLASH_COMMAND } from '@shared/constants';
import { openInNewTab } from '@shared/helper/openUrl';
import * as React from 'react';
import { useContext } from 'react';
import { Router, useRouter } from './GlobalAppsRouter';
import { $KeyboardService } from '@knuddels-app/Keyboard';
import { useSafeAreaPaddingBottom } from '@knuddels-app/overlays/InsideModalContext';
const GlobalAppViewer = observer('GlobalAppViewer', () => {
  const {
    navigate
  } = useRouter();
  const viewService = useService($ViewService);
  const overlay = useContext(InsideOverlayViewContext);
  const service = useService($GlobalAppsAppViewer);
  const app = overlay ? service.overlayApp : service.app;
  React.useEffect(() => {
    if (!app) {
      return;
    }
    navigate('apps');
  }, [app]);
  return <GenericSystemApp viewType={'sidebar'} viewId={globalAppViewId} onAppClose={() => {
    viewService.closeViewAndTryOpenParent(globalAppViewId);
  }} onAppEvent={(event: any) => {
    if (event.type === 'OPEN_URL') {
      openInNewTab(event.url);
    }
  }} appId={GLOBAL_APP_ID} customSlashCommand={overlay ? '/applist openOverview:overlay' : GLOBAL_APP_SLASH_COMMAND} />;
});
const getScale = (width: number, appWidth: number, height: number, appHeight: number) => {
  const result = Math.min(width / appWidth, height / appHeight, 1);
  return Number.isNaN(result) || !Number.isFinite(result) ? 1 : result;
};
const AppViewer = observer('AppViewer', () => {
  const {
    pop
  } = useRouter();
  const keyboardService = useService($KeyboardService);
  const [{
    width,
    height
  }, setSize] = React.useState({
    width: 0,
    height: 0
  });
  const safeAreaPaddingBottom = useSafeAreaPaddingBottom();
  const actualHeight = height - (keyboardService.anyKeyboardVisible ? keyboardService.keyboardHeight : 0);
  const service = useService($GlobalAppsAppViewer);
  const overlay = useContext(InsideOverlayViewContext);
  const app = overlay ? service.overlayApp : service.app;
  const isResizable = app?.isResponsive;
  React.useEffect(() => {
    if (!app) {
      pop();
    }
  }, [app]);
  const appWidth = app && 'width' in app.data.display ? app.data.display.width : width;
  const appHeight = app && 'height' in app.data.display ? app.data.display.height : actualHeight;
  const scale = isResizable || width >= appWidth && actualHeight >= appHeight ? 1 : getScale(width, appWidth, actualHeight, appHeight);
  if (!app) {
    return null;
  }
  return <div className={_c0}>
			<FallbackBox onLayout={e => setSize(e)} className={_c1}>
				<PatternBackground />

				<div className={_c2}>
					<div className={_c3 + (keyboardService.anyKeyboardVisible ? _c4 : _c5)}>
						<div style={{
            position: 'absolute',
            transform: `scale(${scale}) translateZ(0)`
          }} className={_c6}>
							<div style={{
              width: resolveThemingValue(isResizable ? width : appWidth, "sizes", useTheme()),
              height: resolveThemingValue(isResizable ? actualHeight : appHeight, "sizes", useTheme())
            }} className={_c7}>
								<AppsWebview appInstance={app} />
							</div>
						</div>
					</div>
				</div>
			</FallbackBox>
			<div style={{
      height: resolveThemingValue(safeAreaPaddingBottom, "sizes", useTheme())
    }} className={_c8} />
		</div>;
});
export const MainView: React.FC = observer('MainView', () => {
  const service = useService($GlobalAppsAppViewer);
  const i18n = useService($I18n);
  const overlay = useContext(InsideOverlayViewContext);
  const app = overlay ? service.overlayApp : service.app;
  const navBarRegistry = useService($NavBarRegistry);

  // TODO: Replace with generic solution
  const hideTitleBar = app?.appId === 'CtTicketApp' || app?.appId === 'CtLootApp' || app?.appId === 'LoyaltyApp';
  const hasFavoriteInsideBottomNav = navBarRegistry.viewInsideBottomNav(globalAppViewId, true) && !navBarRegistry.viewInsideBottomNav(globalAppViewId, false);
  return <div className={_c9}>
			<Router rootPath={'overview'} onNavigate={e => {
      if (e.newPath === 'overview' && app) {
        service.closeApp(app.appId);
      }
    }}>
				<Router.Route path={'overview'} title={i18n.format(declareFormat({
        id: 'global-apps',
        defaultFormat: 'Global Apps'
      }))} hideTitleBar>
					<GlobalAppViewer />
				</Router.Route>
				<Router.Route path={'apps'} title={app?.title} hideTitleBar={hideTitleBar} navIconStyle={hasFavoriteInsideBottomNav && !overlay ? 'none' : undefined} subtitle={app?.isUserApp ? i18n.format(declareFormat({
        id: 'global-apps.user-content',
        defaultFormat: 'User generated content'
      })) : undefined}>
					<AppViewer />
				</Router.Route>
			</Router>
		</div>;
});
const _c0 = " Knu-FlexCol flex-1 ";
const _c1 = " Knu-Flex flex-1 position-relative ";
const _c2 = " Knu-Flex position-relative size-full ";
const _c3 = " Knu-FlexCol position-absolute inset-none ";
const _c4 = " placeItems-flex-start ";
const _c5 = " placeItems-center ";
const _c6 = " Knu-Flex ";
const _c7 = " Knu-FlexCol ";
const _c8 = " Knu-Flex ";
const _c9 = " Knu-Flex flex-1 ";