import { Box, Flex, FlexCol, FlexColProps, LayoutEvent, Text, ThemeOverride, FallbackBox } from '@knuddels/component-library';
import { PrivateMessageContext } from '@knuddelsModules/Messenger/bundle/contexts/PrivateMessageContext';
import * as React from 'react';
import { Alignment, FormattedText } from './FormattedText';
import { ImageGroup } from './ImageGroup';
import { Link, LinkProps } from './Link';
import { Table } from '@shared/components/molecules/FormattedText/Table';
import { ImageBox } from '@shared/components/molecules/FormattedText/ImageBox';
type TextProps = React.ComponentProps<typeof Text> & {
  selectable?: boolean;
};
export interface FormattedTextDisplayProps {
  text: FormattedText;
  textProps?: TextProps;
  containerProps?: FlexColProps;
  prefix?: React.ReactNode;
  suffix?: React.ReactNode;
  disableBold?: boolean;
  forceBoldLinks?: boolean;
  disableLinks?: boolean;
  renderIndent?: boolean;
  /**
   * Appends a invisible placeholder string to the end of the text.
   * This is used to prevent the text from overlapping the time in the message.
   **/
  placeholderTime?: string;
  isParentImageBox?: boolean;
  getHighlightedLinkColor?(text: string, href: string): string | undefined;

  /**
   * should return false if the command should be opened as an URL
   */
  onLinkClicked?(link: string): boolean;
}
const noopOnLinkClicked: Exclude<FormattedTextDisplayProps['onLinkClicked'], undefined> = () => false;
const determineRenderStyle = (t: FormattedText) => {
  return {
    containsImages: t.containsImages,
    canRenderSingleTextComponent: t.kind !== 'Smiley'
  };
};
const INDENTATION = '.........';
interface State {
  width?: number;
  textOnly: boolean;
  containsImages: boolean;
}
type ElementsWithAlignment = {
  elements: React.ReactNode[];
  alignment: Alignment;
};
export class FormattedTextDisplay extends React.PureComponent<FormattedTextDisplayProps, State> {
  static contextType = PrivateMessageContext;
  constructor(props: FormattedTextDisplayProps) {
    super(props);
    const t = determineRenderStyle(props.text);
    this.state = {
      textOnly: t.canRenderSingleTextComponent,
      containsImages: t.containsImages
    };
  }
  static getDerivedStateFromProps(props: FormattedTextDisplayProps): State | null {
    const t = determineRenderStyle(props.text);
    return {
      textOnly: t.canRenderSingleTextComponent,
      containsImages: t.containsImages
    };
  }
  handleLayout = (e: LayoutEvent) => {
    const newWidth = e.width;
    if (Math.abs(newWidth - (this.state.width || 0)) >= 1) {
      this.setState({
        width: newWidth
      });
    }
  };
  render(): JSX.Element {
    const content: ElementsWithAlignment[] = [{
      elements: [],
      alignment: 'LEFT'
    }];
    if (this.props.prefix) {
      content[0].elements.push(this.props.prefix);
    }
    this.renderFormattedText(this.props.text, {
      disableBoldStyle: !!this.props.disableBold,
      key: new NumberGenerator(),
      renderIndent: this.props.renderIndent ?? false
    }, false, content);
    if (this.props.suffix) {
      content[content.length - 1].elements.push(this.props.suffix);
    }
    if (this.props.placeholderTime) {
      content[content.length - 1].elements.push(<PlaceholderTime text={this.props.placeholderTime} />);
    }
    if (!this.state.textOnly) {
      // android does not allow Views in text. Views are required for smileys.
      // Thus, text wrapping must be implemented with flex.
      return <FallbackBox onLayout={this.state.containsImages ? this.handleLayout : undefined} className={_c0}>
					{content[0].elements}
				</FallbackBox>;
    }
    const fullWidth = this.props.text.kind === 'ImageBox' || isTableWithWeights(this.props.text) || this.props.text.kind === 'List' && this.props.text.items.some(isTableWithWeights);
    return <FlexCol overflow={'visible'} position={'relative'} minWidth={0} width={fullWidth ? 10000 : undefined} maxWidth={'full'} opacity={typeof this.state?.width === 'number' || !this.state.containsImages ? 1 : 0} onLayout={this.state.containsImages ? this.handleLayout : undefined} mx={this.props.isParentImageBox && content[0].alignment === 'CENTER' ? 'auto' : undefined} {...this.props.containerProps}>
				{content.map((lines, index) => <Text key={index} {...this.props.textProps} {...{
        style: ({
          overflowWrap: 'anywhere',
          textAlign: lines.alignment.toLowerCase()
        } as any)
      }}>
						{lines.elements}
					</Text>)}
			</FlexCol>;
  }

  // Each node needs to be rendered in a separate text element to support text alignment

  renderFormattedText(text: FormattedText, props: {
    disableBoldStyle: boolean;
    key: NumberGenerator;
    renderIndent?: boolean;
  }, isInsideList = false, result: ElementsWithAlignment[]): void {
    if (result.length === 0) {
      result.push({
        elements: [],
        alignment: 'LEFT'
      });
    }
    const isPrivateMessage = this.context;
    const {
      getHighlightedLinkColor = (): undefined => undefined
    } = this.props;
    switch (text.kind) {
      case 'List':
        text.items.forEach(node => {
          this.renderFormattedText(node, props, true, result);
        });
        break;
      case 'Smiley':
        if (!isSameAlignment(text.alignment, result[result.length - 1].alignment)) {
          if (result[result.length - 1].elements.length > 0) {
            result.push({
              elements: [],
              alignment: text.alignment ?? 'LEFT'
            });
          } else {
            result[result.length - 1].alignment = text.alignment ?? 'LEFT';
          }
        }
        result[result.length - 1].elements.push(<ImageGroup key={props.key.getNext()} onClick={this.props.onLinkClicked || noopOnLinkClicked} maxWidth={this.state?.width ?? text.data.containerWidth} imageGroupData={text.data} disableLinks={this.props.disableLinks} isSingleSmiley={!isInsideList && isPrivateMessage} />);
        break;
      case 'Text':
        {
          const textProps: TextProps & {
            key: string;
          } = {
            key: props.key.getNext() + '',
            // removing numberOfLines from Text components inside lists
            // to prevent line breaks due to display: -webkit-box
            ...(isInsideList ? {
              ...this.props.textProps,
              numberOfLines: undefined
            } : this.props.textProps),
            bold: !props.disableBoldStyle && text.style.bold,
            italic: this.props.textProps?.italic || text.style.italic
          };
          if (text.style.color) {
            textProps.color = (text.style.color as ThemeOverride);
          }
          if (text.style.state) {
            textProps.state = text.style.state;
          }
          if (!isSameAlignment(text.style.alignment, result[result.length - 1].alignment)) {
            if (result[result.length - 1].elements.length > 0) {
              result.push({
                elements: [],
                alignment: text.style.alignment ?? 'LEFT'
              });
            } else {
              result[result.length - 1].alignment = text.style.alignment ?? 'LEFT';
            }
          }
          result[result.length - 1].elements.push(<Text {...textProps}>
						{text.style.indented && props.renderIndent ? INDENTATION : ''}
						{text.text}
					</Text>);
          break;
        }
      case 'Link':
        {
          const highlightedLinkColor = getHighlightedLinkColor(text.text, text.href);
          const bold = !props.disableBoldStyle && (text.style.bold || this.props.forceBoldLinks);
          const linkProps: LinkProps & {
            key: string;
          } = {
            disabled: this.props.disableLinks,
            command: text.href,
            key: props.key.getNext() + '',
            overrideColor: !this.props.disableLinks ? text.style.color ?? highlightedLinkColor : undefined,
            bold,
            italic: text.style.italic || this.props.textProps?.italic,
            onClick: this.props.onLinkClicked || noopOnLinkClicked
          };

          // Do we need to split words here for android?

          if (!isSameAlignment(text.style.alignment, result[result.length - 1].alignment)) {
            if (result[result.length - 1].elements.length > 0) {
              result.push({
                elements: [],
                alignment: text.style.alignment ?? 'LEFT'
              });
            } else {
              result[result.length - 1].alignment = text.style.alignment ?? 'LEFT';
            }
          }
          result[result.length - 1].elements.push(<Link {...linkProps}>
						{text.style.indented && props.renderIndent ? INDENTATION : ''}
						{text.text}
					</Link>);
          break;
        }
      case 'Table':
        result[result.length - 1].elements.push(<Table passProps={this.props} table={text} />);
        break;
      case 'ImageBox':
        result[result.length - 1].elements.push(<ImageBox passProps={this.props} imageBox={text} />);
        break;
      default:
        // TODO Use error boundaries
        // assertUnreachable(text);
        console.error('Unrecognized FormattedText element ' + (text as any).kind);
        break;
      // return null;
    }
  }
}

class NumberGenerator {
  private cur = 0;
  public getNext(): number {
    return this.cur++;
  }
}
const PlaceholderTime = ({
  text
}: {
  text: string;
}) => <Box ariaHidden pointerEvents={'none'} opacity={0} ml={'base'} style={{
  display: 'inline-block',
  height: 0,
  overflow: 'hidden'
}}>
		<Text type={'tiny'} className={_c1}>{text}</Text>
	</Box>;
function isTableWithWeights(text: FormattedText): boolean {
  if (text.kind !== 'Table') {
    return false;
  }
  return text.columns.some(it => typeof it.weight === 'number');
}
function isSameAlignment(a: Alignment | undefined, b: Alignment | undefined): boolean {
  const actualA = a || 'LEFT';
  const actualB = b || 'LEFT';
  return actualA === actualB;
}
const _c0 = " Knu-Flex position-relative minWidth-0-px alignItems-baseline justifyContent-flex-start overflow-visible flexWrap-wrap ";
const _c1 = "  ";