import { StartWebPayment } from '@generated/graphql';
import { inject, injectable } from '@knuddels-app/DependencyInjection';
import { $AuthenticatedClientService } from '@knuddels-app/Connection';
import {
	PaymentCallbacks,
	PaymentHandlerInterface,
	ProductDetails,
} from './PaymentHandlerInterface';
import { PaymentWebsitePopupManager } from './PaymentWebsitePopupManager';
import { $PaymentSubscriptionHandler } from '../../providedServices';

@injectable()
export class PaymentHandler implements PaymentHandlerInterface {
	private paymentWebsitePopupManager: PaymentWebsitePopupManager | undefined;

	constructor(
		@inject($AuthenticatedClientService)
		private readonly authenticatedClientService: typeof $AuthenticatedClientService.T,
		@inject($PaymentSubscriptionHandler)
		private readonly paymentSubscriptionHandler: typeof $PaymentSubscriptionHandler.T
	) {}

	public buyProduct = (
		productId: string,
		_offerId: string | undefined,
		callbacks: PaymentCallbacks
	): void => {
		if (this.paymentWebsitePopupManager) {
			this.paymentWebsitePopupManager.closePaymentWebsite();
		}
		this.paymentWebsitePopupManager = new PaymentWebsitePopupManager();

		this.authenticatedClientService.currentK3Client
			.mutateWithResultPromise(StartWebPayment, {
				productId: productId,
			})
			.match({
				ok: result => {
					this.paymentSubscriptionHandler.registerHandlerForProductId(
						productId,
						{
							handleSuccess: () => {
								this.handleSuccess(productId, callbacks);
							},
							handleError: (error: any) => {
								this.handleError(productId, error, callbacks);
							},
						}
					);

					this.paymentWebsitePopupManager.openPaymentWebsite(
						result.paymentUrl
					);
				},
				error: err => {
					let errorMessage = '';
					switch (err.kind) {
						case 'DomainError':
							errorMessage = err.error;
							break;
						case 'GraphQlErrors':
							errorMessage = err.errors.join(';');
							break;
						case 'NetworkError':
							errorMessage = err.error as string;
							break;
						default:
							errorMessage = '';
					}

					this.handleError(productId, errorMessage, callbacks);
				},
			});
	};

	buyProductWithSkuIds(
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		productId: string,
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		skuIds: readonly string[],
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		isSubscription: boolean,
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		offerId: string | undefined,
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		callbacks: PaymentCallbacks
	): void {
		// NOOP
	}

	async getDetailsForProducts(
		productInfos: {
			productId?: string;
			skuId?: string;
			offerId?: string;
			isSubscription?: boolean;
		}[]
	): Promise<ProductDetails[]> {
		return Promise.resolve(productInfos);
	}

	private handleSuccess(
		productId: string,
		callbacks: PaymentCallbacks
	): void {
		callbacks.success({ productId });
		this.handleFinally(productId, callbacks);
	}

	private handleError(
		productId: string,
		error: any,
		callbacks: PaymentCallbacks
	): void {
		callbacks.error({ productId, error });
		this.handleFinally(productId, callbacks);
	}

	private handleFinally(
		productId: string,
		callbacks: PaymentCallbacks
	): void {
		if (this.paymentWebsitePopupManager) {
			this.paymentWebsitePopupManager.closePaymentWebsite();
			this.paymentWebsitePopupManager = undefined;
		}
		if (callbacks.finally) {
			callbacks.finally({ productId });
		}
	}
}
