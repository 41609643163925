import { mapEasingToNativeEasing as p } from "./easing.js";
function y(e, t, o, { delay: s = 0, duration: r = 300, repeat: f = 0, repeatType: l = "loop", ease: m, times: n } = {}) {
  const i = { [t]: o };
  n && (i.offset = n);
  const a = p(m, r);
  return Array.isArray(a) && (i.easing = a), e.animate(i, {
    delay: s,
    duration: r,
    easing: Array.isArray(a) ? "linear" : a,
    fill: "both",
    iterations: f + 1,
    direction: l === "reverse" ? "alternate" : "normal"
  });
}
export {
  y as startWaapiAnimation
};
