import * as React from 'react';
import { $UserOnlineStatusService, UserDataInterfaces, UserImage, UserImageType } from '@knuddelsModules/UserData';
import { ContextMenuTrigger, FormattedText, FormattedTextText, PreviewFormattedTextDisplay, Spacer, StatusBadge, StatusBadgeSizes } from '@shared/components';
import { declareProps, injectedComponent, useService } from '@knuddels-app/DependencyInjection';
import { declareFormat, FormattedMessage } from '@knuddels-app/i18n';
import { useContactsMenuContext } from './ContextMenu/ContactsMenuContext';
import { createAccessiblePointerEventHandler, Flex, FlexCol, PointerEvent, Text, ThemeOverride, resolveThemingValue, useTheme, createNativeAccessiblePointerEventHandler, resolveIsDarkColor } from '@knuddels/component-library';
import { NickWithIcons } from '@knuddels-app/shared-components/NickWithIcons';
import { uesFormatLastOnlineTime } from '@knuddels-app/tools/uesFormatLastOnlineTime';
import { $JoinChannelService } from '@knuddelsModules/Channel';
import { useHovered } from '@knuddels-app/tools/useHovered';
import { ContactsUser } from '../../ContactsUser';
import { $ContactsService } from '@knuddelsModules/Contacts';
import { useLazyService } from '@knuddels-app/ModuleSystem';
import { $Environment } from '@knuddels-app/Environment';
const CONTACT_LIST_ENTRY_MAX_HEIGHT = 96;
const MAX_PREVIEW_TEXT_LENGTH = 175;
interface Props {
  contact: ContactsUser;
  showNickSwitchIcon?: boolean;
  noSubscription?: boolean;
  onClick: () => void;
}
export const ContactListEntry = injectedComponent({
  name: 'ContactListEntry',
  props: declareProps<Props>(),
  inject: {
    userOnlineStatusService: $UserOnlineStatusService
  }
}, ({
  contact,
  onClick,
  noSubscription,
  userOnlineStatusService,
  showNickSwitchIcon
}) => {
  const {
    id,
    isOnline,
    currentOnlineChannelName,
    readMe,
    lastOnlineTime,
    latestOnlineChannelName
  } = contact;
  const contactsMenuContext = useContactsMenuContext();
  const contactService = useService($ContactsService);
  const userWithOnlineChannelName = noSubscription ? {
    isOnline,
    currentOnlineChannelName
  } : userOnlineStatusService.getUserWithOnlineChannel(id).userWithOnlineChannelName;
  const readMeText = readMe ? FormattedText.fromJson(JSON.parse(readMe)) : new FormattedTextText('');
  const menuEntriesToShow = contactsMenuContext.getMenuEntriesToShow(contact, contactService);
  const content = <div style={{
    height: resolveThemingValue(CONTACT_LIST_ENTRY_MAX_HEIGHT, "sizes", useTheme())
  }} className={_c0}>
				<div className={_c1}>
					<UserImage type={UserImageType.MESSENGER} uid={id} highlight={'fancy'} url={contact.profilePicture.urlCustomSizeSquare} overlayUrls={contact.profilePictureOverlayUrls} onlineBadge={userWithOnlineChannelName.isOnline && <StatusBadge size={StatusBadgeSizes.NORMAL} />} />
				</div>
				<div style={{
      maxHeight: resolveThemingValue(96 - 2 * 8, "sizes", useTheme())
    }} className={_c2 + ("transparent" ? resolveIsDarkColor("transparent", useTheme()) ? " content-is-dark" : " content-is-light" : "") + (readMe ? _c3 : _c4)}>
					<div style={{
        height: resolveThemingValue(userWithOnlineChannelName.isOnline ? 24 : undefined, "sizes", useTheme())
      }} className={_c5}>
						<NickWithIcons type={'subtitle'} user={contact} hideNegativeAuthenticityStates showNickSwitchIcon={showNickSwitchIcon} />

						<OptionalChannelName userWithOnlineChannelName={userWithOnlineChannelName} />
					</div>
					{!userWithOnlineChannelName.isOnline && !contact.isAppBot && latestOnlineChannelName && <LastChannelWithTime channelName={latestOnlineChannelName} time={+lastOnlineTime} />}
					{!userWithOnlineChannelName.isOnline && <Spacer size={'tiny'} />}
					<PreviewFormattedTextDisplay text={readMeText} textProps={{
        type: 'body1',
        state: 'secondary',
        numberOfLines: 1,
        selectable: false
      }} maxTextLength={MAX_PREVIEW_TEXT_LENGTH} />
				</div>
			</div>;
  return <div {...createNativeAccessiblePointerEventHandler({
    pointerCallback: onClick
  })} className={_c6}>
				{contactsMenuContext.isActive && menuEntriesToShow.length > 0 ? <ContextMenuTrigger showContextMenu={(x, y, width, height) => {
      contactsMenuContext.openMenu(id, menuEntriesToShow, {
        x,
        y,
        width,
        height
      });
    }} onContentWrapperClick={onClick} isMenuActive={contactsMenuContext.openMenuForUserId === id} chevronBackgroundColor={'transparent'} position={'bottom'}>
						{content}
					</ContextMenuTrigger> : content}
			</div>;
});
export const LastChannelWithTime: React.FC<{
  channelName: string;
  time: number;
}> = ({
  channelName,
  time
}) => {
  const text = uesFormatLastOnlineTime(time);
  return <Text bold={true} type={'tiny'} state={'tertiary'} className={_c7}>
			{channelName}
			<Text type={'tiny'} state={'tertiary'} className={_c8}>
				<FormattedMessage id={declareFormat({
        id: 'contacts.entry.time',
        defaultFormat: ' ({time})'
      })} values={{
        time: text
      }} />
			</Text>
		</Text>;
};
function OptionalChannelName({
  userWithOnlineChannelName
}: {
  userWithOnlineChannelName: UserDataInterfaces.UserWithOnlineChannelName;
}): JSX.Element {
  const getJoinChannelService = useLazyService($JoinChannelService);
  const environment = useService($Environment);
  const channel = userWithOnlineChannelName.currentOnlineChannelName;
  const {
    hovered,
    bind
  } = useHovered();
  if (!channel) {
    return null;
  }
  const onPress = channel === '?' ? undefined : (e: PointerEvent) => {
    e.preventDefault();
    e.stopPropagation();
    if (globalEnv.product === 'stapp-messenger') {
      environment.messengerSystemAppInterface.executeOpenChannelSlashCommand(channel);
    } else {
      getJoinChannelService().then(service => service.joinChannelByName(channel, 'ContactListEntry'));
    }
  };
  return <Flex maxWidth={100} ml={'small'} height={'full'} alignSelf={'center'} alignItems={'center'} onPress={onPress} bg={'transparent'} {...bind}>
			<Text state={'highlighted'} type={'tiny'} underline={hovered && !!onPress} numberOfLines={1} className={_c9}>
				{channel === '?' ? <FormattedMessage id={declareFormat({
        id: 'contacts.entry.hiddenChannelName',
        defaultFormat: '?-Channel'
      })} /> : channel}
			</Text>
		</Flex>;
}
const _c0 = " Knu-Flex px-base hoverBg-listHoverAtom overflow-hidden alignItems-center ";
const _c1 = " Knu-FlexCol overflow-visible ";
const _c2 = " Knu-FlexCol flex-1 ml-base overflow-hidden bg-transparent py-8px ";
const _c3 = " justifyContent-flex-start ";
const _c4 = " justifyContent-center ";
const _c5 = " Knu-Flex position-relative width-full alignItems-center ";
const _c6 = " Knu-FlexCol cursor-pointer position-relative overflow-visible ";
const _c7 = "  ";
const _c8 = "  ";
const _c9 = "  ";