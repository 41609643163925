import React from 'react';
import { LexicalComposer } from '@lexical/react/LexicalComposer';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin';
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin';
import { EditorRefPlugin } from '@lexical/react/LexicalEditorRefPlugin';
import { $createTextNode, $getRoot, $getSelection, $isLineBreakNode, $isParagraphNode, $isRangeSelection, $isTextNode, $setSelection, BLUR_COMMAND, COMMAND_PRIORITY_LOW, COMMAND_PRIORITY_NORMAL, createCommand, DELETE_CHARACTER_COMMAND, EditorState, FOCUS_COMMAND, KEY_ENTER_COMMAND, LexicalCommand, LexicalEditor, LexicalNode, ParagraphNode, TextNode } from 'lexical';
import './editor.css';
import { $isEmojiNode, EmojiNode } from './nodes/EmojiNode';
import { MentionNode } from './nodes/MentionNode';
import { $createSmileyNode, $isSmileyNode, SmileyData, SmileyNode } from './nodes/SmileyNode';
import { AutocompleteNode } from './nodes/AutocompleteNode';
import { Box, ThemeOverride, useEvent, resolveThemingValue, useTheme } from '@knuddels/component-library';
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary';
import { OS, os } from '@shared/components/tools/os';
import { PlainTextPlugin } from '@lexical/react/LexicalPlainTextPlugin';
const baseTheme = {
  ltr: 'ltr',
  rtl: 'rtl',
  placeholder: 'editor-placeholder',
  paragraph: 'editor-paragraph'
};
export type RichTextEditorRef = {
  focus(): void;
  blur(): void;
  clear(): void;
  selectLastNode(): void;
  dispatchSubmit(text: string): void;
  appendText(text: string, range?: {
    start: number;
    end: number;
  }): void;
  setText(text: string, range?: {
    start: number;
    end: number;
  }): void;
  getText(): string;
  setState(state: EditorState): void;
  setNodes(getNodes: () => LexicalNode[]): void;
  insertSmiley(data: SmileyData, afterInsertion?: (args: {
    preventSelection: () => void;
  }) => void): void;
  appendSmiley(data: SmileyData, afterInsertion?: (args: {
    preventSelection: () => void;
  }) => void): void;
  appendNode(getNode: () => LexicalNode, afterInsertion?: (args: {
    preventSelection: () => void;
  }) => void): void;
  insertNode(getNode: () => LexicalNode, afterInsertion?: (args: {
    preventSelection: () => void;
  }) => void): void;
  setInputMode(inputMode: 'none' | 'text'): void;
  dispatchCommand<TCommand extends LexicalCommand<unknown>>(command: TCommand, data: any): void;
};
export const FLING_UP_COMMAND = createCommand();
export const FLING_DOWN_COMMAND = createCommand();
export const SUBMIT_COMMAND = createCommand<{
  text: string;
  state: EditorState;
}>();
const useFlingHandler = (onFlingUp: () => void, onFlingDown: () => void) => {
  const startY = React.useRef(0);
  const startTimestamp = React.useRef(0);
  const onTouchStart = React.useCallback((e: React.TouchEvent<HTMLDivElement>) => {
    startY.current = e.nativeEvent.touches.item(0)!.pageY;
    startTimestamp.current = e.nativeEvent.timeStamp;
  }, []);
  const onTouchEnd = React.useCallback((e: React.TouchEvent<HTMLDivElement>) => {
    const dy = e.nativeEvent.changedTouches.item(0)!.pageY - startY.current;
    const dt = e.nativeEvent.timeStamp - startTimestamp.current;
    const vel = -dy / dt;
    if (vel > 0.5) {
      onFlingUp();
    } else if (vel < -0.5) {
      onFlingDown();
    }
  }, [onFlingUp, onFlingDown]);
  return {
    onTouchStart,
    onTouchEnd
  };
};
const handleSelect = (node: LexicalNode, afterInsertion?: (args: {
  preventSelection: () => void;
}) => void) => {
  let select = true;
  afterInsertion?.({
    preventSelection: () => {
      select = false;
    }
  });
  if (select) {
    node.selectEnd();
  } else {
    $setSelection(null);
  }
};
export const RichTextEditor = React.forwardRef<RichTextEditorRef, {
  placeholder: string;
  showErrorBorder?: boolean;
  children?: any;
  onChange(value: string, state: EditorState): void;
  onFocus?(): void;
  onEnter?(): void;
  selectLastNode?(): void;
  onBlur?(): void;
  onInputPress?(): void;
  onFlingUp?(): void;
  onFlingDown?(): void;
}>(({
  children,
  placeholder,
  showErrorBorder,
  onChange,
  onBlur,
  onFocus,
  onEnter,
  onFlingDown,
  onFlingUp,
  onInputPress
}, ref) => {
  const editorRef = React.useRef<LexicalEditor>(null);
  const dispatchAndCall = (command: LexicalCommand<any>, callback?: () => void) => () => {
    editorRef.current?.dispatchCommand(command, null);
    callback?.();
  };
  const flingUp = dispatchAndCall(FLING_UP_COMMAND, onFlingUp);
  const flingDown = dispatchAndCall(FLING_DOWN_COMMAND, onFlingDown);
  const {
    onTouchStart,
    onTouchEnd
  } = useFlingHandler(flingUp, flingDown);
  const focus = useEvent(onFocus || (() => {}));
  const blur = useEvent(onBlur || (() => {}));
  const enter = useEvent(onEnter || (() => {}));
  React.useEffect(() => {
    const unsubFocus = editorRef.current!.registerCommand(FOCUS_COMMAND, () => {
      focus();
      return false;
    }, COMMAND_PRIORITY_LOW);
    const unsubEnter = editorRef.current!.registerCommand(KEY_ENTER_COMMAND, e => {
      if (!onEnter) {
        return false;
      }
      if (!e?.shiftKey) {
        enter();
        e?.preventDefault();
        return true;
      }
      return false;
    }, COMMAND_PRIORITY_LOW);
    const unsubBlur = editorRef.current!.registerCommand(BLUR_COMMAND, () => {
      blur();
      return false;
    }, COMMAND_PRIORITY_LOW);
    const deleteCommandUnsub = editorRef.current!.registerCommand(DELETE_CHARACTER_COMMAND, () => {
      const s = $getSelection();
      const selectedNode = s!.getNodes()[0];
      if ($isLineBreakNode(selectedNode)) {
        const sib = selectedNode.getPreviousSibling();
        if ($isLineBreakNode(sib)) {
          sib.remove();
          return true;
        }
      }
      if ($isTextNode(selectedNode)) {
        // Some Android keyboards seem to skip the first word when holding backspace
        if (os === OS.android && $isRangeSelection(s)) {
          if (s.focus.offset === 0) {
            selectedNode.replace($createTextNode(''));
            return true;
          }
        }
        const sib = selectedNode.getPreviousSibling();
        if (selectedNode.getTextContent().trim().length === 0) {
          if (sib && $isSmileyNode(sib)) {
            sib.remove();
            return true;
          }
          selectedNode.remove();
          return true;
        }
      }
      return false;
    }, COMMAND_PRIORITY_NORMAL);
    return () => {
      unsubFocus();
      unsubBlur();
      unsubEnter();
      deleteCommandUnsub();
    };
  }, [focus, blur, enter]);
  React.useImperativeHandle(ref, () => ({
    dispatchCommand<TCommand extends LexicalCommand<unknown>>(command: TCommand, data: any): void {
      editorRef.current?.dispatchCommand(command, data);
    },
    setInputMode(inputMode: 'none' | 'text'): void {
      if (editorRef.current?.getRootElement()) {
        editorRef.current.getRootElement()!.inputMode = inputMode;
      }
    },
    focus(): void {
      editorRef.current?.focus();
    },
    selectLastNode(): void {
      editorRef.current?.update(() => {
        const root = $getRoot();
        const node = root.getChildren()[0];
        if ($isParagraphNode(node)) {
          const nodeChildren = node.getChildren();
          const last = nodeChildren[nodeChildren.length - 1];
          last?.selectEnd();
        }
      });
    },
    blur(): void {
      editorRef.current?.blur();
    },
    dispatchSubmit(text: string): void {
      editorRef.current?.dispatchCommand(SUBMIT_COMMAND, {
        text,
        state: editorRef.current.getEditorState()
      });
    },
    clear(): void {
      editorRef.current?.update(() => {
        ($getRoot().getChildren()[0] as ParagraphNode).clear();
      });
    },
    appendText(text: string, range?: {
      start: number;
      end: number;
    }): void {
      editorRef.current?.update(() => {
        const node = $createTextNode(text);
        ($getRoot().getChildren()[0] as ParagraphNode).append(node);
        if (!range) {
          node.selectEnd();
        } else {
          node.select(range.start, range.end);
        }
      });
    },
    getText(): string {
      let text = '';
      if (!editorRef.current) {
        return '';
      }
      getTextFromEditorState(editorRef.current.getEditorState(), message => {
        text = message;
      });
      return text;
    },
    setText(text: string, range?: {
      start: number;
      end: number;
    }): void {
      editorRef.current?.update(() => {
        const node = $createTextNode(text);
        ($getRoot().getChildren()[0] as ParagraphNode).clear();
        ($getRoot().getChildren()[0] as ParagraphNode).append(node);
        if (!range) {
          node.selectEnd();
        } else {
          node.select(range.start, range.end);
        }
      });
    },
    setNodes(getNodes: () => LexicalNode[]): void {
      editorRef.current?.update(() => {
        ($getRoot().getChildren()[0] as ParagraphNode).clear();
        ($getRoot().getChildren()[0] as ParagraphNode).append(...getNodes()).select();
      });
    },
    setState(state: EditorState): void {
      editorRef.current?.update(() => {
        editorRef.current?.setEditorState(state);
      });
    },
    insertSmiley(smileyData: SmileyData, afterInsertion = () => {}): void {
      editorRef.current?.update(() => {
        const s = $getSelection();
        if (!$isRangeSelection(s)) {
          this.appendSmiley(smileyData, afterInsertion);
          return;
        }
        const selectedNode = s!.getNodes()[0];
        if (!$isTextNode(selectedNode)) {
          this.appendSmiley(smileyData, afterInsertion);
          return;
        }
        const before = selectedNode.getTextContent().substring(0, s.focus.offset);
        const after = selectedNode.getTextContent().substring(s.focus.offset);
        const beforeNode = $createTextNode(before);
        const afterNode = $createTextNode(after);
        const smileyNode = $createSmileyNode(smileyData);
        const smileyTextNode = $createTextNode(' ');
        selectedNode.replace(beforeNode);
        beforeNode.insertAfter(smileyNode);
        smileyNode.insertAfter(smileyTextNode);
        smileyTextNode.insertAfter(afterNode);
        handleSelect(smileyTextNode, afterInsertion);
      });
    },
    appendSmiley(smileyData: SmileyData, afterInsertion = () => {}): void {
      editorRef.current?.update(() => {
        const node = $createSmileyNode(smileyData);
        ($getRoot().getChildren()[0] as ParagraphNode).append(node);
        const text = $createTextNode(' ');
        ($getRoot().getChildren()[0] as ParagraphNode).append(text);
        handleSelect(text, afterInsertion);
      });
    },
    appendNode(getNode: () => LexicalNode, afterInsertion = () => {}): void {
      editorRef.current?.update(() => {
        const node = getNode();
        ($getRoot().getChildren()[0] as ParagraphNode).append(node);
        handleSelect(node, afterInsertion);
      });
    },
    insertNode(getNode: () => LexicalNode, afterInsertion = () => {}): void {
      editorRef.current?.update(() => {
        const s = $getSelection();
        if (!$isRangeSelection(s)) {
          this.appendNode(getNode, afterInsertion);
          return;
        }
        const selectedNode = s!.getNodes()[0];
        if ($isParagraphNode(selectedNode)) {
          this.appendNode(getNode, afterInsertion);
          return;
        }
        const node = getNode();
        if ($isEmojiNode(selectedNode) || !$isTextNode(selectedNode)) {
          selectedNode.insertAfter(node);
          handleSelect(node, afterInsertion);
          return;
        }
        const before = selectedNode.getTextContent().substring(0, s.focus.offset);
        const after = selectedNode.getTextContent().substring(s.focus.offset);
        const beforeNode = $createTextNode(before);
        const afterNode = $createTextNode(after);
        selectedNode.replace(beforeNode);
        beforeNode.insertAfter(node);
        node.insertAfter(afterNode);
        handleSelect(node, afterInsertion);
      });
    }
  } as RichTextEditorRef), []);
  return <LexicalComposer initialConfig={{
    theme: baseTheme,
    onError(error): void {
      console.log(error);
      editorRef.current!.update(() => {
        const node = $getRoot().getChildren()[0];
        if ($isParagraphNode(node)) {
          const nodeChildren = node.getChildren();
          const last = nodeChildren[nodeChildren.length - 1];
          if ($isLineBreakNode(last)) {
            last.remove();
          }
        }
      });
    },
    editable: true,
    nodes: [EmojiNode, MentionNode, SmileyNode, AutocompleteNode],
    namespace: 'editor'
  }}>
				<EditorRefPlugin editorRef={editorRef} />
				<Box className="editor-container" border={'1px solid'} borderColor={showErrorBorder ? 'red-500' : 'transparent'} m={(-1 as ThemeOverride)}>
					<PlainTextPlugin contentEditable={<ContentEditable className="editor-input" onTouchStart={onTouchStart} onTouchEnd={onTouchEnd} onClick={onInputPress} />} placeholder={<Placeholder text={placeholder} />} ErrorBoundary={LexicalErrorBoundary} />
					<HistoryPlugin />
					{children}

					<OnChangePlugin onChange={editorState => {
        getTextFromEditorState(editorState, message => {
          {
            onChange(message, editorState);
          }
        });
      }} />
				</Box>
			</LexicalComposer>;
});
RichTextEditor.displayName = 'RichTextEditor';
const Placeholder: React.FC<{
  text: string;
}> = ({
  text
}) => {
  return <div className="editor-placeholder">{text}</div>;
};
const getTextFromEditorState = (editorState: EditorState, cb: (text: string) => void): void => {
  editorState.read(() => {
    // Read the contents of the EditorState here.
    const root = $getRoot();
    if (!root?.getChildren().length) {
      cb('');
      return;
    }
    const node = (root.getChildren()[0] as ParagraphNode);
    const allNodes = (node.getChildren() as (TextNode | MentionNode | SmileyNode | EmojiNode)[]);
    const message = allNodes.map(r => {
      if ('toMessage' in r) {
        return r.toMessage();
      }

      /* workaround to support double smiley feature
       * while still allowing a space to be added after the smiley
       */
      if ($isSmileyNode(r.getPreviousSibling())) {
        const text = r.getTextContent();
        if (text.startsWith(' :double')) {
          return text.trimStart();
        }
      }
      return r.getTextContent();
    }).join('');
    cb(message);
  });
};
const _c0 = " Knu-Box border-1pxsolid ";
const _c1 = " borderColor-red-500 ";
const _c2 = " borderColor-transparent ";