import { inject, injectable } from '@knuddels-app/DependencyInjection';
import { action, observable, runInAction } from '@knuddels-app/mobx';
import {
	ConversationMessage,
	ConversationSnapMessageContent,
	SensitiveContentClassification,
	UserSentSnap,
} from '@generated/graphql';
import * as crypto from 'crypto-js';
import { LibWordArray } from 'crypto-js';
import {
	$LocalStorage,
	createJsonSerializer,
	LocalStorageKey,
} from '@knuddels-app/local-storage';

type SnapStorageEntry = {
	id: string;
	decryptedImage: string;
	remainingTime: number;
	creationTimestamp: number;
};

const snapsStorageKey = new LocalStorageKey({
	name: 'snaps',
	serializer: createJsonSerializer({
		defaultValue: [] as SnapStorageEntry[],
	}),
	cookieExpires: { inDays: 365 },
});

const MAX_SNAP_STORAGE_LIFETIME = 1000 * 60 * 60 * 24; // 24 hours

@injectable()
export class SnapService {
	private _snapState: {
		[messageId: string]: ClientSnapState;
	} = {};

	private storedSnapState: {
		[snapId: string]: SnapStorageEntry;
	} = {};

	constructor(
		@inject($LocalStorage)
		private readonly localStorage: typeof $LocalStorage.T
	) {
		this.loadEntriesAndRemoveExpired(
			localStorage.getEntry(snapsStorageKey).get()
		);
	}

	private loadEntriesAndRemoveExpired(entries: SnapStorageEntry[]): void {
		const expiredEntries: SnapStorageEntry[] = [];

		entries.forEach(entry => {
			const lifeTime = Date.now() - entry.creationTimestamp;
			if (
				entry.remainingTime > 0 &&
				lifeTime < MAX_SNAP_STORAGE_LIFETIME
			) {
				this.storedSnapState[entry.id] = entry;
			} else {
				expiredEntries.push(entry);
			}
		});

		if (expiredEntries.length > 0) {
			// Removes expired entries from local storage
			this.persistSnapState();
		}
	}

	getOrCreateClientSnapState(
		messageId: ConversationMessage['id'],
		snapContent: ConversationSnapMessageContent
	): ClientSnapState {
		const clientSnapState = this._snapState[messageId];
		if (!clientSnapState) {
			this._snapState[messageId] = new ClientSnapState(
				messageId,
				snapContent,
				this.storedSnapState[snapContent.snap.photoId],
				newState => {
					if (newState) {
						this.storedSnapState[snapContent.snap.photoId] =
							newState;
					} else {
						delete this.storedSnapState[snapContent.snap.photoId];
					}
					this.persistSnapState();
				}
			);
		}
		return this._snapState[messageId];
	}

	private persistSnapState = () => {
		this.localStorage
			.getEntry(snapsStorageKey)
			.set(Object.values(this.storedSnapState));
	};
}

export class ClientSnapState {
	private _isLoading = false;

	private creationTimestamp: number | undefined;

	@observable
	private _isAvailable: boolean | undefined;

	@observable
	private _decryptedSnap: string | undefined;

	@observable
	private _remainingTime: number | undefined;

	private _decrementTimerTask: any = undefined;

	private _sensitiveContentClassification: SensitiveContentClassification =
		SensitiveContentClassification.None;

	constructor(
		readonly messageId: ConversationMessage['id'],
		private readonly snapContent: Pick<
			ConversationSnapMessageContent,
			'snap' | 'sensitiveContentClassification'
		>,
		private readonly storedSnapState: SnapStorageEntry | undefined,
		private readonly persistSnapState: (
			snapState: SnapStorageEntry | undefined
		) => void
	) {
		if (storedSnapState) {
			this._isAvailable = storedSnapState.remainingTime > 0;
			this._decryptedSnap = storedSnapState.decryptedImage;
			this._remainingTime = storedSnapState.remainingTime;
			this.creationTimestamp = storedSnapState.creationTimestamp;
		} else {
			this.checkAvailability();
		}
	}

	private async checkAvailability(): Promise<void> {
		try {
			const data: RequestInit = {
				method: 'get',
			};
			const downloadUrl = this.snapContent.snap.url + '&checkOnly=true';
			const response = await fetch(downloadUrl, data);
			const text: string = await response.text();
			runInAction('Update snap availability', () => {
				if (!text || !text.startsWith('OK|')) {
					this._isAvailable = false;
				} else {
					this._isAvailable = true;
					this._remainingTime = this.snapContent.snap.duration;
				}
			});
		} catch (e) {
			console.log('error checking snap availability', e);
			runInAction('Reset snap  availability due to error', () => {
				this._isAvailable = false;
			});
		}
	}

	get decryptedSnap(): string | undefined {
		return this._decryptedSnap;
	}

	get isAvailable(): boolean | undefined {
		return this._isAvailable;
	}

	get remainingTime(): number | undefined {
		return this._remainingTime;
	}

	get sensitiveContent(): UserSentSnap {
		return this.snapContent.snap;
	}

	get sensitiveContentClassification(): SensitiveContentClassification {
		return this._sensitiveContentClassification;
	}

	@action private decrementRemainingTime(): void {
		if (this._remainingTime > 0) {
			this._remainingTime--;
		} else {
			this._isAvailable = false;
		}
	}

	startDecrementTimer(): void {
		this.decrementRemainingTime();
		this._decrementTimerTask = setInterval(() => {
			this.decrementRemainingTime();
		}, 1000);
	}

	stopDecrementTimer(): void {
		if (this._decrementTimerTask) {
			clearInterval(this._decrementTimerTask);
			this._decrementTimerTask = undefined;

			this.persistState();
		}
	}

	async maybeDownloadSnap(): Promise<void> {
		if (
			this._isLoading ||
			this._isAvailable === false ||
			this._decryptedSnap
		) {
			return;
		}

		this._isLoading = true;

		try {
			const encryptedImage = await fetchSnap(this.snapContent.snap.url);
			if (encryptedImage) {
				const snap = await decryptImage(
					encryptedImage,
					this.snapContent.snap.decryptionKey
				);
				runInAction('Set decrypted snap', () => {
					this._decryptedSnap = 'data:image/png;base64,' + snap;
					this._isLoading = false;

					this.persistState();
				});
			}
		} catch (error) {
			runInAction('Reset snap state due to error', () => {
				this._isLoading = false;
				this._isAvailable = false;
			});
		}
	}

	private persistState = () => {
		if (!this.creationTimestamp) {
			this.creationTimestamp = Date.now();
		}

		this.persistSnapState(
			this._remainingTime > 0
				? {
						id: this.snapContent.snap.photoId,
						decryptedImage: this._decryptedSnap,
						remainingTime: this._remainingTime,
						creationTimestamp: this.creationTimestamp,
					}
				: undefined
		);
	};
}

async function fetchSnap(url: string): Promise<string | undefined> {
	const data: RequestInit = {
		method: 'get',
	};

	const response = await fetch(url, data);
	const blob: Blob = await response.blob();
	const urlData: string = await readBlob(blob);

	// TODO somehow make sure server returns the correct content-type?
	// or check why StApp currently gets plain text from the blob.
	const contentTypePrefixRegex =
		/^(data:text\/plain;base64,|data:application\/octet-stream;base64,|data:;base64,)/;
	if (urlData.match(contentTypePrefixRegex)) {
		return urlData.replace(contentTypePrefixRegex, '');
	}

	return undefined;
}

function readBlob(blob: Blob): Promise<string> {
	return new Promise<string>((resolve: any): void => {
		const reader = new FileReader();
		reader.addEventListener('loadend', () => {
			resolve(reader.result as string);
		});
		reader.readAsDataURL(blob);
	});
}

function decryptImage(base64Input: string, key: string): Promise<string> {
	return new Promise<string>((resolve): void => {
		const inputWords: any = crypto.enc.Base64.parse(base64Input);

		const pswd = crypto.PBKDF2(key, key, {
			keySize: 4,
			iterations: 128,
		});
		const keyBase64 = crypto.enc.Base64.stringify(pswd);
		const keyWords: LibWordArray = crypto.enc.Base64.parse(keyBase64);
		const iv = crypto.enc.Utf8.parse(key.slice(0, 16));

		const decrypted = crypto.AES.decrypt(
			{
				ciphertext: inputWords,
				salt: '',
			} as any,
			keyWords as any,
			{
				iv: iv,
			}
		);

		resolve(crypto.enc.Base64.stringify(decrypted));
	});
}
