import * as React from 'react';
import { InfiniteScrollView } from '@shared/components';
import { ConversationInput } from './ConversationInput';
import { Lightbox, MessageLightboxSource, UploadImageLightboxSource } from '../Lightbox/Lightbox';
import { ConversationMessage, FullConversationFragment, IgnoreState, Scalars } from '@generated/graphql';
import { ClientConversationState, SendingImage } from '../../../services/conversationServices/MessengerConversationService';
import { declareProps, IModel, inject, injectable, injectedComponent, injectProps, useService } from '@knuddels-app/DependencyInjection';
import { $MessengerConversationService, $ReadSystemService } from '../../../../providedServices';
import { action, observable, observer } from '@knuddels-app/mobx';
import { $AuthenticatedClientService } from '@knuddels-app/Connection';
import { Disposable } from '@knuddels/std';
import { ConversationHistory } from '../../Conversation/ConversationBody/ConversationHistory';
import { ConversationItemData } from '../../Conversation/ConversationBody/ConversationContent/Components';
import { ConversationIdContext } from './ConversationIdContext';
import { FlexCol, useTheme, resolveThemingValue } from '@knuddels/component-library';
import { IconJumpLatest, IconJumpUnread, JumpToBadge, JumpToBadgeParent } from '@shared/components/JumpToControls';
import { $I18n } from '@knuddels-app/i18n';
import { declareFormat } from '@knuddels/i18n';
import { OS, os } from '@shared/components/tools/os';
import { KeyboardAvoidanceView } from '@shared/components/KeyboardAvoidanceView';
import { MiniChatContext } from '@knuddelsModules/Messenger';
import { useReactiveState } from '@knuddels-app/tools/useReactiveState';
@injectable()
class ConversationBodyModel implements IModel {
  public readonly scrollViewRef = React.createRef<InfiniteScrollView<ConversationItemData>>();
  public conversationState: ClientConversationState = this.messengerConversationService.getOrCreateClientConversationState(this.props.conversation.id);
  public readonly dispose = Disposable.fn();
  @observable
  public wasMessageSentRecently = false;
  @observable
  private _lightBoxSource: UploadImageLightboxSource | MessageLightboxSource | string | undefined = undefined;
  constructor(@injectProps()
  private readonly props: {
    conversation: FullConversationFragment;
  }, @inject($MessengerConversationService)
  private readonly messengerConversationService: typeof $MessengerConversationService.T, @inject($AuthenticatedClientService)
  private readonly authenticatedClientService: typeof $AuthenticatedClientService.T, @inject($ReadSystemService)
  private readonly readSystemService: typeof $ReadSystemService.T) {
    this.dispose.track(authenticatedClientService.onReconnected.sub(() => {
      this.messengerConversationService.getFullConversation(this.props.conversation.id);
    }));

    // It's important for this to happen in the constructor, and not in didMount, because otherwise the list
    // won't know the last read message id when it is being mounted and thus can't scroll to it.
    this.readSystemService.handleConversationOpened(this.props.conversation.id);
    this.conversationState.sendingMessagesState.markAllAsUnread();
  }
  get badgeState(): 'scroll-from-bottom' | 'unread' | 'none' {
    if (!this.conversationState.hasSeenUnreadMessage) {
      return 'unread';
    }
    if (!this.conversationState.scrolledToBottom) {
      return 'scroll-from-bottom';
    }
    return 'none';
  }
  public scrollToBottom = (): void => {
    this.scrollViewRef.current.scrollToBottom();
    this.markUnreadAsSeen();
    if (os === OS.ios) {
      // this accounts for inertia scrolling on iOS, which would otherwise result in a blank screen
      setTimeout(() => {
        this.scrollViewRef.current.scrollToBottom();
      }, 200);
    }
  };
  public markUnreadAsSeen(): void {
    this.conversationState.markUnreadAsSeen();
  }
  get lightBoxSource(): UploadImageLightboxSource | MessageLightboxSource | string | undefined {
    return this._lightBoxSource;
  }
  @action.bound
  setLightBoxSource(src: UploadImageLightboxSource | MessageLightboxSource | string | undefined): void {
    this._lightBoxSource = src;
  }

  // has to be an arrow function or bind this.
  closeLightBox = (): void => {
    this.setLightBoxSource(undefined);
  };
  @action.bound
  handleMessageSent(): void {
    this.wasMessageSentRecently = true;
    if (!this.scrollViewRef.current.isScrolledToBottom) {
      this.scrollViewRef.current.scrollToBottom();
    }
  }
  @action.bound
  setScrolledToBottom = (scrolledToBottom: boolean): void => {
    this.conversationState.scrolledToBottom = scrolledToBottom;
    if (scrolledToBottom) {
      this.markUnreadAsSeen();
    }
  };
  get isSnapOpen(): boolean {
    return this.lightBoxSource && typeof this.lightBoxSource !== 'string' && this.lightBoxSource.type === 'snap';
  }
  get otherParticipant(): FullConversationFragment['otherParticipants'][0] | undefined {
    return this.props.conversation.otherParticipants?.[0];
  }
  get messageIdBeforeUnreadDivider(): ConversationMessage['id'] | undefined {
    return this.conversationState.messageIdBeforeUnreadDivider;
  }
  get sendingImage(): SendingImage | undefined {
    return this.conversationState.sendingImage;
  }
}
export const ConversationBody = injectedComponent({
  name: 'ConversationBody',
  model: ConversationBodyModel,
  props: declareProps<{
    scrollToMessageId: Scalars['ID'];
    conversation: FullConversationFragment;
    loading: boolean;
    initialLoading: boolean;
    onOtherParticipantTypingChange: (isTyping: boolean) => void;
    indentInput: boolean;
    fetchMoreMessages: () => Promise<void>;
  }>()
}, ({
  model,
  conversation,
  scrollToMessageId,
  loading,
  onOtherParticipantTypingChange,
  indentInput,
  initialLoading,
  fetchMoreMessages
}) => {
  const isMiniChatContext = React.useContext(MiniChatContext);
  useReactiveState(() => {
    const isTyping = model.conversationState.isOtherParticipantTyping && model.otherParticipant?.ignoreState === IgnoreState.None;
    onOtherParticipantTypingChange(isTyping);
  }, [onOtherParticipantTypingChange]);
  const readSystemService = useService($ReadSystemService);
  const markedReadRef = React.useRef(false);

  // When opening the app through a push notification, there might be
  // a race condition between fetching the full conversation on reconnect
  // and marking it as read. In that case `otherParticipants` will be
  // undefined but `conversation` will be defined. ConversationHistory
  // would the throw an error because it expects `otherParticipants`
  if (!conversation || !conversation.otherParticipants) {
    return null;
  }

  // We mark the conversation as read here to prevent a race condition between
  // the read state change event and loading the conversation. Sometimes the event
  // would arrive before the conversation and in that case the conversation would
  // override the changed read state with an old value
  if (!markedReadRef.current && !loading) {
    readSystemService.markUnreadConversationAsRead(conversation.id);
    markedReadRef.current = true;
  }
  return <ConversationIdContext.Provider value={conversation.id}>
				<div style={{
      pointerEvents: resolveThemingValue(model.isSnapOpen ? 'none' : undefined, "theme", useTheme())
    }} className={_c0}>
					<KeyboardAvoidanceView enabled={!isMiniChatContext} style={{
        flex: 1,
        position: 'relative',
        flexDirection: 'column'
      }}>
						<div className={_c1}>
							<JumpBadges model={model} />

							<ConversationHistory onScrollToBottomStateChange={model.setScrolledToBottom} conversation={conversation} scrollToMessageId={scrollToMessageId} lastReadIndex={model.messageIdBeforeUnreadDivider} setLightboxSrc={model.setLightBoxSource} scrollViewRef={model.scrollViewRef} loading={loading} initialLoading={initialLoading} fetchMoreMessages={fetchMoreMessages} sendingImage={model.sendingImage} wasMessageSentRecently={model.wasMessageSentRecently} onMessageSent={model.handleMessageSent} />
						</div>

						{model.otherParticipant && model.otherParticipant.canReceiveMessages && model.otherParticipant.isAllowedByContactFilter && <ConversationInput conversation={conversation} indent={indentInput} messageSent={model.handleMessageSent} setLightboxSrc={model.setLightBoxSource} />}
						{model.lightBoxSource && <Lightbox source={model.lightBoxSource} onClose={model.closeLightBox} />}
					</KeyboardAvoidanceView>
				</div>
			</ConversationIdContext.Provider>;
});
const JumpBadges: React.FC<{
  model: Omit<ConversationBodyModel, 'componentDidMount' | 'dispose' | 'resumeFrom'>;
}> = observer('JumpBadges', ({
  model
}) => {
  const i18n = useService($I18n);
  return <JumpToBadgeParent isDarkParent={useTheme().id === 'dark'}>
			{model.badgeState === 'unread' && <JumpToBadge key={'unread'} onPress={model.scrollToBottom} icon={<IconJumpUnread />} text={i18n.format(declareFormat({
      id: 'conversation.jumpToBadge.unreadMessages',
      defaultFormat: 'Unread messages'
    }))} />}

			{model.badgeState === 'scroll-from-bottom' && <JumpToBadge key={'scroll-to-bottom'} onPress={model.scrollToBottom} icon={<IconJumpLatest />} text={i18n.format(declareFormat({
      id: 'conversation.jumpToBadge.bottom',
      defaultFormat: 'Scroll to bottom'
    }))} />}
		</JumpToBadgeParent>;
});
const _c0 = " Knu-FlexCol position-relative flex-1 overflow-hidden ";
const _c1 = " Knu-FlexCol flex-1 position-relative ";