export const hashString = async (
	input: string,
	len: number = 20
): Promise<string> => {
	const hash = await crypto.subtle.digest(
		'SHA-256',
		new TextEncoder().encode(input)
	);
	return Array.from(new Uint8Array(hash))
		.map(b => b.toString(16).padStart(2, '0'))
		.join('')
		.slice(0, len);
};
