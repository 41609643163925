import { alpha as r } from "../../../value/types/numbers/index.js";
import { px as e } from "../../../value/types/numbers/units.js";
import { browserNumberValueTypes as m } from "./number-browser.js";
import { transformValueTypes as p } from "./transform.js";
import { int as o } from "./type-int.js";
const l = {
  ...m,
  ...p,
  zIndex: o,
  size: e,
  // SVG
  fillOpacity: r,
  strokeOpacity: r,
  numOctaves: o
};
export {
  l as numberValueTypes
};
