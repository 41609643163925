import {
	ArchiveConversation,
	DeleteConversation,
	MessageHistoryExportReadyFragment,
	MessengerConversation,
	MessengerConversationReadStateChangedFragment,
	MessengerConversationVisibilityChangedFragment,
	MessengerMessageReceivedFragment,
	MessengerMessageStarredStateChangedFragment,
	MessengerSubscription,
	MessengerUserTypingStartedFragment,
	MessengerUserTypingStoppedFragment,
	RestoreConversation,
} from '@generated/graphql';
import {
	$AuthenticatedClientService,
	K3ApolloClient,
} from '@knuddels-app/Connection';
import { inject, injectable } from '@knuddels-app/DependencyInjection';
import { $ViewService } from '@knuddels-app/layout';
import { action } from '@knuddels-app/mobx';
import { $SnackbarService } from '@knuddels-app/SnackbarManager';
import { Disposable, EventEmitter, EventSource } from '@knuddels/std';
import { $AutocompleteProviderService } from '@knuddelsModules/AutocompleteInputBar';
import { messengerViewId } from '@knuddelsModules/Messenger/MessengerViewProvider';
import { $MessengerConversationService } from '../../providedServices';
import { SnackbarDefinitionArchiveConversation } from '../snackbars/ArchiveConversation';
import { getPixelRatio } from '@knuddels-app/tools/getPixelRatio';
import { SnackbarDefinitionDeleteConversation } from '@knuddelsModules/Messenger/bundle/snackbars/DeleteConversation';
import { SnackbarDefinitionRestoreConversation } from '@knuddelsModules/Messenger/bundle/snackbars/RestoreConversation';

@injectable()
export class MessengerService {
	public readonly dispose = Disposable.fn();

	public readonly onMessageStarStateChanged: EventSource<MessengerMessageStarredStateChangedFragment>;
	public readonly onNewMessage: EventSource<MessengerMessageReceivedFragment>;
	public readonly onConversationVisibilityChanged: EventSource<MessengerConversationVisibilityChangedFragment>;
	public readonly onConversationReadStateChanged: EventSource<MessengerConversationReadStateChangedFragment>;
	public readonly onMessageHistoryExportReady: EventSource<MessageHistoryExportReadyFragment>;
	private readonly messageStarStateChangedEmitter =
		new EventEmitter<MessengerMessageStarredStateChangedFragment>();
	private readonly conversationReadStateChanged =
		new EventEmitter<MessengerConversationReadStateChangedFragment>();
	private readonly newMessageEmitter =
		new EventEmitter<MessengerMessageReceivedFragment>();
	private readonly conversationVisibilityChangedEmitter =
		new EventEmitter<MessengerConversationVisibilityChangedFragment>();
	private readonly messageHistoryExportReadyEmitter =
		new EventEmitter<MessageHistoryExportReadyFragment>();

	private get client(): K3ApolloClient {
		return this.authenticatedClientService.currentK3Client;
	}

	constructor(
		@inject($AuthenticatedClientService)
		private readonly authenticatedClientService: typeof $AuthenticatedClientService.T,
		@inject($SnackbarService)
		private readonly snackbarService: typeof $SnackbarService.T,
		@inject($MessengerConversationService)
		private readonly messengerConversationService: typeof $MessengerConversationService.T,
		@inject($ViewService)
		private readonly viewService: typeof $ViewService.T,
		@inject($AutocompleteProviderService)
		private readonly autocompleteProviderService: typeof $AutocompleteProviderService.T
	) {
		this.onMessageStarStateChanged =
			this.messageStarStateChangedEmitter.asEvent();
		this.onNewMessage = this.newMessageEmitter.asEvent();
		this.onConversationVisibilityChanged =
			this.conversationVisibilityChangedEmitter.asEvent();
		this.onConversationReadStateChanged =
			this.conversationReadStateChanged.asEvent();
		this.onMessageHistoryExportReady =
			this.messageHistoryExportReadyEmitter.asEvent();

		const currentK3Client = this.authenticatedClientService.currentK3Client;
		this.dispose.track(
			currentK3Client.subscribeToPrimaryData(
				MessengerSubscription,
				{ pixelDensity: getPixelRatio() },
				{ next: this.handleSubscriptionEvent }
			)
		);

		// We add the user specifically of the opened conversation to the autocomplete service because in the case
		// that it is a new conversation without any messages, the user would not appear in the contacts and thus
		// you wouldn't be able to autocomplete that user.
		this.dispose.track(
			autocompleteProviderService.registerUserProvider(() => {
				const conversationId = this.getOpenConversationId();
				if (conversationId) {
					const conv =
						this.messengerConversationService.getCachedFullConversation(
							conversationId
						);
					if (conv) {
						return [conv.otherParticipants[0]];
					}
				}

				return [];
			})
		);
	}

	public getOpenConversationId = ():
		| MessengerConversation['id']
		| undefined => {
		const view = this.viewService.findView(messengerViewId);
		const conversationState =
			view && view.visibleView.state.topConversationRoute();
		if (conversationState && conversationState.path === 'conversation') {
			return conversationState.params.conversationId;
		} else {
			return undefined;
		}
	};

	public deleteConversation(
		id: MessengerConversation['id']
	): Promise<boolean> {
		return this.client
			.mutateWithResultPromise(DeleteConversation, { id })
			.then(r =>
				r.match({
					ok: () => {
						this.snackbarService.showSnackbar(
							SnackbarDefinitionDeleteConversation
						);
						return true;
					},
					error: () => {
						this.snackbarService.showGenericError();
						return false;
					},
				})
			);
	}

	public archiveConversation(
		id: MessengerConversation['id'],
		options = { showSnackbar: true }
	): Promise<boolean> {
		return this.client
			.mutateWithResultPromise(ArchiveConversation, { id })
			.then(r =>
				r.match({
					ok: () => {
						if (options.showSnackbar) {
							this.snackbarService.showSnackbar(
								SnackbarDefinitionArchiveConversation
							);
						}
						return true;
					},
					error: () => {
						if (options.showSnackbar) {
							this.snackbarService.showGenericError();
						}
						return false;
					},
				})
			);
	}

	public restoreConversation(
		id: MessengerConversation['id']
	): Promise<boolean> {
		return this.client
			.mutateWithResultPromise(RestoreConversation, { id })
			.then(r =>
				r.match({
					ok: () => {
						this.snackbarService.showSnackbar(
							SnackbarDefinitionRestoreConversation
						);
						return true;
					},
					error: () => {
						this.snackbarService.showGenericError();
						return false;
					},
				})
			);
	}

	private readonly handleSubscriptionEvent = (
		eventData: typeof MessengerSubscription.TPrimaryResult
	): void => {
		if (!eventData) {
			return;
		}

		switch (eventData.__typename) {
			case 'MessengerMessageReceived':
				this.newMessageEmitter.emit(eventData);
				break;
			case 'MessengerMessageStarredStateChanged':
				this.messageStarStateChangedEmitter.emit(eventData);
				break;
			case 'MessengerConversationReadStateChanged':
				this.conversationReadStateChanged.emit(eventData);
				break;
			case 'MessengerUserTypingStarted':
				this.handleUserTypingEvent(eventData);
				break;
			case 'MessengerUserTypingStopped':
				this.handleUserStoppedTypingEvent(eventData);
				break;
			case 'MessengerConversationVisibilityChanged':
				this.conversationVisibilityChangedEmitter.emit(eventData);
				break;
			case 'MessengerMessageChanged':
				// Messages are updated automatically by apollo
				break;
			case 'MessageHistoryExportReadyEvent':
				this.messageHistoryExportReadyEmitter.emit(eventData);
				break;
			default:
			// Don't throw here to not prevent the backend to extend the events.
		}
	};

	@action
	private readonly handleUserTypingEvent = ({
		conversation,
		willReceiveStopEvent,
	}: MessengerUserTypingStartedFragment): void => {
		this.messengerConversationService
			.getOrCreateClientConversationState(conversation.id)
			.setOtherParticipantTyping(willReceiveStopEvent);
	};

	@action
	private readonly handleUserStoppedTypingEvent = ({
		conversation,
	}: MessengerUserTypingStoppedFragment): void => {
		this.messengerConversationService
			.getOrCreateClientConversationState(conversation.id)
			.clearOtherParticipantTyping();
	};
}
