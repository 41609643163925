import * as React from 'react';
import { Gender } from '@generated/graphql';
import { UserImage, UserImageType } from '@knuddelsModules/UserData';
import { ContextMenuTrigger, Spacer } from '@shared/components';
import { Flex, Text } from '@knuddels/component-library';
import { GenderIcon } from '@knuddels-app/shared-components/GenderIcon';
import { useService } from '@knuddels-app/DependencyInjection';
import { $OverlayService } from '@knuddels-app/overlays';
import { SentFriendRequestContextMenuOverlay } from './SentFriendRequestContextMenu';
import { useOpenProfile } from '@knuddelsModules/Profile';
import { useTrackFriendRequestsView } from './useTrackFriendRequestsView';
export const SentFriendRequest: React.FC<{
  userId: string;
  nick: string;
  gender: Gender;
  age?: number;
}> = ({
  userId,
  nick,
  gender,
  age
}) => {
  const overlayService = useService($OverlayService);
  const track = useTrackFriendRequestsView();
  const openProfile = useOpenProfile({
    id: userId,
    nick
  }, () => track('SentRequest_ProfileOpened'));
  const openContextMenu = (x: number, y: number, width: number, height: number) => {
    overlayService.showOverlayIfNotVisible(SentFriendRequestContextMenuOverlay, {
      requestUserId: userId,
      chevronX: x,
      chevronY: y,
      chevronWidth: width,
      chevronHeight: height
    });
  };
  return <ContextMenuTrigger chevronBackgroundColor={'transparent'} position={'bottom'} showContextMenu={openContextMenu} onContentWrapperClick={() => openProfile('SentFriendRequest')}>
			<div className={_c0}>
				<UserImage type={UserImageType.MESSENGER} userId={userId} />
				<Spacer size={'base'} />
				<Text type={'subtitle'} bold={true} state={'primary'} numberOfLines={1} className={_c1}>
					{nick}
				</Text>
				<Spacer size={'small'} />
				{gender !== Gender.Unknown && <GenderIcon gender={gender} />}
				<Spacer size={'tiny'} />
				{typeof age === 'number' && <Text type={'body1'} state={'tertiary'} className={_c2}>
						{age}
					</Text>}
			</div>
		</ContextMenuTrigger>;
};
const _c0 = " Knu-Flex p-base alignItems-center ";
const _c1 = "  ";
const _c2 = "  ";