import { isNone as h } from "../../animation/utils/is-none.js";
import { getVariableValue as y } from "./utils/css-variables-conversion.js";
import { isCSSVariableToken as p } from "./utils/is-css-variable.js";
import { positionalKeys as g, isNumOrPxType as l, positionalValues as u } from "./utils/unit-conversion.js";
import { findDimensionValueType as d } from "./value-types/dimensions.js";
import { KeyframeResolver as c } from "../utils/KeyframesResolver.js";
import { makeNoneKeyframesAnimatable as K } from "../html/utils/make-none-animatable.js";
class b extends c {
  constructor(e, r, n, t, o) {
    super(e, r, n, t, o, !0);
  }
  readKeyframes() {
    const { unresolvedKeyframes: e, element: r, name: n } = this;
    if (!r || !r.current)
      return;
    super.readKeyframes();
    for (let s = 0; s < e.length; s++) {
      let i = e[s];
      if (typeof i == "string" && (i = i.trim(), p(i))) {
        const m = y(i, r.current);
        m !== void 0 && (e[s] = m), s === e.length - 1 && (this.finalKeyframe = i);
      }
    }
    if (this.resolveNoneKeyframes(), !g.has(n) || e.length !== 2)
      return;
    const [t, o] = e, a = d(t), f = d(o);
    if (a !== f)
      if (l(a) && l(f))
        for (let s = 0; s < e.length; s++) {
          const i = e[s];
          typeof i == "string" && (e[s] = parseFloat(i));
        }
      else
        this.needsMeasurement = !0;
  }
  resolveNoneKeyframes() {
    const { unresolvedKeyframes: e, name: r } = this, n = [];
    for (let t = 0; t < e.length; t++)
      h(e[t]) && n.push(t);
    n.length && K(e, n, r);
  }
  measureInitialState() {
    const { element: e, unresolvedKeyframes: r, name: n } = this;
    if (!e || !e.current)
      return;
    n === "height" && (this.suspendedScrollY = window.pageYOffset), this.measuredOrigin = u[n](e.measureViewportBox(), window.getComputedStyle(e.current)), r[0] = this.measuredOrigin;
    const t = r[r.length - 1];
    t !== void 0 && e.getValue(n, t).jump(t, !1);
  }
  measureEndState() {
    var e;
    const { element: r, name: n, unresolvedKeyframes: t } = this;
    if (!r || !r.current)
      return;
    const o = r.getValue(n);
    o && o.jump(this.measuredOrigin, !1);
    const a = t.length - 1, f = t[a];
    t[a] = u[n](r.measureViewportBox(), window.getComputedStyle(r.current)), f !== null && this.finalKeyframe === void 0 && (this.finalKeyframe = f), !((e = this.removedTransforms) === null || e === void 0) && e.length && this.removedTransforms.forEach(([s, i]) => {
      r.getValue(s).set(i);
    }), this.resolveNoneKeyframes();
  }
}
export {
  b as DOMKeyframesResolver
};
