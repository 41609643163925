import { injectable } from '@knuddels-app/DependencyInjection';
import { ApolloClient, NormalizedCacheObject, split } from '@apollo/client';
import { getMainDefinition } from '@apollo/client/utilities';
import { Endpoint } from '../Endpoints';
import { AuthTokenProvider } from './AuthTokenProvider';
import { createApolloClientCache } from './createApolloClientCache';
import { K3ApolloClient } from './K3ApolloClient';
import { createHttpLink } from './createLinks';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { SubscriptionClientWrapper } from './SubscriptionClientWrapper';

export interface CreateClientOptions {
	endpoint: Endpoint;
	authTokenProvider: AuthTokenProvider;
	withSubscriptionClient: boolean;
}

@injectable()
export class K3ApolloClientFactory {
	public createClient({
		endpoint,
		authTokenProvider,
		withSubscriptionClient,
	}: CreateClientOptions): K3ApolloClient {
		let link = createHttpLink(endpoint, authTokenProvider);

		let subscriptionClient: SubscriptionClientWrapper | undefined;
		if (withSubscriptionClient) {
			subscriptionClient = new SubscriptionClientWrapper(
				endpoint,
				authTokenProvider
			);

			const wsLink = new GraphQLWsLink(subscriptionClient.client);

			link = split(
				// split based on operation type
				({ query }) => {
					const definition = getMainDefinition(query);
					return (
						definition.name.value === 'UpdateSubscriptionToken' ||
						(definition.kind === 'OperationDefinition' &&
							definition.operation === 'subscription')
					);
				},
				wsLink,
				link
			);
		}

		const client = new ApolloClient({
			link,
			assumeImmutableResults: true,
			cache: createApolloClientCache(),
			resolvers: {},
			// might want to add defaultOptions...
		});

		return new K3ApolloClientImpl(
			endpoint,
			authTokenProvider,
			client,
			subscriptionClient
		);
	}
}

export class K3ApolloClientImpl extends K3ApolloClient {
	constructor(
		private readonly endpoint: Endpoint,
		private readonly authTokenProvider: AuthTokenProvider,
		client: ApolloClient<NormalizedCacheObject>,
		subscriptionClient: SubscriptionClientWrapper | undefined
	) {
		super(client, subscriptionClient);

		if (subscriptionClient) {
			this.dispose.track(() => {
				// prevents trying to reconnect after dispose
				subscriptionClient.dispose();
			});
		}

		this.dispose.track(() => {
			// TODO correctly dispose client (e.g. client.clearStore(), client.stop(), ...?)
			this.client.cache.reset();
		});
	}
}
