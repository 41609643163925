import { isAnimationControls as z } from "../../animation/utils/is-animation-controls.js";
import { isKeyframesTarget as D } from "../../animation/utils/is-keyframes-target.js";
import { shallowCompare as _ } from "../../utils/shallow-compare.js";
import { isVariantLabel as j } from "./is-variant-label.js";
import { resolveVariant as q } from "./resolve-dynamic-variants.js";
import { variantPriorityOrder as k } from "./variant-props.js";
import { animateVisualElement as G } from "../../animation/interfaces/visual-element.js";
import { getVariantContext as J } from "./get-variant-context.js";
const N = [...k].reverse(), Q = k.length;
function U(e) {
  return (f) => Promise.all(f.map(({ animation: c, options: v }) => G(e, c, v)));
}
function re(e) {
  let f = U(e), c = L(), v = !0;
  const B = (s) => (i, m) => {
    var l;
    const a = q(e, m, s === "exit" ? (l = e.presenceContext) === null || l === void 0 ? void 0 : l.custom : void 0);
    if (a) {
      const { transition: u, transitionEnd: y, ...g } = a;
      i = { ...i, ...g, ...y };
    }
    return i;
  };
  function F(s) {
    f = s(e);
  }
  function T(s) {
    const { props: i } = e, m = J(e.parent) || {}, l = [], a = /* @__PURE__ */ new Set();
    let u = {}, y = 1 / 0;
    for (let p = 0; p < Q; p++) {
      const o = N[p], t = c[o], r = i[o] !== void 0 ? i[o] : m[o], w = j(r), P = o === s ? t.isActive : null;
      P === !1 && (y = p);
      let V = r === m[o] && r !== i[o] && w;
      if (V && v && e.manuallyAnimateOnMount && (V = !1), t.protectedKeys = { ...u }, // If it isn't active and hasn't *just* been set as inactive
      !t.isActive && P === null || // If we didn't and don't have any defined prop for this animation type
      !r && !t.prevProp || // Or if the prop doesn't define an animation
      z(r) || typeof r == "boolean")
        continue;
      const I = W(t.prevProp, r);
      let C = I || // If we're making this variant active, we want to always make it active
      o === s && t.isActive && !V && w || // If we removed a higher-priority variant (i is in reverse order)
      p > y && w, b = !1;
      const R = Array.isArray(r) ? r : [r];
      let h = R.reduce(B(o), {});
      P === !1 && (h = {});
      const { prevResolvedValues: x = {} } = t, M = {
        ...x,
        ...h
      }, O = (n) => {
        C = !0, a.has(n) && (b = !0, a.delete(n)), t.needsAnimating[n] = !0;
        const d = e.getValue(n);
        d && (d.liveStyle = !1);
      };
      for (const n in M) {
        const d = h[n], S = x[n];
        if (u.hasOwnProperty(n))
          continue;
        let K = !1;
        D(d) && D(S) ? K = !_(d, S) : K = d !== S, K ? d != null ? O(n) : a.add(n) : d !== void 0 && a.has(n) ? O(n) : t.protectedKeys[n] = !0;
      }
      t.prevProp = r, t.prevResolvedValues = h, t.isActive && (u = { ...u, ...h }), v && e.blockInitialAnimation && (C = !1), C && (!(V && I) || b) && l.push(...R.map((n) => ({
        animation: n,
        options: { type: o }
      })));
    }
    if (a.size) {
      const p = {};
      a.forEach((o) => {
        const t = e.getBaseTarget(o), r = e.getValue(o);
        r && (r.liveStyle = !0), p[o] = t ?? null;
      }), l.push({ animation: p });
    }
    let g = !!l.length;
    return v && (i.initial === !1 || i.initial === i.animate) && !e.manuallyAnimateOnMount && (g = !1), v = !1, g ? f(l) : Promise.resolve();
  }
  function H(s, i) {
    var m;
    if (c[s].isActive === i)
      return Promise.resolve();
    (m = e.variantChildren) === null || m === void 0 || m.forEach((a) => {
      var u;
      return (u = a.animationState) === null || u === void 0 ? void 0 : u.setActive(s, i);
    }), c[s].isActive = i;
    const l = T(s);
    for (const a in c)
      c[a].protectedKeys = {};
    return l;
  }
  return {
    animateChanges: T,
    setActive: H,
    setAnimateFunction: F,
    getState: () => c,
    reset: () => {
      c = L(), v = !0;
    }
  };
}
function W(e, f) {
  return typeof f == "string" ? f !== e : Array.isArray(f) ? !_(f, e) : !1;
}
function A(e = !1) {
  return {
    isActive: e,
    protectedKeys: {},
    needsAnimating: {},
    prevResolvedValues: {}
  };
}
function L() {
  return {
    animate: A(!0),
    whileInView: A(),
    whileHover: A(),
    whileTap: A(),
    whileDrag: A(),
    whileFocus: A(),
    exit: A()
  };
}
export {
  W as checkVariantsDidChange,
  re as createAnimationState
};
