import { isGenerator as s } from "../../generators/utils/is-generator.js";
import { isAnimatable as e } from "../../utils/is-animatable.js";
function c(t) {
  const i = t[0];
  if (t.length === 1)
    return !0;
  for (let n = 0; n < t.length; n++)
    if (t[n] !== i)
      return !0;
}
function h(t, i, n, o) {
  const r = t[0];
  if (r === null)
    return !1;
  if (i === "display" || i === "visibility")
    return !0;
  const l = t[t.length - 1], u = e(r, i), a = e(l, i);
  return !u || !a ? !1 : c(t) || (n === "spring" || s(n)) && o;
}
export {
  h as canAnimate
};
