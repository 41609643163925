import { useContext as C } from "react";
import { isAnimationControls as x } from "../../animation/utils/is-animation-controls.js";
import { PresenceContext as S } from "../../context/PresenceContext.js";
import { resolveVariantFromProps as h } from "../../render/utils/resolve-variants.js";
import { useConstant as N } from "../../utils/use-constant.js";
import { resolveMotionValue as P } from "../../value/utils/resolve-motion-value.js";
import { MotionContext as T } from "../../context/MotionContext/index.js";
import { isControllingVariants as $, isVariantNode as b } from "../../render/utils/is-controlling-variants.js";
function B({ scrapeMotionValuesFromProps: t, createRenderState: e, onMount: o }, r, n, a) {
  const i = {
    latestValues: E(r, n, a, t),
    renderState: e()
  };
  return o && (i.mount = (f) => o(r, f, i)), i;
}
const D = (t) => (e, o) => {
  const r = C(T), n = C(S), a = () => B(t, e, r, n);
  return o ? a() : N(a);
};
function E(t, e, o, r) {
  const n = {}, a = r(t, {});
  for (const m in a)
    n[m] = P(a[m]);
  let { initial: i, animate: f } = t;
  const g = $(t), v = b(t);
  e && v && !g && t.inherit !== !1 && (i === void 0 && (i = e.initial), f === void 0 && (f = e.animate));
  let u = o ? o.initial === !1 : !1;
  u = u || i === !1;
  const s = u ? f : i;
  if (s && typeof s != "boolean" && !x(s)) {
    const m = Array.isArray(s) ? s : [s];
    for (let d = 0; d < m.length; d++) {
      const V = h(t, m[d]);
      if (V) {
        const { transitionEnd: k, transition: F, ...y } = V;
        for (const c in y) {
          let l = y[c];
          if (Array.isArray(l)) {
            const A = u ? l.length - 1 : 0;
            l = l[A];
          }
          l !== null && (n[c] = l);
        }
        for (const c in k)
          n[c] = k[c];
      }
    }
  }
  return n;
}
export {
  D as makeUseVisualState
};
