import { buildHTMLStyles as s } from "./utils/build-styles.js";
import { isCSSVariableName as n } from "../dom/utils/is-css-variable.js";
import { transformProps as m } from "./utils/transform.js";
import { scrapeMotionValuesFromProps as u } from "./utils/scrape-motion-values.js";
import { renderHTML as l } from "./utils/render.js";
import { getDefaultValueType as p } from "../dom/value-types/defaults.js";
import { measureViewportBox as a } from "../../projection/utils/measure.js";
import { DOMVisualElement as c } from "../dom/DOMVisualElement.js";
import { isMotionValue as d } from "../../value/utils/is-motion-value.js";
function f(i) {
  return window.getComputedStyle(i);
}
class C extends c {
  constructor() {
    super(...arguments), this.type = "html", this.renderInstance = l;
  }
  readValueFromInstance(e, t) {
    if (m.has(t)) {
      const r = p(t);
      return r && r.default || 0;
    } else {
      const r = f(e), o = (n(t) ? r.getPropertyValue(t) : r[t]) || 0;
      return typeof o == "string" ? o.trim() : o;
    }
  }
  measureInstanceViewportBox(e, { transformPagePoint: t }) {
    return a(e, t);
  }
  build(e, t, r) {
    s(e, t, r.transformTemplate);
  }
  scrapeMotionValuesFromProps(e, t, r) {
    return u(e, t, r);
  }
  handleChildMotionValue() {
    this.childSubscription && (this.childSubscription(), delete this.childSubscription);
    const { children: e } = this.props;
    d(e) && (this.childSubscription = e.on("change", (t) => {
      this.current && (this.current.textContent = `${t}`);
    }));
  }
}
export {
  C as HTMLVisualElement,
  f as getComputedStyle
};
