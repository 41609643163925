import { LexicalNode, TextModeType, TextNode } from 'lexical';
// tslint:disable-next-line: no-module-bleeding
import { EmojiData } from '@knuddelsModules/AutocompleteInputBar/bundle/services';
export class EmojiNode extends TextNode {
  static getType(): string {
    return 'emoji';
  }
  static clone(node: EmojiNode): EmojiNode {
    return $createEmojiNode(node.data, node.getKey());
  }
  constructor(public data: EmojiData, key?: string) {
    super(data.emoji, key);
  }
  canInsertTextBefore(): boolean {
    return false;
  }
  canInsertTextAfter(): boolean {
    return false;
  }
  getMode(): TextModeType {
    return 'token';
  }
  isUnmergeable(): boolean {
    return true;
  }
}
export function $createEmojiNode(data: EmojiData, key?: string): EmojiNode {
  return new EmojiNode(data, key);
}
export function $isEmojiNode(node: LexicalNode | null | undefined): node is EmojiNode {
  return node instanceof EmojiNode;
}