/**
 * This is the entry point of all products.
 */

import { dangerouslyInitK3Container } from '@knuddels-app/ModuleSystem/dangerouslyInitK3Container';

import { configureMobx } from '@knuddels-app/mobx';
import { setupApp } from './app';

import { restoreDeviceIdFromCookie } from './restoreDeviceIdFromCookie';

restoreDeviceIdFromCookie();

configureMobx();

dangerouslyInitK3Container();

setupApp();

export {};
