import {
	inject,
	injectable,
	K3ServiceBinder,
	newServiceId,
} from '../DependencyInjection';
import { observable } from 'mobx';
import {
	$AppLoadingProvider,
	simpleAppLoadingProvider,
} from '../AppLoadingService';
import { $UserService } from '@knuddelsModules/UserData';
import { $AppService } from '@knuddelsModules/Apps';
import { deepCopy, DeepPartial } from './deepCopy';
import { Disposable } from '@knuddels/std';
import { debounce } from '../tools/debounce';
import { runInAction } from '../mobx';
import { $AuthenticatedClientService } from '../Connection';
import { EvergreenDataChanged } from '@generated/graphql';
import { MenuEvergreenData } from '@knuddels-app/evergreenData/MenuEvergreenData';

export type EvergreenData = {
	mixpanel: {
		superProperties: Record<string, any>;
		enabled: boolean | undefined;
		endpoint?: string;
	};
	loyalty: {
		currentLevel: number;
		state: 'OK' | 'POINT_LOSS' | 'LEVEL_LOSS';
		card:
			| 'NONE'
			| 'POINT_LOSS'
			| 'LEVEL_LOSS'
			| 'ONBOARDING'
			| 'COLLECT_LEVEL';
	};
	user: {
		nick: string;
		knuddelAmount: number;
	};
	menu: MenuEvergreenData;
	settings: {
		crashes: {
			ep: string;
			logIntervall: number;
			reportFastRestartMaxMillis: number;
		};
		timing: {
			ep: string;
			commands: {
				commandName: string;
				parameterRegex: string;
				appId: string;
			}[];
		};
	};
};

@injectable()
export class EvergreenDataService {
	public readonly dispose = Disposable.fn();

	@observable
	public initalized = false;

	@observable.ref
	public data: EvergreenData = {
		mixpanel: {
			superProperties: {},
			enabled: false,
		},
		loyalty: {
			currentLevel: 0,
			state: 'OK',
			card: 'NONE',
		},
		user: {
			nick: '',
			knuddelAmount: 0,
		},
		menu: {
			categories: [],
			badges: {},
		},
		settings: {
			crashes: {
				ep: '',
				logIntervall: -1,
				reportFastRestartMaxMillis: -1,
			},
			timing: {
				ep: '',
				commands: [],
			},
		},
	};

	private _data: EvergreenData = this.data;

	constructor(
		@inject($AuthenticatedClientService)
		private readonly clientService: typeof $AuthenticatedClientService.T,
		@inject.lazy($UserService)
		private getUserService: typeof $UserService.TLazy,
		@inject.lazy($AppService)
		private getAppService: typeof $AppService.TLazy
	) {}

	async init(): Promise<void> {
		const userService = await this.getUserService();

		this.dispose.track(
			userService.addKnuddelAmountChangedListener(newAmount => {
				this.handleUpdate({
					user: {
						knuddelAmount: newAmount,
					},
				});
			})
		);

		const user = await userService.getCurrentUser();

		this.dispose.track(
			this.clientService.currentK3Client.subscribeToPrimaryData(
				EvergreenDataChanged,
				{
					userId: user.id,
				},
				{
					next: data => {
						this.handleUpdate(
							JSON.parse(data.evergreenData ?? '{}')
						);
					},
				}
			)
		);

		this.handleUpdate({
			...JSON.parse(user.evergreenData ?? '{}'),
			user: {
				nick: user.nick,
				knuddelAmount: user.knuddelAmount ?? 0,
			},
		});
		this.initalized = true;
	}

	private handleUpdate = (update: DeepPartial<EvergreenData>) => {
		this._data = deepCopy(this._data, update);
		this.publishNewData();
	};

	private publishNewData = debounce(() => {
		runInAction('Update evergreen data', () => {
			this.data = this._data;

			if (globalEnv.product === 'app') {
				this.getAppService().then(appService => {
					const apps = appService.appViewers
						.flatMap(viewer => viewer.getAllApps())
						.filter(Boolean);
					apps.forEach(app => {
						app.updateEvergreenData(this.data);
					});
				});
			}
		});
	});
}

export const $EvergreenDataService = newServiceId<EvergreenDataService>(
	'$EvergreenDataService'
);

export const registerServices = (ctx: K3ServiceBinder) => {
	ctx.loggedInScope
		.bind($EvergreenDataService)
		.toSingleton(EvergreenDataService);

	ctx.globalScope
		.bind($AppLoadingProvider)
		.toConstant(
			simpleAppLoadingProvider($EvergreenDataService, service =>
				service.init()
			)
		);
};
