import { jsxs as p, jsx as s } from "react/jsx-runtime";
import { clsx as b } from "../../node_modules/clsx/dist/clsx.m.js";
import * as m from "react";
import { IconError as D } from "../Icons/Error.js";
import { ScaleMultiplier as C } from "./ScaleMultiplier.js";
import { motion as T } from "../../deps/framer-motion/dist/es/render/components/motion/proxy.js";
import { useSpring as h } from "../../deps/framer-motion/dist/es/value/use-spring.js";
import { useTransform as G } from "../../deps/framer-motion/dist/es/value/use-transform.js";
import { useTheme as X } from "../../themes/useTheme.js";
import { FlexCol as I } from "../Layout/FlexCol.js";
import { Flex as A } from "../Layout/Flex.js";
const K = 4, g = {
  bounce: 0,
  stiffness: 400,
  mass: 1,
  damping: 35
}, W = ({
  labelHeight: c,
  shouldRenderLabelOnTop: t,
  labelPadding: a,
  immediate: n
}) => {
  const i = t ? -c / 2 * C : 0, r = t ? a : 0, l = t ? 0.8 : 1, o = h(
    i,
    g
  ), d = h(
    r,
    g
  ), e = h(l, g), f = G(
    o,
    (u) => u * e.get()
  );
  return m.useLayoutEffect(() => {
    n ? (o.jump(i), d.jump(r), e.jump(l)) : (o.set(i), d.set(r), e.set(l));
  }, [i, r, l]), {
    labelTranslateX: d,
    scaledLabelTranslateY: f,
    scale: e
  };
}, k = {
  bounce: 0,
  stiffness: 1e3,
  mass: 1,
  damping: 35
}, q = (c) => {
  const t = c ? 1 : 0, a = h(t, k);
  return m.useLayoutEffect(() => {
    a.set(t);
  }, [t]), {
    opacity: a
  };
}, le = ({
  value: c,
  focused: t,
  description: a,
  error: n,
  label: i,
  labelStyle: r = "default",
  adornmentEnd: l,
  adornmentStart: o,
  placeholder: d,
  height: e,
  children: f,
  additionalInfo: u,
  onPress: y,
  adornmentEndInteractive: M = !0
}) => {
  const x = X(), O = x.sizes.input, v = m.useRef(e), j = i && r !== "never", E = c === void 0 || c === "", N = t || !E || r === "always-on-top", z = (N || !i) && E, L = Math.min(e, O.default), B = o ? -Math.ceil(23 * C) : 0, R = n && !l;
  m.useLayoutEffect(() => {
    v.current = e;
  }, [e]);
  const {
    labelTranslateX: H,
    scaledLabelTranslateY: Y,
    scale: _
  } = W({
    labelHeight: L,
    labelPadding: B,
    shouldRenderLabelOnTop: N,
    immediate: v.current !== e
    // do not animate height changes
  }), { opacity: P } = q(z), F = (Math.min(e, x.sizes.input.default) - x.sizes.texts.body1.lineHeight) / 2, V = Math.max(
    Math.floor(
      (e - F) / x.sizes.texts.body1.lineHeight
    ),
    1
  );
  return /* @__PURE__ */ p(
    I,
    {
      width: "100%",
      onPress: (w) => {
        w.stopPropagation(), y == null || y();
      },
      children: [
        /* @__PURE__ */ p(
          I,
          {
            bg: "inputFieldBg",
            borderColor: t ? "accent" : n ? "inputFieldBorderError" : "inputFieldBorder",
            borderWidth: "base",
            height: e + "px",
            px: "small",
            pl: o ? "xlarge" : "small",
            pr: l || n ? "xlarge" : "small",
            borderRadius: "small",
            justifyContent: "center",
            borderStyle: "solid",
            position: "relative",
            children: [
              f,
              /* @__PURE__ */ p(
                A,
                {
                  pointerEvents: "none",
                  position: "absolute",
                  px: "minor",
                  alignItems: "center",
                  inset: "none",
                  children: [
                    o && /* @__PURE__ */ s("div", { className: "Knu-Flex mr-small pointerEvents-none", children: m.cloneElement(o, {
                      type: t ? "highlighted" : "primary"
                    }) }),
                    /* @__PURE__ */ p(
                      "div",
                      {
                        className: "Knu-Flex position-relative flex-1 size-full pointerEvents-none",
                        children: [
                          /* @__PURE__ */ s(
                            T.div,
                            {
                              style: {
                                paddingTop: F,
                                width: "100%",
                                opacity: P
                              },
                              children: /* @__PURE__ */ s(
                                "span",
                                {
                                  className: "Knu-Text text-body1 text-tertiary numberOfLines italic pr-tiny",
                                  style: {
                                    "--numberOfLines": V
                                  },
                                  children: d
                                }
                              )
                            }
                          ),
                          j && /* @__PURE__ */ p(
                            T.div,
                            {
                              style: {
                                display: "flex",
                                x: H,
                                y: Y,
                                scale: _,
                                pointerEvents: "none",
                                position: "absolute",
                                height: L,
                                alignItems: "center",
                                transformOrigin: "left"
                              },
                              children: [
                                /* @__PURE__ */ s(
                                  A,
                                  {
                                    position: "absolute",
                                    bg: "inputFieldBg",
                                    height: "5px",
                                    left: -K + "px",
                                    right: -K + "px"
                                  }
                                ),
                                /* @__PURE__ */ s("div", { className: "Knu-Flex zIndex-1", children: /* @__PURE__ */ s(
                                  "span",
                                  {
                                    className: b(
                                      "Knu-Text singleLine text-body1",
                                      t ? "text-highlighted" : n ? "text-error" : "text-tertiary"
                                    ),
                                    children: i
                                  }
                                ) })
                              ]
                            }
                          )
                        ]
                      }
                    ),
                    R && /* @__PURE__ */ s("div", { className: "Knu-Flex pointerEvents-none", children: /* @__PURE__ */ s(D, { type: "error" }) }),
                    l && /* @__PURE__ */ s(
                      "div",
                      {
                        className: b(
                          "Knu-Flex",
                          M ? "pointerEvents-all" : "pointerEvents-none"
                        ),
                        children: m.cloneElement(
                          l,
                          n ? {
                            type: "error",
                            hoverColor: "red-500"
                          } : {}
                        )
                      }
                    )
                  ]
                }
              )
            ]
          }
        ),
        (n || a || u) && /* @__PURE__ */ p("div", { className: "Knu-Flex ml-minor mr-tiny mt-tiny", children: [
          /* @__PURE__ */ s("div", { className: "Knu-Flex flex-1", children: /* @__PURE__ */ s(
            "span",
            {
              className: b(
                "Knu-Text text-tiny",
                n ? "text-error" : "text-tertiary"
              ),
              children: n || a
            }
          ) }),
          u
        ] })
      ]
    }
  );
};
export {
  le as InputContainer
};
