import { KeyframeResolver as w } from "../../render/utils/KeyframesResolver.js";
import { spring as G } from "../generators/spring/index.js";
import { inertia as x } from "../generators/inertia.js";
import { keyframes as y } from "../generators/keyframes.js";
import { BaseAnimation as I } from "./BaseAnimation.js";
import { pipe as A } from "../../utils/pipe.js";
import { mix as _ } from "../../utils/mix/index.js";
import { calcGeneratorDuration as B } from "../generators/utils/calc-duration.js";
import { millisecondsToSeconds as P, secondsToMilliseconds as C } from "../../utils/time-conversion.js";
import { clamp as O } from "../../utils/clamp.js";
import { frameloopDriver as V } from "./drivers/driver-frameloop.js";
import { getFinalKeyframe as U } from "./waapi/utils/get-final-keyframe.js";
import { isGenerator as W } from "../generators/utils/is-generator.js";
const j = {
  decay: x,
  inertia: x,
  tween: y,
  keyframes: y,
  spring: G
}, q = (p) => p / 100;
class z extends I {
  constructor(t) {
    super(t), this.holdTime = null, this.cancelTime = null, this.currentTime = 0, this.playbackSpeed = 1, this.pendingPlayState = "running", this.startTime = null, this.state = "idle", this.stop = () => {
      if (this.resolver.cancel(), this.isStopped = !0, this.state === "idle")
        return;
      this.teardown();
      const { onStop: n } = this.options;
      n && n();
    };
    const { name: i, motionValue: r, element: e, keyframes: o } = this.options, u = (e == null ? void 0 : e.KeyframeResolver) || w, a = (n, l) => this.onKeyframesResolved(n, l);
    this.resolver = new u(o, a, i, r, e), this.resolver.scheduleResolve();
  }
  initPlayback(t) {
    const { type: i = "keyframes", repeat: r = 0, repeatDelay: e = 0, repeatType: o, velocity: u = 0 } = this.options, a = W(i) ? i : j[i] || y;
    let n, l;
    a !== y && typeof t[0] != "number" && (n = A(q, _(t[0], t[1])), t = [0, 100]);
    const s = a({ ...this.options, keyframes: t });
    o === "mirror" && (l = a({
      ...this.options,
      keyframes: [...t].reverse(),
      velocity: -u
    })), s.calculatedDuration === null && (s.calculatedDuration = B(s));
    const { calculatedDuration: m } = s, f = m + e, T = f * (r + 1) - e;
    return {
      generator: s,
      mirroredGenerator: l,
      mapPercentToKeyframes: n,
      calculatedDuration: m,
      resolvedDuration: f,
      totalDuration: T
    };
  }
  onPostResolved() {
    const { autoplay: t = !0 } = this.options;
    this.play(), this.pendingPlayState === "paused" || !t ? this.pause() : this.state = this.pendingPlayState;
  }
  tick(t, i = !1) {
    const { resolved: r } = this;
    if (!r) {
      const { keyframes: c } = this.options;
      return { done: !0, value: c[c.length - 1] };
    }
    const { finalKeyframe: e, generator: o, mirroredGenerator: u, mapPercentToKeyframes: a, keyframes: n, calculatedDuration: l, totalDuration: s, resolvedDuration: m } = r;
    if (this.startTime === null)
      return o.next(0);
    const { delay: f, repeat: T, repeatType: D, repeatDelay: S, onUpdate: k } = this.options;
    this.speed > 0 ? this.startTime = Math.min(this.startTime, t) : this.speed < 0 && (this.startTime = Math.min(t - s / this.speed, this.startTime)), i ? this.currentTime = t : this.holdTime !== null ? this.currentTime = this.holdTime : this.currentTime = Math.round(t - this.startTime) * this.speed;
    const g = this.currentTime - f * (this.speed >= 0 ? 1 : -1), M = this.speed >= 0 ? g < 0 : g > s;
    this.currentTime = Math.max(g, 0), this.state === "finished" && this.holdTime === null && (this.currentTime = s);
    let K = this.currentTime, F = o;
    if (T) {
      const c = Math.min(this.currentTime, s) / m;
      let v = Math.floor(c), h = c % 1;
      !h && c >= 1 && (h = 1), h === 1 && v--, v = Math.min(v, T + 1), !!(v % 2) && (D === "reverse" ? (h = 1 - h, S && (h -= S / m)) : D === "mirror" && (F = u)), K = O(0, 1, h) * m;
    }
    const d = M ? { done: !1, value: n[0] } : F.next(K);
    a && (d.value = a(d.value));
    let { done: R } = d;
    !M && l !== null && (R = this.speed >= 0 ? this.currentTime >= s : this.currentTime <= 0);
    const b = this.holdTime === null && (this.state === "finished" || this.state === "running" && R);
    return b && e !== void 0 && (d.value = U(n, this.options, e)), k && k(d.value), b && this.finish(), d;
  }
  get duration() {
    const { resolved: t } = this;
    return t ? P(t.calculatedDuration) : 0;
  }
  get time() {
    return P(this.currentTime);
  }
  set time(t) {
    t = C(t), this.currentTime = t, this.holdTime !== null || this.speed === 0 ? this.holdTime = t : this.driver && (this.startTime = this.driver.now() - t / this.speed);
  }
  get speed() {
    return this.playbackSpeed;
  }
  set speed(t) {
    const i = this.playbackSpeed !== t;
    this.playbackSpeed = t, i && (this.time = P(this.currentTime));
  }
  play() {
    if (this.resolver.isScheduled || this.resolver.resume(), !this._resolved) {
      this.pendingPlayState = "running";
      return;
    }
    if (this.isStopped)
      return;
    const { driver: t = V, onPlay: i, startTime: r } = this.options;
    this.driver || (this.driver = t((o) => this.tick(o))), i && i();
    const e = this.driver.now();
    this.holdTime !== null ? this.startTime = e - this.holdTime : this.startTime ? this.state === "finished" && (this.startTime = e) : this.startTime = r ?? this.calcStartTime(), this.state === "finished" && this.updateFinishedPromise(), this.cancelTime = this.startTime, this.holdTime = null, this.state = "running", this.driver.start();
  }
  pause() {
    var t;
    if (!this._resolved) {
      this.pendingPlayState = "paused";
      return;
    }
    this.state = "paused", this.holdTime = (t = this.currentTime) !== null && t !== void 0 ? t : 0;
  }
  complete() {
    this.state !== "running" && this.play(), this.pendingPlayState = this.state = "finished", this.holdTime = null;
  }
  finish() {
    this.teardown(), this.state = "finished";
    const { onComplete: t } = this.options;
    t && t();
  }
  cancel() {
    this.cancelTime !== null && this.tick(this.cancelTime), this.teardown(), this.updateFinishedPromise();
  }
  teardown() {
    this.state = "idle", this.stopDriver(), this.resolveFinishedPromise(), this.updateFinishedPromise(), this.startTime = this.cancelTime = null, this.resolver.cancel();
  }
  stopDriver() {
    this.driver && (this.driver.stop(), this.driver = void 0);
  }
  sample(t) {
    return this.startTime = 0, this.tick(t, !0);
  }
}
function rt(p) {
  return new z(p);
}
export {
  z as MainThreadAnimation,
  rt as animateValue
};
