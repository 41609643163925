import { clamp as n } from "../../../utils/clamp.js";
import { alpha as s, number as i } from "../numbers/index.js";
import { sanitize as p } from "../utils/sanitize.js";
import { isColorString as e, splitColor as f } from "./utils.js";
const l = (r) => n(0, 255, r), o = {
  ...i,
  transform: (r) => Math.round(l(r))
}, h = {
  test: /* @__PURE__ */ e("rgb", "red"),
  parse: /* @__PURE__ */ f("red", "green", "blue"),
  transform: ({ red: r, green: t, blue: a, alpha: m = 1 }) => "rgba(" + o.transform(r) + ", " + o.transform(t) + ", " + o.transform(a) + ", " + p(s.transform(m)) + ")"
};
export {
  o as rgbUnit,
  h as rgba
};
