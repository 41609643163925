import * as React from 'react';
import { createAccessiblePointerEventHandler, Flex, FlexCol, IconClose, Image, Text, ThemeOverride, resolveThemingValue, useTheme, resolveIsDarkColor } from '@knuddels/component-library';
import { useService } from '@knuddels-app/DependencyInjection';
import { $I18n, declareFormat, FormattedMessage } from '@knuddels-app/i18n';
import { isNative } from '@knuddels-app/tools/isNative';
import { DashboardTip } from './tips';
import { $DashboardService } from '@knuddelsModules/Channel';
import { observer } from '@knuddels-app/mobx';
import { HorizontalSwipeOutView } from '@knuddels-app/shared-components/HorizontalSwipeOutView';
import { isUsingTouch } from '@shared/components';
import { useLogEvent } from '@knuddels-app/analytics/firebase/useLogEvent';
import { useHovered } from '@knuddels-app/tools/useHovered';
export const DashboardTipsElement: React.FC<{
  layout: 'desktop' | 'mobile';
}> = observer('DashboardTipsElement', ({
  layout
}) => {
  const i18n = useService($I18n);
  const {
    shownTips,
    onRemove
  } = useShownTips();
  if (shownTips.length === 0) {
    return null;
  }
  return <div className={_c0}>
			<div className={_c1}>
				<Text type={'body2'} state={'tertiary'} bold={true} className={_c2}>
					{declareFormat({
          id: 'channel.dashboard.tips.title',
          defaultFormat: 'Try now'
        }).format(i18n).toUpperCase()}
				</Text>
			</div>
			<div className={_c3 + (layout === 'mobile' ? _c4 : _c5)}>
				{shownTips.map((tip, index) => layout === 'mobile' ? <TipEntryMobile key={tip.id} tip={tip} onSwipeOut={onRemove} /> : <TipEntryDesktop key={tip.id} index={index} tip={tip} onRemove={onRemove} />)}
			</div>
		</div>;
});
const ENTRY_HEIGHT = 88;
const ENTRY_HEIGHT_DESKTOP = 230;
const IMAGE_HEIGHT = 76;
const IMAGE_HEIGHT_DESKTOP = 148;
export const TipEntryDesktop: React.FC<{
  tip: DashboardTip;
  index: number;
  onRemove: (tip: DashboardTip) => void;
}> = ({
  tip,
  index,
  onRemove
}) => {
  const {
    hovered,
    bind
  } = useHovered();
  const dashboardService = useService($DashboardService);
  const track = useLogEvent();
  React.useEffect(() => {
    dashboardService.markTipViewed(tip.id);
  }, [dashboardService, tip.id]);
  return <FlexCol width={320 + 32} overflow={'hidden'} hoverTransform={isNative() ? 'scale(1.00)' : 'scale(1.01)'} {...bind}>
			<div onClick={() => {
      dashboardService.finishTip(tip);
      track('Dashboard_Hints', tip.id + '_Clicked');
    }} style={{
      height: resolveThemingValue(ENTRY_HEIGHT_DESKTOP, "sizes", useTheme())
    }} className={_c6 + ("contentLightBg" ? resolveIsDarkColor("contentLightBg", useTheme()) ? " content-is-dark" : " content-is-light" : "") + (true ? " animated-all " : "") + (index > 0 ? _c7 : _c8) + (index === 0 ? _c9 : _c10)}>
				<Image src={tip.imageSrcDesktop} alt={''} style={{
        height: resolveThemingValue(IMAGE_HEIGHT_DESKTOP, "sizes", useTheme())
      }} className={_c11} />
				<RemoveIcon visible={hovered} onPress={() => onRemove(tip)} />
				<div style={{
        height: resolveThemingValue(ENTRY_HEIGHT_DESKTOP - IMAGE_HEIGHT_DESKTOP, "sizes", useTheme())
      }} className={_c12}>
					<Text numberOfLines={1} type={'subtitle'} bold={true} className={_c13}>
						<FormattedMessage id={tip.title} />
					</Text>
					<Text numberOfLines={2} state={'secondary'} className={_c14}>
						<FormattedMessage id={tip.subtitle} />
						<Flex position={'relative'} bottom={(1 as ThemeOverride)} {...{
            style: {
              display: 'inline'
            }
          }}>
							<Text color={'accent'} type={'tiny'} {...{
              style: {
                letterSpacing: -2
              }
            }}>
								❯❯
							</Text>
						</Flex>
					</Text>
				</div>
			</div>
		</FlexCol>;
};
const RemoveIcon: React.FC<{
  visible: boolean;
  onPress: () => void;
}> = ({
  visible,
  onPress
}) => {
  const {
    hovered,
    bind
  } = useHovered();
  return <FlexCol position={'absolute'} right={'none'} top={'none'} opacity={visible ? 1 : 0} width={36} height={24} bg={'black-transparent-440'} hoverBg={'black-transparent-550'} borderBottomLeftRadius={'base'} placeItems={'center'} {...bind} {...createAccessiblePointerEventHandler({
    pointerCallback: onPress
  })}>
			<IconClose active={hovered} />
		</FlexCol>;
};
const TipEntryMobile: React.FC<{
  tip: DashboardTip;
  onSwipeOut: (tip: DashboardTip) => void;
}> = ({
  tip,
  onSwipeOut
}) => {
  const dashboardService = useService($DashboardService);
  const track = useLogEvent();
  const onSwipeOutTip = React.useCallback(() => {
    onSwipeOut(tip);
    track('Dashboard_Hints', tip.id + '_Skipped');
  }, [tip]);
  React.useEffect(() => {
    dashboardService.markTipViewed(tip.id);
  }, [dashboardService, tip.id]);
  return <div className={_c15}>
			<HorizontalSwipeOutView onSwipeOut={onSwipeOutTip} enabled={isUsingTouch()}>
				<div onClick={() => {
        dashboardService.finishTip(tip);
        track('Dashboard_Hints', tip.id + '_Clicked');
      }} style={{
        transform: resolveThemingValue(isNative() ? undefined : 'scale(1.01)', "theme", useTheme()),
        height: resolveThemingValue(ENTRY_HEIGHT, "sizes", useTheme())
      }} className={_c16 + ("contentLightBg" ? resolveIsDarkColor("contentLightBg", useTheme()) ? " content-is-dark" : " content-is-light" : "") + (true ? " animated-all " : "")}>
					<Image src={tip.imageSrcMobile} alt={''} style={{
          height: resolveThemingValue(IMAGE_HEIGHT, "sizes", useTheme()),
          width: resolveThemingValue(IMAGE_HEIGHT, "sizes", useTheme())
        }} className={_c17} />
					<div style={{
          height: resolveThemingValue(IMAGE_HEIGHT, "sizes", useTheme())
        }} className={_c18}>
						<Text numberOfLines={1} type={'subtitle'} bold={true} className={_c19}>
							<FormattedMessage id={tip.title} />
						</Text>
						<Text numberOfLines={2} state={'secondary'} className={_c20}>
							<FormattedMessage id={tip.subtitle} />
						</Text>
					</div>
					<Text mr={'minor'} type={'subtitle'} color={'accent'} {...{
          style: {
            letterSpacing: -2
          }
        }}>
						❯❯
					</Text>
				</div>
			</HorizontalSwipeOutView>
		</div>;
};
const useShownTips = () => {
  const dashboardService = useService($DashboardService);
  const [shownTips, setShownTips] = React.useState(dashboardService.showDashboardTips);
  React.useEffect(() => {
    if (shownTips.length === 0) {
      setShownTips(dashboardService.showDashboardTips);
    } else {
      setTimeout(() => {
        setShownTips(dashboardService.showDashboardTips);
      }, 1500);
    }
  }, [dashboardService.showDashboardTips]);
  const onRemove = React.useCallback((tip: DashboardTip) => {
    dashboardService.markAsFinished(tip);
    setShownTips(dashboardService.showDashboardTips);
  }, [dashboardService]);
  return {
    shownTips,
    onRemove
  };
};
const _c0 = " Knu-FlexCol ";
const _c1 = " Knu-Flex ml-base mb-minor mt-base ";
const _c2 = "  ";
const _c3 = " Knu-Flex ";
const _c4 = " flexDirection-column ";
const _c5 = " flexDirection-row ";
const _c6 = " Knu-FlexCol position-relative mb-minor borderRadius-base overflow-hidden bg-contentLightBg shadow-Shadow1 cursor-pointer ";
const _c7 = " ml-base ";
const _c8 = " ml-none ";
const _c9 = " mr-base ";
const _c10 = " mr-none ";
const _c11 = " width-full ";
const _c12 = " Knu-FlexCol px-minor py-tiny flex-1 ";
const _c13 = "  ";
const _c14 = " mt-tiny ";
const _c15 = " Knu-FlexCol width-full overflow-hidden ";
const _c16 = " Knu-Flex mx-base mb-minor borderRadius-xlarge overflow-hidden bg-contentLightBg shadow-Shadow1 cursor-pointer alignItems-center ";
const _c17 = " ml-6px ";
const _c18 = " Knu-FlexCol px-minor py-tiny flex-1 ";
const _c19 = "  ";
const _c20 = " mt-tiny ";