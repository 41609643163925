import * as React from 'react';
import { FormattedText } from '@shared/components';
import { Flex, FlexCol, Link, Smiley, Text, ThemeOverride, resolveThemingValue, useTheme } from '@knuddels/component-library';
import { FormattedDateTime, FormattedNumber } from '@knuddels-app/i18n';
import { getFormattedDate } from '@knuddels-app/tools/getDateDifference';
import { $CommandService, FormattedTextDisplayWithCommandHandling } from '@knuddels-app/Commands';
import { DEFAULT_IMAGE, IMAGE_SIZE } from '../constants';
import { useLogEvent } from '@knuddels-app/analytics/firebase/useLogEvent';
import { useService } from '@knuddels-app/DependencyInjection';
import { $JoinChannelService } from '@knuddelsModules/Channel';
import { insertSeparators } from '@knuddels-app/tools/insertSeparators';
import { $ProfileNavigationService } from '@knuddelsModules/Profile';
import { $ViewService } from '@knuddels-app/layout';
import { profileViewId } from '@knuddelsModules/Profile/ProfileViewProvider';
import { useLazyService } from '@knuddels-app/ModuleSystem';
export const ProfileEntry: React.FC<{
  image?: string;
  title: string;
}> = ({
  image = DEFAULT_IMAGE,
  title,
  children
}) => {
  return <div style={{
    paddingTop: resolveThemingValue((2 + 4 as ThemeOverride), "spacing", useTheme())
  }} className={_c0}>
			<div className={_c1}>
				<Smiley size={IMAGE_SIZE} src={image} />
			</div>
			<div className={_c2}>
				<Text type={'body2'} state={'tertiary'} bold={true} className={_c3}>
					{title}
				</Text>
				{children}
			</div>
		</div>;
};
export const ProfileEntryText: React.FC<{
  content: string;
}> = ({
  content
}) => {
  return <Text type={'body1'} className={_c4}>{content}</Text>;
};
export const ProfileEntryLink: React.FC<{
  content: {
    text: string;
    slashCommand?: string;
  };
}> = ({
  content
}) => {
  const commandService = useService($CommandService);
  if (!content.slashCommand) {
    return <Text type={'body1'} className={_c5}>{content.text}</Text>;
  }
  return <Link key={content.text} type={'body1'} onPress={() => commandService.parseAndInvokeCommand(content.slashCommand)}>
			{content.text}
		</Link>;
};
export const ProfileEntryNumber: React.FC<{
  content: number;
}> = ({
  content
}) => {
  return <Text type={'body1'} className={_c6}>
			<FormattedNumber value={content} />
		</Text>;
};
export const ProfileEntryDate: React.FC<{
  content: number;
}> = ({
  content
}) => {
  const d = new Date(content);
  return <Text type={'body1'} className={_c7}>
			<FormattedDateTime value={d} year={'numeric'} month={'2-digit'} day={'2-digit'} />
		</Text>;
};
export const ProfileEntryTime: React.FC<{
  content: number;
}> = ({
  content
}) => {
  const date = getFormattedDate(content).toLowerCase();
  return <Text type={'body1'} className={_c8}>
			{date + ' ('}
			<FormattedDateTime value={content} hour={'numeric'} minute={'numeric'} />
			{')'}
		</Text>;
};
export const ProfileEntryTextList: React.FC<{
  content: readonly string[];
}> = ({
  content
}) => {
  return <Text type={'body1'} className={_c9}>{content.join(', ')}</Text>;
};
export const ProfileEntryLinkList: React.FC<{
  content: readonly {
    text: string;
    slashCommand: string;
  }[];
}> = ({
  content
}) => {
  const commandService = useService($CommandService);
  const links = content.map(({
    text,
    slashCommand
  }) => {
    return <Link key={text} type={'body1'} onPress={() => commandService.parseAndInvokeCommand(slashCommand)}>
				{text}
			</Link>;
  });
  const withSeparators = insertSeparators(links, index => <Text type={'body1'} key={index} className={_c10}>
			,{' '}
		</Text>);
  return <Text type={'body1'} className={_c11}>{withSeparators}</Text>;
};
export const ProfileEntryFormattedText: React.FC<{
  content: FormattedText;
}> = ({
  content
}) => {
  const track = useLogEvent();
  return <FormattedTextDisplayWithCommandHandling textProps={{
    type: 'body1'
  }} text={content} onCommandExecuted={(commandName, parameter) => {
    let trackingKey = `/${commandName}`;
    if (commandName === 'go' && parameter) {
      const sanitizedChannelName = parameter.replace(/^[+?]/, '');
      trackingKey += ` ${sanitizedChannelName}`;
    }
    track('Profile_Profileview', 'LinkClicked_' + trackingKey);
  }} />;
};
export const ProfileEntryChannel: React.FC<{
  channel: string;
}> = ({
  channel
}) => {
  const joinChannel = useJoinChannel();
  if (channel === '?' || channel === '-') {
    return <Text type={'body1'} className={_c12}>{channel}</Text>;
  }
  return <div className={_c13}>
			<Link type={'body1'} onPress={() => joinChannel(channel)}>
				{channel}
			</Link>
		</div>;
};
export const ProfileEntryChannels: React.FC<{
  channels: readonly string[];
}> = ({
  channels
}) => {
  const joinChannel = useJoinChannel();
  const links = channels.map(channel => {
    return channel === '?' ? '?' : <Link key={channel} type={'body1'} onPress={() => joinChannel(channel)}>
				{channel}
			</Link>;
  });
  const withSeparators = insertSeparators(links, index => <Text type={'body1'} key={index} className={_c14}>
			,{' '}
		</Text>);
  return <Text type={'body1'} className={_c15}>{withSeparators}</Text>;
};
export const ProfileEntryNick: React.FC<{
  nick: string;
}> = ({
  nick
}) => {
  const profileNavigationService = useService($ProfileNavigationService);
  return <div className={_c16}>
			<Link type={'body1'} onPress={() => profileNavigationService.showProfileForNick(nick)}>
				{nick}
			</Link>
		</div>;
};
export const ProfileEntryNicks: React.FC<{
  nicks: readonly string[];
}> = ({
  nicks
}) => {
  const profileNavigationService = useService($ProfileNavigationService);
  const links = nicks.map(nick => {
    return <Link key={nick} type={'body1'} onPress={() => profileNavigationService.showProfileForNick(nick)}>
				{nick}
			</Link>;
  });
  const withSeparators = insertSeparators(links, index => <Text type={'body1'} key={index} className={_c17}>
			,{' '}
		</Text>);
  return <Text type={'body1'} className={_c18}>{withSeparators}</Text>;
};
const useJoinChannel = () => {
  const getJoinChannelService = useLazyService($JoinChannelService);
  const viewService = useService($ViewService);
  return async (channel: string) => {
    const joinChannelService = await getJoinChannelService();
    const joined = await joinChannelService.joinChannelByName(channel, 'ProfileEntry');
    if (joined) {
      viewService.closeView(profileViewId);
    }
  };
};
const _c0 = " Knu-Flex pb-minor px-minor overflow-visible ";
const _c1 = " Knu-FlexCol pt-2px ";
const _c2 = " Knu-FlexCol ml-10px flex-1 ";
const _c3 = "  ";
const _c4 = "  ";
const _c5 = "  ";
const _c6 = "  ";
const _c7 = "  ";
const _c8 = "  ";
const _c9 = "  ";
const _c10 = "  ";
const _c11 = "  ";
const _c12 = "  ";
const _c13 = " Knu-Flex ";
const _c14 = "  ";
const _c15 = "  ";
const _c16 = " Knu-Flex ";
const _c17 = "  ";
const _c18 = "  ";