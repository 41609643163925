function i(t) {
  if (typeof Proxy > "u")
    return t;
  const r = /* @__PURE__ */ new Map(), u = (...n) => t(...n);
  return new Proxy(u, {
    /**
     * Called when `motion` is referenced with a prop: `motion.div`, `motion.input` etc.
     * The prop name is passed through as `key` and we can use that to generate a `motion`
     * DOM component with that name.
     */
    get: (n, e) => e === "create" ? t : (r.has(e) || r.set(e, t(e)), r.get(e))
  });
}
export {
  i as createDOMMotionComponentProxy
};
