import * as React from 'react';
import { Gender } from '@generated/graphql';

export const AlbumDetailsUserContext = React.createContext<{
	id: string;
	nick: string;
	gender: Gender;
	canReceivePhotoComments: boolean;
	isAllowedByContactFilter: boolean;
}>(undefined);

export const SelectedAlbumPhotoContext = React.createContext<{
	photoId: string;
	isOwner: boolean;
	photoKind: 'profile' | 'album';
	administrationUrl: string;
}>(undefined);
