import { time as l } from "../../frameloop/sync-time.js";
import { flushKeyframeResolvers as m } from "../../render/utils/KeyframesResolver.js";
import { instantAnimationState as v } from "../../utils/use-instant-transition-state.js";
import { canAnimate as p } from "./utils/can-animate.js";
import { getFinalKeyframe as c } from "./waapi/utils/get-final-keyframe.js";
const u = 40;
class S {
  constructor({ autoplay: e = !0, delay: t = 0, type: i = "keyframes", repeat: o = 0, repeatDelay: r = 0, repeatType: n = "loop", ...s }) {
    this.isStopped = !1, this.hasAttemptedResolve = !1, this.createdAt = l.now(), this.options = {
      autoplay: e,
      delay: t,
      type: i,
      repeat: o,
      repeatDelay: r,
      repeatType: n,
      ...s
    }, this.updateFinishedPromise();
  }
  /**
   * This method uses the createdAt and resolvedAt to calculate the
   * animation startTime. *Ideally*, we would use the createdAt time as t=0
   * as the following frame would then be the first frame of the animation in
   * progress, which would feel snappier.
   *
   * However, if there's a delay (main thread work) between the creation of
   * the animation and the first commited frame, we prefer to use resolvedAt
   * to avoid a sudden jump into the animation.
   */
  calcStartTime() {
    return this.resolvedAt ? this.resolvedAt - this.createdAt > u ? this.resolvedAt : this.createdAt : this.createdAt;
  }
  /**
   * A getter for resolved data. If keyframes are not yet resolved, accessing
   * this.resolved will synchronously flush all pending keyframe resolvers.
   * This is a deoptimisation, but at its worst still batches read/writes.
   */
  get resolved() {
    return !this._resolved && !this.hasAttemptedResolve && m(), this._resolved;
  }
  /**
   * A method to be called when the keyframes resolver completes. This method
   * will check if its possible to run the animation and, if not, skip it.
   * Otherwise, it will call initPlayback on the implementing class.
   */
  onKeyframesResolved(e, t) {
    this.resolvedAt = l.now(), this.hasAttemptedResolve = !0;
    const { name: i, type: o, velocity: r, delay: n, onComplete: s, onUpdate: h, isGenerator: a } = this.options;
    if (!a && !p(e, i, o, r))
      if (v.current || !n) {
        h == null || h(c(e, this.options, t)), s == null || s(), this.resolveFinishedPromise();
        return;
      } else
        this.options.duration = 0;
    const d = this.initPlayback(e, t);
    d !== !1 && (this._resolved = {
      keyframes: e,
      finalKeyframe: t,
      ...d
    }, this.onPostResolved());
  }
  onPostResolved() {
  }
  /**
   * Allows the returned animation to be awaited or promise-chained. Currently
   * resolves when the animation finishes at all but in a future update could/should
   * reject if its cancels.
   */
  then(e, t) {
    return this.currentFinishedPromise.then(e, t);
  }
  updateFinishedPromise() {
    this.currentFinishedPromise = new Promise((e) => {
      this.resolveFinishedPromise = e;
    });
  }
}
export {
  S as BaseAnimation
};
