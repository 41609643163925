import { $ViewService } from '@knuddels-app/layout/serviceIds';
import { dangerouslyGetK3Container } from '@knuddels-app/ModuleSystem';
import { $EvergreenDataService } from '@knuddels-app/evergreenData';
import {
	inject,
	injectable,
	newServiceId,
} from '@knuddels-app/DependencyInjection';
import { $GenericUserEventService, UserEvent, UserEventSubscriber } from '.';
import { Disposable } from '@knuddels/std';
import { Device, DeviceInfo } from '@capacitor/device';
import { onMainThreadIdle } from '@knuddels-app/tools/onMainThreadIdle';
import { ChannelViewState } from '@knuddelsModules/Channel/ChannelViewProvider';
import { autorun } from '../../mobx';
// @ts-expect-error mixpanel-browser is not typed
import mixpanel from 'mixpanel-browser';
import { $UserService } from '@knuddelsModules/UserData';

const PROJECT_TOKEN = '8a6a3a68791099de8d19fb3491359b07';

export const createMixpanelLogger = () => {
	let endpoint = 'https://api-eu.mixpanel.com/track?ip=1';

	const log = (userEvents: UserEvent[]) => {
		if (import.meta.env.DEV) {
			console.log('MixPanelService', userEvents);
			return;
		}

		const formData = new URLSearchParams();
		formData.append(
			'data',
			JSON.stringify(
				userEvents.map(event => {
					const { type, ...properties } = event;
					return {
						event: type,
						properties: {
							token: PROJECT_TOKEN,
							...properties,
						},
					};
				})
			)
		);

		const options = {
			method: 'POST',
			headers: {
				accept: 'application/json',
			},
			body: formData.toString(),
		};

		fetch(endpoint, options);
	};

	return {
		updateEndpoint: (newEndpoint: string) => {
			if (newEndpoint) {
				endpoint = newEndpoint;
			}
		},
		log,
	};
};

const mixpanelLogger = createMixpanelLogger();

const forceMixpanel = new URLSearchParams(window.location.search).has(
	'forceMixpanel'
);
const recordSession = new URLSearchParams(window.location.search).has(
	'recordSession'
);

@injectable()
export class MixPanelService implements UserEventSubscriber {
	private readonly flushInterval = 5000;
	static MAX_BUFFER_SIZE = 30;

	public readonly dispose = Disposable.fn();

	private evergreenDataService: typeof $EvergreenDataService.T | null = null;

	private viewService: typeof $ViewService.T | null = null;
	private userService: typeof $UserService.T | null = null;

	private trackingState: 'enabled' | 'disabled' | 'undecided' = 'undecided';
	private eventBuffer: UserEvent[] = [];
	private undecidedBuffer: UserEvent[] = [];

	private deviceInfo: DeviceInfo | null = null;
	private deviceIdentifier: string | undefined;

	private flushTimer: NodeJS.Timeout | null = null;

	constructor(
		@inject($GenericUserEventService)
		private readonly genericUserEventService: typeof $GenericUserEventService.T
	) {
		this.dispose.track(this.genericUserEventService.subscribe(this));
		Device.getId().then(id => {
			this.deviceIdentifier = id.identifier;
		});
		Device.getInfo().then(info => {
			this.deviceInfo = info;
		});
		this.waitForEvergreenData().then(() => {
			this.setupTrackingIntervalIfEnabled();
		});
	}

	private setupTrackingIntervalIfEnabled() {
		if (recordSession && this.userService?.currentUser?.id) {
			(mixpanel as any).init(PROJECT_TOKEN, {
				record_sessions_percent: 100,
			});
			(mixpanel as any).identify('U' + this.userService.currentUser.id);

			this.dispose.track(() => {
				(mixpanel as any).reset();
			});
		}

		this.dispose.track(
			autorun({ name: 'setup tracking interval' }, () => {
				this.trackingState =
					(this.evergreenDataService?.data.mixpanel?.enabled ??
						false) ||
					forceMixpanel
						? 'enabled'
						: 'disabled';

				if (this.trackingState !== 'enabled') {
					this.clearFlushTimer();
					return;
				}

				if (this.evergreenDataService?.data.mixpanel?.endpoint) {
					mixpanelLogger.updateEndpoint(
						this.evergreenDataService.data.mixpanel.endpoint
					);
				}
				this.flushUndecidedBuffer();
				this.flushTimer = setInterval(
					() => this.flushBuffer(),
					this.flushInterval
				);
				this.dispose.track(() => {
					this.clearFlushTimer();
				});
			})
		);
	}

	private clearFlushTimer() {
		this.eventBuffer = [];
		this.undecidedBuffer = [];
		if (this.flushTimer) {
			clearInterval(this.flushTimer);
			this.flushTimer = null;
		}
	}

	private flushUndecidedBuffer() {
		/**
		 * evergreen data was not available when the events were pushed to the undecided buffer
		 */

		const recordingProps = recordSession
			? (mixpanel as any).get_session_recording_properties()
			: {};

		this.eventBuffer.unshift(
			...this.undecidedBuffer.map(event => ({
				...event,
				...this.evergreenDataService?.data?.mixpanel?.superProperties,
				...recordingProps,
			}))
		);
		this.undecidedBuffer = [];
	}

	private waitForEvergreenData(): Promise<void> {
		return new Promise(resolve => {
			try {
				const container = dangerouslyGetK3Container();
				this.viewService = container.getService($ViewService);
				this.evergreenDataService = container.getService(
					$EvergreenDataService
				);
				this.userService = container.getService($UserService);

				if (!this.evergreenDataService.initalized) {
					throw new Error('EvergreenDataService not initialized');
				}

				resolve();
			} catch (e) {
				setTimeout(
					() => this.waitForEvergreenData().then(resolve),
					500
				);
			}
		});
	}

	handleEvent(event: UserEvent): void {
		if (this.trackingState === 'disabled') {
			return;
		}

		if (
			event.type === 'Executed_SlashCommand' &&
			(event.command === 'opensystemapp' || event.command === 'applist')
		) {
			return;
		}

		if (event.type === 'Client_Crash' && import.meta.env.DEV) {
			return;
		}

		if (event.type === 'View_Opened') {
			event.type = `${event.item}_opened` as any;
		}

		if (event.type === 'SystemApp_OpenApp') {
			event.type = `app_${event.appId}_opened` as any;
		}

		if (this.trackingState === 'enabled') {
			this.pushToQueue(event);
			if (event.type === 'Logout') {
				this.flushSync();
			}
		} else if (
			this.undecidedBuffer.length <= MixPanelService.MAX_BUFFER_SIZE
		) {
			this.undecidedBuffer.push(event);
		}
	}

	/**
	 * evergreenDataService is only available when logged in. this method should only be used for the logged out area
	 */
	handleEventWithoutEvergreenData(event: UserEvent): void {
		this.eventBuffer.push({
			...event,
			...this.getMetadata(),
			visibleViews: ['loggedOutArea'],
		} as any);
		this.flushSync();
	}

	private flushBuffer(): void {
		if (this.eventBuffer.length === 0) {
			return;
		}
		if (
			!this.evergreenDataService ||
			!this.viewService ||
			!this.deviceIdentifier
		) {
			return;
		}

		this.flushWhenIdle();
	}

	private sendEvents(): void {
		const events = this.eventBuffer.slice(
			0,
			MixPanelService.MAX_BUFFER_SIZE
		);

		mixpanelLogger.log(events);
		this.eventBuffer = this.eventBuffer.slice(
			MixPanelService.MAX_BUFFER_SIZE
		);
	}

	private flushWhenIdle(): void {
		onMainThreadIdle(() => {
			this.sendEvents();
		});
	}

	private flushSync(): void {
		this.sendEvents();
	}

	private getMetadata(): Record<string, any> {
		const recordingProps = recordSession
			? (mixpanel as any).get_session_recording_properties()
			: {};

		return {
			time: Date.now() / 1000, // api takes seconds
			$current_url: window.location.href,
			$os: this.deviceInfo?.operatingSystem,
			$os_version: this.deviceInfo?.osVersion,
			$manufacturer: this.deviceInfo?.manufacturer,
			$model: this.deviceInfo?.model,
			uniqueDeviceId: this.deviceIdentifier,
			...recordingProps,
			...this.evergreenDataService?.data?.mixpanel?.superProperties,
			visibleViews: this.viewService?.visibleViews.map(v => {
				if (v.viewConfig.viewId.id === 'channel') {
					return (v.state as ChannelViewState).channelId
						? 'channel'
						: 'discover';
				}
				return v.viewConfig.viewId.id;
			}),
		};
	}

	private pushToQueue(event: UserEvent): void {
		this.eventBuffer.push({
			...event,
			...this.getMetadata(),
			...this.evergreenDataService?.data?.mixpanel?.superProperties,
			visibleViews: this.viewService?.visibleViews.map(v => {
				if (v.viewConfig.viewId.id === 'channel') {
					return (v.state as ChannelViewState).channelId
						? 'channel'
						: 'discover';
				}
				return v.viewConfig.viewId.id;
			}),
		} as any);

		if (this.eventBuffer.length >= MixPanelService.MAX_BUFFER_SIZE) {
			this.flushBuffer();
		}
	}
}

export const $MixPanelService =
	newServiceId<MixPanelService>('$MixPanelService');
