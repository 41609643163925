import { ProfileEditField } from '@generated/graphql';
import { SnackbarData } from '@knuddels-app/SnackbarManager';
import { editProfileFieldData } from './components/ProfileOverlay/EditProfile/EditProfileContent/Entry/EditProfileEntries';
import { declareFormat, formatMessage } from '@knuddels-app/i18n';

import bigHello from '@shared/icons/icon-bighello.gif';
import sorry from '@shared/images/sorry.gif';

export function createInvalidProfileFieldValueSnackbar(
	field: ProfileEditField
): SnackbarData {
	return {
		type: 'invalidProfileFieldValue_' + field,
		text: () =>
			formatMessage(
				declareFormat({
					id: 'SNACKBAR_INVALID_PROFILE_FIELD_VALUE_TEXT',
					defaultFormat: 'Profile could not be saved',
				})
			),
		subtext: () =>
			formatMessage(
				declareFormat({
					id: 'SNACKBAR_INVALID_PROFILE_FIELD_VALUE_SUBTEXT',
					defaultFormat: 'The field {field} has an invalid value.',
				}),
				{
					field: formatMessage(editProfileFieldData[field].label),
				}
			),
		adornment: sorry,
	};
}

export const SnackbarDefinitionProfileSaved: SnackbarData = {
	type: 'profileSaved',
	text: () =>
		formatMessage(
			declareFormat({
				id: 'SNACKBAR_PROFILE_SAVED_TEXT',
				defaultFormat: 'Changes have been saved',
			})
		),
	subtext: () =>
		formatMessage(
			declareFormat({
				id: 'SNACKBAR_PROFILE_SAVED_SUBTEXT',
				defaultFormat: 'Your profile has been updated successfully.',
			})
		),
	adornment: bigHello,
};

export const SnackbarUnableToSaveProfile: SnackbarData = {
	type: 'profileSaved',
	text: () =>
		formatMessage(
			declareFormat({
				id: 'SNACKBAR_UNABLE_TO_SAVE_PROFILE_TEXT',
				defaultFormat: 'Profile could not be saved',
			})
		),
	subtext: () =>
		formatMessage(
			declareFormat({
				id: 'SNACKBAR_UNABLE_TO_SAVE_PROFILE_SUBTEXT',
				defaultFormat: 'Your profile could not be saved. Please retry.',
			})
		),
	adornment: sorry,
};

export const SnackbarEmptyAlbumTitle: SnackbarData = {
	type: 'emptyAlbumTitle',
	text: () =>
		formatMessage(
			declareFormat({
				id: 'SNACKBAR_EMPTY_ALBUM_TITLE_TEXT',
				defaultFormat: 'Profile could not be saved',
			})
		),
	subtext: () =>
		formatMessage(
			declareFormat({
				id: 'SNACKBAR_EMPTY_ALBUM_TITLE_SUBTEXT',
				defaultFormat: 'An album has no title.',
			})
		),
	adornment: sorry,
};
