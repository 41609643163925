import { PanSession as L } from "../pan/PanSession.js";
import { getGlobalLock as M } from "./utils/lock.js";
import { isRefObject as P } from "../../utils/is-ref-object.js";
import { addPointerEvent as S } from "../../events/add-pointer-event.js";
import { applyConstraints as b, calcRelativeConstraints as B, resolveDragElastic as k, calcViewportConstraints as j, rebaseAxisConstraints as T, calcOrigin as w, defaultElastic as G } from "./utils/constraints.js";
import { createBox as R } from "../../projection/geometry/models.js";
import { eachAxis as u } from "../../projection/utils/each-axis.js";
import { measurePageBox as W } from "../../projection/utils/measure.js";
import { extractEventInfo as _ } from "../../events/event-info.js";
import { convertBoxToBoundingBox as O, convertBoundingBoxToBox as U } from "../../projection/geometry/conversion.js";
import { addDomEvent as z } from "../../events/add-dom-event.js";
import { calcLength as F } from "../../projection/geometry/delta-calc.js";
import { mixNumber as V } from "../../utils/mix/number.js";
import { percent as I } from "../../value/types/numbers/units.js";
import { animateMotionValue as K } from "../../animation/interfaces/motion-value.js";
import { getContextWindow as N } from "../../utils/get-context-window.js";
import { addValueToWillChange as x } from "../../value/use-will-change/add-will-change.js";
import { frame as D } from "../../frameloop/frame.js";
const $ = /* @__PURE__ */ new WeakMap();
class gt {
  constructor(t) {
    this.openGlobalLock = null, this.isDragging = !1, this.currentDirection = null, this.originPoint = { x: 0, y: 0 }, this.constraints = !1, this.hasMutatedConstraints = !1, this.elastic = R(), this.visualElement = t;
  }
  start(t, { snapToCursor: s = !1 } = {}) {
    const { presenceContext: i } = this.visualElement;
    if (i && i.isPresent === !1)
      return;
    const n = (e) => {
      const { dragSnapToOrigin: a } = this.getProps();
      a ? this.pauseAnimation() : this.stopAnimation(), s && this.snapToCursor(_(e, "page").point);
    }, r = (e, a) => {
      const { drag: f, dragPropagation: d, onDragStart: m } = this.getProps();
      if (f && !d && (this.openGlobalLock && this.openGlobalLock(), this.openGlobalLock = M(f), !this.openGlobalLock))
        return;
      this.isDragging = !0, this.currentDirection = null, this.resolveConstraints(), this.visualElement.projection && (this.visualElement.projection.isAnimationBlocked = !0, this.visualElement.projection.target = void 0), u((g) => {
        let E = this.getAxisMotionValue(g).get() || 0;
        if (I.test(E)) {
          const { projection: C } = this.visualElement;
          if (C && C.layout) {
            const y = C.layout.layoutBox[g];
            y && (E = F(y) * (parseFloat(E) / 100));
          }
        }
        this.originPoint[g] = E;
      }), m && D.postRender(() => m(e, a)), x(this.visualElement, "transform");
      const { animationState: v } = this.visualElement;
      v && v.setActive("whileDrag", !0);
    }, o = (e, a) => {
      const { dragPropagation: f, dragDirectionLock: d, onDirectionLock: m, onDrag: v } = this.getProps();
      if (!f && !this.openGlobalLock)
        return;
      const { offset: g } = a;
      if (d && this.currentDirection === null) {
        this.currentDirection = q(g), this.currentDirection !== null && m && m(this.currentDirection);
        return;
      }
      this.updateAxis("x", a.point, g), this.updateAxis("y", a.point, g), this.visualElement.render(), v && v(e, a);
    }, l = (e, a) => this.stop(e, a), c = () => u((e) => {
      var a;
      return this.getAnimationState(e) === "paused" && ((a = this.getAxisMotionValue(e).animation) === null || a === void 0 ? void 0 : a.play());
    }), { dragSnapToOrigin: h } = this.getProps();
    this.panSession = new L(t, {
      onSessionStart: n,
      onStart: r,
      onMove: o,
      onSessionEnd: l,
      resumeAnimation: c
    }, {
      transformPagePoint: this.visualElement.getTransformPagePoint(),
      dragSnapToOrigin: h,
      contextWindow: N(this.visualElement)
    });
  }
  stop(t, s) {
    const i = this.isDragging;
    if (this.cancel(), !i)
      return;
    const { velocity: n } = s;
    this.startAnimation(n);
    const { onDragEnd: r } = this.getProps();
    r && D.postRender(() => r(t, s));
  }
  cancel() {
    this.isDragging = !1;
    const { projection: t, animationState: s } = this.visualElement;
    t && (t.isAnimationBlocked = !1), this.panSession && this.panSession.end(), this.panSession = void 0;
    const { dragPropagation: i } = this.getProps();
    !i && this.openGlobalLock && (this.openGlobalLock(), this.openGlobalLock = null), s && s.setActive("whileDrag", !1);
  }
  updateAxis(t, s, i) {
    const { drag: n } = this.getProps();
    if (!i || !A(t, n, this.currentDirection))
      return;
    const r = this.getAxisMotionValue(t);
    let o = this.originPoint[t] + i[t];
    this.constraints && this.constraints[t] && (o = b(o, this.constraints[t], this.elastic[t])), r.set(o);
  }
  resolveConstraints() {
    var t;
    const { dragConstraints: s, dragElastic: i } = this.getProps(), n = this.visualElement.projection && !this.visualElement.projection.layout ? this.visualElement.projection.measure(!1) : (t = this.visualElement.projection) === null || t === void 0 ? void 0 : t.layout, r = this.constraints;
    s && P(s) ? this.constraints || (this.constraints = this.resolveRefConstraints()) : s && n ? this.constraints = B(n.layoutBox, s) : this.constraints = !1, this.elastic = k(i), r !== this.constraints && n && this.constraints && !this.hasMutatedConstraints && u((o) => {
      this.constraints !== !1 && this.getAxisMotionValue(o) && (this.constraints[o] = T(n.layoutBox[o], this.constraints[o]));
    });
  }
  resolveRefConstraints() {
    const { dragConstraints: t, onMeasureDragConstraints: s } = this.getProps();
    if (!t || !P(t))
      return !1;
    const i = t.current, { projection: n } = this.visualElement;
    if (!n || !n.layout)
      return !1;
    const r = W(i, n.root, this.visualElement.getTransformPagePoint());
    let o = j(n.layout.layoutBox, r);
    if (s) {
      const l = s(O(o));
      this.hasMutatedConstraints = !!l, l && (o = U(l));
    }
    return o;
  }
  startAnimation(t) {
    const { drag: s, dragMomentum: i, dragElastic: n, dragTransition: r, dragSnapToOrigin: o, onDragTransitionEnd: l } = this.getProps(), c = this.constraints || {}, h = u((e) => {
      if (!A(e, s, this.currentDirection))
        return;
      let a = c && c[e] || {};
      o && (a = { min: 0, max: 0 });
      const f = n ? 200 : 1e6, d = n ? 40 : 1e7, m = {
        type: "inertia",
        velocity: i ? t[e] : 0,
        bounceStiffness: f,
        bounceDamping: d,
        timeConstant: 750,
        restDelta: 1,
        restSpeed: 10,
        ...r,
        ...a
      };
      return this.startAxisValueAnimation(e, m);
    });
    return Promise.all(h).then(l);
  }
  startAxisValueAnimation(t, s) {
    const i = this.getAxisMotionValue(t);
    return x(this.visualElement, t), i.start(K(t, i, 0, s, this.visualElement, !1));
  }
  stopAnimation() {
    u((t) => this.getAxisMotionValue(t).stop());
  }
  pauseAnimation() {
    u((t) => {
      var s;
      return (s = this.getAxisMotionValue(t).animation) === null || s === void 0 ? void 0 : s.pause();
    });
  }
  getAnimationState(t) {
    var s;
    return (s = this.getAxisMotionValue(t).animation) === null || s === void 0 ? void 0 : s.state;
  }
  /**
   * Drag works differently depending on which props are provided.
   *
   * - If _dragX and _dragY are provided, we output the gesture delta directly to those motion values.
   * - Otherwise, we apply the delta to the x/y motion values.
   */
  getAxisMotionValue(t) {
    const s = `_drag${t.toUpperCase()}`, i = this.visualElement.getProps(), n = i[s];
    return n || this.visualElement.getValue(t, (i.initial ? i.initial[t] : void 0) || 0);
  }
  snapToCursor(t) {
    u((s) => {
      const { drag: i } = this.getProps();
      if (!A(s, i, this.currentDirection))
        return;
      const { projection: n } = this.visualElement, r = this.getAxisMotionValue(s);
      if (n && n.layout) {
        const { min: o, max: l } = n.layout.layoutBox[s];
        r.set(t[s] - V(o, l, 0.5));
      }
    });
  }
  /**
   * When the viewport resizes we want to check if the measured constraints
   * have changed and, if so, reposition the element within those new constraints
   * relative to where it was before the resize.
   */
  scalePositionWithinConstraints() {
    if (!this.visualElement.current)
      return;
    const { drag: t, dragConstraints: s } = this.getProps(), { projection: i } = this.visualElement;
    if (!P(s) || !i || !this.constraints)
      return;
    this.stopAnimation();
    const n = { x: 0, y: 0 };
    u((o) => {
      const l = this.getAxisMotionValue(o);
      if (l && this.constraints !== !1) {
        const c = l.get();
        n[o] = w({ min: c, max: c }, this.constraints[o]);
      }
    });
    const { transformTemplate: r } = this.visualElement.getProps();
    this.visualElement.current.style.transform = r ? r({}, "") : "none", i.root && i.root.updateScroll(), i.updateLayout(), this.resolveConstraints(), u((o) => {
      if (!A(o, t, null))
        return;
      const l = this.getAxisMotionValue(o), { min: c, max: h } = this.constraints[o];
      l.set(V(c, h, n[o]));
    });
  }
  addListeners() {
    if (!this.visualElement.current)
      return;
    $.set(this.visualElement, this);
    const t = this.visualElement.current, s = S(t, "pointerdown", (c) => {
      const { drag: h, dragListener: e = !0 } = this.getProps();
      h && e && this.start(c);
    }), i = () => {
      const { dragConstraints: c } = this.getProps();
      P(c) && c.current && (this.constraints = this.resolveRefConstraints());
    }, { projection: n } = this.visualElement, r = n.addEventListener("measure", i);
    n && !n.layout && (n.root && n.root.updateScroll(), n.updateLayout()), D.read(i);
    const o = z(window, "resize", () => this.scalePositionWithinConstraints()), l = n.addEventListener("didUpdate", ({ delta: c, hasLayoutChanged: h }) => {
      this.isDragging && h && (u((e) => {
        const a = this.getAxisMotionValue(e);
        a && (this.originPoint[e] += c[e].translate, a.set(a.get() + c[e].translate));
      }), this.visualElement.render());
    });
    return () => {
      o(), s(), r(), l && l();
    };
  }
  getProps() {
    const t = this.visualElement.getProps(), { drag: s = !1, dragDirectionLock: i = !1, dragPropagation: n = !1, dragConstraints: r = !1, dragElastic: o = G, dragMomentum: l = !0 } = t;
    return {
      ...t,
      drag: s,
      dragDirectionLock: i,
      dragPropagation: n,
      dragConstraints: r,
      dragElastic: o,
      dragMomentum: l
    };
  }
}
function A(p, t, s) {
  return (t === !0 || t === p) && (s === null || s === p);
}
function q(p, t = 10) {
  let s = null;
  return Math.abs(p.y) > t ? s = "y" : Math.abs(p.x) > t && (s = "x"), s;
}
export {
  gt as VisualElementDragControls,
  $ as elementDragControls
};
