import { $FirebaseAnalyticsService } from '@knuddels-app/analytics/firebase';
import { useLogEvent } from '@knuddels-app/analytics/firebase/useLogEvent';
import { DisconnectedBanner } from '@knuddels-app/Connection';
import { declareProps, IModel, inject, injectable, injectedComponent, injectProps, useService } from '@knuddels-app/DependencyInjection';
import { $I18n, declareFormat } from '@knuddels-app/i18n';
import { $KeyboardService } from '@knuddels-app/Keyboard';
import { observer } from '@knuddels-app/mobx';
import { useLazyService } from '@knuddels-app/ModuleSystem';
import { NativeBackgroundTitleBar } from '@knuddels-app/NativeBackground';
import { $NativeWebViewService } from '@knuddels-app/NativeWebView';
import { $OverlayService } from '@knuddels-app/overlays';
import { useReactiveState } from '@knuddels-app/tools/useReactiveState';
import { createAccessiblePointerEventHandler, Flex, FlexCol, IconCloseCircular, rgb, Text, ThemeOverride, TITLE_BAR_HEIGHT, FallbackBox, createNativeAccessiblePointerEventHandler, resolveThemingValue, useTheme, resolveIsDarkColor } from '@knuddels/component-library';
import { Disposable } from '@knuddels/std';
import { $AdsService } from '@knuddelsModules/Ads';
import { $ChannelAppViewer, $ChannelAppViewerAnimationService, ChannelAppViewerBackgroundSpacerLoadable, ChannelAppViewerLoadable, ChannelBackgroundAppViewLoadable } from '@knuddelsModules/Apps';
import { $MessengerComponents, $MessengerMiniChatService } from '@knuddelsModules/Messenger';
import { $ClientSettingsService } from '@knuddelsModules/Settings';
import { Column, OverflowMenuTrigger, Z_INDEX } from '@shared/components';
import { KeyboardAvoidanceView } from '@shared/components/KeyboardAvoidanceView';
import { OS, os } from '@shared/components/tools/os';
import { dismissKeyboard } from '@shared/helper/dismissKeyboard';
import { IconInfoOutline } from '@shared/icons/IconInfoOutline';
import { IconNicklist } from '@shared/icons/IconNicklist';
import { IconReport } from '@shared/icons/IconReport';
import { motion, useAnimate } from 'framer-motion';
import * as React from 'react';
import { useRegisterBottomInputContext } from '../../../../../app/RegisterBottomInputContext';
import { $ActiveChannelService } from '../../../providedServiceIds';
import { ChannelInfo } from '../../services';
import { ChannelColorMode } from '../../services/channel/calculateChannelColorMode';
import { ChatHistory } from './ChatContent/ChatHistory';
import { ChatItem } from './ChatContent/ChatItem';
import { ChatVirtualList } from './ChatContent/ChatVirtualList';
import { ChatInput } from './ChatInput/ChatInput';
import { ChatOverflowContextMenu } from './ChatOverflowContextMenu';
import { defaultGroupInfo } from './defaultActiveChannel';
import { useDelayedsActiveChannelUserCount } from './useDelayedActiveChannelUserCount';
import { $GenericUserEventService } from '@knuddels-app/analytics/generic';
interface Props {
  isStackedLayout: boolean;
  showNicklistIcon: boolean;
  channel: ChannelInfo | undefined;
  channelColor: string | undefined;
  colorMode: ChannelColorMode;
  mayShowApps: boolean;
  isInBackground: boolean;
  clickNicklistIcon(): void;
}
@injectable()
class ChatViewModel implements IModel {
  public readonly dispose = Disposable.fn();
  public readonly scrollViewRef = React.createRef<ChatVirtualList<ChatItem>>();
  constructor(@injectProps()
  private readonly props: Props & {
    channel: ChannelInfo | undefined;
  }, @inject($OverlayService)
  private readonly overlayService: typeof $OverlayService.T, @inject($FirebaseAnalyticsService)
  private firebaseAnalyticsService: typeof $FirebaseAnalyticsService.T) {}
  public scrollToBottom = (): void => {
    if (this.scrollViewRef.current) {
      this.scrollViewRef.current.scrollToBottom();
    }
  };
  public showOverflowContextMenu = (x: number, y: number, width: number, height: number) => {
    this.firebaseAnalyticsService.logEvent('Chat_Chatraum', 'Titlebar_MoreMenuOpened');
    this.overlayService.showOverlay({
      view: <ChatOverflowContextMenu chevronX={x} chevronY={y} chevronWidth={width} chevronHeight={height} />,
      backgroundType: os === OS.ios ? 'opaque' : undefined
    });
  };
}
export const ChatView = injectedComponent({
  name: 'ChatView',
  model: ChatViewModel,
  props: declareProps<Props>(),
  inject: {
    i18n: $I18n
  }
}, ({
  channel,
  model,
  showNicklistIcon,
  clickNicklistIcon,
  channelColor,
  colorMode,
  mayShowApps,
  isInBackground,
  i18n
}) => {
  const {
    name = '',
    groupInfo = defaultGroupInfo
  } = channel || {};
  const inputBlurRef = React.useRef<{
    blur(): void;
  }>();
  const registerBottomInput = useRegisterBottomInputContext();
  const overlayService = useService($OverlayService);
  const nativeWebViewService = useService($NativeWebViewService);
  const [{
    width,
    height
  }, setSize] = React.useState({
    width: 0,
    height: 0
  });
  const MessengerComponents = useService($MessengerComponents);
  const keyboardService = useService($KeyboardService);
  const clientSettingsService = useService($ClientSettingsService);
  const maxSplitViewPercentage = keyboardService.showSmileyOrFeatureBox && !keyboardService.supportsVirtualKeyboard ? 0.4 : 0.55;
  const openReportContent = useOpenReportContent();
  const openChannelInfo = useOpenChannelInfo();
  const leaveChannel = useLeaveChannel();
  const miniChatOverlay = useService($MessengerMiniChatService);
  const channelAppViewer = useService($ChannelAppViewer);
  useReactiveState(() => {
    if (isInBackground || !nativeWebViewService.isEnabled) {
      return;
    }
    if (overlayService.overlays.length > 0 || overlayService.isSystemAppOverlayVisible) {
      nativeWebViewService.updateOverlayState('visible');
    } else {
      nativeWebViewService.updateOverlayState('hidden');
    }
  }, [isInBackground]);
  React.useLayoutEffect(() => {
    if (!nativeWebViewService.isEnabled) {
      return;
    }
    if (isInBackground) {
      dismissKeyboard();
      nativeWebViewService.updateBackgroundState('background');
    } else {
      nativeWebViewService.updateBackgroundState('active');
      return registerBottomInput();
    }
  }, [isInBackground]);
  return <Column fluid>
				<KeyboardAvoidanceView enabled={!isInBackground && !miniChatOverlay.isOverlayVisible} flip style={{
      flex: 0,
      position: 'relative',
      zIndex: Z_INDEX.TITLE_BAR,
      flexDirection: 'column',
      background: rgb(channel.groupInfo.backgroundColor.red, channel.groupInfo.backgroundColor.green, channel.groupInfo.backgroundColor.blue)
    }}>
					<TitlebarAnimationWrapper>
						<NativeBackgroundTitleBar isInBackground={isInBackground} type={colorMode} color={channelColor} title={name} navIcon={<IconCloseCircular size={'large'} onPress={leaveChannel} />} iconList={[<IconInfoOutline key={'info'} size={'large'} onPress={openChannelInfo} />, <IconReport key={'report'} size={'large'} onPress={openReportContent} />, <OverflowMenuTrigger key={'overflow'} showOverflowMenu={model.showOverflowContextMenu} />, showNicklistIcon && <NickListIcon key={'nicklist'} onPress={() => {
          inputBlurRef.current?.blur();
          clickNicklistIcon();
        }} />]} subtitle={groupInfo.isMyChannel ? declareFormat({
          id: 'channel.subtitle.mychannel',
          defaultFormat: 'Channel created by member'
        }).format(i18n) : undefined} />
					</TitlebarAnimationWrapper>
				</KeyboardAvoidanceView>
				<div className={_c0}>
					<FallbackBox onLayout={e => setSize(e)} className={_c1}>
						{mayShowApps && <ChannelAppViewerBackgroundSpacerLoadable maxWidth={width} maxHeight={height} maxSplitViewPercentage={maxSplitViewPercentage} />}
						<DisconnectedBanner />

						<div className={_c2}>
							{clientSettingsService.privateMessageReplyBehavior === 'MINI_CHAT' && clientSettingsService.miniChatTriggerEnabled && <div className={_c3}>
										<KeyboardAvoidanceView flip style={{
              position: 'relative',
              zIndex: Z_INDEX.HIGHEST_CONTENT
            }} enabled={!isInBackground && !miniChatOverlay.isOverlayVisible && channelAppViewer.apps.length === 0}>
											<MessengerComponents.MiniChatTrigger channelColor={channelColor} />
										</KeyboardAvoidanceView>
									</div>}

							<ChannelBackgroundAppViewLoadable />

							<div className={_c4}>
								<ChatHistory key={channel.id} scrollViewRef={model.scrollViewRef} channel={channel} getMessages={currentChannel => currentChannel.getMessages()} />
							</div>
						</div>

						{mayShowApps && width > 0 && <ChannelAppViewerLoadable maxWidth={width} maxHeight={height} backgroundColor={groupInfo.backgroundColor} highlightColor={groupInfo.highlightColor} maxSplitViewPercentage={maxSplitViewPercentage} />}

						<ChatInput mode={keyboardService.modeInChannel} blurRef={inputBlurRef} channel={channel} messageWasSent={model.scrollToBottom} onSmileyboxOpened={() => {}} onSmileyboxClosed={() => {}} />
					</FallbackBox>
				</div>
			</Column>;
});
const useOpenReportContent = () => {
  const activeChannel = useService($ActiveChannelService).activeChannel;
  const track = useLogEvent();
  return () => {
    if (activeChannel) {
      activeChannel.send('/admincall new');
    }
    track('Chat_Chatraum', 'Titlebar_ReportContentClicked');
  };
};
const useOpenChannelInfo = () => {
  const activeChannel = useService($ActiveChannelService).activeChannel;
  const track = useLogEvent();
  return () => {
    if (activeChannel) {
      activeChannel.send('/info ' + activeChannel.mainChannelName);
    }
    track('Chat_Chatraum', 'Titlebar_ChannelInfoClicked');
  };
};
const useLeaveChannel = () => {
  const track = useLogEvent();
  const activeChannelService = useService($ActiveChannelService);
  const genericUserEventService = useService($GenericUserEventService);
  const getAdsService = useLazyService($AdsService);
  return () => {
    activeChannelService.leaveChannel();
    genericUserEventService.reportEvent({
      type: 'Left_Channel',
      source: 'CloseButton_ChatView'
    });
    track('Chat_Chatraum', 'Titlebar_LeaveChannelClicked');
    getAdsService().then(service => service.showInterstitial());
  };
};
export const NickListIcon: React.FC<{
  onPress: () => void;
}> = observer('NickListIcon', ({
  onPress
}) => {
  const activeChannelService = useService($ActiveChannelService);
  const highlightColor = activeChannelService.activeChannel?.groupInfo?.highlightColor;
  const highlightRgb = highlightColor ? rgb(highlightColor.red, highlightColor.green, highlightColor.blue) : undefined;
  const userCount = useDelayedsActiveChannelUserCount();
  return <div {...createNativeAccessiblePointerEventHandler({
    pointerCallback: onPress
  })} style={{
    background: resolveThemingValue(highlightRgb, "colors", useTheme())
  }} className={_c5 + (highlightRgb ? resolveIsDarkColor(highlightRgb, useTheme()) ? " content-is-dark" : " content-is-light" : "")}>
				<IconNicklist active size={'large'} />
				<Text type={'body1'} bold={true} className={_c6}>
					{userCount}
				</Text>
			</div>;
});
const TitlebarAnimationWrapper: React.FC = ({
  children
}) => {
  const channelAppViewerAnimationService = useService($ChannelAppViewerAnimationService);
  const [scope, titleAnimate] = useAnimate();
  React.useEffect(() => {
    return channelAppViewerAnimationService.registerToggleListener(newState => {
      titleAnimate(scope.current, {
        height: newState === 'full' ? 0 : TITLE_BAR_HEIGHT
      });
    });
  }, []);
  return <motion.div ref={scope} transition={{
    duration: 0.25
  }} style={{
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    zIndex: Z_INDEX.TITLE_BAR,
    backgroundColor: 'transparent',
    boxShadow: 'var(--shadows-Shadow2)'
  }}>
			<div className={_c7}>
				{children}
			</div>
		</motion.div>;
};
const _c0 = " Knu-FlexCol flex-1 overflow-hidden ";
const _c1 = " Knu-FlexCol flex-1 position-relative overflow-hidden height-full ";
const _c2 = " Knu-FlexCol position-relative flex-1 overflow-hidden ";
const _c3 = " Knu-Flex position-absolute top-small right-minor ";
const _c4 = " Knu-FlexCol position-relative flex-1 ";
const _c5 = " Knu-Flex cursor-pointer position-relative borderRadius-minor borderWidth-large borderStyle-solid borderColor-white-transparent-440 alignItems-center px-small py-2px mx-small ";
const _c6 = " ml-tiny ";
const _c7 = " Knu-FlexCol size-full overflow-hidden ";