import { ChannelMessageFragment, GetChannelName } from '@generated/graphql';
import { useQuery } from '@knuddels-app/Connection';
import { useService } from '@knuddels-app/DependencyInjection';
import { FormattedMessage } from '@knuddels-app/i18n';
import { colorToRgbString } from '@knuddels-app/tools/colorToRgbString';
import { Flex, rgb, Text, ThemeOverride } from '@knuddels/component-library';
import { $ActiveChannelService, $JoinChannelService } from '@knuddelsModules/Channel';
import { FormattedText } from '@shared/components';
import { HighlightTextLink } from '@shared/components/atoms/Texts/HighlightTextLink';
import * as React from 'react';
import { PRIVATE_MESSAGE_TITLE_FROM } from '../../../../i18n/shared-formats';
import { ChatRenderConfig } from '../../ChatRenderConfig';
import { ClickableUserText } from '../ClickableUser';
import { GenericMessage } from './GenericMessage';
export interface SystemMessageProps {
  isFirst?: boolean;
  sender: ChannelMessageFragment['sender'];
  text: FormattedText;
  channelId?: string;
  renderConfig: ChatRenderConfig;
}
export const SystemMessage: React.FC<SystemMessageProps> = ({
  text,
  isFirst,
  sender,
  channelId,
  renderConfig
}) => {
  const activeChannel = useService($ActiveChannelService).activeChannel;
  const Message = () => <GenericMessage title={isFirst && <div className={_c0}>
						<ClickableUserText uid={sender.id} openedFromTrackingLabel="SystemMessage" overrideStyles={{
      color: renderConfig.layoutService.renderMode === 'modernWithoutBubbles' ? (colorToRgbString(renderConfig.channelGroupInfo.highlightColorCondensedPrivateMessage) as ThemeOverride) : undefined
    }}>
							{sender.nick}
						</ClickableUserText>
						{activeChannel && activeChannel.id !== channelId && <ChannelInfo channelId={channelId} />}
					</div>} content={text} titleColor={rgb(255, 255, 255, 0.77)} />;
  return <Message />;
};
export const ChannelInfo: React.FC<{
  channelId: string;
}> = ({
  channelId
}) => {
  const {
    data
  } = useQuery(GetChannelName, {
    channelId
  }, 'cache-first');
  return !!data && <>
				<Text state={'secondary'} bold={true} className={_c1}>
					<FormattedMessage id={PRIVATE_MESSAGE_TITLE_FROM} />
				</Text>
				<ClickableChannel channel={data.name} />
			</>;
};
const ClickableChannel: React.FC<{
  channel: string;
}> = ({
  channel
}) => {
  const joinChannelService = useService($JoinChannelService);
  if (channel === '?') {
    return <Text state={'secondary'} bold={true} className={_c2}>
				{channel}
			</Text>;
  }
  const joinChannel = () => {
    joinChannelService.joinChannelByName(channel, 'SystemMessage');
  };
  return <HighlightTextLink bold type={'body1'} state={'secondary'} hoverState={'primary'} onPress={joinChannel}>
			{channel}
		</HighlightTextLink>;
};
const _c0 = " Knu-Flex alignItems-center ";
const _c1 = "  ";
const _c2 = "  ";