import { Channel } from '@generated/graphql';
import { declareProps, IModel, inject, injectable, injectedComponent, injectProps } from '@knuddels-app/DependencyInjection';
import { $ScreenService } from '@knuddels-app/Screen';
import { ChatView } from './Chat/ChatView';
import { NickList } from './NickList/NickList';
import * as React from 'react';
import { enterChannelEvent, leaveChannelEvent, nicklistClosedTimingTracker, nicklistOpenedTimingTracker, nickListTitlebarEvent } from '../../analytics';
import { $ActiveChannelService, $JoinChannelService } from '../../providedServiceIds';
import { ChannelInfo, ChannelState } from '../services';
import { colorToRgbaString } from '@knuddels-app/tools/colorToRgbaString';
import { defaultGroupInfo } from './Chat/defaultActiveChannel';
import { $ViewService, LayoutPosition } from '@knuddels-app/layout';
import { channelViewId } from '@knuddelsModules/Channel/ChannelViewProvider';
import { ChannelLightbox } from './Lightboxes/ChannelLightbox';
import { ChannelDisconnectedContent } from './Lightboxes/ChannelDisconnectedContent';
import { computed, reactionWhen, runInAction } from '@knuddels-app/mobx';
import { ChannelViews } from './ChannelViews';
import { FlexCol, FallbackBox, resolveThemingValue, resolveIsDarkColor, useTheme } from '@knuddels/component-library';
import { observer } from 'mobx-react';
import { NoChannelDashboard } from './Dashboard/NoChannelDashboard';
import { $Environment } from '@knuddels-app/Environment';
import { Disposable } from '@knuddels/std';
import { $GenericUserEventService } from '@knuddels-app/analytics/generic';
interface OwnProps {
  routeChatId: string;
  nicklistOpen?: boolean;
}
type Props = OwnProps;
interface State {
  width: number;
  bigChatView: boolean;
}
const CHAT_NEXT_TO_NICKLIST_THRESHOLD = 760;
const BackgroundScrim = () => <div className={_c0 + ("backgroundScrim" ? resolveIsDarkColor("backgroundScrim", useTheme()) ? " content-is-dark" : " content-is-light" : "")} />;
@injectable()
class ChannelRootModel implements IModel {
  public dispose = Disposable.fn();
  public get activeChannel(): ChannelInfo {
    return this.activeChannelService.activeChannel;
  }
  public joinOnAppVisible = false;
  constructor(@injectProps()
  private readonly props: Props, @inject($ActiveChannelService)
  public readonly activeChannelService: typeof $ActiveChannelService.T, @inject($JoinChannelService)
  public readonly joinChannelService: typeof $JoinChannelService.T, @inject($ViewService)
  private readonly viewService: typeof $ViewService.T, @inject($ScreenService)
  private readonly screenService: typeof $ScreenService.T, @inject($Environment)
  public readonly environment: typeof $Environment.T, @inject($GenericUserEventService)
  private readonly genericUserEventService: typeof $GenericUserEventService.T) {
    this.dispose.track(reactionWhen({
      name: 'join if was hidden previously',
      fireImmediately: false
    }, () => !this.environment.isHidden && this.joinOnAppVisible, () => {
      this.joinOnAppVisible = false;
      this.joinChannelOrUpdateRoute({
        model: this,
        activeChannel: this.activeChannel,
        nicklistOpen: this.props.nicklistOpen,
        routeChatId: this.props.routeChatId
      });
    }));
    this.dispose.track(reactionWhen({
      name: 'join if shown in foreground',
      fireImmediately: false
    }, () => !this.isShownInBackground, () => {
      this.joinChannelOrUpdateRoute({
        model: this,
        activeChannel: this.activeChannel,
        nicklistOpen: this.props.nicklistOpen,
        routeChatId: this.props.routeChatId
      });
    }));
    this.dispose.track(reactionWhen({
      name: 'join from deep link',
      fireImmediately: false
    }, () => this.joinChannelService.overrideInitialChannelName !== undefined, async () => {
      await this.joinChannelService.joinChannelByName(this.joinChannelService.overrideInitialChannelName, 'DeepLink');
      runInAction('Clear override initial channel', () => {
        this.joinChannelService.overrideInitialChannelName = undefined;
      });
    }));
  }
  public setChatActive(active: boolean): void {
    // TODO better type
    const newState: any = {
      currentlyActive: active
    };
    if (!active) {
      newState.lastInactiveTimestamp = Date.now();
    }
  }
  public pushChannelView(cid: Channel['id']): void {
    this.viewService.openView(channelViewId.with(s => s.setChannel(cid)));
  }
  public replaceChannelView(cid: Channel['id']): void {
    this.viewService.openView(channelViewId.with(s => s.setChannel(cid)), {
      locationUpdateMode: 'replace'
    });
  }
  public toggleNickList = (): void => {
    nickListTitlebarEvent.track(this.props.nicklistOpen ? 'Titlebar_BackButton' : 'Titlebar_OpenNicklist');
    if (this.props.nicklistOpen) {
      nicklistClosedTimingTracker.start();
    } else {
      nicklistOpenedTimingTracker.start();
      this.genericUserEventService.reportEvent({
        type: 'Opened_Nicklist',
        amountOfNicks: this.activeChannel.sortedParticipants.length
      });
    }
    this.viewService.openView(channelViewId.with(s => s.toggleNickList()));
  };
  public switchToView = (view: 'chat' | 'nicklist'): void => {
    const currentView = this.props.nicklistOpen ? 'nicklist' : 'chat';
    if (currentView !== view) {
      this.toggleNickList();
    }
  };
  @computed
  public get isShownInBackground(): boolean {
    return this.viewService.layout.isViewInBackground(channelViewId, LayoutPosition.Main);
  }
  public get isStackedLayout(): boolean {
    return this.screenService.isStackedLayout;
  }
  public get channelState(): ChannelState {
    return this.activeChannelService.state;
  }
  public async joinChannelOrUpdateRoute(props: Props & {
    activeChannel: ChannelInfo;
  } & typeof ChannelRoot.TPropsWithModel, oldProps?: Props & {
    activeChannel: ChannelInfo;
  }): Promise<void> {
    if (this.joinChannelService.overrideInitialChannelName) {
      const joined = await this.joinChannelService.joinChannelByName(this.joinChannelService.overrideInitialChannelName, 'OverrideInitialChannelName');
      runInAction('Clear override initial channel', () => {
        this.joinChannelService.overrideInitialChannelName = undefined;
      });
      if (joined) {
        return;
      }
    }
    if (this.isShownInBackground || oldProps && oldProps.activeChannel === props.activeChannel && oldProps.routeChatId === props.routeChatId) {
      // nothing relevant changed => do nothing
      return;
    }
    if (!props.activeChannel) {
      if (this.activeChannelService.state.kind !== 'noChannel') {
        if (props.routeChatId) {
          // user opened client with channel in url
          await this.joinChannelService.joinChannelById(props.routeChatId, 'LinkFromUrl', {
            redirectToChannelListOnFail: false,
            redirectToChannelOnSuccess: false,
            mayJoinSubChannel: true
          });
        } else {
          // don't reopen channel view after this mutation is successful => fix problem with deeplinking
          await this.joinChannelService.initialJoinChannel({
            redirectToChannelOnSuccess: false,
            force: false
          });
        }
      }
    } else if (!props.routeChatId) {
      // update route
      updateRoute(props.activeChannel.id, props);
    } else if (props.routeChatId !== props.activeChannel.id) {
      // route is out of sync with channel
      if (
      // no route => update to current channel id
      !props.routeChatId ||
      // on mount update route
      !oldProps ||
      // there was no channel => fix route to new channel
      !oldProps.activeChannel ||
      // channel updated => fix route
      oldProps.activeChannel.id !== props.activeChannel.id) {
        updateRoute(props.activeChannel.id, props);
      } else if (oldProps.routeChatId !== props.routeChatId) {
        // route changed (maybe because of backbutton) => join channel of new route
        await this.joinChannelService.joinChannelById(props.routeChatId, 'LinkFromUrl');
      }
    }
  }
}
export const ChannelRoot = injectedComponent({
  name: 'ChannelRoot',
  model: ChannelRootModel,
  props: declareProps<Props>()
}, ({
  model,
  ...props
}) => <ChannelRootBase {...props} activeChannel={model.activeChannel} channelState={model.channelState} isShownInBackground={model.isShownInBackground} model={model} />);
type BaseProps = Props & {
  activeChannel: ChannelInfo;
  channelState: ChannelState;
  isShownInBackground: boolean;
} & typeof ChannelRoot.TPropsWithModel;
@observer
class ChannelRootBase extends React.Component<BaseProps, State> {
  constructor(props: BaseProps) {
    super(props);
    this.state = {
      bigChatView: !props.model.isStackedLayout,
      width: 0
    };
  }
  handleLayout = (ev: Size): void => {
    this.setState({
      bigChatView: ev.width >= CHAT_NEXT_TO_NICKLIST_THRESHOLD,
      width: ev.width
    });
  };
  componentWillUnmount(): void {
    this.props.model.setChatActive(false);
  }
  componentDidMount(): void {
    this.props.model.setChatActive(true);
  }
  UNSAFE_componentWillMount(): void {
    if (!this.props.model.environment.isHidden) {
      this.props.model.joinChannelOrUpdateRoute(this.props);
    } else {
      this.props.model.joinOnAppVisible = true;
    }
  }
  UNSAFE_componentWillReceiveProps(newProps: Props & {
    activeChannel: ChannelInfo;
  } & typeof ChannelRoot.TPropsWithModel): void {
    if (!this.props.model.environment.isHidden) {
      this.props.model.joinChannelOrUpdateRoute(newProps, this.props);
    } else {
      this.props.model.joinOnAppVisible = true;
    }
    if (this.props.activeChannel && newProps.activeChannel) {
      if (this.props.activeChannel.mainChannelName !== newProps.activeChannel.mainChannelName) {
        leaveChannelEvent.track(`LeaveChannel_${this.props.activeChannel.mainChannelName}`);
        enterChannelEvent.track(`EnterChannel_${newProps.activeChannel.mainChannelName}`);
      }
    } else if (newProps.activeChannel) {
      enterChannelEvent.track(`EnterChannel_${newProps.activeChannel.mainChannelName}`);
    }
  }
  render(): JSX.Element {
    const {
      // careful with setting defaults for getters (this allows everything even if the types don't match)
      id = undefined,
      groupInfo = defaultGroupInfo,
      colorMode = 'dark'
    } = this.props.activeChannel || ({} as ChannelInfo);
    const isStackedLayout = this.props.model.isStackedLayout;
    const chatNextToNicklist = !isStackedLayout && this.state.bigChatView;
    const {
      backgroundColor,
      backgroundImageInfo
    } = groupInfo;
    const channelBackgroundColorString = colorToRgbaString(backgroundColor);
    const lightbox = this.renderChannelLightbox(this.props.channelState);

    // We only show apps if no lightbox is visible. Otherwise the app would be shown in
    // front of the lightbox.
    const mayShowApps = !lightbox;
    return <FallbackBox onLayout={this.handleLayout} className={_c1}>
				{this.props.activeChannel && <ChannelViews backgroundColor={channelBackgroundColorString} backgroundImageInfo={backgroundImageInfo} width={this.state.width} chatNextToNicklist={chatNextToNicklist} nicklistOpen={this.props.nicklistOpen} switchToView={this.props.model.switchToView} chatView={<ChatView colorMode={colorMode} channelColor={channelBackgroundColorString} channel={this.props.activeChannel} isStackedLayout={isStackedLayout} showNicklistIcon={!chatNextToNicklist} clickNicklistIcon={this.props.model.toggleNickList} isInBackground={this.props.isShownInBackground || this.props.nicklistOpen} mayShowApps={mayShowApps} />} nicklist={<NickList
      // switching channels should also rerender nicklist => refresh adzone
      key={id} isInBackground={this.props.isShownInBackground} channelId={id} showFullScreen={!chatNextToNicklist} clickClose={this.props.model.toggleNickList} channelBackgroundColor={channelBackgroundColorString} channelColorMode={colorMode} isVisible={chatNextToNicklist || this.props.nicklistOpen} />} />}

				{!this.props.activeChannel && !this.props.isShownInBackground && this.props.channelState.kind === 'active' && <BackgroundScrim />}
				{lightbox}
			</FallbackBox>;
  }
  renderChannelLightbox(state: ChannelState): JSX.Element {
    switch (state.kind) {
      case 'noChannel':
        return <NoChannelDashboard />;
      case 'disconnected-by-other-session':
      case 'disconnected-by-server':
        return <ChannelLightbox sideAdPosition={'center'}>
						<ChannelDisconnectedContent type={state.kind} />
					</ChannelLightbox>;
      default:
        return null;
    }
  }
}
function updateRoute(newCid: Channel['id'], props: Props & typeof ChannelRoot.TPropsWithModel): void {
  // change channel route
  if (props.routeChatId === undefined) {
    props.model.replaceChannelView(newCid);
  } else if (props.routeChatId !== newCid) {
    props.model.pushChannelView(newCid);
  }
}
type Size = {
  width: number;
  height: number;
};
const _c0 = " Knu-FlexCol position-absolute inset-none bg-backgroundScrim zIndex-80 ";
const _c1 = " Knu-FlexCol position-absolute inset-none overflow-hidden ";