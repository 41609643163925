import { useConstant as n } from "../../utils/use-constant.js";
import { useUnmountEffect as r } from "../../utils/use-unmount-effect.js";
import { createScopedAnimate as m } from "../animate/index.js";
function c() {
  const t = n(() => ({
    current: null,
    // Will be hydrated by React
    animations: []
  })), o = n(() => m(t));
  return r(() => {
    t.animations.forEach((e) => e.stop());
  }), [t, o];
}
export {
  c as useAnimate
};
