import { ConversationWithParticipants, GetUserIdFromNick, MessengerConversation, User } from '@generated/graphql';
import { $CommandService } from '@knuddels-app/Commands';
import { $AuthenticatedClientService } from '@knuddels-app/Connection';
import { inject, injectable } from '@knuddels-app/DependencyInjection';
import { $SnackbarService } from '@knuddels-app/SnackbarManager';
import { $I18n } from '@knuddels-app/i18n';
import { $ViewService } from '@knuddels-app/layout';
import { declareFormat } from '@knuddels/i18n';
import { Disposable } from '@knuddels/std';
import { $DeepLinkingService, DeepLinkType } from '@knuddelsModules/DeepLinking';
import { $UserService } from '@knuddelsModules/UserData';
import { messengerViewId } from '../../MessengerViewProvider';
import { $GenericUserEventService } from '@knuddels-app/analytics/generic';
@injectable()
export class MessengerNavigationService {
  public readonly dispose = Disposable.fn();
  constructor(@inject($AuthenticatedClientService)
  private readonly authenticatedClientService: typeof $AuthenticatedClientService.T, @inject($ViewService)
  private readonly viewService: typeof $ViewService.T, @inject($DeepLinkingService)
  private readonly deepLinkingService: typeof $DeepLinkingService.T, @inject($CommandService)
  private readonly commandService: typeof $CommandService.T, @inject($SnackbarService)
  private readonly snackbarService: typeof $SnackbarService.T, @inject($UserService)
  private readonly userService: typeof $UserService.T, @inject($I18n)
  private readonly i18n: typeof $I18n.T, @inject($GenericUserEventService)
  private readonly genericUserEventService: typeof $GenericUserEventService.T) {
    this.dispose.track(this.deepLinkingService.registerDeeplinkHandler((type, target) => {
      if (type === DeepLinkType.Conversation && target) {
        this.openConversation(target, 'DeepLink', false, false);
      }
    }));
    ['m', 'p'].forEach(commandName => {
      this.dispose.track(commandService.registerCommand({
        commandName,
        shouldInvoke: (parameter: string): boolean => {
          return parameter.indexOf(':') === -1;
        },
        invoke: async (parameter: string): Promise<void> => {
          const nick = parameter.trim();
          if (nick.length === 0) {
            this.viewService.openViewAsOverlayWithContext(messengerViewId, 'SlashCommand');
            return;
          }
          await this.openConversationFromCommand(nick);
        }
      }));
    });
    this.dispose.track(commandService.registerCommand({
      commandName: 'messenger',
      invoke: async (parameter: string): Promise<void> => {
        if (parameter.trim()) {
          await this.openConversationFromCommand(parameter.trim());
        } else {
          this.viewService.openViewAsOverlayWithContext(messengerViewId, 'SlashCommand');
        }
      }
    }));
  }
  openConversation(conversationId: MessengerConversation['id'], source: string, autoFocus = false, shouldOpenAsOverlay = true): void {
    this.genericUserEventService.reportEvent({
      type: 'Opened_Conversation',
      container: 'messenger',
      conversationId,
      source
    });
    const viewId = messengerViewId.with(s => s.withConversation({
      scrollToMessageId: undefined,
      conversationId,
      autoFocus,
      initialView: true
    }));
    if (shouldOpenAsOverlay) {
      this.viewService.openViewAsOverlayWithContext(viewId, source);
    } else {
      this.viewService.openView(viewId, {
        openContext: source
      });
    }
  }
  getConversationByUserId(userId: User['id'], cb: (conversationId: string) => void): Promise<void> {
    const otherParticipantIds: ReadonlyArray<User['id']> = [userId];
    return this.authenticatedClientService.currentK3Client.queryWithResultPromise(ConversationWithParticipants, {
      ids: otherParticipantIds
    }).match({
      ok: conversation => {
        if (conversation) {
          cb(conversation.id);
        } else {
          console.error(`Error: conversation with userId='${userId}' does not exit.`);
        }
      },
      error: err => {
        console.error(`Error: conversation with userId='${userId}' does not exit.`, err);
      }
    });
  }
  openConversationByUserId(userId: User['id'], source: string, autoFocus = false): Promise<void> {
    return this.getConversationByUserId(userId, conversationId => {
      this.openConversation(conversationId, source, autoFocus);
    });
  }
  openConversationByNick(nick: string, source: string): Promise<void> {
    return this.authenticatedClientService.currentK3Client.queryWithResultPromise(GetUserIdFromNick, {
      nick
    }).match({
      ok: user => {
        this.openConversationByUserId(user.id, source);
      },
      error: err => {
        console.error(`Failed to get user id for user with nick=${nick}:`, err);
      }
    });
  }
  openConversationFromCommand(nick: string): Promise<void> {
    return this.authenticatedClientService.currentK3Client.queryWithResultPromise(GetUserIdFromNick, {
      nick
    }).match({
      ok: user => {
        if (user) {
          if (this.userService.isCurrentUser(user.id)) {
            this.showOpenFailSnackbar('invalidUser', declareFormat({
              id: 'messenger.conversation.open.with-self',
              defaultFormat: 'You cannot open a conversation with yourself.'
            }).format(this.i18n));
          } else {
            this.openConversationByUserId(user.id, 'Command', true);
          }
        } else {
          this.showOpenFailSnackbar('userNotFound', declareFormat({
            id: 'messenger.conversation.open.not-found',
            defaultFormat: "The provided member couldn't be found."
          }).format(this.i18n));
        }
      },
      error: () => {
        this.snackbarService.showGenericError();
      }
    });
  }
  private showOpenFailSnackbar(type: string, subtext: string): void {
    this.snackbarService.showErrorSnackbarWithDefaults({
      type,
      text: declareFormat({
        id: 'messenger.conversation.open.error',
        defaultFormat: "The conversation couldn't be opened."
      }).format(this.i18n),
      subtext
    });
  }
}