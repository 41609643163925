import { Link } from '@knuddels/component-library';
import * as React from 'react';
import { useOpenProfile } from '@knuddelsModules/Profile';
export const NickLink: React.FC<{
  userId: string;
  nick: string;
}> = ({
  userId,
  nick
}) => {
  const openProfile = useOpenProfile({
    id: userId,
    nick
  });
  return <Link bold onPress={() => openProfile('NickLink')}>
			{nick}
		</Link>;
};