import { MessengerConversation, User, UserForQuickWhoisFragment } from '@generated/graphql';
import { declareProps, inject, injectProps, injectable, injectedComponent } from '@knuddels-app/DependencyInjection';
import { Category } from '@knuddels-app/analytics/Category';
import { $FirebaseAnalyticsService } from '@knuddels-app/analytics/firebase';
import { FlexCol, LineDivider, resolveThemingValue, useTheme, resolveIsDarkColor } from '@knuddels/component-library';
import { $ProfileNavigationService } from '@knuddelsModules/Profile';
import { Z_INDEX } from '@shared/components';
import * as React from 'react';
import { ProfileCarousel } from '../PhotoDisplays/ProfileCarousel';
import { FriendStateBanner } from '../shared/FriendStateBanner';
import { InfoPanel } from './InfoPanel';
import { ProfileInputBar } from './ProfileInputBar';
import { $GenericUserEventService } from '@knuddels-app/analytics/generic';
interface Props {
  currentUserId: User['id'];
  user: UserForQuickWhoisFragment;
  conversationId: MessengerConversation['id'];
  onClose: () => void;
}
export const QUICK_WHOIS_WIDTH = 260;
@injectable()
class QuickWhoisModel {
  public readonly trackingCallbacks: (typeof ProfileInputBar.TProps)['trackingCallbacks'] = {
    conversationOpenClicked: () => this.firebaseAnalyticsService.logEvent(Category.ProfileQuickwhois, 'Inputbar_OpenConversation'),
    messageSent: () => this.firebaseAnalyticsService.logEvent(Category.ProfileQuickwhois, 'Inputbar_MessageSent'),
    enterUsed: () => this.firebaseAnalyticsService.logEvent(Category.ProfileQuickwhois, 'InputBar_EnterUsed'),
    sendButtonUsed: () => this.firebaseAnalyticsService.logEvent(Category.ProfileQuickwhois, 'InputBar_SendButtonUsed')
  };
  constructor(@injectProps()
  private readonly props: Props, @inject.lazy($ProfileNavigationService)
  private readonly getProfileNavigationService: typeof $ProfileNavigationService.TLazy, @inject($FirebaseAnalyticsService)
  private readonly firebaseAnalyticsService: typeof $FirebaseAnalyticsService.T, @inject($GenericUserEventService)
  private readonly genericUserEventService: typeof $GenericUserEventService.T) {
    this.genericUserEventService.reportEvent({
      type: 'ProfileQuickWhois_Opened',
      nick: this.props.user.nick
    });
  }
  public get shouldShowInputBar(): boolean {
    return this.props.user.canReceiveMessages && this.props.user.isAllowedByContactFilter && this.props.user.id !== this.props.currentUserId;
  }
  public readonly handleNickClicked = (): void => {
    this.firebaseAnalyticsService.logEvent(Category.ProfileQuickwhois, 'Nickname_Clicked');
    this.getProfileNavigationService().then(service => service.showProfile(this.props.user.id, 'QuickWhois'));
  };
}
export const QuickWhois = injectedComponent({
  name: 'QuickWhois',
  model: QuickWhoisModel,
  props: declareProps<Props>()
}, ({
  model,
  user,
  conversationId,
  onClose
}) => {
  return <div className={_c0 + ("contentBg" ? resolveIsDarkColor("contentBg", useTheme()) ? " content-is-dark" : " content-is-light" : "")}>
				<div className={_c1}>
					<ProfileCarousel user={user} size={QUICK_WHOIS_WIDTH} centerArrows />
					<FriendStateBanner user={user} size={'small'} />
				</div>
				<InfoPanel user={user} onNickClicked={model.handleNickClicked} />
				{model.shouldShowInputBar && <div style={{
      zIndex: resolveThemingValue(Z_INDEX.OVERCONTENT, "theme", useTheme())
    }} className={_c2}>
						<LineDivider className={_c3} />
						<ProfileInputBar user={user} conversationId={conversationId} onClose={onClose} trackingCallbacks={model.trackingCallbacks} />
					</div>}
			</div>;
});
const _c0 = " Knu-FlexCol position-relative borderRadius-base bg-contentBg overflow-hidden ";
const _c1 = " Knu-FlexCol position-relative ";
const _c2 = " Knu-FlexCol shadow-Shadow3 ";
const _c3 = "  ";