import { newServiceId } from '@knuddels-app/DependencyInjection';
import * as services from './bundle/services';

export const $AppService = newServiceId<services.AppService>('$AppService');
export const $ModalAppViewer =
	newServiceId<services.ModalAppViewer>('$ModalAppViewer');
export const $ChannelAppViewer =
	newServiceId<services.ChannelAppViewer>('$ChannelAppViewer');

export const $ChannelAppViewerAnimationService =
	newServiceId<services.ChannelAppViewerAnimationService>(
		'$ChannelAppViewerAnimationService'
	);
export const $CustomPositionAppViewer =
	newServiceId<services.CustomPositionAppViewer>('$CustomPositionAppViewer');
export const $GlobalAppsAppViewer = newServiceId<services.GlobalAppsAppViewer>(
	'$GlobalAppsAppViewer'
);
export const $ChannelBackgroundAppViewer =
	newServiceId<services.ChannelBackgroundAppViewer>(
		'$ChannelBackgroundAppViewer'
	);

export const $StartNativeClientEventHandler =
	newServiceId<services.StartNativeClientEventHandler>(
		'$StartNativeClientEventHandler'
	);

export const $AppsDeeplinkHandler = newServiceId<services.AppsDeeplinkHandler>(
	'$AppsDeeplinkHandler'
);

export const $SystemAppStorageService =
	newServiceId<services.SystemAppStorageService>('$SystemAppStorageService');

export const $SystemAppPerformanceService =
	newServiceId<services.SystemAppPerformanceService>(
		'$SystemAppPerformanceService'
	);
