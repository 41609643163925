import { jsx as d, jsxs as F, Fragment as T } from "react/jsx-runtime";
import r, { useCallback as O } from "react";
import { TitleBar as _ } from "../TitleBar.js";
import { useSplitView as Z } from "./SplitView.js";
import { Portal as q } from "../Layout/Portal.js";
import { equalUpToDepth as B } from "../helper/equalUpToDepth.js";
import { ErrorWithDocsReference as $ } from "../../docs/helper.js";
import { useEvent as U } from "../helper/useEvent.js";
import { clsx as G } from "../../node_modules/clsx/dist/clsx.m.js";
import "./SlideInView.scss.js";
import { IconBack as J } from "../Icons/Back.js";
import { IconCloseCircular as Q } from "../Icons/CloseCircular.js";
import { FallbackBox as X } from "../Layout/Box/Box.js";
import { MemoryHistory as Y } from "./plugins/MemoryHistory.js";
const x = r.createContext(null), p = (e) => {
  const t = {};
  return r.Children.forEach(e, (n) => {
    t[n.props.path] = {
      ...n.props,
      component: n.props.children
    };
  }), t;
};
class ee extends r.PureComponent {
  render() {
    return /* @__PURE__ */ d(
      z,
      {
        ...this.props,
        ref: (t) => {
          t && (this.props.innerRef.current[this.props.index] = t), this.props.slideInViewRefs.current.push(t);
        }
      }
    );
  }
}
const z = r.forwardRef((e, t) => {
  const n = r.useRef(
    !e.isRootView && !e.preventAnimation
  ), l = r.useRef(null);
  r.useLayoutEffect(() => {
    const o = e.slideInViewRefs.current[e.index - 1];
    o == null || o.setInactive();
  }, []), r.useImperativeHandle(
    t,
    () => ({
      key: e.item.key,
      setInactive: () => {
        var o, u, i;
        (i = (u = (o = l.current) == null ? void 0 : o.classList) == null ? void 0 : u.add) == null || i.call(u, "inactive");
      },
      setActive: () => {
        var o, u, i;
        (i = (u = (o = l.current) == null ? void 0 : o.classList) == null ? void 0 : u.remove) == null || i.call(u, "inactive");
      },
      animateOut: (o) => {
        var i, a, f;
        (f = (a = (i = l.current) == null ? void 0 : i.classList) == null ? void 0 : a.add) == null || f.call(a, "animate-out");
        const u = e.slideInViewRefs.current[e.index - 1];
        u == null || u.setActive(), setTimeout(() => {
          o();
        }, 200);
      }
    }),
    []
  );
  const s = r.useMemo(() => ({
    pop: () => {
      var o;
      (o = e.pop) == null || o.call(e);
    },
    navigate: e.navigate,
    getParamsFor(o) {
      return e.item.params;
    },
    configureNavBar(o) {
      e.updateRoute(e.item.key, o);
    },
    updateNavBar(o) {
      const u = {
        ...e.getNavConfig(e.item.key)
      };
      return e.updateRoute(e.item.key, o), () => {
        e.updateRoute(e.item.key, u);
      };
    }
  }), [e.item, e.navigate, e.pop, e.updateRoute]), h = oe({
    navConfig: e.navConfig,
    index: e.index,
    pop: e.pop,
    close: e.onClose,
    isAnimatingRef: e.isAnimatingRef,
    defaultCloseIconStyle: e.defaultCloseIconStyle,
    inSingleView: e.inSingleView
  });
  return /* @__PURE__ */ d(x.Provider, { value: s, children: /* @__PURE__ */ F(
    "div",
    {
      ref: l,
      className: G(
        "Knu-SlideInView Knu-FlexCol",
        n.current && "shouldAnimate"
      ),
      style: { "--SlideInZindex": e.index },
      children: [
        !e.hideTitleBar && /* @__PURE__ */ d(
          _,
          {
            navIcon: h,
            title: e.navConfig.title ?? "",
            subtitle: e.navConfig.subtitle,
            subtitleAdornment: e.navConfig.subtitleAdornment,
            hideShadow: e.navConfig.hideShadow,
            iconList: e.navConfig.iconList,
            fillBackground: e.navConfig.transparentTitleBar === !1
          }
        ),
        /* @__PURE__ */ d("div", { className: "Knu-FlexCol flex-1 minHeight-0", children: e.children })
      ]
    }
  ) });
});
z.displayName = "Screen";
const te = (e, t, n) => {
  const [l] = r.useState(() => {
    if (typeof e == "function") {
      const s = new e();
      return n.current = s.init(t).stack, s;
    } else
      return n.current = e.init(t).stack, e;
  });
  return l;
}, ne = (e, t) => {
  const n = r.useRef(/* @__PURE__ */ new Map()), [l, s] = r.useState({}), h = r.useRef(
    {}
  ), o = r.useCallback(
    (i, a) => {
      n.current.delete(i), h.current[i] = {
        ...h.current[i],
        ...a
      }, s({});
    },
    [s]
  ), u = U((i) => {
    const a = e.find((c) => c.key === i), f = h.current[i] ?? {}, m = { ...t[a == null ? void 0 : a.path] ?? {}, ...f };
    if (n.current.has(i)) {
      const c = n.current.get(i);
      if (B(c, m))
        return c;
    }
    return n.current.set(i, m), m;
  });
  return {
    updateRoute: o,
    getConfigForKey: u
  };
}, re = (e) => {
  switch (e.navIconStyle) {
    case "close":
      return /* @__PURE__ */ d(
        Q,
        {
          onPress: e.onPress,
          size: "large",
          ariaLabel: "Ansicht schließen"
        }
      );
    case "back":
      return /* @__PURE__ */ d(
        J,
        {
          onPress: e.onPress,
          size: "large",
          ariaLabel: "Zurück zur vorherigen Ansicht."
        }
      );
    default:
      return e.navIconStyle;
  }
}, oe = ({
  navConfig: e,
  inSingleView: t,
  index: n,
  pop: l,
  close: s,
  isAnimatingRef: h,
  defaultCloseIconStyle: o
}) => {
  const u = (!t || n !== 0) && !e.finalRoute, i = () => {
    var m;
    if (h.current)
      return;
    let g = !1;
    (m = e.onNavIconPress) == null || m.call(e, {
      preventDefault() {
        g = !0;
      }
    }), !g && (u ? l == null || l() : s == null || s());
  }, a = u ? "back" : s ? o : "none", f = !e.navIconStyle || e.navIconStyle === "auto" ? a : e.navIconStyle;
  return f === "none" ? void 0 : /* @__PURE__ */ d(re, { navIconStyle: f, onPress: i });
}, P = ({
  pop: e,
  innerRef: t,
  routerConfig: n,
  getConfigForKey: l,
  isAnimatingRef: s,
  navigate: h,
  updateRoute: o,
  close: u,
  defaultCloseIconStyle: i,
  inSingleView: a = !0,
  preventAnimation: f,
  containerWidthRef: g,
  hideTitleBar: m,
  stack: c
}) => {
  const R = r.useRef([]);
  return /* @__PURE__ */ d(T, { children: c.map((y, w) => {
    const v = w === 0, b = l(y.key);
    return /* @__PURE__ */ d(
      ee,
      {
        innerRef: t,
        item: y,
        getNavConfig: l,
        index: w,
        slideInViewRefs: R,
        onClose: u,
        defaultCloseIconStyle: i,
        inSingleView: a,
        isAnimatingRef: s,
        hideTitleBar: b.hideTitleBar ?? m,
        preventAnimation: f || v,
        isRootView: v,
        pop: v && a ? void 0 : e,
        navigate: h,
        navConfig: b,
        updateRoute: o,
        containerWidthRef: g,
        children: n[y.path].component
      },
      y.key
    );
  }) });
}, ie = (e, t) => {
  const n = [
    e[0],
    ...e.slice(1).filter((s) => t[s.path].preventSplitView)
  ].filter(Boolean), l = e.slice(1).filter((s) => !t[s.path].preventSplitView).filter(Boolean);
  return { rootViewStack: n, splitViewStack: l };
}, ce = (e) => {
  const { rootViewStack: t, splitViewStack: n } = ie(
    e.stack,
    e.routerConfig
  );
  return /* @__PURE__ */ F(T, { children: [
    /* @__PURE__ */ d(
      P,
      {
        ...e,
        innerRef: e.rootViewRef,
        stack: t,
        pop: () => {
          e.history.popRouteWithKey(
            t[t.length - 1].key
          );
        }
      }
    ),
    /* @__PURE__ */ d(q, { children: n.length > 0 ? /* @__PURE__ */ d(
      P,
      {
        ...e,
        innerRef: e.childViewRef,
        stack: n,
        inSingleView: !1,
        pop: () => {
          e.history.popRouteWithKey(
            n[n.length - 1].key
          );
        }
      }
    ) : null })
  ] });
}, ue = (e) => {
  const t = r.useCallback(() => {
    e.history.pop();
  }, [e.history]);
  return /* @__PURE__ */ d(
    P,
    {
      ...e,
      innerRef: e.rootViewRef,
      pop: t,
      stack: e.stack
    }
  );
}, ae = () => Z() ? ce : ue, se = ({
  rerender: e,
  rootViewRef: t,
  childViewRef: n,
  previousStack: l,
  update: s,
  routerConfig: h,
  isAnimatingRef: o
}) => {
  var g, m;
  const u = new Set(s.stack.map((c) => c.key)), i = l.find(
    (c) => !u.has(c.key)
  ), a = t.current, f = n.current;
  if (!i) {
    e();
    return;
  }
  for (let c = 1; c < a.length; c++)
    if (i.key === ((g = a[a.length - c]) == null ? void 0 : g.key)) {
      o.current = !0;
      for (let R = 1; R < c; R++)
        a[a.length - 1 - R].animateOut(() => {
        });
      a[a.length - 1].animateOut(() => {
        o.current = !1;
        for (let R = 0; R < c; R++)
          a.pop();
        e();
      });
      return;
    }
  if (f.length > 1 && i.key === ((m = f[f.length - 1]) == null ? void 0 : m.key)) {
    o.current = !0, f[f.length - 1].animateOut(() => {
      o.current = !1, f.pop(), e();
    });
    return;
  }
  e();
}, le = ({
  historyPlugin: e = Y,
  rootPath: t,
  initialPath: n,
  onNavigate: l,
  onClose: s,
  containerWidthRef: h,
  defaultCloseIconStyle: o = "close",
  children: u,
  hideTitleBar: i = !1,
  routerRef: a
}) => {
  const f = r.useRef([]), g = r.useRef([]), m = r.useRef(!1), c = r.useRef([]), R = r.useRef(!0), y = r.useRef(null), w = p(u), v = te(
    e,
    {
      routerConfig: w,
      rootPath: t,
      initialPath: n
    },
    c
  ), [b, D] = r.useReducer((C) => C + 1, 0), L = r.useCallback(() => D(null), []), V = c.current, N = V[V.length - 1], { updateRoute: H, getConfigForKey: M } = ne(
    V,
    w
  );
  r.useEffect(() => {
    R.current = !1;
    const C = v.subscribe((k) => {
      if (c.current.length <= k.stack.length) {
        c.current = k.stack, L();
        return;
      }
      se({
        previousStack: c.current,
        isAnimatingRef: m,
        update: k,
        routerConfig: w,
        rootViewRef: f,
        childViewRef: g,
        rerender: () => {
          c.current = k.stack, L();
        }
      });
    });
    return () => {
      C();
    };
  }, []), r.useEffect(() => {
    y.current && (l == null || l({
      newPath: N.path,
      oldPath: y.current.path
    })), y.current = N;
  }, [N]);
  const A = O(
    (C, { params: k = void 0 } = {}) => {
      const S = c.current[c.current.length - 1];
      if ((S == null ? void 0 : S.path) === C && B(S == null ? void 0 : S.params, k))
        return;
      const E = c.current.find(
        (K) => K.path === C && B(K.params, k)
      );
      E && v.popRouteWithKey(E.key), v.push({ params: k, path: C });
    },
    [v]
  );
  r.useImperativeHandle(a, () => ({
    navigate: A,
    pop: () => {
      v.pop();
    }
  }));
  const W = U(() => {
    s == null || s();
  }), j = ae();
  return c.current.length === 0 ? null : /* @__PURE__ */ d(
    j,
    {
      containerWidthRef: h,
      hideTitleBar: i,
      preventAnimation: R.current,
      rootViewRef: f,
      childViewRef: g,
      isAnimatingRef: m,
      close: s ? W : void 0,
      stack: V,
      defaultCloseIconStyle: o,
      navigate: A,
      history: v,
      routerConfig: w,
      getConfigForKey: M,
      updateRoute: H
    }
  );
}, fe = (e) => {
  const t = r.useRef(0);
  return /* @__PURE__ */ d(
    X,
    {
      className: "Knu-FlexCol flex-1 bg-contentBg position-relative size-full overflow-hidden",
      onLayout: (n) => {
        t.current = n.width;
      },
      children: /* @__PURE__ */ d(
        le,
        {
          ...e,
          routerRef: e.routerRef,
          containerWidthRef: t
        }
      )
    }
  );
};
function de() {
  var e;
  return e = class extends r.Component {
    render() {
      return /* @__PURE__ */ d(fe, { ...this.props });
    }
  }, e.Route = () => null, e;
}
function I(e, t) {
  if (!e)
    throw new $(
      `No router context found. Make sure you are using the ${t} hook in a component that is a child of a Router.`,
      "navigation/router"
    );
}
function he() {
  return function() {
    const t = r.useContext(x);
    return I(t, "useRouter"), t;
  };
}
function me() {
  return function(t) {
    const n = r.useContext(x);
    return I(n, "useParams"), n.getParamsFor(t);
  };
}
function ge() {
  return function() {
    const t = r.useContext(x);
    return I(t, "useNavigate"), t.navigate;
  };
}
function Le() {
  return {
    Router: de(),
    /**
     * Hook that lets you access the router instance for navigation.
     */
    useRouter: he(),
    /**
     * Hook that lets you access the params for a given route.
     */
    useParams: me(),
    /**
     * Hook that lets you navigate.
     */
    useNavigate: ge(),
    /**
     * Hook that lets you configure the navigation bar.
     * Use this for dynamic configuration only. If the config is static prefer to
     * set the values on the Route component.
     */
    useConfigureNavBar: (e, t = []) => {
      const n = r.useContext(x);
      I(n, "useConfigureNavBar"), r.useLayoutEffect(() => {
        n.configureNavBar(e);
      }, t);
    }
  };
}
export {
  Le as createRouter,
  p as useRouterConfig
};
