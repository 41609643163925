import * as React from 'react';
import { BannerBox, Spacer } from '@shared/components';
import { $IgnoreService, $UserService, UserTagList } from '@knuddelsModules/UserData';
import { $I18n, declareFormat, FormatId, formatMessage, FormattedMessage } from '@knuddels-app/i18n';
import { getPingPongDepth } from '../../../../getPingPongDepth';
import { communicationRequestBoxEvent, mentorBoxEvent } from '../../../../analytics';
import { FullConversationFragment, IgnoreState } from '@generated/graphql';
import { IModel, inject, injectable, injectProps } from '@knuddels-app/DependencyInjection';
import { $MessengerService } from '@knuddelsModules/Messenger/providedServices';
import { injectedComponent } from '@knuddels-app/DependencyInjection/injectedComponent';
import { CommunicationRequestBoxInfo } from './CommunicationRequestBoxInfo';
import { CommunicationRequestBoxTitle } from './CommunicationRequestBoxTitle';
import { $ProfileNavigationService } from '@knuddelsModules/Profile';
import { CommunicationRequestBoxCommonFriends } from './CommunicationRequestBoxCommonFriends';
import { FlexCol, IconShow, Text } from '@knuddels/component-library';
import { IconReport } from '@shared/icons/IconReport';
import { IconNoInterest } from '@shared/icons/IconNoInterest';
import { $GenericUserEventService } from '@knuddels-app/analytics/generic';
export type CommunicationRequestBoxType = 'sender' | 'sender_no_messages' | 'sender_no_response' | 'receiver' | 'fotomeetMatch' | 'potential_mentee';
interface Props {
  conversation: FullConversationFragment;
  setLightBoxSource(source: string): void;
}
const noMessagesTitle = declareFormat({
  id: 'NEW_CONTACT_TITLE_SENDER_NO_MESSAGES',
  defaultFormat: 'Send {name} a kind Message!'
});
const titleKeys: { [key in CommunicationRequestBoxType]: FormatId } = {
  sender: declareFormat({
    id: 'NEW_CONTACT_TITLE_SENDER',
    defaultFormat: 'Your conversation with {name}.'
  }),
  sender_no_messages: noMessagesTitle,
  potential_mentee: noMessagesTitle,
  sender_no_response: declareFormat({
    id: 'NEW_CONTACT_TITLE_SENDER_NO_RESPONSE',
    defaultFormat: 'No response from {name} yet!'
  }),
  receiver: declareFormat({
    id: 'NEW_CONTACT_TITLE_RECEIVER',
    defaultFormat: '{name} wants to chat with you...'
  }),
  fotomeetMatch: declareFormat({
    id: 'newContact.title.fotomeetMatch',
    defaultFormat: 'You and {name} are a Fotomeet Match!'
  })
};
@injectable()
class CommunicationRequestBoxModel implements IModel {
  constructor(@injectProps()
  readonly props: Props, @inject($MessengerService)
  private readonly messengerService: typeof $MessengerService.T, @inject($UserService)
  private readonly userService: typeof $UserService.T, @inject.lazy($ProfileNavigationService)
  private readonly getProfileNavigationService: typeof $ProfileNavigationService.TLazy, @inject($IgnoreService)
  private readonly ignoreService: typeof $IgnoreService.T, @inject($GenericUserEventService)
  private readonly genericUserEventService: typeof $GenericUserEventService.T) {}
  componentDidMount(): void {
    if (this.shouldRender() && (!this.userService.currentUser || !this.isRequestSender(this.userService.currentUser))) {
      this.getCommunicationRequestBoxType() === 'potential_mentee' ? mentorBoxEvent.track('MentorBox_Shown') : communicationRequestBoxEvent.track('CommunicationRequestBox_Shown');
    }
  }
  public shouldRender(): boolean {
    return this.user && (this.props.conversation.conversationMessages.messages.length === 0 || getPingPongDepth(this.props.conversation) < 3) && this.user.canReceiveMessages && this.user.isAllowedByContactFilter;
  }
  public get user(): FullConversationFragment['otherParticipants'][0] | undefined {
    return this.props.conversation.otherParticipants[0];
  }
  public readonly handleNoInterest = (): void => {
    communicationRequestBoxEvent.track('CommunicationRequestBox_DeniedCommunicationRequestClicked');
    this.genericUserEventService.reportEvent({
      type: 'NoInterest_Clicked',
      nick: this.user!.nick,
      source: 'CommunicationRequestBox'
    });
    this.ignoreService.privateIgnore(this.user!.id);
    this.messengerService.archiveConversation(this.props.conversation.id);
  };
  public readonly handleUserImageClick = () => {
    this.getCommunicationRequestBoxType() === 'potential_mentee' ? mentorBoxEvent.track('MentorBox_ProfileImageClicked') : communicationRequestBoxEvent.track('CommunicationRequestBox_ProfileImageClicked');
    this.openProfile();
  };
  public readonly handleProfileButtonClick = () => {
    communicationRequestBoxEvent.track('CommunicationRequestBox_OpenProfileButtonClicked');
    this.openProfile();
  };
  public readonly openProfile = async () => {
    (await this.getProfileNavigationService()).showProfileForUser(this.props.conversation.otherParticipants[0]!, {
      openedFromTrackingLabel: 'Conversation'
    });
  };
  public get title(): string {
    const type = this.getCommunicationRequestBoxType();
    const nick = this.user!.nick;
    const titleId = titleKeys[type];
    return formatMessage(titleId, {
      name: nick
    });
  }
  public get shouldShowRemoveRequestButton(): boolean {
    return !this.isPrivateIgnored && this.getCommunicationRequestBoxType() === 'receiver';
  }
  public get hasMenteeStatus(): boolean {
    return this.user.menteeStatus !== 'None';
  }
  private getCommunicationRequestBoxType(): CommunicationRequestBoxType {
    if (!this.hasRealMessages()) {
      return this.hasMenteeStatus ? 'potential_mentee' : 'sender_no_messages';
    } else {
      const currentUser = this.userService.currentUser;
      if (currentUser && this.isRequestSender(currentUser)) {
        const hasResponse = this.props.conversation.conversationMessages.messages.some(msg => msg.sender.id !== currentUser.id);
        return hasResponse ? 'sender' : 'sender_no_response';
      } else {
        return 'receiver';
      }
    }
  }
  private get isPrivateIgnored(): boolean {
    return this.user!.ignoreState === IgnoreState.PrivateIgnore;
  }
  private hasMessages(): boolean {
    return this.props.conversation && this.props.conversation.conversationMessages.messages.length > 0;
  }
  private hasRealMessages(): boolean {
    const conversation = this.props.conversation;
    if (!conversation) {
      return false;
    }
    const messages = conversation.conversationMessages.messages;
    if (!messages || messages.length === 0) {
      return false;
    }
    return messages.some(msg => msg.content.__typename === 'ConversationTextMessageContent' || msg.content.__typename === 'ConversationForwardedMessageContent' || msg.content.__typename === 'ConversationImageMessageContent' || msg.content.__typename === 'ConversationSnapMessageContent' || msg.content.__typename === 'ConversationQuotedMessageContent');
  }
  private isRequestSender(currentUser: Exclude<(typeof $UserService.T)['currentUser'], undefined>): boolean {
    return this.hasMessages() && this.props.conversation.conversationMessages.messages[0].sender.id === currentUser.id;
  }
}
export const CommunicationRequestBox = injectedComponent({
  name: 'CommunicationRequestBox',
  model: CommunicationRequestBoxModel,
  inject: {
    i18n: $I18n
  }
}, ({
  model,
  i18n
}) => {
  if (!model.shouldRender()) {
    return null;
  }
  return <div className={_c0 + (
  // if mentor use more space to prevent being covered by mentor bar
  model.hasMenteeStatus ? _c1 : _c2)}>
				<BannerBox showFooterButtons={!model.hasMenteeStatus} primaryButton={{
      symbol: IconShow,
      text: i18n.format(declareFormat({
        id: 'conversation.requestbox.button.openProfile',
        defaultFormat: 'Open profile'
      })),
      onClick: model.handleProfileButtonClick
    }} secondaryButton={model.shouldShowRemoveRequestButton ? {
      symbol: IconNoInterest,
      text: i18n.format(declareFormat({
        id: 'conversation.requestbox.button.nointerest',
        defaultFormat: 'No interest'
      })),
      onClick: model.handleNoInterest
    } : undefined}>
					<CommunicationRequestBoxTitle model={model} />
					<Spacer size={'base'} />
					<CommunicationRequestBoxInfo model={model} />
					{model.user.profileTags.length > 0 && <>
							<Spacer size={'base'} />
							<UserTagList tags={model.user.profileTags} />
						</>}
					<CommunicationRequestBoxCommonFriends conversationUser={model.user} />
				</BannerBox>
				<Spacer size={'minor'} />
				<Text type={'tiny'} state={'tertiary'} className={_c3}>
					<FormattedMessage id={declareFormat({
        id: 'conversation.notice.report',
        defaultFormat: 'Knuddels wishes you a nice conversation!\nIf you have any problems you can find further help via the {reportIcon} icon.'
      })} values={{
        reportIcon: <IconReport size={'minor'} />
      }} />
				</Text>
			</div>;
});
const _c0 = " Knu-FlexCol alignSelf-center ";
const _c1 = " mt-xxlarge ";
const _c2 = " mt-none ";
const _c3 = " textAlign-center ";