import { ConversationDeletedPhotoCommentMessageContent, ConversationHiddenPhotoCommentMessageContent, ConversationVisiblePhotoCommentMessageContent, DeleteAlbumPhotoComment, GetPhotoUrl } from '@generated/graphql';
import { createAccessiblePointerEventHandler, Flex, FlexCol, Image, LineDivider, Text } from '@knuddels/component-library';
import { BannerBoxButton, FormattedText, FormattedTextDisplay, FormattedTextList, FormattedTextText, Spacer } from '@shared/components';
import { $I18n, declareFormat, FormattedMessage } from '@knuddels-app/i18n';
import * as React from 'react';
import { useOpenAlbumDetails } from '@knuddelsModules/Profile';
import { useService } from '@knuddels-app/DependencyInjection';
import { $SnackbarService } from '@knuddels-app/SnackbarManager';
import { $AuthenticatedClientService, useMutation } from '@knuddels-app/Connection';
import { useConfirmDeleteDialog } from '@knuddels-app/shared-hooks/useConfirmDeleteDialog';
import { $MessengerConversationService, $SendMessageService } from '@knuddelsModules/Messenger/providedServices';
import { useConversationId } from '../ConversationBody/ConversationIdContext';
import { observer } from '@knuddels-app/mobx';
import { useTrackConversation } from '../useTrackConversation';
import { BasicUser } from '../../../utils/BasicUser';
import { SuggestionsType } from '../../../services/conversationServices/MessengerConversationService';
import { $Environment } from '@knuddels-app/Environment';
import { $GenericUserEventService } from '@knuddels-app/analytics/generic';
export const PhotoCommentMessage: React.FC<{
  content: ConversationDeletedPhotoCommentMessageContent | ConversationHiddenPhotoCommentMessageContent | ConversationVisiblePhotoCommentMessageContent;
  sentByActiveUser: boolean;
  sender: BasicUser;
  messageId: string;
  photoOwnerId: string;
}> = ({
  content,
  sender,
  sentByActiveUser,
  messageId,
  photoOwnerId
}) => {
  if (content.__typename === 'ConversationDeletedPhotoCommentMessageContent') {
    return <DeletedPhotoCommentMessage sentByActiveUser={sentByActiveUser} />;
  } else if (content.__typename === 'ConversationHiddenPhotoCommentMessageContent') {
    if (sentByActiveUser) {
      return <VisiblePhotoCommentMessage sentByActiveUser={sentByActiveUser} messageId={messageId} photoComment={content} photoOwnerId={photoOwnerId} />;
    } else {
      return <HiddenPhotoCommentMessage senderNick={sender.nick} />;
    }
  } else if (content.__typename === 'ConversationVisiblePhotoCommentMessageContent') {
    return <VisiblePhotoCommentMessage sentByActiveUser={sentByActiveUser} messageId={messageId} photoComment={content} photoOwnerId={photoOwnerId} />;
  }
};
const DeletedPhotoCommentMessage: React.FC<{
  sentByActiveUser: boolean;
}> = ({
  sentByActiveUser
}) => {
  const track = useTrackConversation();
  React.useEffect(() => {
    track('SystemMessages_RemovedImageShown');
  }, []);
  return <div className={_c0 + (sentByActiveUser ? _c1 : _c2)}>
			<Spacer size={'small'} />
			<Text state={'tertiary'} className={_c3}>
				<FormattedMessage id={declareFormat({
        id: 'messenger.photoComment.deleted',
        defaultFormat: 'This picture does no longer exist.'
      })} />
			</Text>
		</div>;
};
const HiddenPhotoCommentMessage: React.FC<{
  senderNick: string;
}> = ({
  senderNick
}) => {
  const track = useTrackConversation();
  React.useEffect(() => {
    track('SystemMessages_RemovedCommentShown');
  }, []);
  return <div className={_c4}>
			<Text state={'tertiary'} className={_c5}>
				<FormattedMessage id={declareFormat({
        id: 'messenger.photoComment.hidden',
        defaultFormat: "You've deleted the comment from {nick}."
      })} values={{
        nick: senderNick
      }} />
			</Text>
		</div>;
};
type PhotoComment = {
  albumPhotoId: string;
  commentId?: string;
  formattedText: string;
  photoUrl: string;
};
const VisiblePhotoCommentMessage: React.FC<{
  sentByActiveUser: boolean;
  messageId: string;
  photoComment: PhotoComment;
  photoOwnerId: string;
}> = ({
  sentByActiveUser,
  messageId,
  photoOwnerId,
  photoComment
}) => {
  const track = useTrackConversation();
  React.useEffect(() => {
    track('PhotocommentMessages_Shown');
  }, []);
  const accessiblePointerEventProps = useOpenPhoto(photoOwnerId, photoComment.albumPhotoId);
  const commentText = React.useMemo(() => {
    return new FormattedTextList([new FormattedTextText('"'), FormattedText.fromJsonString(photoComment.formattedText), new FormattedTextText('"')]);
  }, [photoComment.formattedText]);
  return <div className={_c6}>
			<FlexCol width={'100%'} {...accessiblePointerEventProps}>
				<Image src={photoComment.photoUrl} alt={''} resizeMode={'cover'} className={_c7} />
			</FlexCol>
			{/* Force container to grow to 370 if there's space*/}
			<div className={_c8} />
			<div className={_c9}>
				<FormattedTextDisplay textProps={{
        textAlign: 'center'
      }} text={commentText} />
			</div>
			{!sentByActiveUser && !!photoComment.commentId && <PhotoCommentButtonBar messageId={messageId} photoComment={photoComment} />}
		</div>;
};
const useOpenPhoto = (photoOwnerId: string, albumPhotoId: string) => {
  const openAlbumDetails = useOpenAlbumDetails();
  const openPhotoUrl = useOpenPhotoUrl();
  const track = useTrackConversation();
  return React.useMemo(() => {
    return createAccessiblePointerEventHandler({
      pointerCallback: () => {
        track('PhotocommentMessages_ImageClicked');
        if (globalEnv.product === 'stapp-messenger') {
          openPhotoUrl(albumPhotoId);
        } else {
          openAlbumDetails({
            userId: photoOwnerId,
            photoId: albumPhotoId
          });
        }
      }
    });
  }, [openAlbumDetails, photoOwnerId, albumPhotoId]);
};
const useOpenPhotoUrl = () => {
  const authenticatedClientService = useService($AuthenticatedClientService);
  const snackbarService = useService($SnackbarService);
  const environment = useService($Environment);
  return React.useCallback(async (photoId: string) => {
    const result = await authenticatedClientService.currentK3Client.query(GetPhotoUrl, {
      photoId
    }, 'network-only');
    if (!result.isOk()) {
      snackbarService.showGenericError();
      return;
    }
    const primaryData = result.unwrap().primaryData;
    if (primaryData.__typename === 'PhotoUrl') {
      environment.openUrl(primaryData.url);
    } else {
      snackbarService.showGenericError();
    }
  }, []);
};
const PhotoCommentButtonBar: React.FC<{
  messageId: string;
  photoComment: PhotoComment;
}> = observer('PhotoCommentButtonBar', ({
  messageId,
  photoComment
}) => {
  const i18n = useService($I18n);
  const thankData = useThank(messageId);
  const deleteComment = useDeleteComment(photoComment);
  return <>
			<div className={_c10}>
				<LineDivider color={'hairlineAtom'} className={_c11} />
			</div>
			<div className={_c12}>
				<BannerBoxButton text={i18n.format(declareFormat({
        id: 'messenger.photoComment.delete-comment',
        defaultFormat: 'remove'
      }))} onClick={deleteComment} />
				{thankData.enabled && <>
						<div className={_c13}>
							<LineDivider color={'hairlineAtom'} orientation={'vertical'} className={_c14} />
						</div>
						<BannerBoxButton text={i18n.format(declareFormat({
          id: 'messenger.photoComment.thank-comment',
          defaultFormat: 'thank'
        }))} onClick={thankData.onPress} />
					</>}
			</div>
		</>;
});
const useThank = (messageId: string) => {
  const track = useTrackConversation();
  const sendMessageService = useService($SendMessageService);
  const genericUserEventService = useService($GenericUserEventService);
  const messengerConversationService = useService($MessengerConversationService);
  const conversationId = useConversationId();
  const clientConversationState = messengerConversationService.getOrCreateClientConversationState(conversationId);
  return {
    enabled: sendMessageService.reactionSmileys.length > 0,
    onPress: () => {
      track('PhotocommentMessages_ReactionsClicked');
      genericUserEventService.reportEvent({
        type: 'ThankYou_Reaction',
        source: 'PhotocommentMessage'
      });
      clientConversationState.setSelectedMessageIdForSuggestions(messageId, SuggestionsType.ThankSuggestions);
    }
  };
};
const useDeleteComment = (photoComment: PhotoComment) => {
  const showConfirmDialog = useConfirmDeleteDialog();
  const i18n = useService($I18n);
  const snackbarService = useService($SnackbarService);
  const [deleteComment] = useMutation(DeleteAlbumPhotoComment);
  const track = useTrackConversation();
  return () => {
    track('PhotocommentMessages_RemoveClicked');
    showConfirmDialog({
      headline: i18n.format(declareFormat({
        id: 'messenger.photoComment.delete-comment.confirm.headline',
        defaultFormat: 'Delete comment'
      })),
      text: i18n.format(declareFormat({
        id: 'messenger.photoComment.delete-comment.confirm.text',
        defaultFormat: 'Do you really want to delete the comment from your photo?'
      })),
      onConfirm: () => {
        track('PhotocommentMessages_RemoveConfirmed');
        deleteComment({
          albumPhotoId: photoComment.albumPhotoId,
          albumPhotoCommentId: photoComment.commentId
        }).then(result => {
          if (result.data?.__typename !== 'DeleteAlbumPhotoCommentSuccess') {
            snackbarService.showGenericError();
          }
        });
      },
      onCancel: () => {
        track('PhotocommentMessages_RemoveNotConfirmed');
      }
    });
  };
};
const _c0 = " Knu-Flex justifyContent-flex-start ";
const _c1 = " flexDirection-row-reverse ";
const _c2 = " flexDirection-row ";
const _c3 = "  ";
const _c4 = " Knu-Flex pl-small ";
const _c5 = "  ";
const _c6 = " Knu-FlexCol borderRadius-base ";
const _c7 = " borderTopRadius-base width-100-percent height-164px ";
const _c8 = " Knu-FlexCol width-370px ";
const _c9 = " Knu-FlexCol pt-minor px-base pb-base justifySelf-stretch ";
const _c10 = " Knu-Flex ";
const _c11 = "  ";
const _c12 = " Knu-Flex ";
const _c13 = " Knu-FlexCol ";
const _c14 = "  ";