import { transformProps as M } from "../../render/html/utils/transform.js";
import { animateMotionValue as S } from "./motion-value.js";
import { setTarget as A } from "../../render/utils/setters.js";
import { getValueTransition as H } from "../utils/get-value-transition.js";
import { getOptimisedAppearId as P } from "../optimized-appear/get-appear-id.js";
import { addValueToWillChange as B } from "../../value/use-will-change/add-will-change.js";
import { frame as V } from "../../frameloop/frame.js";
function I({ protectedKeys: t, needsAnimating: a }, n) {
  const i = t.hasOwnProperty(n) && a[n] !== !0;
  return a[n] = !1, i;
}
function W(t, a, { delay: n = 0, transitionOverride: i, type: m } = {}) {
  var r;
  let { transition: p = t.getDefaultTransition(), transitionEnd: e, ...d } = a;
  i && (p = i);
  const f = [], u = m && t.animationState && t.animationState.getState()[m];
  for (const o in d) {
    const s = t.getValue(o, (r = t.latestValues[o]) !== null && r !== void 0 ? r : null), c = d[o];
    if (c === void 0 || u && I(u, o))
      continue;
    const l = {
      delay: n,
      ...H(p || {}, o)
    };
    let T = !1;
    if (window.MotionHandoffAnimation) {
      const h = P(t);
      if (h) {
        const w = window.MotionHandoffAnimation(h, o, V);
        w !== null && (l.startTime = w, T = !0);
      }
    }
    B(t, o), s.start(S(o, s, c, t.shouldReduceMotion && M.has(o) ? { type: !1 } : l, t, T));
    const g = s.animation;
    g && f.push(g);
  }
  return e && Promise.all(f).then(() => {
    V.update(() => {
      e && A(t, e);
    });
  }), f;
}
export {
  W as animateTarget
};
