import { declareProps, injectedComponent } from '@knuddels-app/DependencyInjection';
import { $I18n, declareFormat } from '@knuddels-app/i18n';
import { Spacer } from '@shared/components';
import * as React from 'react';
import { Button, Flex, FlexCol, Smiley, resolveThemingValue, resolveIsDarkColor, useTheme } from '@knuddels/component-library';
export const ErrorScreenWrapper = injectedComponent({
  name: 'ErrorScreenWrapper',
  inject: {
    i18n: $I18n
  },
  props: declareProps<React.PropsWithChildren<{
    logout: () => void;
  }>>()
}, ({
  i18n,
  logout,
  children
}) => {
  return <div className={_c0 + ("backgroundScrim" ? resolveIsDarkColor("backgroundScrim", useTheme()) ? " content-is-dark" : " content-is-light" : "")}>
				<div className={_c1}>
					<div className={_c2 + ("dialogBoxBg" ? resolveIsDarkColor("dialogBoxBg", useTheme()) ? " content-is-dark" : " content-is-light" : "")}>
						<div className={_c3}>
							<div className={_c4}>
								<Spacer size="large" />
								<Smiley src={require('./error-smiley.png')} width={'36px'} height={'32px'} />
								<Spacer size="base" />
								{children}
								<Spacer size="xlarge" />
							</div>
							<div className={_c5}>
								<Button onPress={logout}>
									{i18n.format(declareFormat({
                id: 'refresh-session-error.logout',
                defaultFormat: 'Logout'
              }))}
								</Button>
							</div>
						</div>
					</div>
				</div>
			</div>;
});
const _c0 = " Knu-Flex position-absolute inset-none bg-backgroundScrim ";
const _c1 = " Knu-Flex maxWidth-600px px-base py-large mx-auto height-full width-full position-relative placeItems-center ";
const _c2 = " Knu-FlexCol maxHeight-full bg-dialogBoxBg p-large borderRadius-large ";
const _c3 = " Knu-FlexCol maxWidth-full gap-base overflow-hidden ";
const _c4 = " Knu-FlexCol overflow-auto gap-base ";
const _c5 = " Knu-Flex ";