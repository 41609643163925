import * as React from 'react';
import { ChannelListItem } from '../../Overview/ChannelSelection/ChannelList/ChannelListItem';
import { ChannelGroupInfoFragment, ChannelPreviewMembersFragment } from '@generated/graphql';
import { useService } from '@knuddels-app/DependencyInjection';
import { $JoinChannelService } from '../../../../../Channel';
import { useLazyService } from '@knuddels-app/ModuleSystem';
import { $AdsService } from '../../../../../Ads';
import { ChannelGroupExpandedContext } from '../../ChannelGroupExpandedContext';
import { $I18n, declareFormat } from '@knuddels-app/i18n';
import { useUpdateRecommendedChannels } from '../../Overview/ChannelSelection/RecommendedChannelsContext';
import { useLogEvent } from '@knuddels-app/analytics/firebase/useLogEvent';
export type ChannelGroupData = {
  id: string;
  name: string;
  info: ChannelGroupInfoFragment;
  channels: ReadonlyArray<{
    id: string;
    name: string;
    onlineUserCount: number;
    previewMembers?: ChannelPreviewMembersFragment;
  }>;
  previewMembers?: ChannelPreviewMembersFragment;
};
const useSubline = (channelGroup: ChannelGroupData, shouldUseShortDescription: boolean) => {
  const i18n = useService($I18n);
  if (shouldUseShortDescription) {
    return channelGroup.info.shortDescription;
  }
  const myChannelSuffix = channelGroup.info.isMyChannel ? ' · ' + declareFormat({
    id: 'channellist.shortDescription.mychannel',
    defaultFormat: 'created by member'
  }).format(i18n) : '';
  const mainCategoryName = channelGroup.info.mainCategoryName;
  if (mainCategoryName) {
    return mainCategoryName + '-Channel' + myChannelSuffix;
  }
  if (channelGroup.info.isMyChannel) {
    return 'MyChannel' + myChannelSuffix;
  }
  return declareFormat({
    id: 'channellist.shortDescription.temporarychannel',
    defaultFormat: 'Temporary Channel'
  }).format(i18n) + myChannelSuffix;
};
function ChannelListGroupItemsInner({
  expandingKey,
  channelGroup,
  userCount,
  size,
  isLast,
  shouldUseShortDescription,
  trackingContentType,
  onChannelSelected
}: {
  expandingKey: string;
  channelGroup: ChannelGroupData;
  userCount: number;
  size: 'condensed' | 'default';
  isLast: boolean;
  shouldUseShortDescription: boolean;
  trackingContentType: string;
  onChannelSelected?: (channelName: string) => void;
}): React.ReactElement {
  const {
    joinChannel,
    joinChannelGroup
  } = useJoinCallbacks(channelGroup, trackingContentType);
  const track = useLogEvent();
  const {
    isExpanded,
    setExpanded
  } = React.useContext(ChannelGroupExpandedContext);
  const isGroupExpanded = isExpanded(expandingKey);
  const subline = useSubline(channelGroup, shouldUseShortDescription);
  return <>
			<ChannelListItem id={channelGroup.id} name={channelGroup.name} userCount={userCount} backgroundColor={channelGroup.info.backgroundColor} channelImage={channelGroup.info.previewImageInfo.url} blurredChannelImage={channelGroup.info.previewImageInfo.urlBlurred} hasSubChannels={channelGroup.channels.length > 1} previewMembers={channelGroup.previewMembers} expanded={isGroupExpanded} onEntryClick={id => {
      joinChannelGroup(id);
      onChannelSelected?.(channelGroup.name);
    }} onExpanderClick={() => {
      if (!isGroupExpanded) {
        track(trackingContentType, 'Channellist_SubChannelViewed');
      }
      setExpanded(expandingKey, !isGroupExpanded);
    }} subline={subline} type={size} isLast={isLast && !isGroupExpanded} hideImageBorder={channelGroup.info.isSpecialPreviewImage} />
			{isGroupExpanded && channelGroup.channels.map((channel, channelIndex, channelArray) => {
      return <ChannelListItem key={channel.id} id={channel.id} name={channel.name} userCount={channel.onlineUserCount} previewMembers={channel.previewMembers} backgroundColor={channelGroup.info.backgroundColor} channelImage={channelGroup.info.previewImageInfo.url} blurredChannelImage={channelGroup.info.previewImageInfo.urlBlurred} onEntryClick={id => {
        joinChannel(id);
        onChannelSelected?.(channel.name);
      }} indented type={'condensed'} isLast={isLast && channelIndex === channelArray.length - 1} hideImageBorder={channelGroup.info.isSpecialPreviewImage} />;
    })}
		</>;
}
export const ChannelListGroupItems = React.memo(ChannelListGroupItemsInner);
const useJoinCallbacks = (channelGroup: ChannelGroupData, trackingContentType: string) => {
  const channelService = useService($JoinChannelService);
  const getAdsService = useLazyService($AdsService);
  const updateLastVisitedChannels = useUpdateRecommendedChannels();
  const track = useLogEvent();
  const trackJoinChannel = React.useCallback(() => {
    track(trackingContentType, 'Channellist_ChannelEntered');
  }, [trackingContentType]);
  const joinChannel = React.useCallback(async channelId => {
    trackJoinChannel();
    await channelService.joinChannelById(channelId, 'ChannelList', {
      redirectToChannelOnSuccess: true,
      redirectToChannelListOnFail: true,
      mayJoinSubChannel: false
    });
    updateLastVisitedChannels();
    getAdsService().then(it => it.showInterstitial());
  }, [channelGroup]);
  const joinChannelGroup = React.useCallback(async groupId => {
    trackJoinChannel();
    await channelService.joinChannelByGroupId(groupId, channelGroup.name, 'ChannelList');
    updateLastVisitedChannels();
    getAdsService().then(it => it.showInterstitial());
  }, [channelGroup]);
  return {
    joinChannel,
    joinChannelGroup
  };
};