import { createGeneratorEasing as Q } from "../../easing/utils/create-generator-easing.js";
import { defaultOffset as R } from "../../utils/offsets/default.js";
import { fillOffset as U } from "../../utils/offsets/fill.js";
import { progress as W } from "../../utils/progress.js";
import { secondsToMilliseconds as X } from "../../utils/time-conversion.js";
import { isMotionValue as Y } from "../../value/utils/is-motion-value.js";
import { resolveSubjects as Z } from "../animate/resolve-subjects.js";
import { isGenerator as _ } from "../generators/utils/is-generator.js";
import { calcNextTime as q } from "./utils/calc-time.js";
import { addKeyframes as $ } from "./utils/edit.js";
import { compareByTime as ee } from "./utils/sort.js";
const te = "easeInOut";
function ye(e, { defaultTransition: t = {}, ...C } = {}, F, A) {
  const z = t.duration || 0.3, b = /* @__PURE__ */ new Map(), M = /* @__PURE__ */ new Map(), H = {}, S = /* @__PURE__ */ new Map();
  let j = 0, l = 0, v = 0;
  for (let p = 0; p < e.length; p++) {
    const i = e[p];
    if (typeof i == "string") {
      S.set(i, l);
      continue;
    } else if (!Array.isArray(i)) {
      S.set(i.name, q(l, i.at, j, S));
      continue;
    }
    let [m, r, o = {}] = i;
    o.at !== void 0 && (l = q(l, o.at, j, S));
    let s = 0;
    const h = (a, u, f, d = 0, y = 0) => {
      const n = ne(a), { delay: T = 0, times: g = R(n), type: x = "keyframes", ...J } = u;
      let { ease: D = t.ease || "easeOut", duration: c } = u;
      const O = typeof T == "function" ? T(d, y) : T, k = n.length, E = _(x) ? x : A == null ? void 0 : A[x];
      if (k <= 2 && E) {
        let G = 100;
        if (k === 2 && ie(n)) {
          const P = n[1] - n[0];
          G = Math.abs(P);
        }
        const L = { ...J };
        c !== void 0 && (L.duration = X(c));
        const N = Q(L, G, E);
        D = N.ease, c = N.duration;
      }
      c ?? (c = z);
      const K = l + O, V = K + c;
      g.length === 1 && g[0] === 0 && (g[1] = 1);
      const w = g.length - n.length;
      w > 0 && U(g, w), n.length === 1 && n.unshift(null), $(f, n, D, g, K, V), s = Math.max(O + c, s), v = Math.max(V, v);
    };
    if (Y(m)) {
      const a = I(m, M);
      h(r, o, B("default", a));
    } else {
      const a = Z(m, r, F, H), u = a.length;
      for (let f = 0; f < u; f++) {
        r = r, o = o;
        const d = a[f], y = I(d, M);
        for (const n in r)
          h(r[n], oe(o, n), B(n, y), f, u);
      }
    }
    j = l, l += s;
  }
  return M.forEach((p, i) => {
    for (const m in p) {
      const r = p[m];
      r.sort(ee);
      const o = [], s = [], h = [];
      for (let u = 0; u < r.length; u++) {
        const { at: f, value: d, easing: y } = r[u];
        o.push(d), s.push(W(0, v, f)), h.push(y || "easeOut");
      }
      s[0] !== 0 && (s.unshift(0), o.unshift(o[0]), h.unshift(te)), s[s.length - 1] !== 1 && (s.push(1), o.push(null)), b.has(i) || b.set(i, {
        keyframes: {},
        transition: {}
      });
      const a = b.get(i);
      a.keyframes[m] = o, a.transition[m] = {
        ...t,
        duration: v,
        ease: h,
        times: s,
        ...C
      };
    }
  }), b;
}
function I(e, t) {
  return !t.has(e) && t.set(e, {}), t.get(e);
}
function B(e, t) {
  return t[e] || (t[e] = []), t[e];
}
function ne(e) {
  return Array.isArray(e) ? e : [e];
}
function oe(e, t) {
  return e && e[t] ? {
    ...e,
    ...e[t]
  } : { ...e };
}
const se = (e) => typeof e == "number", ie = (e) => e.every(se);
export {
  ye as createAnimationsFromSequence,
  oe as getValueTransition
};
