import { K3ServiceBinder } from '@knuddels-app/DependencyInjection';
import * as ids from '../providedServices';
import * as s from './services';
import { foreachProp } from '@knuddels/std';

export function registerServices(ctx: K3ServiceBinder): void {
	foreachProp(ids, {
		$AppService: ctx.loggedInScope.toSingleton(s.AppService),
		$ModalAppViewer: ctx.loggedInScope.toSingleton(s.ModalAppViewer),
		$ChannelAppViewer: ctx.loggedInScope.toSingleton(s.ChannelAppViewer),
		$ChannelAppViewerAnimationService: ctx.loggedInScope.toSingleton(
			s.ChannelAppViewerAnimationService
		),
		$StartNativeClientEventHandler: ctx.loggedInScope.toSingleton(
			s.StartNativeClientEventHandler
		),
		$ChannelBackgroundAppViewer: ctx.loggedInScope.toSingleton(
			s.ChannelBackgroundAppViewer
		),
		$CustomPositionAppViewer: ctx.loggedInScope.toSingleton(
			s.CustomPositionAppViewer
		),
		$GlobalAppsAppViewer: ctx.loggedInScope.toSingleton(
			s.GlobalAppsAppViewer
		),
		$AppsDeeplinkHandler: ctx.loggedInScope.toSingletonAutostart(
			s.AppsDeeplinkHandler
		),
		$SystemAppStorageService: ctx.loggedInScope.toSingleton(
			s.SystemAppStorageService
		),
		$SystemAppPerformanceService: ctx.loggedInScope.toSingleton(
			s.SystemAppPerformanceService
		),
	});
}
