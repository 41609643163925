import { NotifyProfileVisited, User } from '@generated/graphql';
import { $AuthenticatedClientService } from '@knuddels-app/Connection';
import { inject, injectable } from '@knuddels-app/DependencyInjection';
import { $ViewService } from '@knuddels-app/layout';
import { $UserService } from '@knuddelsModules/UserData';
import { OwnProfileViewOpenedEvent, profileOpenedTracker, ProfileViewOpenedEvent, quickWhoisOpenedTracker } from '../../analytics';
import { profileViewId } from '../../ProfileViewProvider';
import { $OverlayService } from '@knuddels-app/overlays';
import { QuickWhoisModalOverlay } from '../components/QuickWhois/QuickWhoisModal';
import { VisibleOverlay } from '@knuddels-app/overlays/OverlayService';
import { $Environment } from '@knuddels-app/Environment';
import { TabType } from '../components/ProfileOverlay/ProfileTabsMapping';
import { ProfileContentRouterConfig } from '../components/ProfileOverlay/ProfileContent/ProfileContentRouter';
import { $GenericUserEventService } from '@knuddels-app/analytics/generic';
type ProfileOpenOptions = {
  openedFromTrackingLabel?: string;
  silent?: boolean;
  initialTab?: TabType;
  initialRoute?: keyof ProfileContentRouterConfig;
  showNickSwitchIcon?: boolean;
};
@injectable()
export class ProfileNavigationService {
  constructor(@inject($OverlayService)
  private readonly overlayService: typeof $OverlayService.T, @inject($AuthenticatedClientService)
  private readonly authenticatedClientService: typeof $AuthenticatedClientService.T, @inject($UserService)
  private readonly userService: typeof $UserService.T, @inject($ViewService)
  private readonly viewService: typeof $ViewService.T, @inject($Environment)
  private readonly environment: typeof $Environment.T, @inject($GenericUserEventService)
  private readonly genericUserEventService: typeof $GenericUserEventService.T) {}
  public readonly showAuthenticityInfoForNick = (nick: string) => {
    if (this.environment.messengerSystemAppInterface) {
      this.environment.messengerSystemAppInterface.executeAuthenticityInfoSlashCommand(nick);
    } else {
      return this.userService.getUserIdFromNick(nick).then(userId => {
        if (userId) {
          this.showProfileForUser({
            id: userId,
            nick
          }, {
            initialRoute: 'authenticityInfoOther'
          });
        }
      });
    }
  };
  public readonly showAuthenticityForUser = (user: Pick<User, 'id' | 'nick'>, options: Pick<ProfileOpenOptions, 'openedFromTrackingLabel'> = {}) => {
    if (this.environment.messengerSystemAppInterface) {
      this.environment.messengerSystemAppInterface.executeAuthenticityInfoSlashCommand(user.nick);
    } else {
      this.showProfileForUser(user, {
        ...options,
        initialRoute: 'authenticityInfoOther'
      });
    }
  };
  public readonly showAuthenticityOverview = () => {
    const user = this.userService.currentUser;
    if (!user) {
      return;
    }
    if (this.environment.messengerSystemAppInterface) {
      this.environment.messengerSystemAppInterface.executeAuthenticityInfoSlashCommand(user.nick);
    } else {
      this.showProfileForUser(user, {
        initialRoute: 'authenticityOverview'
      });
    }
  };
  public readonly showProfileForNick = (nick: string, options: ProfileOpenOptions = {}) => {
    if (this.environment.messengerSystemAppInterface) {
      this.environment.messengerSystemAppInterface.executeOpenProfileSlashCommand(nick);
    } else {
      return this.userService.getUserIdFromNick(nick, true).then(userId => {
        this.showProfileForUser({
          id: userId,
          nick
        }, options);
      });
    }
  };

  /**
   * Handles Stapp Messenger environment where it opens the stapp profile.
   * Otherwise calls `showProfile`
   */
  public readonly showProfileForUser = (user: Pick<User, 'id' | 'nick'>, options: ProfileOpenOptions = {}) => {
    if (this.environment.messengerSystemAppInterface) {
      this.environment.messengerSystemAppInterface.executeOpenProfileSlashCommand(user.nick);
    } else {
      this.showProfileIntern(user.id, options);
    }
  };
  public readonly showCurrentUserProfile = (options: ProfileOpenOptions = {}): void => {
    const user = this.userService.currentUser;
    if (user) {
      this.showProfileForUser(user, options);
    }
  };

  // Use `showProfileFromUser` to automatically to correctly handle stapp messenger environment.
  public readonly showProfile = (userId: User['id'], openedFromTrackingLabel?: string, silent?: boolean, showNickSwitchIcon?: boolean): void => {
    this.showProfileIntern(userId, {
      openedFromTrackingLabel,
      silent,
      showNickSwitchIcon
    });
  };
  private readonly showProfileIntern = (userId: User['id'], options: ProfileOpenOptions): void => {
    profileOpenedTracker.start();
    if (userId === this.userService.currentUser?.id) {
      this.genericUserEventService.reportEvent({
        type: 'Opened_Own_Profile',
        source: options.openedFromTrackingLabel
      });
    } else {
      this.genericUserEventService.reportEvent({
        type: 'Opened_Profile',
        userId,
        source: options.openedFromTrackingLabel
      });
    }
    if (!options.silent) {
      this.authenticatedClientService.currentK3Client.mutate(NotifyProfileVisited, {
        userId
      });
    }
    if (options.openedFromTrackingLabel) {
      const currentUser = this.userService.currentUser;
      if (currentUser) {
        if (currentUser.id === userId) {
          OwnProfileViewOpenedEvent.track('Opened_From' + options.openedFromTrackingLabel);
        } else {
          ProfileViewOpenedEvent.track('Opened_From' + options.openedFromTrackingLabel);
        }
      }
    }
    this.viewService.openView(profileViewId.with(s => s.showProfile(userId, options.initialTab, options.initialRoute, options.showNickSwitchIcon)), {
      locationUpdateMode: 'push',
      closeOtherOverlays: true
    });
  };
  public readonly showEditProfile = (): void => {
    this.viewService.openView(profileViewId.with(s => s.editProfile()), {
      locationUpdateMode: 'push',
      closeOtherOverlays: true
    });
  };
  public readonly showQuickWhois = (userId: User['id'], x: number, y: number, w: number, h: number): void => {
    quickWhoisOpenedTracker.start();
    this.overlayService.showOverlay(QuickWhoisModalOverlay.create({
      x,
      y,
      w,
      h,
      userId
    }));
  };
  public findQuickWhoisOverlay(userId?: User['id']): VisibleOverlay<{
    data: {
      userId: string;
    };
  }> | undefined {
    return this.overlayService.findOverlay(QuickWhoisModalOverlay.getFilter(t => !userId || t.userId === userId));
  }
}