import { calcGeneratorDuration as i, maxGeneratorDuration as m } from "../../animation/generators/utils/calc-duration.js";
import { millisecondsToSeconds as s } from "../../utils/time-conversion.js";
function f(t, e = 100, n) {
  const r = n({ ...t, keyframes: [0, e] }), o = Math.min(i(r), m);
  return {
    type: "keyframes",
    ease: (a) => r.next(o * a).value / e,
    duration: s(o)
  };
}
export {
  f as createGeneratorEasing
};
