import * as React from 'react';
import { AlbumPhotoCommentFragment } from '@generated/graphql';
import { $UserService, UserImage, UserImageType } from '@knuddelsModules/UserData';
import { getMessageDayText } from '@knuddels-app/tools/getMessageDayText';
import { ContextMenuTrigger, FormattedText, PreviewFormattedTextDisplay, Spacer, Swipeable, SwipeAction } from '@shared/components';
import { Box, createAccessiblePointerEventHandler, Flex, FlexCol, IconTrash, Text, useTheme, createNativeAccessiblePointerEventHandler, resolveThemingValue, resolveIsDarkColor } from '@knuddels/component-library';
import { useService } from '@knuddels-app/DependencyInjection';
import { $OverlayService } from '@knuddels-app/overlays';
import { PhotoCommentContextMenuOverlay } from './PhotoCommentContextMenu';
import { observer } from '@knuddels-app/mobx';
import { SelectedAlbumPhotoContext } from '../AlbumDetailsUserContext';
import { $ProfileNavigationService } from '../../../../providedServices';
import { $I18n, declareFormat } from '@knuddels-app/i18n';
import { IconReport } from '@shared/icons/IconReport';
import { useReportComment } from './useReportComment';
import { useRemoveComment } from './useRemoveComment';
export const PhotoComment: React.FC<{
  comment: AlbumPhotoCommentFragment;
  showDivider: boolean;
  removeCommentFromCache: () => void;
}> = observer('PhotoComment', ({
  comment,
  showDivider,
  removeCommentFromCache
}) => {
  const {
    photoId,
    isOwner,
    administrationUrl
  } = React.useContext(SelectedAlbumPhotoContext);
  const overlayService = useService($OverlayService);
  const userService = useService($UserService);
  const isOwnComment = comment.sender.id === userService.currentUser?.id;
  const openContextMenu = (x: number, y: number, width: number, height: number) => {
    overlayService.showOverlayIfNotVisible(PhotoCommentContextMenuOverlay, {
      photoId,
      isOwner,
      isOwnComment,
      canAdministrate: !!administrationUrl,
      commentId: comment.id,
      chevronX: x,
      chevronY: y,
      chevronWidth: width,
      chevronHeight: height,
      removeCommentFromCache
    });
  };
  const isContextMenuOpen = !!overlayService.findOverlay(PhotoCommentContextMenuOverlay.getFilter(t => t.commentId === comment.id && t.photoId === photoId));
  const colors = useTheme().colors.basic;
  const i18n = useService($I18n);
  const reportComment = useReportComment();
  const removeComment = useRemoveComment(removeCommentFromCache);
  const removeAction: SwipeAction = {
    component: () => <ActionContent Icon={IconTrash} text={declareFormat({
      id: 'album.details.comments.swipe.remove',
      defaultFormat: 'Remove'
    }).format(i18n)} />,
    backgroundColor: colors['red-500'],
    onAction: async cb => {
      const success = await removeComment(photoId, comment.id);
      cb(!success);
    }
  };
  const reportAction: SwipeAction = {
    component: () => <ActionContent Icon={IconReport} text={declareFormat({
      id: 'album.details.comments.swipe.report',
      defaultFormat: 'Report'
    }).format(i18n)} />,
    backgroundColor: colors['orange-500'],
    onAction: async cb => {
      const success = await reportComment(photoId, comment.id);
      cb(!success);
    }
  };
  const primaryAction = isOwnComment ? removeAction : administrationUrl ? reportAction : isOwner ? removeAction : reportAction;
  const secondaryAction = isOwnComment ? undefined : administrationUrl ? removeAction : isOwner ? reportAction : undefined;
  return <ContextMenuTrigger chevronBackgroundColor={'transparent'} position={'bottom'} showContextMenu={openContextMenu} isMenuActive={isContextMenuOpen}>
				<Swipeable swipeId={comment.id} threshold={200} rightActionPrimary={primaryAction} rightActionSecondary={secondaryAction}>
					<Content comment={comment} showDivider={showDivider} />
				</Swipeable>
			</ContextMenuTrigger>;
});
const ActionContent: React.FC<{
  Icon: React.ComponentType<React.ComponentProps<typeof IconTrash>>;
  text: string;
}> = ({
  Icon,
  text
}) => {
  return <>
			<Icon size={'large'} active />
			<Text type={'tiny'} bold={true} className={_c0}>
				{text}
			</Text>
		</>;
};
const Content: React.FC<{
  comment: AlbumPhotoCommentFragment;
  showDivider: boolean;
}> = ({
  comment,
  showDivider
}) => {
  const openProfile = useOpenProfile(comment.sender.id);
  const normalizedSender = useHandleDeletedUser(comment.sender);
  return <div onPointerDown={() => {
    /* this is required for scrolling on ipads. otherwise no touch events are processed */
  }} className={_c1 + ("contentBg" ? resolveIsDarkColor("contentBg", useTheme()) ? " content-is-dark" : " content-is-light" : "")}>
			{showDivider && <div className={_c2 + ("hairlineAtom" ? resolveIsDarkColor("hairlineAtom", useTheme()) ? " content-is-dark" : " content-is-light" : "")} />}
			<div className={_c3}>
				<div {...createNativeAccessiblePointerEventHandler({
        pointerCallback: openProfile,
        disabled: !normalizedSender.isValid
      })} className={_c4}>
					<UserImage type={UserImageType.NAV_SMALL} userId={comment.sender.id} />
				</div>
				<div className={_c5}>
					<div className={_c6}>
						<Text type={'body2'} state={'secondary'} bold={true} italic={!normalizedSender.isValid} onPress={normalizedSender.isValid ? openProfile : undefined} className={_c7}>
							{normalizedSender.nick}
						</Text>
						<Text type={'tiny'} state={'tertiary'} className={_c8}>
							{getMessageDayText(+comment.timestamp, {
              showTodayMoreSpecific: true
            })}
						</Text>
					</div>
					<Spacer size={'tiny'} />
					<PreviewFormattedTextDisplay textProps={{
          type: 'body2',
          state: 'primary'
        }} text={FormattedText.fromJsonString(comment.text)} />
				</div>
			</div>
		</div>;
};
const useOpenProfile = (userId: string) => {
  const profileNavigationService = useService($ProfileNavigationService);
  return () => {
    profileNavigationService.showProfile(userId, 'PhotoComment');
  };
};
const useHandleDeletedUser = (rawUserData: AlbumPhotoCommentFragment['sender']): AlbumPhotoCommentFragment['sender'] & {
  isValid: boolean;
} => {
  const i18n = useService($I18n);
  const isValid = !!rawUserData.nick;
  return {
    ...rawUserData,
    nick: rawUserData.nick || i18n.format(declareFormat({
      id: 'album.details.comment.deleted-user',
      defaultFormat: 'Deleted member'
    })),
    isValid
  };
};
const _c0 = " mt-tiny ";
const _c1 = " Knu-FlexCol bg-contentBg ";
const _c2 = " Knu-Flex mx-small height-1px bg-hairlineAtom ";
const _c3 = " Knu-Flex px-small py-base ";
const _c4 = " Knu-Box cursor-pointer ";
const _c5 = " Knu-FlexCol ml-minor mr-small flex-1 ";
const _c6 = " Knu-Flex justifyContent-space-between alignItems-flex-end alignSelf-stretch ";
const _c7 = "  ";
const _c8 = "  ";