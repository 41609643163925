import { buildTransform as p } from "./build-transform.js";
import { isCSSVariableName as u } from "../../dom/utils/is-css-variable.js";
import { transformProps as c } from "./transform.js";
import { getValueAsType as g } from "../../dom/value-types/get-as-type.js";
import { numberValueTypes as y } from "../../dom/value-types/number.js";
function S(f, i, e) {
  const { style: o, vars: l, transformOrigin: t } = f;
  let m = !1, a = !1;
  for (const r in i) {
    const n = i[r];
    if (c.has(r)) {
      m = !0;
      continue;
    } else if (u(r)) {
      l[r] = n;
      continue;
    } else {
      const s = g(n, y[r]);
      r.startsWith("origin") ? (a = !0, t[r] = s) : o[r] = s;
    }
  }
  if (i.transform || (m || e ? o.transform = p(i, f.transform, e) : o.transform && (o.transform = "none")), a) {
    const { originX: r = "50%", originY: n = "50%", originZ: s = 0 } = t;
    o.transformOrigin = `${r} ${n} ${s}`;
  }
}
export {
  S as buildHTMLStyles
};
