import * as React from 'react';
import { IModel, inject, injectable, injectedComponent, injectProps } from '@knuddels-app/DependencyInjection';
import { OwnProfileViewFABEvent } from '@knuddelsModules/Profile/analytics';
import { $ProfileNavigationService } from '@knuddelsModules/Profile/providedServices';
import { Fab, IconEdit } from '@knuddels/component-library';
import { $GenericUserEventService } from '@knuddels-app/analytics/generic';
@injectable()
class OpenEditProfileButtonModel implements IModel {
  constructor(@injectProps()
  private readonly props: unknown, @inject($ProfileNavigationService)
  private readonly profileNavigationService: typeof $ProfileNavigationService.T, @inject($GenericUserEventService)
  private readonly genericUserEventService: typeof $GenericUserEventService.T) {}
  public readonly openEditProfile = (): void => {
    OwnProfileViewFABEvent.track('FAB_ProfileEditClicked');
    this.genericUserEventService.reportEvent({
      type: 'Clicked_ProfileEdit'
    });
    this.profileNavigationService.showEditProfile();
  };
  componentDidMount(): void {}
}
export const OpenEditProfileButton = injectedComponent({
  name: 'OpenEditProfileButton',
  model: OpenEditProfileButtonModel
}, ({
  model
}) => {
  return <Fab onPress={model.openEditProfile}>
				<Fab.Icon icon={IconEdit} />
			</Fab>;
});