import { anticipate as F } from "../../easing/anticipate.js";
import { backInOut as K } from "../../easing/back.js";
import { circInOut as E } from "../../easing/circ.js";
import { DOMKeyframesResolver as R } from "../../render/dom/DOMKeyframesResolver.js";
import { noop as g } from "../../utils/noop.js";
import { millisecondsToSeconds as T, secondsToMilliseconds as S } from "../../utils/time-conversion.js";
import { isGenerator as b } from "../generators/utils/is-generator.js";
import { BaseAnimation as D } from "./BaseAnimation.js";
import { MainThreadAnimation as A } from "./MainThreadAnimation.js";
import { acceleratedValues as M } from "./utils/accelerated-values.js";
import { startWaapiAnimation as U } from "./waapi/index.js";
import { isWaapiSupportedEasing as W } from "./waapi/easing.js";
import { attachTimeline as w } from "./waapi/utils/attach-timeline.js";
import { getFinalKeyframe as x } from "./waapi/utils/get-final-keyframe.js";
import { supportsLinearEasing as C } from "./waapi/utils/supports-linear-easing.js";
import { supportsWaapi as G } from "./waapi/utils/supports-waapi.js";
const d = 10, O = 2e4;
function k(r) {
  return b(r.type) || r.type === "spring" || !W(r.ease);
}
function I(r, e) {
  const t = new A({
    ...e,
    keyframes: r,
    repeat: 0,
    delay: 0,
    isGenerator: !0
  });
  let i = { done: !1, value: r[0] };
  const s = [];
  let n = 0;
  for (; !i.done && n < O; )
    i = t.sample(n), s.push(i.value), n += d;
  return {
    times: void 0,
    keyframes: s,
    duration: n - d,
    ease: "linear"
  };
}
const P = {
  anticipate: F,
  backInOut: K,
  circInOut: E
};
function L(r) {
  return r in P;
}
class ne extends D {
  constructor(e) {
    super(e);
    const { name: t, motionValue: i, element: s, keyframes: n } = this.options;
    this.resolver = new R(n, (o, a) => this.onKeyframesResolved(o, a), t, i, s), this.resolver.scheduleResolve();
  }
  initPlayback(e, t) {
    var i;
    let { duration: s = 300, times: n, ease: o, type: a, motionValue: m, name: h, startTime: p } = this.options;
    if (!(!((i = m.owner) === null || i === void 0) && i.current))
      return !1;
    if (typeof o == "string" && C() && L(o) && (o = P[o]), k(this.options)) {
      const { onComplete: c, onUpdate: y, motionValue: f, element: v, ...V } = this.options, u = I(e, V);
      e = u.keyframes, e.length === 1 && (e[1] = e[0]), s = u.duration, n = u.times, o = u.ease, a = "keyframes";
    }
    const l = U(m.owner.current, h, e, { ...this.options, duration: s, times: n, ease: o });
    return l.startTime = p ?? this.calcStartTime(), this.pendingTimeline ? (w(l, this.pendingTimeline), this.pendingTimeline = void 0) : l.onfinish = () => {
      const { onComplete: c } = this.options;
      m.set(x(e, this.options, t)), c && c(), this.cancel(), this.resolveFinishedPromise();
    }, {
      animation: l,
      duration: s,
      times: n,
      type: a,
      ease: o,
      keyframes: e
    };
  }
  get duration() {
    const { resolved: e } = this;
    if (!e)
      return 0;
    const { duration: t } = e;
    return T(t);
  }
  get time() {
    const { resolved: e } = this;
    if (!e)
      return 0;
    const { animation: t } = e;
    return T(t.currentTime || 0);
  }
  set time(e) {
    const { resolved: t } = this;
    if (!t)
      return;
    const { animation: i } = t;
    i.currentTime = S(e);
  }
  get speed() {
    const { resolved: e } = this;
    if (!e)
      return 1;
    const { animation: t } = e;
    return t.playbackRate;
  }
  set speed(e) {
    const { resolved: t } = this;
    if (!t)
      return;
    const { animation: i } = t;
    i.playbackRate = e;
  }
  get state() {
    const { resolved: e } = this;
    if (!e)
      return "idle";
    const { animation: t } = e;
    return t.playState;
  }
  get startTime() {
    const { resolved: e } = this;
    if (!e)
      return null;
    const { animation: t } = e;
    return t.startTime;
  }
  /**
   * Replace the default DocumentTimeline with another AnimationTimeline.
   * Currently used for scroll animations.
   */
  attachTimeline(e) {
    if (!this._resolved)
      this.pendingTimeline = e;
    else {
      const { resolved: t } = this;
      if (!t)
        return g;
      const { animation: i } = t;
      w(i, e);
    }
    return g;
  }
  play() {
    if (this.isStopped)
      return;
    const { resolved: e } = this;
    if (!e)
      return;
    const { animation: t } = e;
    t.playState === "finished" && this.updateFinishedPromise(), t.play();
  }
  pause() {
    const { resolved: e } = this;
    if (!e)
      return;
    const { animation: t } = e;
    t.pause();
  }
  stop() {
    if (this.resolver.cancel(), this.isStopped = !0, this.state === "idle")
      return;
    this.resolveFinishedPromise(), this.updateFinishedPromise();
    const { resolved: e } = this;
    if (!e)
      return;
    const { animation: t, keyframes: i, duration: s, type: n, ease: o, times: a } = e;
    if (t.playState === "idle" || t.playState === "finished")
      return;
    if (this.time) {
      const { motionValue: h, onUpdate: p, onComplete: l, element: c, ...y } = this.options, f = new A({
        ...y,
        keyframes: i,
        duration: s,
        type: n,
        ease: o,
        times: a,
        isGenerator: !0
      }), v = S(this.time);
      h.setWithVelocity(f.sample(v - d).value, f.sample(v).value, d);
    }
    const { onStop: m } = this.options;
    m && m(), this.cancel();
  }
  complete() {
    const { resolved: e } = this;
    e && e.animation.finish();
  }
  cancel() {
    const { resolved: e } = this;
    e && e.animation.cancel();
  }
  static supports(e) {
    const { motionValue: t, name: i, repeatDelay: s, repeatType: n, damping: o, type: a } = e;
    return G() && i && M.has(i) && t && t.owner && t.owner.current instanceof HTMLElement && /**
     * If we're outputting values to onUpdate then we can't use WAAPI as there's
     * no way to read the value from WAAPI every frame.
     */
    !t.owner.getProps().onUpdate && !s && n !== "mirror" && o !== 0 && a !== "inertia";
  }
}
export {
  ne as AcceleratedAnimation
};
