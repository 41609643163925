import * as React from 'react';
import { useEffect, useState } from 'react';
import { FlexCol, SwitchListItem, ListGroup } from '@knuddels/component-library';
import { observer } from '@knuddels-app/mobx';
import { useService } from '@knuddels-app/DependencyInjection';
import { $FirebaseAnalyticsService } from '@knuddels-app/analytics/firebase';
import { $MentorService } from '@knuddelsModules/MentorSystem';
import { $SnackbarService } from '@knuddels-app/SnackbarManager';
import { $I18n, declareFormat } from '@knuddels-app/i18n';
import { MentorPauseState, UpdateMentorPauseState } from '@generated/graphql';
import { Spacer } from '@shared/components';
import { useMutation } from '@knuddels-app/Connection';
import { $GenericUserEventService } from '@knuddels-app/analytics/generic';
export const MentorSystemSettings: React.FC = observer('MentorSystemSettings', () => {
  const {
    pauseState,
    updatePauseState
  } = usePauseState();
  const genericUserEventService = useService($GenericUserEventService);
  const i18n = useService($I18n);
  const isPauseTodayActivated = pauseState === MentorPauseState.PausedForToday;
  const isPauseTwoWeeksActivated = pauseState === MentorPauseState.PausedForFourteenDays;
  return <div className={_c0}>
				<ListGroup title={declareFormat({
      id: 'settings.mentorSystemSettings.settingsGroup.title',
      defaultFormat: 'Mentor system'
    }).format(i18n)}>
					<SwitchListItem caption={declareFormat({
        id: 'settings.mentorSystemSettings.settingsGroup.pause-today',
        defaultFormat: 'Pause for today'
      }).format(i18n)} checked={isPauseTodayActivated} onChange={checked => {
        genericUserEventService.reportEvent({
          type: 'Settings_Changed',
          field: 'MentorPauseToday'
        });
        updatePauseState(checked, MentorPauseState.PausedForToday);
      }} />
					<SwitchListItem caption={declareFormat({
        id: 'settings.mentorSystemSettings.settingsGroup.pause-two-weeks',
        defaultFormat: 'Pause for 14 days'
      }).format(i18n)} checked={isPauseTwoWeeksActivated} onChange={checked => {
        genericUserEventService.reportEvent({
          type: 'Settings_Changed',
          field: 'MentorPauseTwoWeeks'
        });
        updatePauseState(checked, MentorPauseState.PausedForFourteenDays);
      }} separator={'none'} />
				</ListGroup>
				<Spacer size={'xxxlarge'} />
			</div>;
});
const usePauseState = () => {
  const [update] = useMutation(UpdateMentorPauseState);
  const activeStatus = useMentorSystemStatusActive();
  const snackbarService = useService($SnackbarService);
  const firebaseAnalyticsService = useService($FirebaseAnalyticsService);
  useEffect(() => {
    if (activeStatus) {
      setPauseState(activeStatus.pauseState);
    }
  }, [activeStatus.pauseState]);
  const [pauseState, setPauseState] = useState<MentorPauseState>(activeStatus.pauseState);
  const updatePauseState = async (checked: boolean, state: MentorPauseState) => {
    const oldState = pauseState;
    const newState = checked ? state : MentorPauseState.NotPaused;
    setPauseState(newState);
    await update({
      state: newState
    }).then(res => {
      if (res.error || res.data.__typename === 'InternalError') {
        handleError(oldState);
      }
      firebaseAnalyticsService.logEvent('Settings_MentorState', checked ? `${state}_Activated` : `${state}_Deactivated`);
    }).catch(() => {
      handleError(oldState);
    });
  };
  const handleError = (revertState: MentorPauseState) => {
    setPauseState(revertState);
    snackbarService.showGenericError();
  };
  return {
    pauseState,
    updatePauseState
  };
};
export const useMentorSystemStatusActive = () => {
  const mentorService = useService($MentorService);
  return mentorService.mentorStatus?.__typename === 'MentorStatusActive' ? mentorService.mentorStatus : undefined;
};
const _c0 = " Knu-FlexCol height-100-percent ";