import * as React from 'react';
import { GetCurrentUserNickProps, MenteeStatus, withGetCurrentUserNick } from '@generated/graphql';
import { inject, injectable, injectProps, useService } from '@knuddels-app/DependencyInjection';
import { injectedComponent } from '@knuddels-app/DependencyInjection/injectedComponent';
import { declareFormat, formatMessage, FormattedMessage } from '@knuddels-app/i18n';
import { observer } from '@knuddels-app/mobx';
import { Flex, rgb, Text, TextProps } from '@knuddels/component-library';
import { messageEvent } from '@knuddelsModules/Channel/analytics';
import { Spacer } from '@shared/components';
import { PRIVATE_MESSAGE_TITLE, PRIVATE_MESSAGE_TITLE_FROM, TO, YOU } from '../../../../../i18n/shared-formats';
import { ClickableUserText } from '../../ClickableUser';
import { AnswerButtonWrapper } from '../AnswerButtonWrapper';
import { GenericMessage } from '../GenericMessage';
import { MessengerMessageContent } from './MessengerMessageContent';
import { MessengerMessageProps } from './MessengerMessageProps';
import { SheepIcon } from '@shared/icons/Sheep';
import { $ActiveChannelService, $JoinChannelService } from '@knuddelsModules/Channel';
import { HighlightTextLink } from '@shared/components/atoms/Texts/HighlightTextLink';
import { useHandleAnswerButton } from '../../ChatItems/useHandleAnswerButton';
@injectable()
class MessengerMessageModel {
  constructor(@injectProps()
  public readonly props: MessengerMessageProps & GetCurrentUserNickProps, @inject($ActiveChannelService)
  private readonly activeChannelService: typeof $ActiveChannelService.T) {}
  public getName = (isPrivateSent: boolean): string => {
    const {
      participants,
      sender
    } = this.props;
    if (isPrivateSent) {
      return participants.map(r => r.nick).join(', ');
    }
    return sender.nick;
  };
  public readonly handleClick = (): void => {
    messageEvent.track('Message_NicknameClicked');
  };
  public readonly activeUserId = (): string | undefined => {
    return this.props.data && this.props.data.user && this.props.data.user.currentUser.id;
  };
  public readonly activeUserNick = (): string | undefined => {
    return this.props.data && this.props.data.user && this.props.data.user.currentUser.nick;
  };
  public get receiverId(): string {
    // default doesn't matter because nothing is rendered
    // until activeUserId is available
    const activeUserId = this.activeUserId() ?? '';
    return activeUserId === this.props.sender.id ? this.props.participants[0]?.id ?? activeUserId : activeUserId;
  }
  public get receiverNick(): string {
    // default doesn't matter because nothing is rendered
    // until activeUserId is available
    const activeUserId = this.activeUserId() ?? '';
    return activeUserId === this.props.sender.id ? this.props.participants[0]?.nick ?? this.activeUserNick() : this.activeUserNick();
  }
  public readonly getHighlightedLinkColor = (): string | undefined => {
    return undefined;
  };
  public readonly currentUserChannel = (): string | undefined => {
    return this.activeChannelService.activeChannel?.name;
  };
}
export const MessengerMessageBase = injectedComponent({
  name: 'MessengerMessageBase',
  model: MessengerMessageModel
}, ({
  model,
  overrideTitleStyles
}) => {
  const activeUserId = model.activeUserId();
  const {
    isFirst,
    sender,
    message,
    renderTime = true
  } = model.props;
  const handleAnswerButton = useHandleAnswerButton([sender]);
  if (!activeUserId) {
    return null;
  }
  const content = <GenericMessage title={isFirst && <MessageTitle overrideTitleStyles={overrideTitleStyles} model={model} />} content={<MessengerMessageContent renderTime={renderTime} activeUserId={activeUserId} receiverId={model.receiverId} receiverNick={model.receiverNick} sender={message.sender} message={message} getHighlightedLinkColor={model.getHighlightedLinkColor} />} titleColor={rgb(255, 255, 255, 0.77)} />;
  return <>
				{model.props.hasAnswerButton ? <AnswerButtonWrapper onButtonPress={handleAnswerButton} color={model.props.buttonColor}>
						{content}
					</AnswerButtonWrapper> : content}
			</>;
});
export const MessengerMessage = withGetCurrentUserNick<MessengerMessageProps>({
  options: {
    fetchPolicy: 'cache-first'
  }
})(MessengerMessageBase);
const MessageTitle = observer('MessageTitle', ({
  model,
  overrideTitleStyles
}: typeof MessengerMessageBase.TPropsWithModel & {
  overrideTitleStyles?: {
    color?: TextProps['color'];
  };
}): JSX.Element => {
  const activeUserId = model.activeUserId();
  const {
    sender,
    participants
  } = model.props;
  const isPrivateSent = activeUserId === sender.id;
  const isSelfAddressedMessage = participants.length === 0;
  const otherParticipant = isSelfAddressedMessage ? activeUserId : participants.map(n => n.id)[0];
  const name = model.getName(isPrivateSent);
  return <div className={_c0}>
				{isPrivateSent && <Text state={'secondary'} bold={true} className={_c1}>
						{formatMessage(YOU)}
						{!isSelfAddressedMessage && <Text bold={true} state={'secondary'} className={_c2}>
								{` ${formatMessage(TO)} `}
							</Text>}
					</Text>}
				<ClickableUserText openedFromTrackingLabel="MessageTitle" overrideStyles={overrideTitleStyles} uid={otherParticipant} onClick={model.handleClick}>
					{isSelfAddressedMessage ? formatMessage(declareFormat({
        id: 'PRIVATE_SELF_ADDRESSED_MESSAGE_TITLE',
        defaultFormat: '{name} (private to you)'
      }), {
        name
      }) : formatMessage(PRIVATE_MESSAGE_TITLE, {
        name
      })}
				</ClickableUserText>
				{sender.menteeStatus === MenteeStatus.Mentee && <>
						<Spacer size={'small'} />
						<SheepIcon />
					</>}
				{sender.id === otherParticipant && sender.currentOnlineChannelName && sender.currentOnlineChannelName !== model.currentUserChannel() && <>
							<Text state={'secondary'} bold={true} className={_c3}>
								<FormattedMessage id={PRIVATE_MESSAGE_TITLE_FROM} />
							</Text>
							<ClickableChannel channel={sender.currentOnlineChannelName} />
						</>}
			</div>;
});
const ClickableChannel: React.FC<{
  channel: string;
}> = ({
  channel
}) => {
  const joinChannelService = useService($JoinChannelService);
  if (channel === '?') {
    return <Text state={'secondary'} bold={true} className={_c4}>
				{channel}
			</Text>;
  }
  const joinChannel = () => {
    joinChannelService.joinChannelByName(channel, 'MessengerMessage');
  };
  return <HighlightTextLink bold type={'body1'} state={'secondary'} hoverState={'primary'} onPress={joinChannel}>
			{channel}
		</HighlightTextLink>;
};
const _c0 = " Knu-Flex alignItems-center flexWrap-wrap ";
const _c1 = "  ";
const _c2 = "  ";
const _c3 = "  ";
const _c4 = "  ";