import * as React from 'react';
import { FormattedText, GenericModal, Spacer } from '@shared/components';
import { FormattedTextDisplayWithCommandHandling } from '@knuddels-app/Commands';
import { CHANNEL_JOIN_ERROR_COMMAND_CONTEXT } from '@shared/constants';
import { declareFormat } from '@knuddels/i18n';
import { useService } from '@knuddels-app/DependencyInjection';
import { $I18n } from '@knuddels-app/i18n';
import { useCloseThisOverlay } from '@knuddels-app/overlays';
export const ChannelJoinErrorModal: React.FC<{
  headline: string;
  redirectToChannelList: boolean;
  text: FormattedText;
}> = ({
  headline,
  redirectToChannelList,
  text
}) => {
  const i18n = useService($I18n);
  const closeOverlay = useCloseThisOverlay();
  return <GenericModal headline={headline} primaryButton={{
    width: 195,
    text: i18n.format(redirectToChannelList ? declareFormat({
      id: 'CANNOT_JOIN_BUTTON_CHANNELLIST',
      defaultFormat: 'Choose another channel'
    }) : declareFormat({
      id: 'CANNOT_JOIN_BUTTON',
      defaultFormat: 'Okay'
    })),
    onClick: closeOverlay
  }} cancelModal={closeOverlay}>
			<FormattedTextDisplayWithCommandHandling textProps={{
      type: 'body1'
    }} text={text} onCommandExecuted={() => {
      closeOverlay();
    }} commandContext={CHANNEL_JOIN_ERROR_COMMAND_CONTEXT} />
			<Spacer size="xlarge" />
		</GenericModal>;
};