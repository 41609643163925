import { K3ServiceBinder } from '@knuddels-app/DependencyInjection';
import {
	$GenericUserEventService,
	GenericUserEventService,
} from './GenericUserEventService';
import { $MixPanelService, MixPanelService } from './MixPanelService';

export function registerServices(ctx: K3ServiceBinder): void {
	ctx.globalScope
		.bind($GenericUserEventService)
		.toSingletonAutostart(GenericUserEventService);

	ctx.globalScope
		.bind($MixPanelService)
		.toSingletonAutostart(MixPanelService);
}
