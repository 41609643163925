import { inject, injectable } from '@knuddels-app/DependencyInjection';
import { action, computed, observable, runInAction } from '@knuddels-app/mobx';
import { $LocalStorage, LocalStorageKey } from '@knuddels-app/local-storage';
import {
	$FirebaseAnalyticsService,
	$K3Firebase,
} from '@knuddels-app/analytics/firebase';
import { Disposable } from '@knuddels/std';
import { $FeatureService } from '@knuddels-app/featureFlags';
import { $GenericUserEventService } from '@knuddels-app/analytics/generic';

export const RenderModes = [
	'modern',
	'modernWithoutBubbles',
	'condensed',
] as const;
export type RenderMode = (typeof RenderModes)[number];

export const FontSizes = {
	smallest: 12,
	small: 14,
	normal: 16,
	large: 18,
	largest: 20,
};
export type FontSize = keyof typeof FontSizes;

const layoutSettingsKey = LocalStorageKey.withJsonSerializer<{
	size: FontSize;
	mode: RenderMode;
	showChannelBackground: boolean;
}>({
	name: 'layoutSettings',
	cookieExpires: { inDays: Number.MAX_SAFE_INTEGER },
});

const LINE_HEIGHT_FACTOR = 1.3;
const SPACING_DIVIDER = 2;

const AnalyticsContentType = 'DisplayOptions_Channel';

@injectable()
export class MessageLayoutSettingsService implements Disposable {
	public readonly dispose = Disposable.fn();

	@computed
	get fontSizePx(): number {
		return FontSizes[this.fontSize];
	}

	get messageSpacingPx(): number {
		if (this.renderMode === 'modernWithoutBubbles') {
			return this.fontSizePx;
		}
		return this.fontSizePx / SPACING_DIVIDER;
	}
	@computed
	get lineHeightPx(): number {
		return this.fontSizePx * LINE_HEIGHT_FACTOR;
	}
	@observable
	public renderMode: RenderMode;

	@observable
	public fontSize: FontSize = 'normal';

	@observable
	public showChannelBackground = true;

	constructor(
		@inject($LocalStorage)
		private readonly localStorage: typeof $LocalStorage.T,
		@inject($FirebaseAnalyticsService)
		private readonly firebaseAnalyticsService: typeof $FirebaseAnalyticsService.T,
		@inject($K3Firebase)
		private readonly k3Firebase: typeof $K3Firebase.T,
		@inject($FeatureService)
		private readonly featureService: typeof $FeatureService.T,
		@inject($GenericUserEventService)
		private readonly genericUserEventService: typeof $GenericUserEventService.T
	) {
		this.renderMode = 'modern';

		this.loadFromLocalStorage();

		this.dispose.track(() => {
			this.k3Firebase.analytics.setUserProperties({
				['Chatdesign']: null,
			});
		});
	}

	private loadFromLocalStorage = () => {
		const entry = this.localStorage.getEntry(layoutSettingsKey);
		const value = entry.get();
		if (!value) {
			this.trackInitialValues();
			return;
		}

		runInAction('Restore Settings from Storage', () => {
			this.renderMode = value.mode;
			this.fontSize = value.size;
			this.showChannelBackground = value.showChannelBackground;
			this.trackInitialValues();
		});
	};

	@action
	public setShowChannelBackground(show: boolean): void {
		this.showChannelBackground = show;
		this.updateStorage();
		this.genericUserEventService.reportEvent({
			type: 'Settings_Changed',
			field: 'ShowChannelBackground',
		});
	}

	@action
	public setRenderMode(renderMode: RenderMode): void {
		this.trackSwitchRenderMode(this.renderMode, renderMode);
		this.renderMode = renderMode;
		this.updateStorage();
		this.genericUserEventService.reportEvent({
			type: 'Settings_Changed',
			field: 'ChannelMessageLayout',
		});
	}

	@action
	public setFontSize(fontSize: FontSize): void {
		this.fontSize = fontSize;
		this.updateStorage();
		this.trackFontSize();
		this.genericUserEventService.reportEvent({
			type: 'Settings_Changed',
			field: 'FontSize',
		});
	}

	private updateStorage(): void {
		const entry = this.localStorage.getEntry(layoutSettingsKey);
		entry.set({
			mode: this.renderMode,
			size: this.fontSize,
			showChannelBackground: this.showChannelBackground,
		});
	}

	private trackInitialValues(): void {
		this.k3Firebase.analytics.setUserProperties({
			['Chatdesign']: this.getRenderModeKey(this.renderMode),
		});
	}

	private trackFontSize(): void {
		switch (this.fontSize) {
			case 'smallest':
				this.firebaseAnalyticsService.logEvent(
					AnalyticsContentType,
					'Size_SmallestClicked'
				);
				break;
			case 'small':
				this.firebaseAnalyticsService.logEvent(
					AnalyticsContentType,
					'Size_SmallClicked'
				);
				break;
			case 'normal':
				this.firebaseAnalyticsService.logEvent(
					AnalyticsContentType,
					'Size_NormalClicked'
				);
				break;
			case 'large':
				this.firebaseAnalyticsService.logEvent(
					AnalyticsContentType,
					'Size_BigClicked'
				);
				break;
			case 'largest':
				this.firebaseAnalyticsService.logEvent(
					AnalyticsContentType,
					'Size_BiggestClicked'
				);
				break;
			default: {
				// eslint-disable-next-line @typescript-eslint/no-unused-vars
				const n: never = this.fontSize;
			}
		}
	}

	private trackSwitchRenderMode(
		prevMode: RenderMode,
		newMode: RenderMode
	): void {
		this.firebaseAnalyticsService.logEvent(
			AnalyticsContentType,
			`CondensedMode_${this.getRenderModeKey(
				prevMode
			)}To${this.getRenderModeKey(newMode)}Clicked`
		);
	}

	private getRenderModeKey(renderMode: RenderMode): string {
		switch (renderMode) {
			case 'modern':
				return 'Modern';
			case 'modernWithoutBubbles':
				return 'Slim';
			case 'condensed':
				return 'Classic';
			default: {
				// eslint-disable-next-line @typescript-eslint/no-unused-vars
				const n: never = renderMode;
			}
		}
	}
}
