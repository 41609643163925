import * as React from 'react';
import { useCallback } from 'react';
import { createSvgIcon, FlexCol, IconSearch, IconSettings, LineDivider, Panel } from '@knuddels/component-library';
import { ChannelSelectionTabs } from './ChannelSelectionTabs';
import { RecommendedChannelsTab } from './RecommendedChannelsTab';
import { ChannelCategoriesTab } from './ChannelCategoriesTab';
import { GameChannelsTab } from './GameChannelsTab';
import { useConfigureNavBar, useNavigate } from '../../ChannelListRouter';
import { SearchResults } from './ChannelList/Search/SearchResults';
import { useService } from '@knuddels-app/DependencyInjection';
import { $I18n } from '@knuddels-app/i18n';
import { CHANNEL_LIST_TITLE } from '../../../i18n/shared-formats';
import { useFilteredChannels } from './ChannelList/Search/useFilteredChannels';
import { ChannelListItem } from '../../shared/ChannelList/ChannelListVirtualItems';
import { ChannelGroupData } from '../../shared/ChannelList/ChannelListGroupItems';
import { $JoinChannelService } from '@knuddelsModules/Channel';
import { useLazyService } from '@knuddels-app/ModuleSystem';
import { $AdsService } from '@knuddelsModules/Ads';
import { SearchBar } from './SearchBar';
import { $UserService } from '@knuddelsModules/UserData';
import { CommunityStatus } from '@generated/graphql';
import { $OverlayService } from '@knuddels-app/overlays';
import { NotAllowedToCreateChannelModal } from './NotAllowedToCreateChannelModal';
import { useSaveSearchText } from './ChannelList/Search/useSaveSearchText';
import { useLogEvent } from '@knuddels-app/analytics/firebase/useLogEvent';
import { dismissKeyboard } from '@shared/helper/dismissKeyboard';
export const ChannelSelectionContent: React.FC<{
  refetchOverview: boolean;
}> = ({
  refetchOverview
}) => {
  const [isSearching, setSearching] = React.useState(false);
  const [searchText, setSearchText] = React.useState('');
  const {
    onSubmit,
    data,
    loading
  } = useSearch(searchText);
  const openSearch = React.useCallback(() => {
    setSearching(true);
  }, [setSearching]);
  const onClose = () => {
    setSearching(false);
    setSearchText('');
  };
  useSetupTitlebar(isSearching, openSearch);
  if (isSearching) {
    return <div className={_c0}>
				<SearchBar value={searchText} onChange={setSearchText} onSubmit={onSubmit} onClose={onClose} />
				<LineDivider className={_c1} />
				<div style={{
        display: 'contents'
      }} onTouchStart={() => dismissKeyboard()}>
					<SearchResults searchText={searchText} setSearchText={setSearchText} data={data} loading={loading} />
				</div>
			</div>;
  }
  return <ChannelSelectionTabs>
			<Panel>
				<RecommendedChannelsTab refetchOverview={refetchOverview} />
			</Panel>
			<Panel>
				<GameChannelsTab refetchOverview={refetchOverview} />
			</Panel>
			<Panel>
				<ChannelCategoriesTab refetchOverview={refetchOverview} />
			</Panel>
		</ChannelSelectionTabs>;
};
const useSetupTitlebar = (hideTitleBar: boolean, onSearchClicked: () => void) => {
  const i18n = useService($I18n);
  const navigate = useNavigate();
  useConfigureNavBar({
    iconList: [<IconSearch key={'search'} size={'large'} onPress={onSearchClicked} />, <IconSettings key={'settings'} size={'large'} onPress={() => {
      navigate('channel-list-settings');
    }} />],
    title: i18n.format(CHANNEL_LIST_TITLE),
    hideShadow: true,
    hideTitleBar
  }, [navigate, hideTitleBar, onSearchClicked]);
};
const useSearch = (searchText: string) => {
  const {
    data,
    loading
  } = useFilteredChannels(searchText);
  const joinGroup = useJoinGroup();
  const joinByName = useJoinByName();
  const saveSearchText = useSaveSearchText();
  const userService = useService($UserService);
  const overlayService = useService($OverlayService);
  const onSubmit = React.useCallback(() => {
    if (searchText.length === 0) {
      return;
    }
    saveSearchText(searchText);
    const group = findFirstChannelGroup(data);
    if (group && group.name.toLocaleLowerCase() === searchText.toLowerCase()) {
      joinGroup(group);
    } else {
      if (userService.currentUser.status === CommunityStatus.Normal) {
        overlayService.showOverlay({
          view: <NotAllowedToCreateChannelModal />
        });
      } else {
        joinByName(searchText);
      }
    }
  }, [data, searchText]);
  return {
    onSubmit,
    data,
    loading
  };
};
const useJoinGroup = () => {
  const channelService = useService($JoinChannelService);
  const getAdsService = useLazyService($AdsService);
  const track = useLogEvent();
  return useCallback((channelGroup: ChannelGroupData) => {
    track('ChannelSelection_Search', 'Searchresult_ChannelEntered');
    channelService.joinChannelByGroupId(channelGroup.id, channelGroup.name, 'ChannelSelection_Search');
    getAdsService().then(it => it.showInterstitial());
  }, []);
};
const useJoinByName = () => {
  const channelService = useService($JoinChannelService);
  const getAdsService = useLazyService($AdsService);
  const track = useLogEvent();
  return useCallback((name: string) => {
    track('ChannelSelection_Search', 'Searchresult_ChannelEntered');
    channelService.joinChannelByName(name, 'ChannelSelection_Search');
    getAdsService().then(it => it.showInterstitial());
  }, []);
};
function findFirstChannelGroup(data: ChannelListItem[]): ChannelGroupData {
  for (const item of data) {
    if (item.type === 'channelGroup') {
      return item.channelGroup;
    }
  }
  return null;
}
const IconEnter = createSvgIcon(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#000000">
		<path d="M 5 3 C 3.9069372 3 3 3.9069372 3 5 L 3 11 L 3 13 L 3 19 C 3 20.093063 3.9069372 21 5 21 L 19 21 C 20.093063 21 21 20.093063 21 19 L 21 5 C 21 3.9069372 20.093063 3 19 3 L 5 3 z M 5 5 L 19 5 L 19 19 L 5 19 L 5 13 L 11.585938 13 L 9.2929688 15.292969 L 10.707031 16.707031 L 15.414062 12 L 10.707031 7.2929688 L 9.2929688 8.7070312 L 11.585938 11 L 5 11 L 5 5 z" fill="currentColor" />
	</svg>);
IconEnter.displayName = 'IconEnter';
const _c0 = " Knu-FlexCol flex-1 ";
const _c1 = "  ";