import { RefreshSessionErrorFragment } from '@generated/graphql';
import { AllProviders } from '@knuddels-app/AllProviders';
import { $AppLoadingService } from '@knuddels-app/AppLoadingService';
import { $AuthService, K3ApolloProvider, RefreshSessionErrorScreen } from '@knuddels-app/Connection';
import { IModel, inject, injectedComponent, injectProps } from '@knuddels-app/DependencyInjection';
import { LoadingScreen } from '@knuddels-app/LoadingScreen';
import { autorun, observable, observer, runInAction } from '@knuddels-app/mobx';
import { $ScreenService, ScreenSizeCalculator, ScreenWidth } from '@knuddels-app/Screen';
import { Flex, FlexCol, useTheme, Z_INDEX, clearPersistedVirtualListPersistence, resolveThemingValue, resolveIsDarkColor } from '@knuddels/component-library';
import { Disposable } from '@knuddels/std';
import { injectable } from 'inversify';
import * as React from 'react';
import { LoggedOutArea } from './LoggedOutArea';
import { hideLandingpageHtml } from './utils/hideLandingpageHtml';
import { K3SnackbarProvider } from '@knuddels-app/SnackbarManager';
import { NAV_BAR_BOTTOM_HEIGHT } from '@shared/constants';
// tslint:disable-next-line
import { LoggedInView } from '@knuddelsModules/LoggedInArea/bundle/components/LoggedInView';
import '@knuddels/component-library/dist/esm/component-library.css';
import { useDevSwitchThemeShortcut } from '../core/tools/useDevSwitchThemeShortcut';
import { RegisterBottomInputContext, useBottomInputs } from './RegisterBottomInputContext';
import { getSafeAreaBottomHeight } from '@shared/helper/getSafeArea';
import { $NativeWebViewService } from '@knuddels-app/NativeWebView';
import { $KeyboardService } from '@knuddels-app/Keyboard';
import { PrivacyScreenProvider } from '@shared/components/contexts/PrivacyScreenContext';
import { Capacitor } from '@capacitor/core';
import { BackgroundTask } from '@knuddels/background-task-capacitor-plugin';
import { $GenericUserEventService } from '@knuddels-app/analytics/generic';

/**
 * The root react component of the app.
 * We cannot use an injected component here as
 * we need the container provider as parent.
 */

export class App extends React.Component {
  render(): React.ReactNode {
    return <AllProviders>
				<AppWithProviders />
			</AllProviders>;
  }
}
@injectable()
class AppModel implements IModel {
  dispose = Disposable.fn();
  @observable.ref
  private appLoadingService: typeof $AppLoadingService.T | undefined = undefined;
  constructor(@injectProps()
  props: {}, @inject($AuthService)
  private authService: typeof $AuthService.T, @inject.lazy($AppLoadingService)
  private readonly getAppLoadingService: typeof $AppLoadingService.TLazy, @inject($GenericUserEventService)
  genericUserEventService: typeof $GenericUserEventService.T) {
    genericUserEventService.reportEvent({
      type: 'App_Start'
    });
    this.dispose.track(autorun({
      name: 'hide landingpage footer if not logged out (only show on landingpage)'
    }, () => {
      if (this.viewState.kind === 'loggedOut') {
        hideLandingpageHtml(false);
      } else {
        hideLandingpageHtml();
      }
    }));
    this.dispose.track(autorun({
      name: 'Load services after login and throw reference away on logout'
    }, async () => {
      if (this.authServiceState.kind === 'loggedIn') {
        const appLoadingService = await this.getAppLoadingService();
        runInAction('Set loggedIn services', () => {
          this.appLoadingService = appLoadingService;
          appLoadingService.init();
        });
      } else if (this.authServiceState.kind === 'loggedOut') {
        if (this.appLoadingService) {
          runInAction('Reset loggedIn services', () => {
            this.appLoadingService = undefined;
          });
        }
      }
    }));
    this.dispose.track(autorun({
      name: "Setup BackgroundRunner if logged in and it's not already setup"
    }, async () => {
      if (this.authServiceState.kind === 'loggedIn' && Capacitor.isNativePlatform()) {
        await BackgroundTask.setBearerToken({
          token: this.authService.state.kind === 'loggedIn' ? this.authService.state.sessionTokenProvider.currentAuthToken : undefined
        });
        await BackgroundTask.setGQLEndpoint({
          endpoint: this.authService.state.kind === 'loggedIn' ? this.authService.state.endpoint.urls.graphQl : undefined
        });
      }
    }));
    this.dispose.track(autorun({
      name: 'Clear PersistedVirtualListPersistence if logged out'
    }, () => {
      if (this.authServiceState.kind === 'loggedOut') {
        console.log('Clear persistence');
        clearPersistedVirtualListPersistence();
      }
    }));
  }
  private get authServiceState(): (typeof $AuthService.T)['state'] {
    return this.authService.state;
  }
  get viewState(): AppRootViewState {
    const authServiceState = this.authServiceState;
    if (globalEnv.product === 'stapp-messenger') {
      // can only render AppRoot if logged in (we have authenticated client with a session token).
      // It will set the authenticated client in context and crashes if not defined.
      return authServiceState.kind === 'loggedIn' ? {
        kind: 'loggedIn'
      } : {
        kind: 'empty'
      };
    }
    if (authServiceState.kind === 'loggedIn') {
      if (!this.appLoadingService || !this.appLoadingService.isReady) {
        return {
          kind: 'loading'
        };
      }
      return {
        kind: 'loggedIn'
      };
    } else if (authServiceState.kind === 'initializing' || authServiceState.kind === 'loggingIn' && authServiceState.reason === 'obtainingSessionToken') {
      // prevent flickering while checking for deviceToken
      return {
        kind: 'loading'
      };
    } else if (authServiceState.kind === 'loggedOut' && authServiceState.error && authServiceState.error.kind === 'SessionConnectionError') {
      return {
        kind: 'error',
        error: authServiceState.error.data
      };
    } else {
      return {
        kind: 'loggedOut'
      };
    }
  }
}

// not exactly the inputbar height but looks better like this
const INPUTBAR_HEIGHT = 40;

/**
 * The root react component of the app.
 */
const AppWithProviders = injectedComponent({
  model: AppModel,
  name: 'AppWithProviders',
  inject: {
    screenService: $ScreenService,
    nativeWebViewService: $NativeWebViewService,
    keyboardService: $KeyboardService
  }
}, ({
  model,
  screenService,
  nativeWebViewService,
  keyboardService
}) => {
  useDevSwitchThemeShortcut();
  const {
    registerInput,
    inputCount
  } = useBottomInputs();
  const theme = useTheme();
  React.useEffect(() => {
    nativeWebViewService.currentTheme = theme;
  }, [theme]);
  const DEFAULT_SPACING = theme.spacings.xlarge + getSafeAreaBottomHeight();
  const NavSpacing = screenService.isStackedLayout && !keyboardService.showSmileyOrFeatureBox ? NAV_BAR_BOTTOM_HEIGHT : 0;
  const InputSpacing = inputCount > 0 && !keyboardService.showSmileyOrFeatureBox ? INPUTBAR_HEIGHT : 0;
  return <ScreenSizeCalculator>
				<RegisterBottomInputContext.Provider value={registerInput}>
					<K3SnackbarProvider isStackedLayout={screenService.screenWidth === ScreenWidth.XS} bottomSpacing={DEFAULT_SPACING + NavSpacing + InputSpacing}>
						<PrivacyScreenProvider>
							<AppRootViews model={(model as AppModel)} />
						</PrivacyScreenProvider>
					</K3SnackbarProvider>
				</RegisterBottomInputContext.Provider>
			</ScreenSizeCalculator>;
});
type AppRootViewState = {
  kind: 'loggedIn';
} | {
  kind: 'loading';
} | {
  kind: 'loggedOut';
} | {
  kind: 'empty';
} | {
  kind: 'error';
  error: RefreshSessionErrorFragment;
};
const AppRootViews: React.FC<{
  model: AppModel;
}> = observer('AppRootViews', ({
  model
}) => {
  const theme = useTheme();
  console.log('AppRootViews', model.viewState.kind);
  switch (model.viewState.kind) {
    case 'loggedOut':
      return <LoggedOutArea />;
    case 'loading':
      return <LoadingScreen />;
    case 'loggedIn':
      return <K3ApolloProvider>
						<div id={'safe-area-inset-top'} style={{
          height: 'env(safe-area-inset-top)',
          zIndex: Z_INDEX.OVER_TITLE_BAR,
          position: 'relative'
        }} />
						<div className={_c0}>
							<div id={'safe-area-inset-left'} style={{
            background: theme.colors.basic.naviBg,
            width: 'env(safe-area-inset-left)',
            zIndex: Z_INDEX.OVER_TITLE_BAR,
            position: 'relative'
          }} />
							<div className={_c1 + ("contentBg" ? resolveIsDarkColor("contentBg", useTheme()) ? " content-is-dark" : " content-is-light" : "")}>
								<LoggedInView />
							</div>
							<div id={'safe-area-inset-right'} style={{
            background: theme.colors.basic.naviBg,
            width: 'env(safe-area-inset-right)',
            zIndex: Z_INDEX.OVER_TITLE_BAR,
            position: 'relative'
          }} />
						</div>
						<div id={'safe-area-inset-bottom'} style={{
          background: theme.colors.basic.naviBg,
          height: 'env(safe-area-inset-bottom)',
          position: 'relative',
          zIndex: Z_INDEX.NAV
        }} />
					</K3ApolloProvider>;
    case 'error':
      return <RefreshSessionErrorScreen refreshSessionError={model.viewState.error} />;
    case 'empty':
    default:
      return null;
  }
});
const _c0 = " Knu-Flex flex-1 ";
const _c1 = " Knu-FlexCol flex-1 bg-contentBg position-relative ";