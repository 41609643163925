import { GetNotificationSettings, NotificationSettingsSubscription } from '@generated/graphql';
import { useQuery, useSubscription } from '@knuddels-app/Connection';
import { useService } from '@knuddels-app/DependencyInjection';
import { $I18n } from '@knuddels-app/i18n';
import { observer } from '@knuddels-app/mobx';
import { Flex, LineDivider, ListGroup, ScrollView, SwitchListItem } from '@knuddels/component-library';
import { declareFormat } from '@knuddels/i18n';
import { $ClientSettingsService } from '@knuddelsModules/Settings';
import { Spacer } from '@shared/components';
import * as React from 'react';
import { NotificationStatusType } from '../../../constants';
import { NotificationSettingsGroupItem } from './NotificationSettingsGroupItem';
import { useUpdateMutations } from './useUpdateMutations';
import { $GenericUserEventService } from '@knuddels-app/analytics/generic';
export const MessengerSettingsOverview: React.FC = observer('MessengerSettingsOverview', () => {
  const i18n = useService($I18n);
  const clientSettingsService = useService($ClientSettingsService);
  const genericUserEventService = useService($GenericUserEventService);
  const {
    data
  } = useQuery(GetNotificationSettings, {}, 'cache-and-network');
  const [friendSettings, setFriendSettings] = React.useState(data?.friendStatus);
  const [watchlistSettings, setWatchlistSettings] = React.useState(data?.watchlistStatus);
  const [warnOnSensitiveContent, setWarnOnSensitiveContent] = React.useState(data?.sensitiveContentSettings.warnOnSensitiveContent);
  React.useEffect(() => {
    setFriendSettings(data?.friendStatus);
    setWatchlistSettings(data?.watchlistStatus);
    setWarnOnSensitiveContent(data?.sensitiveContentSettings.warnOnSensitiveContent);
  }, [data]);
  useSubscription(NotificationSettingsSubscription, {}, ({
    friendStatus,
    watchlistStatus,
    sensitiveContentSettings
  }) => {
    setFriendSettings(friendStatus);
    setWatchlistSettings(watchlistStatus);
    setWarnOnSensitiveContent(sensitiveContentSettings.warnOnSensitiveContent);
  });
  const {
    updateFriendStatus,
    updateWatchlistStatus,
    setFriendStatusEnabled,
    setWatchlistStatusEnabled,
    setSensitiveContentWarningEnabled
  } = useUpdateMutations();
  const enableWarning = async (enabled: boolean) => {
    genericUserEventService.reportEvent({
      type: 'Settings_Changed',
      field: 'WarnOnSensitiveContent'
    });
    const oldEnabled = warnOnSensitiveContent;
    setWarnOnSensitiveContent(enabled);
    const ok = await setSensitiveContentWarningEnabled(enabled);
    if (!ok) {
      setWarnOnSensitiveContent(oldEnabled);
    }
  };
  const areMessengerNotificationsEnabled = clientSettingsService.inappMessengerNotificationEnabled;
  const messengerNotificationsEnabledChanged = (enabled: boolean) => {
    clientSettingsService.setEnableInappMessengerNotifications(enabled);
  };
  if (!friendSettings || !watchlistSettings) {
    return null;
  }
  return <div className={_c0}>
				<ListGroup title={declareFormat({
      id: 'settings.messengerSettings.settingsGroup.images',
      defaultFormat: 'Receiving images'
    }).format(i18n)}>
					<SwitchListItem caption={declareFormat({
        id: 'settings.messengerSettings.settingsGroup.images.warning.caption',
        defaultFormat: 'Critical content warning'
      }).format(i18n)} additionalInfo={declareFormat({
        id: 'settings.messengerSettings.settingsGroup.images.warning.additionalInfo',
        defaultFormat: 'Images suspected of being erotic or violent are not immediately displayed'
      }).format(i18n)} checked={warnOnSensitiveContent} onChange={enableWarning} separator={'none'} />
				</ListGroup>

				<ListGroup title={declareFormat({
      id: 'settings.messengerSettings.settingsGroup.notifications',
      defaultFormat: 'In-app notifications'
    }).format(i18n)}>
					<SwitchListItem caption={declareFormat({
        id: 'settings.messengerSettings.settingsGroup.notifications.messenger.caption',
        defaultFormat: 'Messenger'
      }).format(i18n)} additionalInfo={declareFormat({
        id: 'settings.messengerSettings.settingsGroup.notifications.messenger.additionalInfo',
        defaultFormat: 'Notifications for new messages in Messenger'
      }).format(i18n)} checked={areMessengerNotificationsEnabled} onChange={messengerNotificationsEnabledChanged} separator={'none'} />
				</ListGroup>

				<ListGroup title={declareFormat({
      id: 'settings.messengerSettings.settingsGroup.events',
      defaultFormat: 'Event notification'
    }).format(i18n)}>
					<NotificationSettingsGroupItem type={NotificationStatusType.Friends} caption={declareFormat({
        id: 'settings.messengerSettings.friends',
        defaultFormat: 'Friends'
      }).format(i18n)} status={friendSettings} updateRemoteSettings={updateFriendStatus} updateRemoteEnabled={enabled => {
        genericUserEventService.reportEvent({
          type: 'Settings_Changed',
          field: 'EventMessageFriends'
        });
        return setFriendStatusEnabled(enabled);
      }} />
					<div className={_c1}>
						<LineDivider color={'hairlineAtom'} className={_c2} />
					</div>
					<NotificationSettingsGroupItem type={NotificationStatusType.Watchlist} caption={declareFormat({
        id: 'settings.messengerSettings.watchlist',
        defaultFormat: 'Watchlist'
      }).format(i18n)} status={watchlistSettings} updateRemoteSettings={updateWatchlistStatus} updateRemoteEnabled={enabled => {
        genericUserEventService.reportEvent({
          type: 'Settings_Changed',
          field: 'EventMessageWatchlist'
        });
        return setWatchlistStatusEnabled(enabled);
      }} />
				</ListGroup>
				<Spacer size={'xxxlarge'} />
			</div>;
});
const _c0 = " Knu-ScrollView height-100-percent ";
const _c1 = " Knu-Flex pl-minor pr-minor alignSelf-stretch ";
const _c2 = "  ";