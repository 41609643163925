import { createRendererMotionComponent as i } from "../../motion/index.js";
import { isSVGComponent as f } from "../dom/utils/is-svg-component.js";
import { svgMotionConfig as m } from "../svg/config-motion.js";
import { htmlMotionConfig as c } from "../html/config-motion.js";
import { createUseRender as p } from "../dom/use-render.js";
function R(n, r) {
  return function(o, { forwardMotionProps: t } = { forwardMotionProps: !1 }) {
    const e = {
      ...f(o) ? m : c,
      preloadedFeatures: n,
      useRender: p(t),
      createVisualElement: r,
      Component: o
    };
    return i(e);
  };
}
export {
  R as createMotionComponentFactory
};
