import { progress as s } from "../../../../utils/progress.js";
const i = 10, g = (o, e) => {
  let t = "";
  const r = Math.max(Math.round(e / i), 2);
  for (let n = 0; n < r; n++)
    t += o(s(0, r - 1, n)) + ", ";
  return `linear(${t.substring(0, t.length - 2)})`;
};
export {
  g as generateLinearEasing
};
