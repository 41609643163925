import { observer } from '@knuddels-app/mobx';
import * as React from 'react';
import { RichTextEditor, RichTextEditorRef } from '@shared/components/RichText/RichTextEditor';
import { useService } from '@knuddels-app/DependencyInjection';
import { $KeyboardService } from '@knuddels-app/Keyboard';
import { Box, BoxProps, ChildThemeProvider, createSvgIcon, Flex, FlexCol, IconKeyboard, IconSend, isTouchDevice, LineDivider, Modal, ModalParentProvider, Text, ThemeOverride, useChildTheme, useEvent, useTheme, Z_INDEX, resolveThemingValue, resolveIsDarkColor } from '@knuddels/component-library';
import { IconSmiley } from '@shared/icons/IconSmiley';
import { Smileybox } from '@knuddelsModules/Smileybox/bundle/components/Smileybox';
import { declareFormat, formatMessage } from '@knuddels-app/i18n';
import { OS, os } from '@shared/components/tools/os';
import { isNative } from '@knuddels-app/tools/isNative';
import { isSafari, isSafariMobile } from '@knuddels-app/tools/isSafari';
import { dismissKeyboard } from '@shared/helper/dismissKeyboard';
import { DELETE_CHARACTER_COMMAND, EditorState, UNDO_COMMAND } from 'lexical';
import { $Environment } from '@knuddels-app/Environment';
import { useInsideModal } from '@knuddels-app/overlays/InsideModalContext';
import { IconSlash } from '@shared/icons/IconSlash';
// tslint:disable-next-line: no-module-bleeding
import { FeatureBox } from '@knuddelsModules/Smileybox/bundle/components/FeatureBox';
import { IconFlash } from '@shared/icons/IconFlash';
import { AnimatePresence, motion } from 'framer-motion';
import { $OverlayService } from '@knuddels-app/overlays';
import { useReactiveState } from '@knuddels-app/tools/useReactiveState';
import { StappSmileybox } from '@knuddelsModules/Smileybox/bundle/components/StappSmileyBox';
import { $GenericUserEventService } from '@knuddels-app/analytics/generic';
export type RichInputRef = RichTextEditorRef;
export type RichTextPluginsGetter = (wrapperRef: React.RefObject<HTMLDivElement>) => React.ReactNode;
export type RichInputProps = {
  placeholder?: string;
  children?: any[];
  editorRef?: React.MutableRefObject<RichInputRef>;
  autoFocus?: boolean;
  adornmentRight?: React.ReactNode;
  afterSubmitBehavior?: (editorRef: RichInputRef) => void;
  plugins?: RichTextPluginsGetter;
  useModal?: boolean;
  mode: 'toolbar' | 'inline' | 'inline-chat' | 'inline-button';
  bg?: 'inputFieldBg' | 'transparent';
  characterLimit?: number;
  onFocus?(): void;
  onBlur?(): void;
  onChange?(text: string, state: EditorState): void;
  onSubmit(text: string): void;
  context: string;
};

// iOS needs to focus a different input element to clear the keyboard suggestions
const appendAndFocusTempInput = () => {
  const input = document.createElement('input');
  input.style.position = 'absolute';
  input.style.top = '-1000px';
  document.body.appendChild(input);
  input.focus();
  input.remove();
};
export const AfterSubmitBehavior = {
  ClearAndRefocus: (editorRef: RichInputRef) => {
    editorRef.clear();
    if (os === OS.ios || isSafariMobile()) {
      appendAndFocusTempInput();
      setTimeout(() => {
        editorRef.focus();
      });
    } else {
      editorRef.focus();
    }
  },
  ClearAndDismissKeyboard: (editorRef: RichInputRef) => {
    editorRef.clear();
    setTimeout(() => {
      dismissKeyboard();
    }, 200);
  }
};
const ICON_SPACING = '44px';
const useSmileyBoxBehavior = (keyboardService: typeof $KeyboardService.T, editorRef: React.MutableRefObject<RichTextEditorRef | null>, mode: 'toolbar' | 'inline' | 'inline-chat' | 'inline-button'): {
  style: any;
  onPress: BoxProps['onPress'];
  onPointerDown: BoxProps['onPointerDown'];
  left: BoxProps['left'];
  right: BoxProps['right'];
  smileyButtonIsLeft: boolean;
} => {
  const toggleSmileyBox = () => {
    keyboardService.setFocusable(editorRef.current);
    keyboardService.showSmileyBoxToggle();
  };
  const isIos = os === OS.ios || isSafariMobile();
  const isApple = isIos || isSafari();

  /**
   * iOS triggers keyboard after click, which leads to race conditions,
   * when trying to toggle between keyboard and smileybox.
   */
  const onPress = isIos ? undefined : toggleSmileyBox;
  const onPointerDown = isIos ? toggleSmileyBox : undefined;
  const style = isApple ? {
    '--editor-input-padding-right': mode === 'inline-button' ? undefined : ICON_SPACING
  } : {
    '--editor-input-padding-left': mode === 'inline-button' ? undefined : ICON_SPACING
  };
  const left = isApple ? undefined : 'none';
  const right = isApple ? 'none' : undefined;
  return {
    style,
    onPress,
    onPointerDown,
    left,
    right,
    smileyButtonIsLeft: !isApple
  };
};
const root = document.querySelector('#root')!;
const targetHasParent = (target: HTMLElement, parent: HTMLElement | null) => {
  if (!target || !target.parentElement || !parent) {
    return false;
  }
  let current = target;
  while (current) {
    if ((current as any).parent === parent) {
      return true;
    }
    current = current.parentElement!;
  }
  return false;
};
const useClickOutside = (ref: React.RefObject<HTMLDivElement>, onClickOutside: () => void) => {
  const activeRef = React.useRef(false);
  const handleClick = (e: MouseEvent) => {
    if (!activeRef.current) {
      return;
    }
    const targetInsideContainer = ref.current?.contains((e.target as Node));
    const targetNotInModal = !root.contains((e.target as Node));
    if (!targetInsideContainer && !targetNotInModal && !targetHasParent((e.target as HTMLElement), ref.current)) {
      onClickOutside();
    }
  };
  React.useEffect(() => {
    document.addEventListener('click', handleClick, true);
    return () => {
      document.removeEventListener('click', handleClick, true);
    };
  }, []);
  return (active: boolean) => {
    activeRef.current = active;
  };
};
const useGetIconColor = () => {
  return (active: boolean) => {
    return active ? useTheme().id === 'dark' ? ('white-solid-white' as const) : ('black-solid-black' as const) : undefined;
  };
};
export const RichInput = observer('RichInput', ({
  onFocus,
  onBlur,
  plugins = () => null,
  onSubmit,
  onChange,
  adornmentRight,
  afterSubmitBehavior = AfterSubmitBehavior.ClearAndRefocus,
  placeholder,
  editorRef,
  bg = 'inputFieldBg',
  useModal,
  autoFocus = false,
  mode = 'inline',
  characterLimit,
  context
}: RichInputProps) => {
  const genericUserEventService = useService($GenericUserEventService);
  const [value, setValue] = React.useState('');
  const wrapperRef = React.useRef<HTMLDivElement>(null);
  const ref = React.useRef<RichTextEditorRef | null>(null);
  const insideModal = useInsideModal();
  const isOverCharacterLimit = characterLimit !== undefined ? value.trim().length > characterLimit : false;
  const disabled = value.trim().length === 0 || isOverCharacterLimit;
  const keyboardService = useService($KeyboardService);
  React.useEffect(() => {
    if (keyboardService.currentlyVisibleCustomKeyboard === 'none' || keyboardService.focusable !== ref.current) {
      return;
    } else {
      genericUserEventService.reportEvent({
        type: keyboardService.currentlyVisibleCustomKeyboard === 'smiley' ? 'Smileybox_Opened' : 'Featurebox_Opened',
        context: context
      });
    }
  }, [keyboardService.currentlyVisibleCustomKeyboard]);
  React.useEffect(() => {
    if (autoFocus) {
      ref?.current?.focus();
    }
  }, []);
  const setWatcherActive = useClickOutside(wrapperRef, () => {
    keyboardService.removeFocusable();
    if (!isTouchDevice()) {
      keyboardService.closeAll();
    }
    setWatcherActive(false);
  });
  useReactiveState(() => {
    if (keyboardService.focusable === ref.current || keyboardService.focusable?.parentFocusable === ref.current) {
      setWatcherActive(true);
    }
  }, []);
  const submit = useEvent(() => {
    if (disabled) {
      return;
    }
    onSubmit(value);
    ref?.current?.dispatchSubmit(value);
    afterSubmitBehavior(ref.current!);
  });
  const smileyBoxBehavior = useSmileyBoxBehavior(keyboardService, ref, mode);
  const refUpdater = React.useCallback((r: RichTextEditorRef) => {
    ref.current = r;
    if (editorRef) {
      editorRef.current = (r as RichTextEditorRef);
    }
  }, [editorRef]);
  const getIconColor = useGetIconColor();
  return <div ref={wrapperRef} className={_c0}>
				{useTheme().id === 'light' && <LineDivider className={_c1} />}
				<div style={{
      background: resolveThemingValue(bg, "colors", useTheme())
    }} className={_c2 + (bg ? resolveIsDarkColor(bg, useTheme()) ? " content-is-dark" : " content-is-light" : "")}>
					<AnimatePresence presenceAffectsLayout>
						{isOverCharacterLimit && <motion.div initial={{
          opacity: 0,
          scale: 0.8,
          y: -10
        }} animate={{
          opacity: 1,
          scale: 1,
          y: 0
        }} exit={{
          opacity: 0,
          scale: 0.8,
          y: -10
        }} transition={{
          duration: 0.2
        }} style={{
          position: 'absolute',
          top: -28,
          right: 48
        }}>
								<div className={_c3 + ("red-500" ? resolveIsDarkColor("red-500", useTheme()) ? " content-is-dark" : " content-is-light" : "")}>
									<Text bold={true} className={_c4}>
										{`${value.trim().length} / ${characterLimit}`}
									</Text>
								</div>
							</motion.div>}
					</AnimatePresence>
					<div className={_c5}>
						<div className={_c6}>
							{mode === 'inline-chat' && <AnimatePresence initial={false}>
									{(!value || value === '/') && <motion.div initial={{
              opacity: 0,
              scale: 0
            }} animate={{
              opacity: 1,
              scale: 1
            }} exit={{
              opacity: 0,
              scale: 0
            }} style={{
              position: 'absolute',
              height: '100%',
              right: !smileyBoxBehavior.smileyButtonIsLeft ? 40 : 0,
              zIndex: 1
            }}>
											<IconSlash size={'large'} onPress={() => {
                toggleInputText(editorRef?.current, '/');
              }} />
										</motion.div>}
								</AnimatePresence>}

							{mode !== 'toolbar' && mode !== 'inline-button' ? <div style={{
            left: resolveThemingValue(smileyBoxBehavior.left, "spacing", useTheme()),
            right: resolveThemingValue(smileyBoxBehavior.right, "spacing", useTheme())
          }} className={_c7}>
									{keyboardService.currentlyVisibleCustomKeyboard === 'smiley' && (isNative() || isTouchDevice()) ? <IconKeyboard size={'large'} onPress={smileyBoxBehavior.onPress} onPointerDown={smileyBoxBehavior.onPointerDown} /> : <IconSmiley color={getIconColor(keyboardService.currentlyVisibleCustomKeyboard === 'smiley')} size={'large'} onPress={smileyBoxBehavior.onPress} onPointerDown={smileyBoxBehavior.onPointerDown} />}
								</div> : null}

							<div style={{
            display: 'contents',
            ...(mode === 'toolbar' ? {} : smileyBoxBehavior.style)
          }}>
								<RichTextEditor ref={refUpdater} placeholder={placeholder ?? formatMessage(declareFormat({
              id: 'CHAT_PLACEHOLDER',
              defaultFormat: 'Write a nice message...'
            }))} onChange={(text, state) => {
              setValue(text);
              onChange?.(text, state);
            }} onEnter={isTouchDevice() ? undefined : submit} onInputPress={() => {
              if (keyboardService.isKeyboardVisible) {
                return;
              }
              if (keyboardService.currentlyVisibleCustomKeyboard !== 'none' && isTouchDevice()) {
                keyboardService.openKeyboardOrClose(editorRef!.current);
              }
              if (keyboardService.currentlyVisibleCustomKeyboard === 'none') {
                keyboardService.setKeyboardVisible(true);
                editorRef?.current?.focus();
              }
            }} onFlingDown={() => {
              keyboardService.closeAll();
            }} onFocus={() => {
              keyboardService.setFocusable(ref.current);
              setWatcherActive(true);
              onFocus?.();
            }} onBlur={() => {
              onBlur?.();
            }} showErrorBorder={isOverCharacterLimit}>
									{plugins(wrapperRef)}
								</RichTextEditor>
							</div>
						</div>
						{mode !== 'inline-chat' ? !adornmentRight ? <SendButton onSubmit={submit} disabled={disabled} /> : <AnimatePresence initial={false}>
									<div className={_c8}>
										<div className={_c9}>
											<motion.div style={{
                position: 'absolute',
                top: 0,
                right: 0,
                bottom: 0,
                left: 0
              }} key={value ? 'send' : 'flash'} initial={{
                opacity: 0,
                scale: 0
              }} animate={{
                opacity: 1,
                scale: 1
              }} exit={{
                opacity: 0,
                scale: 0
              }}>
												{value ? <SendButton onSubmit={submit} disabled={disabled} /> : <div className={_c10}>
														{adornmentRight}
													</div>}
											</motion.div>
										</div>
									</div>
								</AnimatePresence> : <AnimatePresence initial={false}>
								<div className={_c11}>
									<div className={_c12}>
										<motion.div style={{
                position: 'absolute',
                top: 0,
                right: 0,
                bottom: 0,
                left: 0
              }} key={value ? 'send' : 'flash'} initial={{
                opacity: 0,
                scale: 0
              }} animate={{
                opacity: 1,
                scale: 1
              }} exit={{
                opacity: 0,
                scale: 0
              }}>
											{value ? <SendButton onSubmit={submit} disabled={disabled} /> : <div className={_c13}>
													<IconFlash size={'large'} color={getIconColor(keyboardService.currentlyVisibleCustomKeyboard === 'feature')} onPress={() => keyboardService.openFeatureBoxOrClose(editorRef!.current!)} />
												</div>}
										</motion.div>
									</div>
								</div>
							</AnimatePresence>}
					</div>
					{mode === 'toolbar' && <Toolbar editorRef={editorRef!} />}
				</div>
				{insideModal && <div style={{
      height: 'env(safe-area-inset-bottom)',
      background: resolveThemingValue(bg, "colors", useTheme())
    }} className={_c14 + (bg ? resolveIsDarkColor(bg, useTheme()) ? " content-is-dark" : " content-is-light" : "")} />}

				<ModalParentProvider element={wrapperRef.current!}>
					<SmileyBoxContent containerRef={wrapperRef} editorRef={ref} useModal={useModal ?? keyboardService.supportsVirtualKeyboard} />
				</ModalParentProvider>
			</div>;
});
const Toolbar: React.FC<{
  editorRef: React.MutableRefObject<RichTextEditorRef>;
}> = observer('Toolbar', ({
  editorRef
}) => {
  const getIconColor = useGetIconColor();
  const keyboardService = useService($KeyboardService);
  return <div className={_c15}>
			{isTouchDevice() && <IconKeyboard color={getIconColor(keyboardService.isKeyboardVisible && keyboardService.currentlyVisibleCustomKeyboard === 'none')} size={'large'} onPress={() => keyboardService.openKeyboardOrClose(editorRef!.current)} />}

			<IconSmiley color={getIconColor(keyboardService.currentlyVisibleCustomKeyboard === 'smiley')} size={'large'} onPress={() => keyboardService.openSmileyBoxOrClose(editorRef!.current)} />

			<IconFlash color={getIconColor(keyboardService.currentlyVisibleCustomKeyboard === 'feature')} size={'large'} onPress={() => keyboardService.openFeatureBoxOrClose(editorRef!.current)} />

			<IconAt size={'large'} onPress={() => {
      toggleInputText(editorRef?.current, '@');
    }} />

			<IconSlash size={'large'} onPress={() => {
      toggleInputText(editorRef?.current, '/');
    }} />
		</div>;
});
export const getCommand = () => {
  if (os === OS.ios || isSafariMobile()) {
    return UNDO_COMMAND;
  } else {
    return DELETE_CHARACTER_COMMAND;
  }
};
const toggleInputText = (editorRef: RichTextEditorRef | undefined, text: string) => {
  if (!editorRef) {
    return;
  }
  const currentText = editorRef.getText().trim();
  if (currentText === text) {
    editorRef.dispatchCommand(getCommand(), {});
  } else {
    editorRef.appendText(text);
  }
};
const SendButton: React.FC<{
  onSubmit: () => void;
  disabled: boolean;
}> = React.memo(({
  onSubmit,
  disabled
}) => {
  const theme = useChildTheme({
    sizes: {
      icons: {
        base: 20
      }
    }
  }, []);
  return <Flex bg={disabled ? useTheme().id === 'dark' ? 'white-transparent-080' : 'black-transparent-040' : 'accent'} borderRadius={'circle'} size={36} animated={'all 0.1s cubic-bezier(0.38, 0.7, 0.125, 1.0)'} flexShrink={0} pl={(2 as ThemeOverride)} placeItems={'center'}>
			<div className={_c17 + ("transparent" ? resolveIsDarkColor("transparent", useTheme()) ? " content-is-dark" : " content-is-light" : "")}>
				<ChildThemeProvider theme={theme}>
					<IconSend disabled={disabled} color={disabled ? undefined : ('white-solid-white' as const)} onPress={onSubmit} />
				</ChildThemeProvider>
			</div>
		</Flex>;
});
SendButton.displayName = 'SendButton';
const SmileyBoxContent = observer('SmileyBoxContent', ({
  editorRef,
  containerRef,
  useModal
}: {
  containerRef: React.RefObject<HTMLDivElement>;
  editorRef: React.MutableRefObject<RichTextEditorRef | null>;
  useModal: boolean;
}) => {
  const environment = useService($Environment);
  const keyboardService = useService($KeyboardService);
  const overlayService = useService($OverlayService);
  const overlays = overlayService.overlays;
  React.useEffect(() => {
    if (os !== OS.android) {
      return;
    }
    const disposable = environment.nativeInterface?.prependBackHandler(() => {
      if (keyboardService.currentlyVisibleCustomKeyboard === 'none') {
        return false;
      }
      keyboardService.removeFocusable();
      return true;
    });
    return () => {
      disposable?.dispose();
    };
  }, []);
  const contentBox = keyboardService.currentlyVisibleCustomKeyboard === 'smiley' ? <ContentBoxWrapper key={'smiley'}>
					<div style={{
      height: resolveThemingValue(keyboardService.keyboardHeight, "sizes", useTheme())
    }} className={_c18 + ("contentBg" ? resolveIsDarkColor("contentBg", useTheme()) ? " content-is-dark" : " content-is-light" : "")}>
						{environment.messengerSystemAppInterface ? <StappSmileybox editorRef={editorRef} /> : <Smileybox editorRef={editorRef} containerRef={containerRef} />}
					</div>
				</ContentBoxWrapper> : keyboardService.currentlyVisibleCustomKeyboard === 'feature' ? <ContentBoxWrapper key={'feature'}>
					<div style={{
      height: resolveThemingValue(keyboardService.keyboardHeight, "sizes", useTheme())
    }} className={_c19 + ("contentBg" ? resolveIsDarkColor("contentBg", useTheme()) ? " content-is-dark" : " content-is-light" : "")}>
						<FeatureBox editorRef={editorRef} containerRef={containerRef} />
					</div>
				</ContentBoxWrapper> : null;
  if (editorRef.current !== keyboardService.focusable && keyboardService.focusable?.parentFocusable !== editorRef.current) {
    return <></>;
  }
  if (contentBox && !useModal) {
    return <div style={{
      minHeight: resolveThemingValue(keyboardService.keyboardHeight, "sizes", useTheme())
    }} className={_c20 + ("contentBg" ? resolveIsDarkColor("contentBg", useTheme()) ? " content-is-dark" : " content-is-light" : "")}>
					{contentBox}
				</div>;
  }
  if (!useModal || !isNative() && !contentBox) {
    return <></>;
  }
  return <Modal backgroundStyle={'transparent'} pointerEvents={'none'} zIndex={Z_INDEX.OVERLAY + overlays.length + 1}>
				<AnimatePresence>{contentBox}</AnimatePresence>
			</Modal>;
});
export const getAnimationConfig = (open: boolean) => {
  if (os === OS.ios) {
    return {
      duration: open ? 0.5 : 0.3,
      ease: [0.38, 0.7, 0.125, 1.0],
      type: 'tween'
    };
  }
  return {
    duration: open ? 0.3 : 0.1,
    ease: [0.05, 0.7, 0.1, 1],
    type: 'tween'
  };
};
const IconAt = createSvgIcon(<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M12 2C6.48867 2 2 6.48867 2 12C2 17.5113 6.48867 22 12 22H16C16.1325 22.0019 16.2641 21.9774 16.387 21.928C16.51 21.8786 16.6219 21.8052 16.7163 21.7122C16.8107 21.6191 16.8856 21.5082 16.9368 21.386C16.9879 21.2637 17.0142 21.1325 17.0142 21C17.0142 20.8675 16.9879 20.7363 16.9368 20.614C16.8856 20.4918 16.8107 20.3809 16.7163 20.2878C16.6219 20.1948 16.51 20.1214 16.387 20.072C16.2641 20.0226 16.1325 19.9981 16 20H12C7.56933 20 4 16.4307 4 12C4 7.56933 7.56933 4 12 4C16.4307 4 20 7.56933 20 12V13.5C20 14.3408 19.3408 15 18.5 15C17.6592 15 17 14.3408 17 13.5V12C17.0001 11.9628 16.9982 11.9256 16.9941 11.8887C16.9339 9.19039 14.712 7 12 7C9.25042 7 7 9.25042 7 12C7 14.7496 9.25042 17 12 17C13.4136 17 14.6879 16.3989 15.5996 15.4473C16.2307 16.3809 17.2977 17 18.5 17C20.4212 17 22 15.4212 22 13.5V12C22 6.48867 17.5113 2 12 2ZM12 9C13.6687 9 15 10.3313 15 12C15 13.6687 13.6687 15 12 15C10.3313 15 9 13.6687 9 12C9 10.3313 10.3313 9 12 9Z" fill="currentColor" />
	</svg>);
const ContentBoxWrapper: React.FC = ({
  children
}) => {
  const keyboardService = useService($KeyboardService);
  if (!isTouchDevice()) {
    return <>{children}</>;
  }
  return <motion.div style={{
    position: 'absolute',
    bottom: 0,
    overflow: 'hidden',
    width: '100%',
    height: keyboardService.keyboardHeight + 'px',
    pointerEvents: 'none'
  }} transition={getAnimationConfig(keyboardService.showSmileyOrFeatureBox)} initial={{
    y: keyboardService.keyboardHeight / 2,
    opacity: 0
  }} exit={{
    y: keyboardService.keyboardHeight / 2,
    opacity: 0
  }} animate={{
    y: keyboardService.showSmileyOrFeatureBox ? 0 : keyboardService.keyboardHeight / 2,
    opacity: keyboardService.showSmileyOrFeatureBox ? 1 : 0
  }}>
			{children}
		</motion.div>;
};
const _c0 = " Knu-FlexCol flex-1 ";
const _c1 = "  ";
const _c2 = " Knu-FlexCol zIndex-1 py-tiny px-6px position-relative ";
const _c3 = " Knu-Flex bg-red-500 px-minor py-tiny borderRadius-small alignSelf-flex-start ";
const _c4 = "  ";
const _c5 = " Knu-Flex flex-1 alignItems-center ";
const _c6 = " Knu-Flex flex-1 flexShrink-0 maxHeight-200px py-tiny position-relative gap-base alignItems-center minHeight-0-px mr-6px ";
const _c7 = " Knu-Flex position-absolute height-full placeItems-flex-end zIndex-1 ";
const _c8 = " Knu-Flex height-full alignItems-flex-end pb-6px ";
const _c9 = " Knu-Flex size-36px flexShrink-0 position-relative ";
const _c10 = " Knu-Flex size-36px flexShrink-0 placeItems-center ";
const _c11 = " Knu-Flex height-full alignItems-flex-end pb-6px ";
const _c12 = " Knu-Flex size-36px flexShrink-0 position-relative ";
const _c13 = " Knu-Flex size-36px flexShrink-0 placeItems-center ";
const _c14 = " Knu-Flex ";
const _c15 = " Knu-Flex height-32px alignItems-center ";
const _c16 = " Knu-Flex borderRadius-circle size-36px flexShrink-0 pl-2px placeItems-center ";
const _c17 = " Knu-Box display-contents bg-transparent ";
const _c18 = " Knu-FlexCol pointerEvents-all alignSelf-flex-end width-full bg-contentBg ";
const _c19 = " Knu-FlexCol pointerEvents-all alignSelf-flex-end width-full bg-contentBg ";
const _c20 = " Knu-Flex flex-1 bg-contentBg ";