import { Disposable, EventEmitter } from '@knuddels/std';
import { Endpoint } from '@knuddels-app/Connection';
import { AuthTokenProvider } from '@knuddels-app/Connection/client/AuthTokenProvider';
import { Client, createClient } from 'graphql-ws';
import { getVersion } from '@knuddels-app/tools/clientInformation/version';
import { PluginListenerHandle } from '@capacitor/core';
import { Network } from '@capacitor/network';
import { App } from '@capacitor/app';

export class SubscriptionClientWrapper implements Disposable {
	public readonly dispose = Disposable.fn();

	public readonly client: Client;

	protected socket: WebSocket | undefined;

	private readonly forceResubscribeEmitter = new EventEmitter<void>();
	public readonly onForceResubscribe = this.forceResubscribeEmitter.asEvent();

	constructor(endpoint: Endpoint, tokenProvider: AuthTokenProvider) {
		let currentRetryResolve: ((value: unknown) => void) | undefined;
		let retryOffset = 0;

		this.client = createClient({
			url: endpoint.urls.graphQlSubscription,
			lazy: false,
			connectionParams: async () =>
				expectValueOfType<{
					Authorization: string | undefined;
					ClientVersion: string;
				}>({
					Authorization:
						'Bearer ' +
						(await tokenProvider.getOrRetrieveAuthToken()),
					ClientVersion: getVersion().toSimpleString(),
				}),
			onNonLazyError: error => {
				console.error('Subscription client error:', error);
			},
			shouldRetry: () => {
				return true;
			},
			retryAttempts: Number.MAX_SAFE_INTEGER,
			retryWait: async rawAttempt => {
				if (rawAttempt === 0) {
					retryOffset = 0;
				}
				const attempt = rawAttempt - retryOffset;
				const wait = Math.min(1000 * 2 ** attempt, 1000 * 15);

				let networkStatusHandle: PluginListenerHandle | undefined;

				const networkStatus = await Network.getStatus();
				if (!networkStatus.connected) {
					networkStatusHandle = await Network.addListener(
						'networkStatusChange',
						status => {
							if (status.connected) {
								retryOffset = rawAttempt + 1;
								currentRetryResolve?.(undefined);
							}
						}
					);
				}

				const appStatusHandle = await App.addListener(
					'appStateChange',
					state => {
						if (state.isActive) {
							retryOffset = rawAttempt + 1;
							currentRetryResolve?.(undefined);
						}
					}
				);

				await new Promise(resolve => {
					currentRetryResolve = () => {
						resolve(undefined);
						clearTimeout(timeout);
						networkStatusHandle?.remove();
						appStatusHandle?.remove();
					};

					const timeout = setTimeout(currentRetryResolve, wait);
				});
			},
		});

		this.dispose.track(
			this.client.on('opened', socket => {
				this.socket = socket as WebSocket;
			})
		);

		App.addListener('appStateChange', state => {
			if (state.isActive) {
				let tries = 0;

				const checkSocket = () => {
					setTimeout(() => {
						if (
							!this.socket ||
							this.socket.readyState === WebSocket.CLOSED
						) {
							this.forceResubscribeEmitter.emit();
						} else if (tries < 10) {
							tries++;
							checkSocket();
						}
					}, 750);
				};

				checkSocket();
			}
		}).then(handle => this.dispose.track(handle.remove));
	}
}

// for better typing instead of using `as`.
function expectValueOfType<T>(obj: T): T {
	return obj;
}
