import { AlbumFragment, ChangeAlbumOrder } from '@generated/graphql';
import { useMutation } from '@knuddels-app/Connection';
import { useService } from '@knuddels-app/DependencyInjection';
import { $SnackbarService } from '@knuddels-app/SnackbarManager';
import { moveElementInArray } from '@knuddels-app/tools/moveElementInArray';
import { Flex, FlexCol, IconChevronRight, Input, LineDivider, resolveThemingValue, useTheme } from '@knuddels/component-library';
import * as React from 'react';
import { useRefetchProfile, useUpdateProfileAlbums } from '../../../profileQueryHelper';
import { useTrackOwnProfileView } from '../../../shared/useTrackOwnProfileView';
import { OpenEditAlbumContextMenu } from '../../EditAlbumContextMenu/OpenEditAlbumContextMenu';
import { useScrollIntoView } from '../EditProfileContent/Entry/useScrollIntoView';
import { AlbumsEditor } from '../EditProfileContent/logic/AlbumsEditor';
import { $I18n, declareFormat } from '@knuddels-app/i18n';
export const EditAlbumHeader: React.FC<{
  album: AlbumFragment;
  editor: AlbumsEditor;
  index: number;
  albumCount: number;
  onTitleChange(newTitle: string): void;
}> = ({
  album,
  editor,
  onTitleChange,
  index,
  albumCount
}) => {
  const {
    ref,
    onFocus
  } = useScrollIntoView({
    useScrollTop: false
  });
  const [title, setTitle] = React.useState(editor.albumEntries[album.id]?.newTitle || album.title);
  const i18n = useService($I18n);
  return <div className={_c0}>
			<ChangeOrderButtons albumId={album.id} position={index} albumCount={albumCount} />
			<div className={_c1}>
				<Input innerRef={ref} onFocus={onFocus} value={title} onChange={({
        value: v
      }) => {
        setTitle(v);
        onTitleChange(v);
      }} size={'condensed'} error={title ? undefined : declareFormat({
        id: 'profile.edit.error.empty-album-title',
        defaultFormat: 'Title must not be empty'
      }).format(i18n)} />
			</div>
			<OpenEditAlbumContextMenu albumId={album.id} />
		</div>;
};
const ChangeOrderButtons: React.FC<{
  albumId: string;
  position: number;
  albumCount: number;
}> = props => {
  const updatePosition = useUpdatePosition(props.position);
  return <div className={_c2}>
			<IconChevron direction={'up'} disabled={props.position === 0} onPress={() => updatePosition(props.position - 1)} />
			<div className={_c3}>
				<LineDivider className={_c4} />
			</div>
			<IconChevron direction={'down'} disabled={props.position === props.albumCount - 1} onPress={() => updatePosition(props.position + 1)} />
		</div>;
};
type Direction = 'right' | 'left' | 'up' | 'down';
const rotationMap: { [key in Direction]: number } = {
  right: 0,
  down: 90,
  left: 180,
  up: 270
};
const IconChevron: React.FC<React.ComponentProps<typeof IconChevronRight> & {
  direction: Direction;
}> = ({
  direction,
  ...props
}) => {
  return <div style={{
    transform: resolveThemingValue(`rotate(${rotationMap[direction]}deg)`, "theme", useTheme())
  }} className={_c5}>
			<IconChevronRight size={'base'} {...props} />
		</div>;
};
const useUpdatePosition = (currentPosition: number) => {
  const track = useTrackOwnProfileView();
  const [updateAlbumOrder] = useMutation(ChangeAlbumOrder);
  const snackbarService = useService($SnackbarService);
  const updateProfileAlbums = useUpdateProfileAlbums();
  const refetchProfile = useRefetchProfile();
  return (newPosition: number) => {
    track('AlbumEdit_ChangeAlbumOrder');
    updateProfileAlbums(oldData => {
      const oldAlbums = [...oldData.albums];
      const newAlbums = moveElementInArray(oldAlbums, currentPosition, newPosition);
      updateAlbumOrder({
        newAlbumOrderIds: newAlbums.map(a => a.id)
      }).then(r => {
        if (!r.data || r.data.__typename !== 'Success') {
          snackbarService.showGenericError();
          refetchProfile();
        }
      });
      return {
        ...oldData,
        albums: newAlbums
      };
    });
  };
};
const _c0 = " Knu-Flex pl-base pt-small alignItems-flex-start minHeight-76px ";
const _c1 = " Knu-FlexCol flex-1 minWidth-0-px py-small pl-minor ";
const _c2 = " Knu-Flex borderRadius-small borderStyle-solid borderWidth-base borderColor-hairlineAtom alignItems-center mt-small ";
const _c3 = " Knu-FlexCol alignSelf-stretch py-tiny ";
const _c4 = "  ";
const _c5 = " Knu-Flex ";