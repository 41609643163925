import { circOut as R } from "../../easing/circ.js";
import { progress as h } from "../../utils/progress.js";
import { mixNumber as f } from "../../utils/mix/number.js";
import { noop as B } from "../../utils/noop.js";
import { percent as u, px as L } from "../../value/types/numbers/units.js";
const a = ["TopLeft", "TopRight", "BottomLeft", "BottomRight"], M = a.length, p = (t) => typeof t == "string" ? parseFloat(t) : t, d = (t) => typeof t == "number" || L.test(t);
function V(t, i, o, n, b, x) {
  b ? (t.opacity = f(
    0,
    // TODO Reinstate this if only child
    o.opacity !== void 0 ? o.opacity : 1,
    C(n)
  ), t.opacityExit = f(i.opacity !== void 0 ? i.opacity : 1, 0, O(n))) : x && (t.opacity = f(i.opacity !== void 0 ? i.opacity : 1, o.opacity !== void 0 ? o.opacity : 1, n));
  for (let s = 0; s < M; s++) {
    const c = `border${a[s]}Radius`;
    let r = m(i, c), e = m(o, c);
    if (r === void 0 && e === void 0)
      continue;
    r || (r = 0), e || (e = 0), r === 0 || e === 0 || d(r) === d(e) ? (t[c] = Math.max(f(p(r), p(e), n), 0), (u.test(e) || u.test(r)) && (t[c] += "%")) : t[c] = e;
  }
  (i.rotate || o.rotate) && (t.rotate = f(i.rotate || 0, o.rotate || 0, n));
}
function m(t, i) {
  return t[i] !== void 0 ? t[i] : t.borderRadius;
}
const C = /* @__PURE__ */ y(0, 0.5, R), O = /* @__PURE__ */ y(0.5, 0.95, B);
function y(t, i, o) {
  return (n) => n < t ? 0 : n > i ? 1 : o(h(t, i, n));
}
export {
  V as mixValues
};
