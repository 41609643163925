import * as React from 'react';
import { ContactsView } from './ContactsView/ContactsView';
import { inject, injectable, injectedComponent, injectProps } from '@knuddels-app/DependencyInjection';
import { User } from '@generated/graphql';
import { $ProfileNavigationService } from '@knuddelsModules/Profile';
import { FlexCol, IconSettings } from '@knuddels/component-library';
import { useParams, useRouter } from './ContactsRouter';
@injectable()
class ContactsModel {
  constructor(@injectProps()
  private readonly props: unknown, @inject.lazy($ProfileNavigationService)
  private readonly getProfileNavigationService: typeof $ProfileNavigationService.TLazy) {}
  handleContactClicked = (id: User['id']): void => {
    this.getProfileNavigationService().then(service => service.showProfile(id, 'Contacts'));
  };
}
export const Contacts = injectedComponent({
  name: 'Contacts',
  model: ContactsModel
}, ({
  model
}) => {
  const {
    configureNavBar,
    navigate
  } = useRouter();
  const params = useParams('contacts');
  React.useLayoutEffect(() => {
    configureNavBar({
      iconList: [<IconSettings key={'settings'} size={'large'} onPress={() => navigate('contacts-settings')} />]
    });
  }, []);
  return <div className={_c0}>
				<ContactsView initialTab={params?.initialTab} onContactClicked={model.handleContactClicked} />
			</div>;
});
const _c0 = " Knu-FlexCol flex-1 height-full ";