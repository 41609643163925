import {
	BugIndicatingError,
	Disposable,
	err,
	ok,
	Result,
	transformKey,
} from '@knuddels/std';
import { inject, injectable } from '@knuddels-app/DependencyInjection';
import { $Environment } from '@knuddels-app/Environment';
import { openInNewTab } from '@shared/helper/openUrl';
import { $GenericUserEventService } from '@knuddels-app/analytics/generic';
import { $SystemAppPerformanceService } from '@knuddelsModules/Apps';

export interface CommandDefinition {
	/** The name without leading slash */
	commandName: string;

	invoke(parameter: string, context?: string): Promise<void>;

	/**
	 * If it returns false, the invoke method will not be called.
	 * Used to prevent a command from being executed without
	 * polluting the default case (i.e. handling the command)
	 * @param parameter
	 * @param context Optional context in which the command is being invoked.
	 */
	shouldInvoke?(parameter: string, context?: string): boolean;
}

export interface CommandListenerDefinition {
	commandName: string;

	/**
	 * Called whenever the command is invoked. This is called for any
	 * kind of invocation: channel, non-channel, or client side invocation.
	 */
	onInvoked(parameter: string, context?: string): void;
}

export interface FallbackCommandHandler {
	invoke(
		commandName: string,
		parameter: string,
		context?: string
	): Promise<boolean>;
}

@injectable()
export class CommandService {
	private readonly registeredCommands = transformKey(
		(key: string) => key.toLowerCase(),
		new Map<string, CommandDefinition>()
	);

	private readonly registeredCommandListeners = transformKey(
		(key: string) => key.toLowerCase(),
		new Map<string, CommandListenerDefinition[]>()
	);

	private fallbackCommandHandler: FallbackCommandHandler | undefined =
		undefined;

	constructor(
		@inject($Environment)
		private readonly environment: typeof $Environment.T,
		@inject.lazy($SystemAppPerformanceService)
		private readonly systemAppPerformanceService: typeof $SystemAppPerformanceService.TLazy,
		@inject($GenericUserEventService)
		private readonly genericUserEventService: typeof $GenericUserEventService.T
	) {}

	/**
	 * Registers a listener for a certain command. Use this if something
	 * needs to happen when the command is executed but you don't want to
	 * interrupt the default command execution.
	 */
	public registerCommandListener(
		definition: CommandListenerDefinition
	): Disposable {
		if (!this.registeredCommandListeners.has(definition.commandName)) {
			this.registeredCommandListeners.set(definition.commandName, []);
		}

		this.registeredCommandListeners
			.get(definition.commandName)!
			.push(definition);

		return Disposable.create(() => {
			const listeners = this.registeredCommandListeners.get(
				definition.commandName
			);
			if (listeners) {
				const index = listeners.indexOf(definition);
				if (index !== -1) {
					listeners.splice(index, 1);
				}
			}
		});
	}

	public registerCommand(definition: CommandDefinition): Disposable {
		if (this.registeredCommands.has(definition.commandName)) {
			return;
			throw new BugIndicatingError(
				`A command with name "${definition.commandName}" has already been registered!`
			);
		}
		this.registeredCommands.set(definition.commandName, definition);

		return Disposable.create(() => {
			this.registeredCommands.delete(definition.commandName);
		});
	}

	public registerOpenUrlCommand({
		commandName,
		url,
		shouldInvokeWithParameter = false,
	}: {
		commandName: string;
		url: string;
		shouldInvokeWithParameter?: boolean;
	}): Disposable {
		return this.registerCommand({
			commandName,
			invoke: async (): Promise<void> => {
				openInNewTab(url);
			},
			shouldInvoke(parameter: string): boolean {
				return shouldInvokeWithParameter || !parameter.trim();
			},
		});
	}

	public setFallbackCommandHandler(
		handler: FallbackCommandHandler
	): Disposable {
		if (this.fallbackCommandHandler) {
			throw new BugIndicatingError(
				'There is already a fallback command handler!'
			);
		}

		this.fallbackCommandHandler = handler;

		return Disposable.create(() => {
			this.fallbackCommandHandler = undefined;
		});
	}

	public async parseAndInvokeCommand(
		commandString: string,
		context?: string
	): Promise<
		Result<
			void,
			| 'NoCommandDefinitionFound'
			| 'CommandNotSuccessful'
			| 'CommandNotParsable'
		>
	> {
		const command = this.tryParseCommandCall(commandString);

		if (command) {
			return this.invokeCommand(command, context);
		}

		return err('CommandNotParsable');
	}

	public tryParseCommandCall(
		text: string
	): { commandName: string; parameter: string } | undefined {
		const groups = text.match(/^\/(.*?)( (.*))?$/);
		if (!groups || !groups[1]) {
			return undefined;
		}
		const commandName = groups[1].toLowerCase();
		const parameter = groups[3] || '';

		return {
			commandName,
			parameter,
		};
	}

	public async invokeCommand(
		commandCall: {
			commandName: string;
			parameter: string;
		},
		context?: string,
		explicitlyTriggeredByUser = false
	): Promise<
		Result<void, 'NoCommandDefinitionFound' | 'CommandNotSuccessful'>
	> {
		const systemAppPerformanceService =
			await this.systemAppPerformanceService();
		systemAppPerformanceService.handleCommandCall(commandCall);

		this.genericUserEventService.reportEvent({
			type: 'Executed_SlashCommand',
			command: commandCall.commandName,
			parameter: commandCall.parameter
				? commandCall.parameter.split(':')
				: [],
			context: context || 'unknown',
			triggeredByUserInput: explicitlyTriggeredByUser,
		});
		if (this.environment.messengerSystemAppInterface) {
			// if we want some commands to be handled by this client (currently none),
			// then we can extend the registerSlashCommandHandler and add another parameter
			// like "forceHandleByK3" or "dontSendToEmbeddedClient"...

			this.environment.messengerSystemAppInterface.client.executeSlashCommand(
				`/${commandCall.commandName} ${commandCall.parameter}`
			);
			return ok();
		}

		const def = this.registeredCommands.get(commandCall.commandName);
		if (
			def &&
			(!def.shouldInvoke ||
				def.shouldInvoke(commandCall.parameter, context))
		) {
			this.invokeListeners(commandCall, context);

			await def.invoke(commandCall.parameter, context);

			return ok();
		}

		if (this.fallbackCommandHandler) {
			const success = await this.fallbackCommandHandler.invoke(
				commandCall.commandName,
				commandCall.parameter,
				context
			);

			return success ? ok() : err('CommandNotSuccessful');
		}

		return err('NoCommandDefinitionFound');
	}

	public shouldInvoke(commandCall: {
		commandName: string;
		parameter: string;
	}): boolean {
		const commandDefinition = this.registeredCommands.get(
			commandCall.commandName
		);

		if (!commandDefinition) {
			return false;
		}

		return (
			!commandDefinition.shouldInvoke ||
			commandDefinition.shouldInvoke(commandCall.parameter)
		);
	}

	public invokeListeners(
		commandCall: {
			commandName: string;
			parameter: string;
		},
		context?: string
	): void {
		const listeners = this.registeredCommandListeners.get(
			commandCall.commandName
		);
		if (listeners) {
			for (const listener of listeners) {
				listener.onInvoked(commandCall.parameter, context);
			}
		}
	}
}
