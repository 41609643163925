import { ChannelGroupInfo } from '@generated/graphql';
import { FormattedText, Spacer } from '@shared/components';
import { MessengerMessageType } from '@knuddelsModules/Messenger';
import * as React from 'react';
import { AggregatedMessage, ChatItem } from './ChatItem';
import { ChannelActionMessageGroup } from './ChatItems/ChannelActionMessageGroup';
import { ChannelMessageGroup } from './ChatItems/ChannelMessageGroup';
import { getMessageGroupKey, MessengerMessageGroup } from './ChatItems/MessengerMessageGroup';
import { PrivateGroupMessageGroup } from './ChatItems/PrivateGroupMessageGroup';
import { FlexCol, ThemeOverride, resolveThemingValue, useTheme } from '@knuddels/component-library';
import { ChatRenderConfig } from '../ChatRenderConfig';
import { ImageGroupClickOnceIdContext } from '@shared/components/molecules/FormattedText/ImageGroup';
export type ChatItemFactoryArgs = {
  message: AggregatedMessage;
  firstOfSet: boolean;
  lastOfSet: boolean;
  animated: boolean;
  isStackedLayout: boolean;
  channelGroupInfo: Pick<ChannelGroupInfo, 'backgroundColor' | 'highlightColor'>;
} | {
  uniqueKey: string;
  spacerHeight: number | 'minor' | 'small';
};
function determineTypeFromFormattedText(formattedText: string): FormattedText['kind'] {
  const ft = FormattedText.fromJsonString(formattedText);
  if (ft.kind === 'List') {
    if (!ft.containsImages) {
      return 'Text';
    }
    return 'List';
  }
  return ft.kind;
}
function getMessageComponent(args: {
  message: AggregatedMessage;
  firstOfSet: boolean;
  lastOfSet: boolean;
  animated: boolean;
  isStackedLayout: boolean;
  renderConfig: ChatRenderConfig;
  channelGroupInfo: Pick<ChannelGroupInfo, 'backgroundColor' | 'highlightColor'>;
}): {
  uniqueKey: string;
  type: string;
  node: JSX.Element;
} {
  if (args.message.__typename === MessengerMessageType) {
    return {
      uniqueKey: getMessageGroupKey(args.message),
      type: 'messenger-message-group',
      node: <MessengerMessageGroup renderConfig={args.renderConfig} event={args.message} firstOfSet={args.firstOfSet} lastOfSet={args.lastOfSet} animated={args.animated} isStackedLayout={args.isStackedLayout} channelGroupInfo={args.channelGroupInfo} />
    };
  } else if (args.message.__typename === 'ChannelMsgAction') {
    return {
      type: 'channel-action-message-group',
      uniqueKey: ChannelActionMessageGroup.getKey(args.message),
      node: <ChannelActionMessageGroup renderConfig={args.renderConfig} message={args.message} animated={args.animated} isStackedLayout={args.isStackedLayout} />
    };
  } else if (args.message.__typename === 'ChannelMsgPrivateGroup') {
    return {
      type: 'private-group-message-group',
      uniqueKey: 'private_group_' + args.message.id,
      node: <PrivateGroupMessageGroup renderConfig={args.renderConfig} message={args.message} animated={args.animated} isStackedLayout={args.isStackedLayout} channelGroupInfo={args.channelGroupInfo} firstOfSet={args.firstOfSet} lastOfSet={args.lastOfSet} />
    };
  } else {
    return {
      type: 'channel-message-group' + determineTypeFromFormattedText(args.message.formattedText),
      uniqueKey: ChannelMessageGroup.getKey(args.message),
      node: <ChannelMessageGroup renderConfig={args.renderConfig} message={args.message} firstOfSet={args.firstOfSet} animated={args.animated} isStackedLayout={args.isStackedLayout} />
    };
  }
}
const FlexSpacer: React.FC<{
  height: number;
}> = ({
  height
}) => <div style={{
  height: resolveThemingValue(height, "sizes", useTheme())
}} className={_c0} />;
export class ChatItemFactory {
  static create(args: ChatItemFactoryArgs, renderConfig: ChatRenderConfig): ChatItem {
    if ('spacerHeight' in args) {
      if (typeof args.spacerHeight === 'number') {
        return {
          type: 'spacer-flex',
          uniqueKey: args.uniqueKey,
          node: <FlexSpacer height={args.spacerHeight} />
        };
      } else {
        return {
          type: 'spacer',
          uniqueKey: args.uniqueKey,
          node: <Spacer size={args.spacerHeight} />
        };
      }
    }
    const marginTop = args.firstOfSet ? (renderConfig.layoutService.messageSpacingPx as ThemeOverride) : 'tiny';
    const messageCmp = getMessageComponent({
      ...args,
      renderConfig
    });
    const actualNode = messageCmp.node;
    messageCmp.node = <FlexCol mt={marginTop}>
				<ImageGroupClickOnceIdContext.Provider value={'chat_' + messageCmp.uniqueKey}>
					{actualNode}
				</ImageGroupClickOnceIdContext.Provider>
			</FlexCol>;
    return messageCmp;
  }
}
const _c0 = " Knu-FlexCol ";
const _c1 = " Knu-FlexCol ";