import { scale as t, alpha as s } from "../../../value/types/numbers/index.js";
import { degrees as r, px as e, progressPercentage as a } from "../../../value/types/numbers/units.js";
const n = {
  rotate: r,
  rotateX: r,
  rotateY: r,
  rotateZ: r,
  scale: t,
  scaleX: t,
  scaleY: t,
  scaleZ: t,
  skew: r,
  skewX: r,
  skewY: r,
  distance: e,
  translateX: e,
  translateY: e,
  translateZ: e,
  x: e,
  y: e,
  z: e,
  perspective: e,
  transformPerspective: e,
  opacity: s,
  originX: a,
  originY: a,
  originZ: e
};
export {
  n as transformValueTypes
};
