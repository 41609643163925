import * as React from 'react';
import { inputBarEvent, sendMessageEvent, slashCommandEvent } from '@knuddelsModules/Channel/analytics';
import { $ProfileNavigationService } from '@knuddelsModules/Profile';
import { declareProps, IModel, inject, injectable, injectedComponent, injectProps, useService } from '@knuddels-app/DependencyInjection';
import { action } from '@knuddels-app/mobx';
import { $CommandService } from '@knuddels-app/Commands/serviceIds';
import { CHANNEL_COMMAND_CONTEXT, ChannelInfo } from '../../../services';
import { BugIndicatingError, Disposable } from '@knuddels/std';
import { $FirebaseAnalyticsService } from '@knuddels-app/analytics/firebase';
import { registerTextFieldCommands } from '../../../services/commands/TextFieldCommands';
import { Flex } from '@knuddels/component-library';
import { $ChannelAppViewerAnimationService } from '@knuddelsModules/Apps';
import { RichTextEditorRef } from '@shared/components/RichText/RichTextEditor';
import { RichInput } from '@shared/components/RichInput';
import { ChatInputPlugins } from '@knuddelsModules/Channel/bundle/components/Chat/ChatInput/ChatInputPlugins';
import { $createTextNode, LexicalNode } from 'lexical';
import { $createMentionNode } from '@shared/components/RichText/nodes/MentionNode';
import { SlashPlugin } from '@knuddelsModules/Channel/bundle/components/Chat/ChatInput/SlashPlugin';
import { $AutocompleteProviderService } from '@knuddelsModules/AutocompleteInputBar';
@injectable()
class ChatInputModel implements IModel {
  public readonly dispose = Disposable.fn();
  public editor: RichTextEditorRef;
  public get isDisabled(): boolean {
    return !this.props.channel;
  }
  public get preventEvents(): boolean {
    return this.channelAppViewerAnimationService.state === 'full';
  }
  constructor(@injectProps()
  private props: {
    blurRef: React.MutableRefObject<{
      blur(): void;
    }>;
    channel: ChannelInfo | undefined;
    messageWasSent(): void;
    onSmileyboxOpened?(): void;
    onSmileyboxClosed?(): void;
  }, @inject($CommandService)
  private readonly commandService: typeof $CommandService.T, @inject($FirebaseAnalyticsService)
  private readonly firebaseAnalyticsService: typeof $FirebaseAnalyticsService.T, @inject.lazy($ProfileNavigationService)
  private readonly getProfileNavigationService: typeof $ProfileNavigationService.TLazy, @inject($ChannelAppViewerAnimationService)
  private readonly channelAppViewerAnimationService: typeof $ChannelAppViewerAnimationService.T) {}
  componentDidMount(): void {
    this.dispose.track(registerTextFieldCommands(this.commandService, () => this.editor, fragmentsToNodes));
  }
  public readonly sendText = (text: string): void => {
    const fullText = this.handleSlashCommands(text.trim().replace(/\n/g, '#'));
    const backendText = this.convertToBackendMentionLinks(fullText);
    const command = this.commandService.tryParseCommandCall(backendText);
    if (!this.props.channel) {
      throw new BugIndicatingError('No channel');
    }
    if (!command) {
      // normal public message
      sendMessageEvent.track(`SendMessage_PublicMessage`);
      this.props.channel.send(backendText);
    } else {
      slashCommandEvent.track(`/-CommandEntered_${command.commandName}`);
      this.commandService.invokeCommand(command, CHANNEL_COMMAND_CONTEXT, true);
    }
    if (this.props.messageWasSent) {
      this.props.messageWasSent();
    }
  };
  public validateInput = (text: string): boolean => {
    return text.trim().length > 0;
  };
  @action.bound
  public handleTextChanged(): void {
    this.getProfileNavigationService().then(service => {
      const quickWhois = service.findQuickWhoisOverlay();
      if (quickWhois) {
        quickWhois.dispose();
      }
    });
  }
  public readonly handleReturnKeySubmit = (): void => {
    inputBarEvent.track(`InputBar_EnterUsed`);
  };
  public readonly handleSubmitButtonClick = (): void => {
    inputBarEvent.track(`InputBar_SendButtonUsed`);
  };
  public readonly handleFocus = (): void => {
    inputBarEvent.track('InputBar_Focused');
  };
  public readonly handleBlur = (): void => {
    inputBarEvent.track('InputBar_Unfocused');
  };
  public handleSmileyboxClosed = (): void => {
    inputBarEvent.track('SmileyboxClosed');
    this.props.onSmileyboxClosed?.();
  };
  public handleSmileyboxOpened = (): void => {
    inputBarEvent.track('SmileyboxOpened');
    this.props.onSmileyboxOpened?.();
  };
  public trackAutoComplete = (itemId: string): void => {
    this.firebaseAnalyticsService.logEvent('Chat_Chatraum', itemId);
  };
  private handleSlashCommands(text: string): string {
    if (!this.isSlashCommand(text)) {
      return text;
    }
    if (this.isSpecialHandlingSlashCommand(text)) {
      // For /p and /m we want to keep mention links for the free text parameter (everything after first colon)
      const indexOfFirstColon = text.indexOf(':');
      if (indexOfFirstColon === -1) {
        return this.removeDoubleSpaces(this.stripMentionLinks(text));
      }
      const command = text.substring(0, indexOfFirstColon);
      const freeText = text.substring(indexOfFirstColon + 1);
      return this.removeDoubleSpaces(this.stripMentionLinks(command)) + ':' + freeText;
    }
    return this.removeDoubleSpaces(this.stripMentionLinks(text));
  }
  private isSlashCommand(text: string): boolean {
    return text.startsWith('/');
  }
  private isSpecialHandlingSlashCommand(text: string): boolean {
    return text.startsWith('/p ') || text.startsWith('/m ');
  }
  private stripMentionLinks = (text: string): string => {
    return text.replace(/\$\$.(.+?)\$\$/g, '$1');
  };
  private convertToBackendMentionLinks = (text: string): string => {
    return text.replace(/\$\$(.+?)\$\$/g, '{$1}');
  };
  private removeDoubleSpaces = (text: string): string => {
    return text.replace(/\s\s+?/g, ' ');
  };
}
export const ChatInput = injectedComponent({
  model: ChatInputModel,
  name: 'ChatInput',
  props: declareProps<{
    mode?: 'new' | 'default';
  }>()
}, ({
  model
}) => {
  const editorRef = React.useRef<RichTextEditorRef>(null);
  const autocompleteProviderService = useService($AutocompleteProviderService);
  React.useEffect(() => {
    model.editor = editorRef.current;
  }, [editorRef.current]);
  return <div className={_c0 + (model.preventEvents ? _c1 : _c2)}>
				<RichInput context={'chat'} mode={'inline-chat'} editorRef={editorRef} onSubmit={model.sendText} plugins={(anchorRef: React.RefObject<HTMLDivElement>) => {
      return <>
								{autocompleteProviderService.autocompleteSlashCommands && <SlashPlugin anchor={anchorRef} />}

								{ChatInputPlugins(anchorRef)}
							</>;
    }} />
			</div>;
});
const fragmentsToNodes = (fragments: string[]): LexicalNode[] => {
  return fragments.map(mapTextToNodes);
};
const mapTextToNodes = (text: string): LexicalNode => {
  if (text.startsWith('@')) {
    return $createMentionNode({
      text: text.slice(1),
      trigger: '@',
      class: 'user-mention'
    });
  }
  return $createTextNode(text);
};
const _c0 = " Knu-Flex position-relative overflow-hidden shadow-Shadow4 ";
const _c1 = " pointerEvents-none ";
const _c2 = " pointerEvents-all ";