import * as React from 'react';
import { createAccessiblePointerEventHandler, createSvgIcon, Flex, FlexCol, LineDivider, Text, ThemeOverride, createNativeAccessiblePointerEventHandler, resolveThemingValue, resolveIsDarkColor, useTheme } from '@knuddels/component-library';
import { declareFormat, FormattedMessage } from '@knuddels-app/i18n';
import { useTrackConversation } from '../../useTrackConversation';
import { Spacer } from '@shared/components';
import { PrivateMessageFooter } from './PrivateMessageFooter';
import { UserImage } from '@knuddelsModules/UserData';
import { useSlashCommandWithoutChannel } from '@knuddels-app/Commands/useSlashCommandWithoutChannel';
import { useHovered } from '@knuddels-app/tools/useHovered';
import { useService } from '@knuddels-app/DependencyInjection';
import { $MessengerConversationService } from '../../../../../providedServices';
import { useConversationId } from '../ConversationIdContext';
import { SuggestionsType } from '../../../../services/conversationServices/MessengerConversationService';
import { useOpenProfile } from '@knuddelsModules/Profile';
import { TextStates } from '@shared/components/atoms/Texts/TextProps';
export const NickSwitchMessage: React.FC<{
  senderId: string;
  messageId: string;
  oldNick: string;
  newNick: string;
}> = ({
  senderId,
  messageId,
  oldNick,
  newNick
}) => {
  const track = useTrackConversation();
  const execute = useSlashCommandWithoutChannel();
  const {
    onPress
  } = useCongratulate(messageId);
  const openProfile = useOpenProfile({
    id: senderId,
    nick: senderId
  }, () => track('SystemMessages_NickSwitchMessageOpenProfile'));
  React.useEffect(() => {
    track('SystemMessages_NickSwitchMessageShown');
  }, []);
  return <div className={_c0}>
			<div className={_c1 + ("contentLightBg" ? resolveIsDarkColor("contentLightBg", useTheme()) ? " content-is-dark" : " content-is-light" : "")}>
				<div className={_c2}>
					<IconInfoOutline hoverColor={'black-transparent-660'} darkHoverColor={'white-transparent-660'} onPress={() => {
          execute('/helpcenter https://hilfe.knuddels.de/de/articles/3785067');
        }} />
				</div>
				<div {...createNativeAccessiblePointerEventHandler({
        pointerCallback: () => openProfile('NickSwitchMessage')
      })} className={_c3}>
					<UserImage type={40} userId={senderId} />
					<div className={_c4}>
						<Text type={'tiny'} state={'tertiary'} numberOfLines={1} className={_c5}>
							<FormattedMessage id={declareFormat({
              id: 'conversation.message.nickswitch',
              defaultFormat: '{nick} is now called'
            })} values={{
              nick: oldNick
            }} />
						</Text>
						<Text bold={true} numberOfLines={1} className={_c6}>
							{newNick}
						</Text>
					</div>
				</div>
				<div className={_c7}>
					<LineDivider color={'hairlineAtom'} className={_c8} />
				</div>
				<CongratulateButton onPress={onPress} />
			</div>
			<Spacer size={'minor'} />
			<PrivateMessageFooter />
		</div>;
};
const CongratulateButton: React.FC<{
  onPress: () => void;
  disabled?: boolean;
}> = ({
  onPress,
  disabled = false
}) => {
  const {
    bind,
    hovered
  } = useHovered();
  return <FlexCol alignItems={'center'} py={'minor'} {...bind} {...createAccessiblePointerEventHandler({
    pointerCallback: onPress,
    disabled
  })}>
			<Text bold={true} state={((disabled ? 'disabled' : hovered ? 'secondary' : 'tertiary') as TextStates)} className={_c9}>
				<FormattedMessage id={declareFormat({
        id: 'conversation.message.nickswitch.button',
        defaultFormat: 'Congratulate'
      })} />
			</Text>
		</FlexCol>;
};
const IconInfoOutline = createSvgIcon(<svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M10 0C4.489 0 0 4.489 0 10C0 15.511 4.489 20 10 20C15.511 20 20 15.511 20 10C20 4.489 15.511 0 10 0ZM10 2C14.4301 2 18 5.56988 18 10C18 14.4301 14.4301 18 10 18C5.56988 18 2 14.4301 2 10C2 5.56988 5.56988 2 10 2ZM9 5V7H11V5H9ZM9 9V15H11V9H9Z" fill="currentColor" />
	</svg>);
IconInfoOutline.displayName = 'IconInfoOutline';
const useCongratulate = (messageId: string) => {
  const track = useTrackConversation();
  const messengerConversationService = useService($MessengerConversationService);
  const conversationId = useConversationId();
  const clientConversationState = messengerConversationService.getOrCreateClientConversationState(conversationId);
  return {
    onPress: () => {
      track('NickSwitchMessage_CongratulateClicked');
      clientConversationState.setSelectedMessageIdForSuggestions(messageId, SuggestionsType.CongratulateReactions);
    }
  };
};
const _c0 = " Knu-FlexCol alignItems-center mt-small py-small ";
const _c1 = " Knu-FlexCol position-relative overflow-visible shadow-Shadow2 bg-contentLightBg borderRadius-large mt-36px mx-xlarge width-288px ";
const _c2 = " Knu-Flex position-absolute right-none top-none ";
const _c3 = " Knu-Flex cursor-pointer px-44px pt-20px pb-minor alignItems-center ";
const _c4 = " Knu-FlexCol ml-minor flex-1 ";
const _c5 = "  ";
const _c6 = "  ";
const _c7 = " Knu-Flex ";
const _c8 = "  ";
const _c9 = "  ";