import * as React from 'react';
import { Box, Fade, Flex, FlexCol, IconClose, rgb, ScrollView, useIsDarkColor, toPointerHandler, FallbackBox, resolveThemingValue, resolveIsDarkColor, useTheme } from '@knuddels/component-library';
import { GetCachedUserData, GetUserForMacroBox } from '@generated/graphql';
import { useService } from '@knuddels-app/DependencyInjection';
import { $ThisVisibleOverlay } from '@knuddels-app/overlays';
import { MacroBoxContent } from './MacroBoxContent';
import { OperationResult, useQuery } from '@knuddels-app/Connection';
import { getPixelRatio } from '@knuddels-app/tools/getPixelRatio';
import { $ViewService } from '@knuddels-app/layout';
import { $ActiveChannelService, channelViewId } from '@knuddelsModules/Channel';
import { $ScreenService, useIsStackedLayout } from '@knuddels-app/Screen';
import { MacroBoxHeader } from './MacroBoxHeader';
import { observer } from '@knuddels-app/mobx';
import { MacroBoxFooter } from './MacroBoxFooter';
import { dismissKeyboard } from '@shared/helper/dismissKeyboard';
import { useLogEvent } from '@knuddels-app/analytics/firebase/useLogEvent';
import styles from './MakroBox.module.scss';
import { useDrag } from '@use-gesture/react';
import { SkeletonAnimation } from '@shared/components';
import { MacroBoxDataTypes } from '@knuddelsModules/Channel/bundle/components/Macrobox/MacroBoxDataTypes';
import { $KeyboardService } from '@knuddels-app/Keyboard';
import { $AdsService } from '@knuddelsModules/Ads';
import { $GenericUserEventService } from '@knuddels-app/analytics/generic';
type MacroBoxProps = {
  user: {
    id: string;
    nick?: string;
  };
};
export const MacroBox: React.FC<MacroBoxProps> = observer('MacroBox', ({
  user
}) => {
  const track = useLogEvent();
  React.useEffect(() => {
    track('Chat_MacroQuickAccess', 'Opened');
    return () => {
      track('Chat_MacroQuickAccess', 'Closed');
    };
  }, []);
  const isStackedLayout = useIsStackedLayout();
  const {
    data: cachedUser
  } = useQuery(GetCachedUserData, {
    userId: user.id,
    pixelDensity: getPixelRatio()
  }, 'cache-only', {
    errorPolicy: 'ignore'
  });
  const {
    data
  } = useQuery(GetUserForMacroBox, {
    userId: user.id,
    pixelDensity: getPixelRatio()
  }, 'no-cache');
  const mergedData = {
    ...cachedUser,
    ...(data ?? {})
  };
  return isStackedLayout ? <MacroBoxBottomSheet user={user} data={mergedData} /> : <MacroBoxModal user={user} data={mergedData} />;
});
type MacroBoxInternalProps = MacroBoxProps & {
  data: MacroBoxDataTypes;
};
const MacroBoxModal: React.FC<MacroBoxInternalProps> = ({
  user,
  data
}) => {
  const close = useService($ThisVisibleOverlay).dispose;
  if (!data) {
    return null;
  }
  return <div className={_c0 + ("backgroundScrim" ? resolveIsDarkColor("backgroundScrim", useTheme()) ? " content-is-dark" : " content-is-light" : "")}>
			<div onClick={toPointerHandler(close)} className={_c1 + ("transparent" ? resolveIsDarkColor("transparent", useTheme()) ? " content-is-dark" : " content-is-light" : "")} />
			<div className={_c2 + ("contentBg" ? resolveIsDarkColor("contentBg", useTheme()) ? " content-is-dark" : " content-is-light" : "")}>
				<MacroBoxHeader userId={user.id} user={data} close={close} isSmallHeight={false} />

				<div className={_c3}>
					<IconClose size={'base'} onPress={close} />
				</div>

				<div className={_c4 + ("contentBg" ? resolveIsDarkColor("contentBg", useTheme()) ? " content-is-dark" : " content-is-light" : "")}>
					<MacroBoxContent user={data} close={close} />
				</div>

				{!isFullData(data) && <InputSkeleton />}
				{isFullData(data) && data.canReceiveMessages && <MacroBoxFooter user={data} />}
			</div>
		</div>;
};
const isFullData = (data: MacroBoxDataTypes): data is OperationResult<typeof GetUserForMacroBox.TPrimaryResult>['data'] => {
  return 'canReceiveMessages' in data;
};
const MacroBoxBottomSheet: React.FC<MacroBoxInternalProps> = observer('MacroBoxBottomSheet', ({
  user,
  data
}) => {
  const isAnimating = React.useRef(false);
  const viewHeight = React.useRef(0);
  const domRef = React.useRef<HTMLDivElement | null>(null);
  const overlay = useService($ThisVisibleOverlay);
  const viewService = useService($ViewService);
  const keyboardService = useService($KeyboardService);
  const adsService = useService($AdsService);
  const activeChannelService = useService($ActiveChannelService);
  const genericUserEventService = useService($GenericUserEventService);
  const backgroundColor = activeChannelService.activeChannel?.groupInfo?.backgroundColor;
  React.useEffect(() => {
    genericUserEventService.reportEvent({
      type: 'Macrobox_Opened',
      nick: user.nick
    });
  }, []);
  const bgDark = useIsDarkColor(backgroundColor ? rgb(backgroundColor.red, backgroundColor.green, backgroundColor.blue) : undefined);
  const bind = useDrag(({
    movement,
    velocity,
    dragging,
    first
  }) => {
    const domElement = domRef.current;
    if (!domElement) {
      return;
    }
    const translateY = Math.max(0, movement[1] / 2);
    if (first) {
      domElement.style.animation = 'none';
    }
    domElement.style.transform = `translateY(${translateY}px)`;
    if (!dragging) {
      if (translateY > 92) {
        const animation = domElement.animate({
          transform: 'translateY(100%)'
        }, {
          duration: Math.min((domElement.offsetHeight - translateY) / Math.abs(velocity[1]), 200),
          fill: 'forwards',
          easing: 'cubic-bezier(0.3, 0, 0.8, 0.15)'
        });
        animation.addEventListener('finish', () => {
          overlay.dispose();
        }, {
          once: true
        });
      } else {
        const animation = domElement.animate({
          transform: 'translateY(0%)'
        }, {
          duration: 200,
          fill: 'forwards',
          easing: 'cubic-bezier(0.3, 0, 0.8, 0.15)'
        });
        animation.addEventListener('finish', () => {
          if (domRef.current) {
            domRef.current.style.transform = 'translateY(0%)';
            domRef.current.style.animation = 'none';
          }
          animation.cancel();
        }, {
          once: true
        });
      }
    }
  }, {
    axis: 'y',
    enabled: true
  });
  const isStackedLayout = useIsStackedLayout();
  const isLandscape = useService($ScreenService).isLandscape;
  const close = React.useCallback((goToChannel?: boolean) => {
    if (goToChannel) {
      viewService.openView(channelViewId.with(s => s.closeNickList()));
    }
    if (!domRef.current) {
      overlay.dispose();
      return;
    }
    const animation = domRef.current.animate({
      transform: 'translateY(100%)'
    }, {
      duration: 300,
      fill: 'forwards',
      easing: 'cubic-bezier(0.3, 0, 0.8, 0.15)'
    });
    animation.addEventListener('finish', () => {
      overlay.dispose();
    }, {
      once: true
    });
  }, []);
  const showCapacitorAdzone = adsService.areCapacitorAdsVisible && isStackedLayout && !isLandscape;
  const showWebAdzone = adsService.areWebAdsVisible && isStackedLayout && !isLandscape;
  const areAdsVisible = showCapacitorAdzone || showWebAdzone;
  return <FlexCol size={'full'} position={'absolute'} style={areAdsVisible ? {
    backdropFilter: 'blur(20px)',
    WebkitBackdropFilter: 'blur(10px)',
    transform: 'translate3d(0, 0, 0)' // force hardware acceleration
  } : undefined}>
				<div onClick={() => close()} className={_c6 + ((bgDark ? "transparentDark" : "transparentLight") ? resolveIsDarkColor(bgDark ? "transparentDark" : "transparentLight", useTheme()) ? " content-is-dark" : " content-is-light" : "") + (bgDark ? _c7 : _c8)}>
					{showCapacitorAdzone && <adsService.CapacitorAdzone adVisible bannerId={'K3MacrosheetBanner'} />}
					{showWebAdzone && <adsService.Adzone adzone={'macrosheet'} adsBadgeLocation={'bottom'} fixedSize={250 + 24} />}
				</div>
				<div ref={domRef} className={styles['Knu-SlideUpView']} style={{
      height: isLandscape ? '100%' : areAdsVisible ? 'calc(100% - 290px)' : '70%',
      maxHeight: '70%',
      width: isStackedLayout ? '100%' : 376,
      flexDirection: 'column'
    }}>
					<FallbackBox onLayout={({
        height
      }) => {
        viewHeight.current = height;
        if (!isAnimating.current) {
          isAnimating.current = true;
        }
      }} className={_c9}>
						<div className={'Knu-FlexCol' + ' ' + styles['MacroBox-Header']} onClick={dismissKeyboard} {...bind()}>
							<MacroBoxHeader userId={user.id} user={data} close={close} isSmallHeight={isLandscape} />
						</div>

						<div className={_c10 + ("contentBg" ? resolveIsDarkColor("contentBg", useTheme()) ? " content-is-dark" : " content-is-light" : "") + (!keyboardService.anyKeyboardVisible || !keyboardService.supportsVirtualKeyboard ? _c11 : _c12)}>
							<Fade visible={!keyboardService.anyKeyboardVisible || !keyboardService.supportsVirtualKeyboard} className={_c13}>
								<MacroBoxContent user={data} close={close} />
							</Fade>
						</div>

						{!isFullData(data) && <InputSkeleton />}

						{isFullData(data) && data.canReceiveMessages && data.isAllowedByContactFilter && <MacroBoxFooter user={data} onFocus={focus} onBlur={blur} />}
					</FallbackBox>
				</div>
			</FlexCol>;
});
const InputSkeleton: React.FC = () => {
  return <div className={_c14 + ("contentLightBg" ? resolveIsDarkColor("contentLightBg", useTheme()) ? " content-is-dark" : " content-is-light" : "")}>
			<SkeletonAnimation>
				<div className={_c15}>
					<div className={_c16 + ("skeleton" ? resolveIsDarkColor("skeleton", useTheme()) ? " content-is-dark" : " content-is-light" : "")} />
					<div className={_c17 + ("skeleton" ? resolveIsDarkColor("skeleton", useTheme()) ? " content-is-dark" : " content-is-light" : "")} />
				</div>
			</SkeletonAnimation>
		</div>;
};
const _c0 = " Knu-FlexCol position-absolute inset-none bg-backgroundScrim p-xxlarge placeItems-center ";
const _c1 = " Knu-Box position-absolute inset-none bg-transparent ";
const _c2 = " Knu-FlexCol position-relative maxWidth-376px width-full maxHeight-100-percent height-817px borderRadius-xlarge overflow-hidden bg-contentBg ";
const _c3 = " Knu-Box position-absolute right-base top-small ";
const _c4 = " Knu-ScrollView bg-contentBg flex-1 ";
const _c5 = " Knu-FlexCol size-full position-absolute ";
const _c6 = " Knu-FlexCol flex-1 ";
const _c7 = " bg-transparentDark ";
const _c8 = " bg-transparentLight ";
const _c9 = " Knu-FlexCol position-relative size-full justifyContent-flex-end ";
const _c10 = " Knu-ScrollView bg-contentBg flex-1 position-relative ";
const _c11 = " pointerEvents-all ";
const _c12 = " pointerEvents-none ";
const _c13 = " flex-1 flexDirection-column width-full ";
const _c14 = " Knu-FlexCol shadow-Shadow3 bg-contentLightBg height-66px justifyContent-center ";
const _c15 = " Knu-Flex flex-1 alignItems-center gap-small pl-small pr-tiny ";
const _c16 = " Knu-FlexCol flex-1 bg-skeleton height-50px borderRadius-base ";
const _c17 = " Knu-FlexCol bg-skeleton size-40px borderRadius-circle ";