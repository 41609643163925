import React from 'react';
import { MenuOption } from '@lexical/react/LexicalTypeaheadMenuPlugin';
import { Flex, ScrollView, ThemeOverride, Z_INDEX, resolveThemingValue, useTheme, toPointerHandler, resolveIsDarkColor } from '@knuddels/component-library';
import { MentionPlugin } from '@shared/components/RichText/plugins/MentionPlugin';
import { MenuWrapper } from '@knuddelsModules/Channel/bundle/components/Chat/ChatInput/TypeaheadMenu';
import { $createTextNode, $getRoot, $getSelection, COMMAND_PRIORITY_NORMAL, ParagraphNode } from 'lexical';
import { withSearchCache } from '@knuddelsModules/Channel/bundle/components/Chat/ChatInput/withSearchCache';
import { $SmileyService } from '@knuddelsModules/AutocompleteInputBar';
import { useService } from '@knuddels-app/DependencyInjection';
import { SmileyImage } from '@knuddelsModules/Channel/bundle/components/Chat/ChatInput/SmileyImage';
import { ActionSheet } from '@knuddelsModules/Channel/bundle/components/Chat/ChatInput/Components';
import { $createSmileyNode, $isSmileyNode, SmileyData } from '@shared/components/RichText/nodes/SmileyNode';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { SUBMIT_COMMAND } from '@shared/components/RichText/RichTextEditor';
import { $createEmojiNode, $isEmojiNode } from '@shared/components/RichText/nodes/EmojiNode';

// tslint:disable-next-line: no-module-bleeding
import { EmojiData } from '@knuddelsModules/AutocompleteInputBar/bundle/services';
import { CssAnimatePresence, useAnimateContext } from '@shared/components/RichText/CSSAnimatePrecense';
import { useOptionSelection } from '@shared/components/RichText/plugins/useOptionSelection';
import { useMenuPosition } from './useMenuPosition';
import { $OverlayService } from '@knuddels-app/overlays';
import { $FirebaseAnalyticsService } from '@knuddels-app/analytics/firebase';
class EmojiOption extends MenuOption {
  readonly type = 'emoji';
  constructor(public data: EmojiData) {
    super(data.emoji);
  }
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
class SmileyOption extends MenuOption {
  readonly type = 'smiley';
  constructor(public data: SmileyData) {
    super(data.code);
  }
}
const searchSmiliesAndEmojis = withSearchCache(async (smileyService: typeof $SmileyService.T, queryText: string) => {
  const actualQuery = queryText.replace(':', '').toLowerCase();
  const {
    topResults
  } = await smileyService.getSmiliesAndEmojis(actualQuery, 'inputbar');
  const mappedResults = topResults.map((r: SmileyData | EmojiData) => {
    if ('hexcode' in r) {
      const renderEmoji = smileyService.emojiOverrides[r.hexcode] ? r.skins[smileyService.emojiOverrides[r.hexcode]].emoji : r.emoji;
      return new EmojiOption({
        ...r,
        emoji: renderEmoji
      });
    } else {
      const renderData = r.multi && smileyService.smileyOverrides[r.id] ? smileyService.smileyOverrides[r.id] : r;
      return new SmileyOption({
        ...r,
        url: renderData.url,
        rpl: renderData.rpl
      });
    }
  });
  return mappedResults;
}, 2);
const Menu: React.FC<{
  anchor: React.MutableRefObject<HTMLElement>;
  onClose: () => void;
}> = ({
  anchor,
  children,
  onClose
}) => {
  const position = useMenuPosition(anchor, onClose);
  const overlayService = useService($OverlayService);
  if (!position) {
    return null;
  }
  return <MenuWrapper bottom={position.bottom} zIndex={Z_INDEX.OVERLAY + overlayService.overlays.length}>
			<div style={{
      width: resolveThemingValue(anchor.current.offsetWidth, "sizes", useTheme()),
      left: resolveThemingValue((position.left as ThemeOverride), "spacing", useTheme())
    }} className={_c0}>
				<ActionSheet bg={'transparent'}>{children}</ActionSheet>
			</div>
		</MenuWrapper>;
};
type OptionProps = {
  active: boolean;
  hovered: boolean;
  onPress: () => void;
  onHover?: () => void;
};
const Option: React.FC<OptionProps> = ({
  children,
  active,
  onPress,
  onHover,
  hovered
}) => {
  const animateContext = useAnimateContext();
  return <div onClick={toPointerHandler(e => {
    e.preventDefault();
    onPress();
    animateContext.onExit();
  })} onMouseEnter={toPointerHandler(onHover)} style={{
    backdropFilter: 'blur(15px)',
    WebkitBackdropFilter: 'blur(15px)',
    transform: 'translate3d(0, 0, 0)' // force hardware acceleration
    ,
    borderColor: resolveThemingValue(active ? 'accent' : hovered ? 'accentHover' : 'transparent', "colors", useTheme())
  }} className={_c1 + ("white-transparent-160" ? resolveIsDarkColor("white-transparent-160", useTheme()) ? " content-is-dark" : " content-is-light" : "")}>
			{children}
		</div>;
};
const ANALYTICS_CONTEXT_AUTOCOMPLETE = 'Autocomplete_Smileys';
const ANALYTICS_CONTEXT_RECOMMENDATIONS = 'Smileybox_Recommendations';
export const SmileyPlugin: React.FC<{
  anchor: React.MutableRefObject<HTMLElement>;
}> = ({
  anchor
}) => {
  const smileyService = useService($SmileyService);
  const firebaseAnalytics = useService($FirebaseAnalyticsService);
  const renderSimilarSmileysLastRequestId = React.useRef<number>(null);
  const [editor] = useLexicalComposerContext();
  const {
    options: similarOptions,
    selectedIndex: similarSelectedIndex,
    setOptions: setSmiliarOptions,
    unsetOptions: unsetSmiliarOptions
  } = useOptionSelection<SmileyOption>({
    autoSelectFirst: false,
    onOptionSelected: option => {
      handleSimilarOptionClick(option);
    },
    initialOptions: [],
    retriggerEnter: () => false
  });
  const handleSimilarOptionClick = (option: SmileyOption) => {
    if (!option.data.owned) {
      firebaseAnalytics.logEvent(ANALYTICS_CONTEXT_RECOMMENDATIONS, 'UnownedSmiley_Clicked');
      smileyService.showBuySmiley(option.data);
      return;
    }
    firebaseAnalytics.logEvent(ANALYTICS_CONTEXT_RECOMMENDATIONS, 'OwnedSmiley_Used');
    editor.update(() => {
      const allNodes = ($getRoot().getChildren()[0] as ParagraphNode).getChildren();
      const lastEmoji = allNodes.reverse().find($isEmojiNode);
      if (lastEmoji) {
        const smileyNode = $createSmileyNode(option.data);
        lastEmoji.replace(smileyNode);
        const textNode = $createTextNode(' ');
        smileyNode.insertAfter(textNode);
      }
    });
    unsetSmiliarOptions();
  };
  React.useEffect(() => {
    const removeHandlers = [editor.registerCommand(SUBMIT_COMMAND, payload => {
      payload.state.read(() => {
        const allNodes = ($getRoot().getChildren()[0] as ParagraphNode).getChildren();
        const usedSmilies = allNodes.filter($isSmileyNode).map(node => ({
          id: node.data.id,
          rpl: node.data.rpl,
          url: node.data.url
        } as SmileyData));
        smileyService.pushSmileys(usedSmilies);
        const usedEmojis = allNodes.filter($isEmojiNode);
        smileyService.pushEmoji(usedEmojis.map(node => ({
          emoji: node.data.emoji,
          hexcode: node.data.hexcode
        })));
      });
      return false;
    }, COMMAND_PRIORITY_NORMAL), editor.registerUpdateListener(() => {
      editor.getEditorState().read(() => {
        const lastNode = ($getRoot().getChildren()[0] as ParagraphNode).getLastChild();
        const selectedNode = $getSelection()?.getNodes()?.[0];
        if (selectedNode !== lastNode) {
          unsetSmiliarOptions();
          return;
        }
        const emojiNode = lastNode && $isEmojiNode(lastNode) ? lastNode : null;
        if (emojiNode) {
          const id = Math.random();
          renderSimilarSmileysLastRequestId.current = id;
          smileyService.getSimilarSmileys(emojiNode.data.originalEmoji ?? emojiNode.data.emoji).then(smilies => {
            if (renderSimilarSmileysLastRequestId.current !== id) {
              return;
            }
            setSmiliarOptions(smilies.slice(0, 5).map(s => new SmileyOption(s)));
          });
        } else {
          renderSimilarSmileysLastRequestId.current = null;
          setSmiliarOptions([]);
        }
      });
    })];
    return () => {
      removeHandlers.forEach(h => h());
    };
  }, []);
  return <>
			<MentionPlugin<EmojiOption | SmileyOption> autoSelectFirst={false} getOptions={async query => searchSmiliesAndEmojis(smileyService, query)} allowSelection={option => {
      if (!(option instanceof SmileyOption)) {
        firebaseAnalytics.logEvent(ANALYTICS_CONTEXT_AUTOCOMPLETE, 'Emoji_Used');
        return true;
      }
      if (option.data.owned) {
        firebaseAnalytics.logEvent(ANALYTICS_CONTEXT_AUTOCOMPLETE, 'OwnedSmiley_Used');
        return true;
      }
      firebaseAnalytics.logEvent(ANALYTICS_CONTEXT_AUTOCOMPLETE, 'UnownedSmiley_Clicked');
      smileyService.showBuySmiley(option.data);
      return false;
    }} renderMenu={({
      options,
      itemProps,
      onClose
    }) => {
      return <Menu anchor={anchor} onClose={onClose}>
							<div className={_c2 + ({
          horizontal: " scroll-direction-horizontal "
        }['horizontal'] ?? "") + (true ? " hide-scroll-indicator " : "")}>
								{options.map((option, i) => {
            const shared: OptionProps = {
              active: itemProps.selectedIndex === i,
              hovered: itemProps.highlightedIndex === i,
              onPress: () => itemProps.selectOptionAndCleanUp(option),
              onHover: () => {
                itemProps.setHighlightedIndex(i);
              }
            };
            if (option instanceof SmileyOption) {
              return <Option key={option.key} {...shared}>
												<SmileyTypeaheadOptionRenderer option={option} />
											</Option>;
            }
            return <Option key={option.data.emoji} {...shared}>
											{option.data.emoji}
										</Option>;
          })}
							</div>
						</Menu>;
    }} disableWhenStartingWith={'/'} trigger={`:`} createNode={option => {
      if (option.type === 'emoji') {
        return $createEmojiNode(option.data);
      } else {
        return $createSmileyNode(option.data);
      }
    }} />
			<CssAnimatePresence>
				{similarOptions.length > 0 && <Menu anchor={anchor} onClose={() => {}}>
						<div className={_c3}>
							{similarOptions.map((option, i) => {
            return <Option key={option.key} onPress={() => {
              handleSimilarOptionClick(option);
            }} active={similarSelectedIndex === i} hovered={false}>
										<SmileyTypeaheadOptionRenderer option={option} />
									</Option>;
          })}
						</div>
					</Menu>}
			</CssAnimatePresence>
		</>;
};
const SmileyTypeaheadOptionRenderer: React.FC<{
  option: SmileyOption;
}> = ({
  option
}) => {
  return <Flex
  // @ts-expect-error not exported
  title={option.data.name} size={'full'} placeItems={'center'} style={option.data.owned ? {} : {
    filter: 'grayscale(1)'
  }}>
			<SmileyImage imageUrl={option.data.url} width={30} height={30} />
		</Flex>;
};
const _c0 = " Knu-Flex position-absolute height-50px overflow-hidden bottom-none ";
const _c1 = " Knu-Flex cursor-pointer overflow-hidden flexShrink-0 size-44px bg-white-transparent-160 borderWidth-large borderStyle-solid shadow-Shadow4 borderRadius-large placeItems-center ";
const _c2 = " Knu-ScrollView height-full px-tiny pb-tiny gap-small ";
const _c3 = " Knu-Flex overflow-hidden height-full alignItems-flex-end px-tiny pb-tiny gap-small ";
const _c4 = " Knu-Flex size-full placeItems-center ";