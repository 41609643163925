import { ConversationListFilterType, MessengerConversation, MessengerConversationState } from '@generated/graphql';
import { useService } from '@knuddels-app/DependencyInjection';
import { $Environment } from '@knuddels-app/Environment';
import { observer } from '@knuddels-app/mobx';
import { $FriendRequestsService } from '@knuddelsModules/Contacts';
import { $MessengerListService } from '@knuddelsModules/Messenger';
import { MessengerList } from '@knuddelsModules/Messenger/bundle/components/MessengerList/MessengerList';
import { $ClientSettingsService } from '@knuddelsModules/Settings';
import * as React from 'react';
import { getReadStateFromConversations, messengerReadStateToString } from '../../utils/useMessengerReadStateString';
export const OverviewMessengerList: React.FC<{
  isMinifiedOverview: boolean;
  openConversation: (conversationId: MessengerConversation['id'], index: number) => void;
}> = observer('OverviewMessengerList', ({
  isMinifiedOverview,
  openConversation
}) => {
  const clientSettingsService = useService($ClientSettingsService);
  const environment = useService($Environment);
  const conversations = useService($MessengerListService).unreadConversations;
  const friendRequests = useService($FriendRequestsService).friendRequestsForConversation;
  const filterByState = clientSettingsService.conversationListFilterType === ConversationListFilterType.UnreadMessages ? MessengerConversationState.Unread : MessengerConversationState.NotArchived;
  const messengerListService = useService($MessengerListService);
  const query = filterByState === MessengerConversationState.Unread ? messengerListService.messengerUnreadQuery : messengerListService.messengerOverviewQuery;
  React.useEffect(() => {
    if (globalEnv.product !== 'stapp-messenger' || !environment.messengerSystemAppInterface) {
      return;
    }
    const unreadCount = messengerReadStateToString(getReadStateFromConversations([...conversations, ...friendRequests]));
    const title = unreadCount !== undefined ? `[${unreadCount}] Knuddels Messenger` : 'Knuddels Messenger';
    environment.messengerSystemAppInterface.client.getHostFrame().setTitle(title);
  }, [conversations, environment.messengerSystemAppInterface, friendRequests]);
  return <MessengerList openConversation={openConversation} isArchive={false} conversations={filterByState === MessengerConversationState.Unread ? messengerListService.unreadConversationsAndActiveConversation : messengerListService.overviewConversations} fetchMore={query.fetchMoreConversations} fetchStatus={query.fetchStatus} isMinifiedOverview={isMinifiedOverview} />;
});