import * as React from 'react';
import { Box, Flex, FlexCol, Image, resolveThemingValue, useTheme } from '@knuddels/component-library';
import { FormattedTextDisplay, FormattedTextDisplayProps, FormattedTextImageBox, ImageBoxSizes } from '@shared/components';
export const ImageBox: React.FC<{
  passProps: FormattedTextDisplayProps;
  imageBox: FormattedTextImageBox;
}> = ({
  passProps,
  imageBox
}) => {
  return <div className={_c0}>
			<ImageBoxBackground imageBox={imageBox} />
			<FormattedTextDisplay {...passProps} prefix={undefined} suffix={undefined} placeholderTime={undefined} containerProps={undefined} text={imageBox.content} isParentImageBox />
		</div>;
};
export const ImageBoxBackground: React.FC<{
  imageBox: FormattedTextImageBox;
}> = ({
  imageBox
}) => {
  const urls = imageBox.data.urls;
  const [sizes, setSizes] = React.useState<ImageBoxSizes | undefined>(undefined);
  const isMountedRef = React.useRef(true);
  React.useEffect(() => {
    imageBox.data.imageSizes.then(sizes => {
      if (isMountedRef.current) {
        setSizes(sizes);
      }
    });
  }, [imageBox.data.imageSizes]);
  React.useEffect(() => {
    return () => {
      isMountedRef.current = false;
    };
  }, []);
  if (!sizes) {
    return null;
  }
  return <div className={_c1}>
			<div className={_c2}>
				{sizes.topLeft && <Image src={urls.topLeft} alt={''} style={{
        height: resolveThemingValue(sizes.topLeft.height, "sizes", useTheme())
      }} className={_c3} />}
				<div style={{
        height: resolveThemingValue(sizes.topCenter?.height, "sizes", useTheme())
      }} className={_c4}>
					{sizes.topCenter && <Image src={urls.topCenter} alt={''} style={{
          height: resolveThemingValue(sizes.topCenter.height, "sizes", useTheme())
        }} className={_c5} />}
				</div>
				{sizes.topRight && <Image src={urls.topRight} alt={''} style={{
        height: resolveThemingValue(sizes.topRight.height, "sizes", useTheme())
      }} className={_c6} />}
			</div>
			<div className={_c7}>
				{sizes.centerLeft && <Image src={urls.centerLeft} alt={''} style={{
        width: resolveThemingValue(sizes.centerLeft.width, "sizes", useTheme())
      }} className={_c8} />}
				<div className={_c9}>
					{sizes.centerCenter && <Image src={urls.centerCenter} alt={''} className={_c10} />}
				</div>
				{sizes.centerRight && <Image src={urls.centerRight} alt={''} style={{
        width: resolveThemingValue(sizes.centerRight.width, "sizes", useTheme())
      }} className={_c11} />}
			</div>

			<div className={_c12}>
				{sizes.bottomLeft && <Image src={urls.bottomLeft} alt={''} style={{
        height: resolveThemingValue(sizes.bottomLeft.height, "sizes", useTheme())
      }} className={_c13} />}
				<div style={{
        height: resolveThemingValue(sizes.bottomCenter?.height, "sizes", useTheme())
      }} className={_c14}>
					{sizes.bottomCenter && <Image src={urls.bottomCenter} alt={''} style={{
          height: resolveThemingValue(sizes.bottomCenter.height, "sizes", useTheme())
        }} className={_c15} />}
				</div>
				{sizes.bottomRight && <Image src={urls.bottomRight} alt={''} style={{
        height: resolveThemingValue(sizes.bottomRight.height, "sizes", useTheme())
      }} className={_c16} />}
			</div>
		</div>;
};
const _c0 = " Knu-Flex position-relative width-full ";
const _c1 = " Knu-FlexCol position-absolute inset-none ";
const _c2 = " Knu-Flex width-full position-relative ";
const _c3 = "  ";
const _c4 = " Knu-Box flex-1 ";
const _c5 = " width-full ";
const _c6 = "  ";
const _c7 = " Knu-Flex position-relative flex-1 minHeight-0-px ";
const _c8 = " height-full ";
const _c9 = " Knu-Box flex-1 ";
const _c10 = " height-full width-full ";
const _c11 = " height-full ";
const _c12 = " Knu-Flex width-full position-relative ";
const _c13 = "  ";
const _c14 = " Knu-Box flex-1 ";
const _c15 = " width-full ";
const _c16 = "  ";