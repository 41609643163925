import { transformPropOrder as l } from "./transform.js";
import { getValueAsType as m } from "../../dom/value-types/get-as-type.js";
import { numberValueTypes as p } from "../../dom/value-types/number.js";
const y = {
  x: "translateX",
  y: "translateY",
  z: "translateZ",
  transformPerspective: "perspective"
}, v = l.length;
function A(u, i, s) {
  let e = "", o = !0;
  for (let a = 0; a < v; a++) {
    const t = l[a], r = u[t];
    if (r === void 0)
      continue;
    let n = !0;
    if (typeof r == "number" ? n = r === (t.startsWith("scale") ? 1 : 0) : n = parseFloat(r) === 0, !n || s) {
      const f = m(r, p[t]);
      if (!n) {
        o = !1;
        const c = y[t] || t;
        e += `${c}(${f}) `;
      }
      s && (i[t] = f);
    }
  }
  return e = e.trim(), s ? e = s(i, o ? "" : e) : o && (e = "none"), e;
}
export {
  A as buildTransform
};
