import Cookies from 'js-cookie';

/**
 * this reads the uid that was created at knuddels.de and stores it in the local storage, so that
 * calls from Device.getId() from capacitor will return the same id as the one used during the registration.
 * Only relevant for web.
 */
export function restoreDeviceIdFromCookie() {
	const uid = Cookies.get('uid');
	if (uid) {
		localStorage.setItem('_capuid', uid);
		Cookies.remove('uid');
	}
}
