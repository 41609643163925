import { App, AppState } from '@capacitor/app';
import { Device, DeviceInfo } from '@capacitor/device';
import { ConnectionStatus, Network } from '@capacitor/network';
import { inject, injectable } from '@knuddels-app/DependencyInjection';
import {
	$EvergreenDataService,
	EvergreenData,
} from '@knuddels-app/evergreenData';
import { reaction } from '@knuddels-app/mobx';
import { getVersion } from '@knuddels-app/tools/clientInformation/version';
import { isNative } from '@knuddels-app/tools/isNative';
import { Disposable } from '@knuddels/std';
import { $AdsService } from '@knuddelsModules/Ads';
import { $ActiveChannelService } from '@knuddelsModules/Channel';
import { $UserService } from '@knuddelsModules/UserData';
import { hashString } from '@shared/helper/hashString';

const TIMEOUT_MS = 20000;
const COMMAND_NAMES = {
	OPEN_SYSTEM_APP: 'opensystemapp',
	APP_LIST: 'applist',
} as const;

type CommandCall = {
	commandName: string;
	parameter: string;
};

interface PerformanceMeasurement {
	appId: string;
	pageData?: string;
	startTime: number;
	firstServerResponseTime?: number;
	appReadyTime?: number;
	initialPropsLoadDuration?: number;
	completed: boolean;
	hasUserAction: boolean;
	initialPropsLoaded: boolean;
	appReady: boolean;
	additionalStartAttempts: number;
	timeout?: NodeJS.Timeout;
}

interface PerformanceMetrics {
	timestamp: number;
	appId: string;
	k3AppInForeground: boolean;
	initialPropsProvided: boolean;
	deviceInfo: DeviceInfo;
	success: boolean;
	additionalStartAttempts: number;
	errorMsg?: string;
	memoryInfo?: any;
	k3ClientVersion?: string;
	nick: string;
	userId?: string;
	currentChannel?: string;
	adsVisible: boolean;
	connectionType: ConnectionStatus['connectionType'];
	timings: {
		userAction: number;
		firstServerResponse: number;
		initialProps: number;
		appReady: number;
		total: number;
	};
}

@injectable()
export class SystemAppPerformanceService {
	public readonly dispose = Disposable.fn();
	private readonly measurements = new Map<string, PerformanceMeasurement>();

	private endpointDisabledUntil: number | null = null;
	private readonly ENDPOINT_DISABLE_DURATION = 10 * 60 * 1000;
	private config: EvergreenData['settings']['timing'] | null = null;

	constructor(
		@inject($EvergreenDataService)
		private readonly evergreenDataService: typeof $EvergreenDataService.T,
		@inject($UserService)
		private readonly userService: typeof $UserService.T,
		@inject($AdsService)
		private readonly adsService: typeof $AdsService.T,
		@inject.lazy($ActiveChannelService)
		private readonly activeChannelService: typeof $ActiveChannelService.TLazy
	) {
		this.config = this.evergreenDataService.data.settings.timing;

		this.dispose.track(
			reaction(
				{ name: 'set config when evergreen data is available' },
				() => this.evergreenDataService.data.settings.timing.ep !== '',
				() => {
					this.config =
						this.evergreenDataService.data.settings.timing;
				}
			)
		);
	}

	private get isMeasuringEnabled(): boolean {
		if (!this.config?.ep) {
			return false;
		}

		if (this.endpointDisabledUntil) {
			if (Date.now() < this.endpointDisabledUntil) {
				return false;
			}
			this.endpointDisabledUntil = null;
		}

		return true;
	}

	private setMeasurementTimeout(appId: string): NodeJS.Timeout {
		return setTimeout(() => {
			const measurement = this.measurements.get(appId);
			if (measurement?.completed === false) {
				this.completeMeasurement(
					appId,
					new Error('Measurement timeout')
				);
			}
		}, TIMEOUT_MS);
	}

	private initializeMeasurement(
		appId: string,
		hasUserAction: boolean
	): PerformanceMeasurement {
		const measurement: PerformanceMeasurement = {
			appId,
			startTime: Date.now(),
			completed: false,
			hasUserAction,
			initialPropsLoaded: false,
			appReady: false,
			additionalStartAttempts: 0,
		};

		this.measurements.set(appId, measurement);
		measurement.timeout = this.setMeasurementTimeout(appId);

		return measurement;
	}

	public recordUserAction(appId: string) {
		if (!this.isMeasuringEnabled) {
			return;
		}

		const measurement = this.measurements.get(appId);

		if (measurement) {
			measurement.additionalStartAttempts++;
		} else {
			this.initializeMeasurement(appId, true);
		}
	}

	public recordFirstServerResponse(
		appIdString: string,
		pageData: string
	): void {
		if (!this.isMeasuringEnabled) {
			return;
		}

		const appId = this.removeParameterFromAppId(appIdString);

		let measurement = this.measurements.get(appId);

		if (!measurement) {
			measurement = this.initializeMeasurement(appId, false);
		} else if (measurement.firstServerResponseTime) {
			if (!measurement.hasUserAction) {
				measurement.additionalStartAttempts++;
			}
			return;
		}

		measurement.firstServerResponseTime = Date.now();
		measurement.pageData = pageData;
	}

	public recordInitialPropsLoad(
		appIdString: string,
		measurements: { start: number; end: number }
	): void {
		if (!this.isMeasuringEnabled) {
			return;
		}

		const appId = this.removeParameterFromAppId(appIdString);

		const measurement = this.measurements.get(appId);

		if (!measurement) {
			return;
		} else if (measurement.initialPropsLoaded) {
			return;
		}

		measurement.initialPropsLoadDuration =
			measurements.end - measurements.start;
		measurement.initialPropsLoaded = true;

		this.tryCompleteMeasurement(measurement);
	}

	public recordAppReady(appIdString: string): void {
		if (!this.isMeasuringEnabled) {
			return;
		}

		const appId = this.removeParameterFromAppId(appIdString);

		const measurement = this.measurements.get(appId);

		if (!measurement || measurement.appReady) {
			return;
		}

		measurement.appReadyTime = Date.now();
		measurement.appReady = true;

		this.tryCompleteMeasurement(measurement);
	}

	private cleanupMeasurementTimeout(appId: string): void {
		const measurement = this.measurements.get(appId);
		if (measurement?.timeout) {
			clearTimeout(measurement.timeout);
			measurement.timeout = undefined;
		}
	}

	public handleCommandCall({ commandName, parameter }: CommandCall): void {
		if (!this.isMeasuringEnabled) {
			return;
		}

		switch (commandName) {
			case COMMAND_NAMES.OPEN_SYSTEM_APP:
				this.recordUserAction(this.removeParameterFromAppId(parameter));
				return;
			case COMMAND_NAMES.APP_LIST:
				// eslint-disable-next-line no-case-declarations
				const appId = this.extractGlobalAppsAppId(parameter);
				if (appId) {
					this.recordUserAction(appId);
				}
				return;
		}

		const command = this.config.commands.find(
			cmd => cmd.commandName === commandName
		);

		if (!command) {
			return;
		}

		const regex = new RegExp(command.parameterRegex);

		if (regex.test(parameter)) {
			this.recordUserAction(command.appId);
		}

		return;
	}

	private extractGlobalAppsAppId(parameter: string): string | null {
		const appId = parameter.split(':').pop();
		return appId || null;
	}

	private removeParameterFromAppId(appIdString: string): string {
		const appId = appIdString.split(':')[0];
		return appId || appIdString;
	}

	private tryCompleteMeasurement(measurement: PerformanceMeasurement): void {
		if (!measurement.pageData) {
			return;
		}

		const initialPropsProvided = this.areInitialPropsProvided(
			measurement.pageData
		);

		if (initialPropsProvided && measurement.appReady) {
			this.completeMeasurement(measurement.appId);
			return;
		}

		if (
			!initialPropsProvided &&
			measurement.initialPropsLoaded &&
			measurement.appReady
		) {
			this.completeMeasurement(measurement.appId);
			return;
		}
	}

	private async completeMeasurement(
		appId: string,
		error?: Error
	): Promise<void> {
		const measurement = this.measurements.get(appId);
		if (!measurement || measurement.completed) return;

		measurement.completed = true;

		this.cleanupMeasurementTimeout(appId);

		const metrics = await this.createMetrics(measurement, error);
		await this.sendMetrics(metrics);
		this.measurements.delete(appId);
	}

	private areInitialPropsProvided(pageDataString: string): boolean {
		if (!pageDataString) {
			return false;
		}

		const parsedPageData = JSON.parse(pageDataString);

		if (!parsedPageData?.config?.pageDataLoadUrl) {
			return false;
		}

		const pageDataLoadUrl: string =
			JSON.parse(pageDataString).config.pageDataLoadUrl;

		return pageDataLoadUrl.startsWith('data:');
	}

	private async createMetrics(
		measurement: PerformanceMeasurement,
		error?: Error
	): Promise<PerformanceMetrics> {
		const currentChannelService = await this.activeChannelService();

		const {
			startTime,
			firstServerResponseTime,
			appReadyTime,
			hasUserAction,
			initialPropsLoadDuration,
		} = measurement;

		const timings = this.calculateTimings(
			startTime,
			firstServerResponseTime,
			appReadyTime,
			hasUserAction,
			this.areInitialPropsProvided(measurement.pageData),
			initialPropsLoadDuration
		);

		const deviceInfo = await Device.getInfo();
		if ('name' in deviceInfo) {
			deviceInfo.name = deviceInfo.name
				? await hashString(deviceInfo.name)
				: 'unknown';
		}

		return {
			timestamp: Date.now(),
			appId: measurement.appId,
			k3AppInForeground: (await this.isAppInForeground()).isActive,
			deviceInfo,
			success: !error,
			errorMsg: error?.message.substring(0, 200),
			timings,
			initialPropsProvided: this.areInitialPropsProvided(
				measurement.pageData
			),
			memoryInfo:
				'memory' in performance ? performance.memory : undefined,
			k3ClientVersion: getVersion().toSimpleString(),
			additionalStartAttempts: measurement.additionalStartAttempts,
			nick: this.userService.currentUser?.nick || '-',
			userId: this.userService.currentUser?.id,
			adsVisible: isNative()
				? this.adsService.areCapacitorAdsVisible
				: this.adsService.areWebAdsVisible,
			currentChannel: currentChannelService.activeChannel?.name
				? currentChannelService.activeChannel.name
				: undefined,
			connectionType: (await Network.getStatus()).connectionType,
		};
	}

	private calculateTimings(
		startTime: number,
		firstServerResponseTime?: number,
		appReadyTime?: number,
		hasUserAction?: boolean,
		initialPropsProvided?: boolean,
		initialPropsLoadDuration?: number
	) {
		const firstServerResponseTiming = firstServerResponseTime
			? hasUserAction
				? firstServerResponseTime - startTime
				: 0
			: -1;

		let appReadyTiming = -1;
		let initialPropsTiming = -1;

		if (!initialPropsProvided && initialPropsLoadDuration) {
			initialPropsTiming = initialPropsLoadDuration;
		}

		if (firstServerResponseTime && appReadyTime) {
			appReadyTiming = appReadyTime - firstServerResponseTime;

			if (!initialPropsProvided && initialPropsLoadDuration) {
				appReadyTiming -= initialPropsLoadDuration;
			}
		}

		const totalTiming = appReadyTime ? appReadyTime - startTime : -1;

		return {
			userAction: hasUserAction ? 0 : -1,
			firstServerResponse: firstServerResponseTiming,
			initialProps: initialPropsTiming,
			appReady: appReadyTiming,
			total: totalTiming,
		};
	}

	private disableEndpointTemporarily(): void {
		this.endpointDisabledUntil =
			Date.now() + this.ENDPOINT_DISABLE_DURATION;
	}

	private async sendMetrics(metrics: PerformanceMetrics): Promise<void> {
		if (!this.isMeasuringEnabled) {
			return;
		}

		try {
			const response = await fetch(this.config.ep, {
				method: 'POST',
				body: JSON.stringify(metrics),
			});

			if (!response.ok) {
				throw new Error(`HTTP error! status: ${response.status}`);
			}
		} catch (error) {
			console.warn(
				'SystemAppPerformanceService: Failed to send performance metrics',
				error
			);
			this.disableEndpointTemporarily();
		}
	}

	private async isAppInForeground(): Promise<AppState> {
		return App.getState();
	}
}
