import { $CommandService } from '@knuddels-app/Commands';
import { inject, injectable } from '@knuddels-app/DependencyInjection';
import { $MessageFormatProvider } from '@knuddels-app/i18n';
import { $ViewService } from '@knuddels-app/layout';
import { Disposable } from '@knuddels/std';
import { VisitorsPanel } from '../components/VisitorsPanel/VisitorsPanel';

import de from '../i18n/formats.de.json';
import en from '../i18n/formats.en.json';
import { viewIdForSystemApp } from '@knuddelsModules/SystemApps';

@injectable()
export class ProfileVisitors {
	public readonly VisitorsPanel = VisitorsPanel;

	public readonly dispose = Disposable.fn();

	constructor(
		@inject($MessageFormatProvider)
		messageFormatProvider: typeof $MessageFormatProvider.T,
		@inject($CommandService)
		private readonly commandService: typeof $CommandService.T,
		@inject($ViewService)
		private readonly viewService: typeof $ViewService.T
	) {
		messageFormatProvider.registerFormatProvider(
			locale =>
				// Workaround for metro bundler because it can't handle dynamic imports.
				// See https://github.com/facebook/metro/issues/52
				(
					({
						de,
						en,
					}) as any
				)[locale.language]
		);

		this.dispose.track(
			commandService.registerCommand({
				commandName: 'openk3profilevisitors',
				invoke: async (): Promise<void> => {
					viewService.openViewAsOverlayWithContext(
						viewIdForSystemApp('ProfileVisitorsApp'),
						'SlashCommand'
					);
				},
			})
		);
	}
}
