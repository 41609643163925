import { FullConversationFragment } from '@generated/graphql';
import { useService } from '@knuddels-app/DependencyInjection';
import { Flex, IconClose, Text, resolveThemingValue, resolveIsDarkColor, useTheme } from '@knuddels/component-library';
import { $ProfileNavigationService } from '@knuddelsModules/Profile';
import { UserOnlineStatusBadge } from '@knuddelsModules/UserData';
import { StatusBadgeSizes } from '@shared/components';
import { userIsJames } from '@shared/helper/user';
import React from 'react';
import { ConversationOverflowMenu } from '../../Conversation/ConversationOverflowMenu';
import { ReportButton } from '../../Conversation/ReportButton';
import { TypingIndicator } from '@knuddelsModules/Messenger/bundle/components/TypingIndicator';
import { declareFormat, FormattedMessage } from '@knuddels-app/i18n';
const MiniChatHeader: React.FC<{
  isTyping: boolean;
  conversation: FullConversationFragment | undefined;
  onClose: () => void;
}> = ({
  conversation,
  onClose,
  isTyping
}) => {
  const otherParticipant = conversation?.otherParticipants?.[0] || undefined;
  const shouldShowReportButton = !!otherParticipant && !userIsJames(otherParticipant);
  const profileNavigationService = useService($ProfileNavigationService);
  return <div className={_c0 + ("contentBarsAtom" ? resolveIsDarkColor("contentBarsAtom", useTheme()) ? " content-is-dark" : " content-is-light" : "")}>
			<div onClick={() => profileNavigationService.showProfile(otherParticipant?.id, 'MiniChatHeader')} className={_c1}>
				<Text type={'body2'} numberOfLines={1} className={_c2}>
					<FormattedMessage id={declareFormat({
          id: 'messenger.minichat.header',
          defaultFormat: 'Conversation with {nick}'
        })} values={{
          nick: <Text bold={true} type={'body2'} className={_c3}>
									{otherParticipant ? otherParticipant.nick : '...'}
								</Text>
        }} />
				</Text>
				{otherParticipant && <UserOnlineStatusBadge size={StatusBadgeSizes.SMALL} userId={otherParticipant.id} borderPreset={'none'} />}
				{isTyping && <TypingIndicator />}
			</div>
			<div className={_c4}>
				{shouldShowReportButton && <ReportButton otherParticipant={otherParticipant} />}
				<ConversationOverflowMenu conversation={conversation} />
				<IconClose size="large" onPress={() => onClose()} />
			</div>
		</div>;
};
export default MiniChatHeader;
const _c0 = " Knu-Flex justifyContent-space-between alignItems-center pl-base pr-tiny width-full bg-contentBarsAtom borderTopRadius-minor shadow-Shadow1 zIndex-1 ";
const _c1 = " Knu-Flex alignItems-center gap-small ";
const _c2 = "  ";
const _c3 = "  ";
const _c4 = " Knu-Flex ";