import { newServiceId } from '@knuddels-app/DependencyInjection';
import {
	CrashDetectionService,
	IgnoreService,
	OnlineStatusService,
	UserDataComponents,
	UserOnlineStatusService,
	UserService,
} from './bundle/services';

export const $UserDataComponents = newServiceId<UserDataComponents>(
	'$UserDataComponents'
);
export const $OnlineStatusService = newServiceId<OnlineStatusService>(
	'$OnlineStatusService'
);
export const $UserService = newServiceId<UserService>('$UserService');
export const $UserOnlineStatusService = newServiceId<UserOnlineStatusService>(
	'$UserOnlineStatusService'
);
export const $IgnoreService = newServiceId<IgnoreService>('$IgnoreService');
export const $CrashDetectionService = newServiceId<CrashDetectionService>(
	'$CrashDetectionService'
);
