import { createAbstractMessage } from '@shared/components';
import { User } from '@generated/graphql';
import { ClickableUser } from './ClickableUser';
import { UserImage, UserImageType } from '@knuddelsModules/UserData';
import * as React from 'react';
import { messageEvent } from '@knuddelsModules/Channel/analytics';
import { FlexCol } from '@knuddels/component-library';
const track = () => messageEvent.track('Message_UserImageClicked');
export const MessageClickableSender = createAbstractMessage<{
  uid?: User['id'];
}>(props => <div className={_c0}>
		<ClickableUser uid={props.uid} onClick={track} disableMacroBox openedFromTrackingLabel="MessageUserImage">
			<UserImage shadow uid={props.uid} type={UserImageType.CHAT} />
		</ClickableUser>
	</div>);
const _c0 = " Knu-FlexCol position-relative overflow-visible ";