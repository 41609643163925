import { injectable, newServiceId } from '@knuddels-app/DependencyInjection';

export interface UserEventSubscriber {
	handleEvent: (event: UserEvent) => void;
}

export type UserEvent =
	| {
			type: 'App_Start';
	  }
	| {
			type: 'Logout';
			source: string;
	  }
	| {
			type: 'Created_Album';
	  }
	| {
			type: 'Uploaded_AlbumPhoto';
	  }
	| {
			type: 'Commented_Photo';
	  }
	| {
			type: 'Viewed_Photo';
	  }
	| {
			type: 'Opened_Conversation';
			container: 'messenger' | 'mini-messenger';
			conversationId: string;
			source: string;
	  }
	| {
			type: 'Executed_SlashCommand';
			command: string;
			parameter: string[];
			context: string;
			triggeredByUserInput: boolean;
	  }
	| {
			type: 'Opened_SystemApp';
			itemId: string;
	  }
	| {
			type: 'Opened_Nicklist';
			amountOfNicks: number;
	  }
	| {
			type: 'Opened_Settings';
			source: 'string';
	  }
	| {
			type: 'Contact_Clicked';
			context: 'search' | 'overview';
			index: number;
			online: boolean;
	  }
	| {
			type: 'Contacts_TabOpened';
			tab: string;
	  }
	| {
			type: 'Contacts_Searched';
			searchText: string;
			hitCount: number;
	  }
	| {
			type: 'Smileybox_Opened';
			context: string;
	  }
	| {
			type: 'Featurebox_Opened';
			context: string;
	  }
	| {
			type: 'View_Opened';
			item: string;
			source: string;
	  }
	| {
			type: 'Settings_Navigated';
			newPath: string;
	  }
	| {
			type: 'SystemApp_OpenApp';
			appId: string;
	  }
	| {
			type: 'InstallApp_Clicked';
	  }
	| {
			type: 'Sent_Image';
			recipient: string;
			context: 'messenger' | 'mini-messenger';
	  }
	| {
			type: 'Allowed_Images';
			recipient: string;
			source: string;
			context: 'messenger' | 'mini-messenger';
	  }
	| {
			type: 'Disallowed_Images';
			recipient: string;
			source: string;
			context: 'messenger' | 'mini-messenger';
	  }
	| {
			type: 'Opened_Profile';
			userId: string;
			source: string;
	  }
	| {
			type: 'Opened_Own_Profile';
			source: string;
	  }
	| {
			type: 'Added_To_Watchlist';
			target: string;
			source: string;
	  }
	| {
			type: 'Album_Created';
	  }
	| {
			type: 'AlbumPhoto_Uploaded';
	  }
	| {
			type: 'Sent_PhotoComment';
	  }
	| {
			type: 'Viewed_AlbumPhoto';
			index: number;
	  }
	| {
			type: 'Settings_Changed';
			field: string;
	  }
	| {
			type: 'Smiley_Searched';
			query: string;
			context: 'smileybox' | 'inputbar';
			results: string[];
	  }
	| {
			type: 'NoInterest_Clicked';
			nick: string;
			source: string;
	  }
	| {
			type: 'Ignored_User';
			nick: string;
			source: string;
	  }
	| {
			type: 'Blocked_User';
			nick: string;
			source: string;
	  }
	| {
			type: 'ThankYou_Reaction';
			source: string;
	  }
	| {
			type: 'Clicked_DashboardTip';
			tipId: string;
	  }
	| {
			type: 'ChannelOverview_TabChanged';
			tab: string;
	  }
	| {
			type: 'ChannelSelection_Opened_Search';
	  }
	| {
			type: 'ChannelSelection_Search';
			searchTerm: string;
			result: string[];
	  }
	| {
			type: 'ProfileQuickWhois_Opened';
			nick: string;
	  }
	| {
			type: 'Macrobox_Opened';
			nick: string;
	  }
	| {
			type: 'Clicked_NewConversation';
	  }
	| {
			type: 'Clicked_ProfileEdit';
	  }
	| {
			type: 'Switched_ProfileTab';
			tab: string;
	  }
	| {
			type: 'Joined_Channel';
			channelId: string;
			subId: string;
			channelName: string;
			source: string;
			numberOfMembers: number;
	  }
	| {
			type: 'Left_Channel';
			source: string;
	  }
	| {
			type: 'Client_Crash';
			crashType: string;
			timeSinceLastClose: number;
	  };

@injectable()
export class GenericUserEventService {
	private subscribers: Set<UserEventSubscriber> = new Set();

	public subscribe = (subscriber: UserEventSubscriber): (() => void) => {
		this.subscribers.add(subscriber);
		return () => {
			this.subscribers.delete(subscriber);
		};
	};

	public reportEvent = (event: UserEvent): void => {
		this.subscribers.forEach(subscriber => subscriber.handleEvent(event));
	};
}

export const $GenericUserEventService = newServiceId<GenericUserEventService>(
	'$GenericUserEventService'
);
