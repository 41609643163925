import { inject, injectable } from '@knuddels-app/DependencyInjection';
import { $MessageFormatProvider } from '@knuddels-app/i18n';
import { GenericSystemApp } from '../components/GenericSystemApp';
import { $ViewService } from '@knuddels-app/layout';
import { Disposable } from '@knuddels/std';
import {
	$CommandService,
	$CommandWithoutChannelService,
} from '@knuddels-app/Commands';
import { dismissKeyboard } from '@shared/helper/dismissKeyboard';
import {
	globalAppViewId,
	smileyTradeAppViewId,
	viewIdForSystemApp,
} from '@knuddelsModules/SystemApps';
import { GLOBAL_APP_SLASH_COMMAND } from '@shared/constants';
import {
	$DeepLinkingService,
	DeepLinkType,
} from '@knuddelsModules/DeepLinking';
import { action } from '@knuddels-app/mobx';
import { $NavHidingService } from '@knuddelsModules/LoggedInArea';

import de from '../i18n/formats.de.json';
import en from '../i18n/formats.en.json';

@injectable()
export class SystemAppService {
	public readonly dispose = Disposable.fn();

	private overlayClosedListeners: (() => void)[] = [];

	GenericSystemApp = GenericSystemApp;

	constructor(
		@inject($MessageFormatProvider)
		private readonly messageFormatProvider: typeof $MessageFormatProvider.T,
		@inject($ViewService)
		private readonly viewService: typeof $ViewService.T,
		@inject($CommandService)
		private readonly commandService: typeof $CommandService.T,
		@inject($CommandWithoutChannelService)
		private readonly commandWithoutChannelService: typeof $CommandWithoutChannelService.T,
		@inject($DeepLinkingService)
		private readonly deepLinkingService: typeof $DeepLinkingService.T,
		@inject($NavHidingService)
		private readonly navHidingService: typeof $NavHidingService.T
	) {
		messageFormatProvider.registerFormatProvider(
			locale =>
				// Workaround for metro bundler because it can't handle dynamic imports.
				// See https://github.com/facebook/metro/issues/52
				(
					({
						de,
						en,
					}) as any
				)[locale.language]
		);

		const commands = ['tb', 'sb', 'smileybörse'];
		for (const command of commands) {
			this.dispose.track(
				commandService.registerCommand({
					commandName: command,
					invoke: async (): Promise<void> => {
						this.viewService.openView(smileyTradeAppViewId);
					},
					shouldInvoke: (parameter: string): boolean => {
						return (
							!parameter.trim() &&
							this.viewService.isViewInBackground(
								smileyTradeAppViewId
							)
						);
					},
				})
			);
		}

		this.dispose.track(
			commandService.registerCommand({
				commandName: GLOBAL_APP_SLASH_COMMAND,
				invoke: async (): Promise<void> => await this.openGlobalApps(),
				shouldInvoke: (parameter: string): boolean => {
					return !parameter.trim();
				},
			})
		);

		this.dispose.track(
			this.deepLinkingService.registerDeeplinkHandler((type, target) => {
				if (
					type === DeepLinkType.App &&
					target.toLowerCase() === 'smileytrade'
				) {
					this.viewService.openView(smileyTradeAppViewId);
				}
			})
		);

		this.dispose.track(
			this.deepLinkingService.registerDeeplinkHandler((type, target) => {
				if (
					type === DeepLinkType.App &&
					target.toLowerCase() === 'loyaltysystem'
				) {
					this.commandWithoutChannelService.invokeCommand(
						'loyaltySystem',
						'open'
					);
				}
			})
		);

		this.dispose.track(
			this.deepLinkingService.registerDeeplinkHandler((type, target) => {
				if (
					type === DeepLinkType.App &&
					target.toLowerCase() === 'profilevisitors'
				) {
					// TODO: set correct command
					this.commandWithoutChannelService.invokeCommand(
						'profileVisitors',
						'open'
					);
				}
			})
		);

		this.dispose.track(
			this.deepLinkingService.registerDeeplinkHandler((type, target) => {
				if (
					type === DeepLinkType.App &&
					target.toLowerCase().startsWith('profilecustomization')
				) {
					if (target.includes('/')) {
						const overlayId = target.split('/')[1];
						this.commandWithoutChannelService.invokeCommand(
							'opensystemapp',
							`ProfileCustomizationApp:{"overlayId":"${overlayId}"}`
						);
					} else {
						this.commandWithoutChannelService.invokeCommand(
							'opensystemapp',
							'ProfileCustomizationApp'
						);
					}
				}
			})
		);

		this.dispose.track(
			this.deepLinkingService.registerDeeplinkHandler((type, target) => {
				if (
					type === DeepLinkType.App &&
					target.toLowerCase() === 'profilevisitors'
				) {
					this.commandWithoutChannelService.invokeCommand(
						'profilevisitors',
						'app'
					);
				}
			})
		);

		this.dispose.track(
			commandService.registerCommand({
				commandName: 'profilevisitors',
				invoke: async (): Promise<void> => {
					this.viewService.openViewAsOverlayWithContext(
						viewIdForSystemApp('ProfileVisitorsApp'),
						'SlashCommand'
					);
				},
				shouldInvoke: (parameter: string): boolean => {
					return parameter.trim() === 'app';
				},
			})
		);
	}

	@action
	setOverlayOpened = (opened: boolean): void => {
		this.navHidingService.setSystemAppOverlayOpened(opened);
		if (!opened) {
			this.overlayClosedListeners.forEach(listener => listener());
			this.overlayClosedListeners = [];
		}
	};

	onOverlayClosed = (): Promise<void> => {
		return new Promise(resolve => {
			this.overlayClosedListeners.push(resolve);
		});
	};

	private openGlobalApps = async (): Promise<void> => {
		dismissKeyboard();
		this.viewService.openViewAsOverlay(globalAppViewId);
	};
}
