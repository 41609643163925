import { invariant as n } from "../../utils/errors.js";
import { cubicBezier as c } from "../cubic-bezier.js";
import { noop as m } from "../../utils/noop.js";
import { easeIn as a, easeInOut as p, easeOut as u } from "../ease.js";
import { circIn as s, circInOut as f, circOut as I } from "../circ.js";
import { backIn as O, backInOut as b, backOut as g } from "../back.js";
import { anticipate as k } from "../anticipate.js";
import { isBezierDefinition as l } from "./is-bezier-definition.js";
const x = {
  linear: m,
  easeIn: a,
  easeInOut: p,
  easeOut: u,
  circIn: s,
  circInOut: f,
  circOut: I,
  backIn: O,
  backInOut: b,
  backOut: g,
  anticipate: k
}, T = (r) => {
  if (l(r)) {
    n(r.length === 4);
    const [t, o, e, i] = r;
    return c(t, o, e, i);
  } else if (typeof r == "string")
    return x[r];
  return r;
};
export {
  T as easingDefinitionToFunction
};
