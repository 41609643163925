import { makeUseVisualState as t } from "../../motion/utils/use-visual-state.js";
import { scrapeMotionValuesFromProps as e } from "./utils/scrape-motion-values.js";
import { createHtmlRenderState as o } from "./utils/create-render-state.js";
const i = {
  useVisualState: t({
    scrapeMotionValuesFromProps: e,
    createRenderState: o
  })
};
export {
  i as htmlMotionConfig
};
