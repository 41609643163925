import { isForcedMotionValue as u } from "../../../motion/utils/is-forced-motion-value.js";
import { isMotionValue as s } from "../../../value/utils/is-motion-value.js";
function c(n, l, e) {
  var i;
  const { style: t } = n, r = {};
  for (const o in t)
    (s(t[o]) || l.style && s(l.style[o]) || u(o, n) || ((i = e == null ? void 0 : e.getValue(o)) === null || i === void 0 ? void 0 : i.liveStyle) !== void 0) && (r[o] = t[o]);
  return r;
}
export {
  c as scrapeMotionValuesFromProps
};
