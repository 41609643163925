import { useLazyService } from '@knuddels-app/ModuleSystem';
import { useService } from '@knuddels-app/DependencyInjection';
import { $Environment } from '@knuddels-app/Environment';
import { User } from '@generated/graphql';
import { $ProfileNavigationService } from './providedServices';

// Can handle open profile for both K3 and StApp messenger
export const useOpenProfile = (
	user: Pick<User, 'id' | 'nick'>,
	onOpen?: () => void
) => {
	const environment = useService($Environment);
	const getProfileNavigationService = useLazyService(
		$ProfileNavigationService
	);

	return (openedFromTrackingLabel: string) => {
		if (onOpen) {
			onOpen();
		}

		if (environment.messengerSystemAppInterface) {
			environment.messengerSystemAppInterface.executeOpenProfileSlashCommand(
				user.nick
			);
		} else {
			getProfileNavigationService().then(s =>
				s.showProfile(user.id, openedFromTrackingLabel)
			);
		}
	};
};
