import {
	ClientEventHandler,
	StartNativePaymentEvent,
} from './ClientEventHandler';
import { inject, injectable } from '@knuddels-app/DependencyInjection';
import {
	$PaymentErrorDisplay,
	$PaymentHandler,
	$PaymentSuccessDisplay,
} from '@knuddelsModules/Payment';
import { $ModalAppViewer } from '@knuddelsModules/Apps';
import type { PaymentCallbacks } from '@knuddelsModules/Payment';

@injectable()
export class StartNativeClientEventHandler
	implements ClientEventHandler<StartNativePaymentEvent>
{
	constructor(
		@inject($PaymentHandler)
		private readonly paymentHandler: typeof $PaymentHandler.T,
		@inject($PaymentErrorDisplay)
		private readonly paymentErrorDisplay: typeof $PaymentErrorDisplay.T,
		@inject($PaymentSuccessDisplay)
		private readonly paymentSuccessDisplay: typeof $PaymentSuccessDisplay.T,
		@inject($ModalAppViewer)
		private readonly modalAppViewer: typeof $ModalAppViewer.T
	) {}

	handleEvent(event: StartNativePaymentEvent): void {
		this.startPaymentForProductId(
			event.productId,
			event.appId,
			event.skuIds,
			event.offerId,
			event.isSubscription
		);
	}

	private startPaymentForProductId(
		productId: string,
		appId: string,
		skuIds: string[] | undefined,
		offerId: string | undefined,
		isSubscription = false
	): void {
		const callbacks: PaymentCallbacks = {
			success: () => {
				this.modalAppViewer.closeApp(appId);
				this.paymentSuccessDisplay.display();
			},
			error: ({ error }) => {
				this.paymentErrorDisplay.display(error, {
					onRetry: () => {
						this.startPaymentForProductId(
							productId,
							appId,
							skuIds,
							offerId
						);
					},
					onCancel: () => {
						this.modalAppViewer.closeApp(appId);
					},
				});
			},
			nativeStoreError: () => {
				const app = this.modalAppViewer.getApp('BuyDirectKnuddelApp');
				// XXX what about other (payment) apps? This pattern does not scale well / is hard to maintain.
				if (app) {
					app.sendEventToWebview('nativePaymentError', {});
				}
			},
		};

		if (skuIds && skuIds.length > 0) {
			this.paymentHandler.buyProductWithSkuIds(
				productId,
				skuIds,
				isSubscription,
				offerId,
				callbacks
			);
		} else {
			this.paymentHandler.buyProduct(productId, offerId, callbacks);
		}
	}
}
