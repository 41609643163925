import { millisecondsToSeconds as F, secondsToMilliseconds as R } from "../../../utils/time-conversion.js";
import { calcGeneratorVelocity as S } from "../utils/velocity.js";
import { findSpring as V, calcAngularFreq as A } from "./find.js";
const w = ["duration", "bounce"], B = ["stiffness", "damping", "mass"];
function T(e, s) {
  return s.some((r) => e[r] !== void 0);
}
function G(e) {
  let s = {
    velocity: 0,
    stiffness: 100,
    damping: 10,
    mass: 1,
    isResolvedFromDuration: !1,
    ...e
  };
  if (!T(e, B) && T(e, w)) {
    const r = V(e);
    s = {
      ...s,
      ...r,
      mass: 1
    }, s.isResolvedFromDuration = !0;
  }
  return s;
}
function z({ keyframes: e, restDelta: s, restSpeed: r, ...f }) {
  const p = e[0], c = e[e.length - 1], u = { done: !1, value: p }, { stiffness: g, damping: x, mass: M, duration: v, velocity: b, isResolvedFromDuration: y } = G({
    ...f,
    velocity: -F(f.velocity || 0)
  }), h = b || 0, t = x / (2 * Math.sqrt(g * M)), a = c - p, i = F(Math.sqrt(g / M)), q = Math.abs(a) < 5;
  r || (r = q ? 0.01 : 2), s || (s = q ? 5e-3 : 0.5);
  let d;
  if (t < 1) {
    const n = A(i, t);
    d = (o) => {
      const l = Math.exp(-t * i * o);
      return c - l * ((h + t * i * a) / n * Math.sin(n * o) + a * Math.cos(n * o));
    };
  } else if (t === 1)
    d = (n) => c - Math.exp(-i * n) * (a + (h + i * a) * n);
  else {
    const n = i * Math.sqrt(t * t - 1);
    d = (o) => {
      const l = Math.exp(-t * i * o), m = Math.min(n * o, 300);
      return c - l * ((h + t * i * a) * Math.sinh(m) + n * a * Math.cosh(m)) / n;
    };
  }
  return {
    calculatedDuration: y && v || null,
    next: (n) => {
      const o = d(n);
      if (y)
        u.done = n >= v;
      else {
        let l = 0;
        t < 1 && (l = n === 0 ? R(h) : S(d, n, o));
        const m = Math.abs(l) <= r, D = Math.abs(c - o) <= s;
        u.done = m && D;
      }
      return u.value = u.done ? c : o, u;
    }
  };
}
export {
  z as spring
};
