import { inject, injectable } from '@knuddels-app/DependencyInjection';
import { observable, runInAction } from '@knuddels-app/mobx';
import { $AppLoadingProvider } from './extensionPoints';
import { $ModuleService } from '@knuddels-app/ModuleSystem';

@injectable()
export class AppLoadingService {
	@observable
	private _isReady = false;

	private isInitialized = false;

	constructor(
		@inject.many($AppLoadingProvider)
		private readonly providers: (typeof $AppLoadingProvider.T)[],
		@inject($ModuleService)
		private readonly moduleService: typeof $ModuleService.T
	) {}

	init = async (): Promise<void> => {
		if (this.isInitialized) {
			return;
		}
		this.isInitialized = true;

		await Promise.all(
			this.providers.map(async it => {
				const service = await this.moduleService.loadServiceAndModule(
					it.serviceId
				);
				return await it.load(service);
			})
		);

		runInAction('Set app loading ready', () => {
			this._isReady = true;
		});
	};

	public get isReady(): boolean {
		return this._isReady;
	}
}
