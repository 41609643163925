import { isVariantLabel as a } from "./is-variant-label.js";
import { variantProps as p } from "./variant-props.js";
const f = p.length;
function c(n) {
  if (!n)
    return;
  if (!n.isControllingVariants) {
    const r = n.parent ? c(n.parent) || {} : {};
    return n.props.initial !== void 0 && (r.initial = n.props.initial), r;
  }
  const o = {};
  for (let r = 0; r < f; r++) {
    const i = p[r], t = n.props[i];
    (a(t) || t === !1) && (o[i] = t);
  }
  return o;
}
export {
  c as getVariantContext
};
