import * as React from 'react';
import { RadialHeaderMessage } from './RadialHeaderMessage';
import { FlexCol, Text, Image } from '@knuddels/component-library';
import { FormattedMessage } from '@knuddels-app/i18n';
import { declareFormat } from '@knuddels/i18n';
import { HeaderIcon } from './HeaderIcon';
import { useTrackConversation } from '../../useTrackConversation';
import './MentorAchievedMessage.css';
export const MentorAchievedMessage: React.FC<{
  otherParticipantNick: string;
}> = ({
  otherParticipantNick
}) => {
  const track = useTrackConversation();
  React.useEffect(() => {
    track('SystemMessages_BecameMentorShown');
  }, []);
  return <RadialHeaderMessage radialInnerColor={'var(--mentor-radial-inner-color)'} radialOuterColor={'var(--mentor-radial-outer-color)'} headerIcon={<HeaderIcon width={31} height={27} left={20} top={22} src={require('@shared/images/sm_abo_15-05_schulabschluss.gif')} />}>
			<div className={_c0}>
				<Text bold={true} className={_c1}>
					<FormattedMessage id={declareFormat({
          id: 'conversation.message.mentorachieved.title',
          defaultFormat: 'Congratulations!'
        })} />
				</Text>
				<Text className={_c2}>
					<FormattedMessage id={declareFormat({
          id: 'conversation.message.mentorachieved.text',
          defaultFormat: "You're now the mentor of {nick}.\n(+5{mp} received)"
        })} values={{
          nick: otherParticipantNick,
          mp: <Image src={'https://cdnc.knuddelscom.de/pics/mp.png'} alt={'mentor points'} {...{
            style: {
              verticalAlign: 'bottom'
            }
          }} />
        }} />
				</Text>
			</div>
		</RadialHeaderMessage>;
};
const _c0 = " Knu-FlexCol alignItems-center ";
const _c1 = "  ";
const _c2 = " textAlign-center ";