import { App } from '@capacitor/app';
import { ClientState } from '@generated/graphql';
const idleTimeInMs = 60 * 1000;

class AppVisibilityUtils {
	private activeUntilTs = Date.now() + idleTimeInMs;

	constructor() {
		// Handle test environment where document is not defined.
		if (typeof document !== 'undefined') {
			window.addEventListener('focus', this._onFocus);
			window.addEventListener('blur', this._onBlur);

			this._trackIdleStatus();
		}
	}

	public async hasFocus(): Promise<boolean> {
		return (await App.getState()).isActive;
	}

	public isIdle(): boolean {
		return Date.now() > this.activeUntilTs;
	}

	private _trackIdleStatus(): void {
		document.addEventListener('mousemove', this.onActivityOccurred);
		document.addEventListener('keyup', this.onActivityOccurred);
		document.addEventListener('touchstart', this.onActivityOccurred);
		document.addEventListener('scroll', this.onActivityOccurred);

		this.onActivityOccurred();
	}

	public onActivityOccurred = () => {
		this.activeUntilTs = Date.now() + idleTimeInMs;
	};

	private _onFocus = () => {
		this.onActivityOccurred();
	};

	private _onBlur = () => {
		this.activeUntilTs = Date.now() - 1;
	};
}

class UserPresence {
	private visibilityUtils = new AppVisibilityUtils();
	private _previousState: ClientState | undefined;

	async getClientState(): Promise<ClientState> {
		const hasFocus = await this.visibilityUtils.hasFocus();
		const isIdle = this.visibilityUtils.isIdle();
		let state = ClientState.Background;

		if (hasFocus) {
			state = isIdle ? ClientState.Inactive : ClientState.Active;

			if (this._previousState === ClientState.Background) {
				this.visibilityUtils.onActivityOccurred();
				state = ClientState.Active;
			}
		}

		this._previousState = state;
		return state;
	}

	onActivityOccurred(): void {
		this.visibilityUtils.onActivityOccurred();
	}
}

const userPresence = new UserPresence();

export async function getClientState(): Promise<ClientState> {
	return await userPresence.getClientState();
}

export function handleActivityOccurred(): void {
	userPresence.onActivityOccurred();
}
