import { visualElementStore as u } from "../../render/store.js";
import { isMotionValue as S } from "../../value/utils/is-motion-value.js";
import { animateTarget as d } from "../interfaces/visual-element-target.js";
import { createDOMVisualElement as g, createObjectVisualElement as E } from "../utils/create-visual-element.js";
import { isDOMKeyframes as c } from "../utils/is-dom-keyframes.js";
import { resolveSubjects as V } from "./resolve-subjects.js";
import { animateSingleValue as h } from "./single-value.js";
function b(e, t) {
  return S(e) || typeof e == "number" || typeof e == "string" && !c(t);
}
function K(e, t, n, s) {
  const a = [];
  if (b(e, t))
    a.push(h(e, c(t) && t.default || t, n && (n.default || n)));
  else {
    const r = V(e, t, s), m = r.length;
    for (let o = 0; o < m; o++) {
      const l = r[o], f = l instanceof Element ? g : E;
      u.has(l) || f(l);
      const p = u.get(l), i = { ...n };
      "delay" in i && typeof i.delay == "function" && (i.delay = i.delay(o, m)), a.push(...d(p, { ...t, transition: i }, {}));
    }
  }
  return a;
}
export {
  K as animateSubject
};
