import { ImageBoxData } from '@shared/components';
import { getImageData } from '@shared/components/atoms/Image/getImageData';

export function parseImageBox(imageId: string, json: any): ImageBoxData {
	const path = json.path ?? '';
	const basePath = path + '/' + imageId;

	const topCenter = rewriteURL(basePath + '_tc.png');
	const topLeft = rewriteURL(basePath + '_tl.png');
	const topRight = rewriteURL(basePath + '_tr.png');

	const centerRight = rewriteURL(basePath + '_cr.png');
	const centerCenter = rewriteURL(basePath + '_cc.png');
	const centerLeft = rewriteURL(basePath + '_cl.png');

	const bottomCenter = rewriteURL(basePath + '_bc.png');
	const bottomLeft = rewriteURL(basePath + '_bl.png');
	const bottomRight = rewriteURL(basePath + '_br.png');

	const imageSizes = Promise.all([
		getImageData(topCenter),
		getImageData(topLeft),
		getImageData(topRight),
		getImageData(centerRight),
		getImageData(centerCenter),
		getImageData(centerLeft),
		getImageData(bottomCenter),
		getImageData(bottomLeft),
		getImageData(bottomRight),
	]).then(sizes => {
		const [
			topCenter,
			topLeft,
			topRight,
			centerRight,
			centerCenter,
			centerLeft,
			bottomCenter,
			bottomLeft,
			bottomRight,
		] = sizes;
		return {
			topCenter,
			topLeft,
			topRight,
			centerRight,
			centerCenter,
			centerLeft,
			bottomCenter,
			bottomLeft,
			bottomRight,
		};
	});

	return {
		urls: {
			topCenter,
			topLeft,
			topRight,
			centerRight,
			centerCenter,
			centerLeft,
			bottomCenter,
			bottomLeft,
			bottomRight,
		},
		imageSizes,
		path,
	};
}

const PICS_FOLDER = 'pics/';
const rewriteURL = (url: string): string => {
	if (
		url.startsWith('http://') ||
		url.startsWith('https://') ||
		url.startsWith('//')
	) {
		return url;
	}

	url = url.indexOf(PICS_FOLDER) === 0 ? url : PICS_FOLDER + url;
	return 'https://cdnc.knuddelscom.de/' + url;
};
