import { floatRegex as i } from "../utils/float-regex.js";
import { isNullish as n } from "../utils/is-nullish.js";
import { singleColorRegex as f } from "../utils/single-color-regex.js";
const h = (r, o) => (t) => !!(typeof t == "string" && f.test(t) && t.startsWith(r) || o && !n(t) && Object.prototype.hasOwnProperty.call(t, o)), u = (r, o, t) => (e) => {
  if (typeof e != "string")
    return e;
  const [l, s, p, a] = e.match(i);
  return {
    [r]: parseFloat(l),
    [o]: parseFloat(s),
    [t]: parseFloat(p),
    alpha: a !== void 0 ? parseFloat(a) : 1
  };
};
export {
  h as isColorString,
  u as splitColor
};
