import { px as o } from "../../../value/types/numbers/units.js";
const i = {
  // Border props
  borderWidth: o,
  borderTopWidth: o,
  borderRightWidth: o,
  borderBottomWidth: o,
  borderLeftWidth: o,
  borderRadius: o,
  radius: o,
  borderTopLeftRadius: o,
  borderTopRightRadius: o,
  borderBottomRightRadius: o,
  borderBottomLeftRadius: o,
  // Positioning props
  width: o,
  maxWidth: o,
  height: o,
  maxHeight: o,
  top: o,
  right: o,
  bottom: o,
  left: o,
  // Spacing props
  padding: o,
  paddingTop: o,
  paddingRight: o,
  paddingBottom: o,
  paddingLeft: o,
  margin: o,
  marginTop: o,
  marginRight: o,
  marginBottom: o,
  marginLeft: o,
  // Misc
  backgroundPositionX: o,
  backgroundPositionY: o
};
export {
  i as browserNumberValueTypes
};
