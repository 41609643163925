import { inject, injectable } from '@knuddels-app/DependencyInjection';
import { Disposable, err, ok, Result } from '@knuddels/std';
import {
	ConversationMessage,
	ConversationMessageFragment,
	FilterReason,
	ForwardMessage,
	MessengerConversation,
	MessengerForwardMessageResult,
	MessengerSendMessage,
	MessengerSendMessageError,
	MessengerSendMessageErrorType,
	MessengerSendMessageResponse,
	QuoteMessage,
	ReactionSmileys,
	SendConversationStarterSmiley,
	SendNicknameChangeSmileyReaction,
	SendSmileyReaction,
	SmileyDetails,
	User,
} from '@generated/graphql';
import {
	$MessengerConversationService,
	$MessengerService,
	$NewMessageService,
} from '@knuddelsModules/Messenger';
import { $SnackbarService, SnackbarData } from '@knuddels-app/SnackbarManager';
import {
	$AuthenticatedClientService,
	GraphQlOperationError,
} from '@knuddels-app/Connection';
import { SnackbarDefinitionSendMessagePostboxIsFull } from '../../snackbars/SendMessagePostboxIsFull';
import { SnackbarDefinitionSendMessageIsModerator } from '../../snackbars/SendMessageIsModerator';
import { SnackbarDefinitionSendMessageContactFilter } from '../../snackbars/SendMessageContactFilter';
import { SnackbarDefinitionSendMessageFilteredByNoProfilePic } from '../../snackbars/SendMessageFilteredByNoProfilePic';
import { $ProfileNavigationService } from '@knuddelsModules/Profile';
import { $UserService } from '@knuddelsModules/UserData';
import { action, runInAction } from '@knuddels-app/mobx';
import {
	createForwardManyMessageSuccessSnackbar,
	SnackbarDefinitionForwardOneMessageSuccess,
} from '../../snackbars/ForwardMessageSuccess';
import { SendingMessageFactory } from './SendingMessageFactory';
import { ClientConversationState } from './MessengerConversationService';
import { SendingMessageContext } from './SendingMessageContext';
import { $I18n } from '@knuddels-app/i18n';
import {
	SnackbarDefinitionImageErrorBlocked,
	SnackbarDefinitionImageErrorBlockedPingPong,
	SnackbarDefinitionImageErrorNotAllowed,
	SnackbarDefinitionImageErrorUnavailable,
} from '../../snackbars/ImageErrorSnackbars';
import { SnackbarDefinitionTooManyMessagesWithoutPong } from '@knuddelsModules/Messenger/bundle/snackbars/SendMessageTooManyMessagesWithoutPong';

@injectable()
export class SendMessageService {
	public readonly dispose = Disposable.fn();

	public get reactionSmileys(): readonly Pick<
		SmileyDetails,
		'id' | 'image' | 'textRepresentation'
	>[] {
		const queryValue = this.reactionSmileyQuery.value;
		if (queryValue === 'loading' || queryValue === 'error') {
			return [];
		}
		return queryValue.smileybox.reactionSmileys;
	}

	private readonly reactionSmileyQuery =
		this.authenticatedClientService.currentK3Client.watchQuery(
			ReactionSmileys,
			{}
		);

	private readonly sendingMessageFactory = new SendingMessageFactory(
		() => this.userService.currentUser
	);

	constructor(
		@inject($SnackbarService)
		private readonly snackbarService: typeof $SnackbarService.T,
		@inject($AuthenticatedClientService)
		private readonly authenticatedClientService: typeof $AuthenticatedClientService.T,
		@inject.lazy($ProfileNavigationService)
		private readonly getProfileNavigationService: typeof $ProfileNavigationService.TLazy,
		@inject($UserService)
		private readonly userService: typeof $UserService.T,
		@inject($NewMessageService)
		private readonly newMessageService: typeof $NewMessageService.T,
		@inject($MessengerConversationService)
		private readonly messengerConversationService: typeof $MessengerConversationService.T,
		@inject($MessengerService)
		private readonly messengerService: typeof $MessengerService.T,
		@inject($I18n)
		private readonly i18n: typeof $I18n.T
	) {
		this.dispose.track(
			newMessageService.onNewMessage.sub(e => {
				runInAction('remove sending message', () => {
					const conversation =
						this.messengerConversationService.getOrCreateClientConversationState(
							e.conversation.id
						);
					conversation.sendingMessagesState.removeByMessageCorrelationId(
						e.messageCorrelationId
					);
				});
			})
		);
	}

	@action.bound
	public sendMessage(
		conversationId: MessengerConversation['id'],
		text: string
	): Promise<Result<void, unknown>> {
		const conversation =
			this.messengerConversationService.getOrCreateClientConversationState(
				conversationId
			);
		const context = this.addSendingMessage(
			'text',
			conversation,
			text,
			() => {
				this.doSendMessage(text, context);
			}
		);

		return this.doSendMessage(text, context);
	}

	@action.bound
	private doSendMessage(
		text: string,
		context: SendingMessageContext
	): Promise<Result<void, unknown>> {
		return this.authenticatedClientService.currentK3Client
			.mutateWithResultPromise(MessengerSendMessage, {
				id: context.conversation.conversationId,
				text,
				messageCorrelationId: context.messageCorrelationId,
			})
			.match({
				ok: r => {
					this.handleSendMessageResponse(r, context);
					return ok();
				},
				error: e => {
					this.handleMutationError(e, [context], 'non-retryable');
					return err(e);
				},
			});
	}

	@action.bound
	public sendSmileyReaction(
		conversationId: MessengerConversation['id'],
		messageId: ConversationMessage['id'],
		smiley: Pick<SmileyDetails, 'id' | 'image' | 'textRepresentation'>
	): Promise<Result<void, unknown>> {
		const conversation =
			this.messengerConversationService.getOrCreateClientConversationState(
				conversationId
			);
		const context = this.addSendingMessage(
			'text',
			conversation,
			smiley.textRepresentation,
			() => {
				this.doSendSmileyReaction(messageId, smiley.id, context);
			}
		);

		return this.doSendSmileyReaction(messageId, smiley.id, context);
	}

	@action.bound
	private doSendSmileyReaction(
		messageId: ConversationMessage['id'],
		smileyId: SmileyDetails['id'],
		context: SendingMessageContext
	): Promise<Result<void, unknown>> {
		return this.authenticatedClientService.currentK3Client
			.mutateWithResultPromise(SendSmileyReaction, {
				messageId,
				smileyId,
				messageCorrelationId: context.messageCorrelationId,
			})
			.match({
				ok: r => {
					this.handleSendMessageResponse(r, context);
					return ok();
				},
				error: e => {
					this.handleMutationError(e, [context], 'non-retryable');
					return err(e);
				},
			});
	}

	@action.bound
	public sendConversationStarterSmiley(
		conversationId: MessengerConversation['id'],
		smiley: Pick<SmileyDetails, 'id' | 'image' | 'textRepresentation'>
	): Promise<Result<void, unknown>> {
		const conversation =
			this.messengerConversationService.getOrCreateClientConversationState(
				conversationId
			);
		const context = this.addSendingMessage(
			'text',
			conversation,
			smiley.textRepresentation,
			() => {
				this.doSendConversationStarterSmiley(smiley.id, context);
			}
		);

		return this.doSendConversationStarterSmiley(smiley.id, context);
	}

	@action.bound
	private doSendConversationStarterSmiley(
		smileyId: SmileyDetails['id'],
		context: SendingMessageContext
	): Promise<Result<void, unknown>> {
		return this.authenticatedClientService.currentK3Client
			.mutateWithResultPromise(SendConversationStarterSmiley, {
				conversationId: context.conversation.conversationId,
				smileyId,
				messageCorrelationId: context.messageCorrelationId,
			})
			.match({
				ok: r => {
					this.handleSendMessageResponse(r, context);
					return ok();
				},
				error: e => {
					this.handleMutationError(e, [context], 'non-retryable');
					return err(e);
				},
			});
	}

	@action.bound
	public sendNicknameChangeSmileyReaction(
		conversationId: MessengerConversation['id'],
		smiley: Pick<SmileyDetails, 'id' | 'image' | 'textRepresentation'>
	): Promise<Result<void, unknown>> {
		const conversation =
			this.messengerConversationService.getOrCreateClientConversationState(
				conversationId
			);
		const context = this.addSendingMessage(
			'text',
			conversation,
			smiley.textRepresentation,
			() => {
				this.doSendNicknameChangeSmileyReaction(
					conversationId,
					smiley.id,
					context
				);
			}
		);

		return this.doSendNicknameChangeSmileyReaction(
			conversationId,
			smiley.id,
			context
		);
	}

	@action.bound
	private doSendNicknameChangeSmileyReaction(
		conversationId: MessengerConversation['id'],
		smileyId: SmileyDetails['id'],
		context: SendingMessageContext
	): Promise<Result<void, unknown>> {
		return this.authenticatedClientService.currentK3Client
			.mutateWithResultPromise(SendNicknameChangeSmileyReaction, {
				conversationId,
				smileyId,
				messageCorrelationId: context.messageCorrelationId,
			})
			.match({
				ok: r => {
					this.handleSendMessageResponse(r, context);
					return ok();
				},
				error: e => {
					this.handleMutationError(e, [context], 'non-retryable');
					return err(e);
				},
			});
	}

	public quoteMessage(
		conversationId: MessengerConversation['id'],
		quotingMessage: ConversationMessageFragment,
		text: string
	): void {
		const conversation =
			this.messengerConversationService.getOrCreateClientConversationState(
				conversationId
			);
		const context = this.addSendingMessage(
			'quote',
			conversation,
			text,
			() => this.doQuoteMessage(quotingMessage.id, text, context),
			quotingMessage
		);

		this.doQuoteMessage(quotingMessage.id, text, context);
	}

	private doQuoteMessage(
		messageId: ConversationMessageFragment['id'],
		text: string,
		context: SendingMessageContext
	): void {
		this.authenticatedClientService.currentK3Client
			.mutateWithResultPromise(QuoteMessage, {
				messageId,
				text,
				messageCorrelationId: context.messageCorrelationId,
			})
			.match({
				ok: r => this.handleSendMessageResponse(r, context),
				error: e =>
					this.handleMutationError(e, [context], 'non-retryable'),
			});
	}

	public async forwardMessage(
		message: ConversationMessageFragment,
		recipients: User['id'][]
	): Promise<void> {
		const conversationIds = await Promise.all(
			recipients.map(it =>
				this.messengerConversationService.getConversationId(it)
			)
		);
		const conversations = conversationIds.map(it =>
			this.messengerConversationService.getOrCreateClientConversationState(
				it
			)
		);

		const contexts: SendingMessageContext[] = recipients.map(
			(userId, index) =>
				this.addSendingMessage(
					'forward',
					conversations[index],
					'', // forwarded messages have no additional text
					() =>
						this.doForwardMessage(
							message,
							[userId],
							[contexts[index]]
						),
					message
				)
		);

		this.doForwardMessage(message, recipients, contexts);
	}

	public doForwardMessage(
		message: Pick<ConversationMessageFragment, 'id' | 'sender'>,
		recipients: User['id'][],
		contexts: SendingMessageContext[]
	): void {
		this.authenticatedClientService.currentK3Client
			.mutateWithResultPromise(ForwardMessage, {
				messageId: message.id,
				recipients,
				messageCorrelationId: contexts[0].messageCorrelationId,
			})
			.match({
				ok: data => {
					if (data.results.length === 1) {
						this.handleForwardOneMessageResult(
							data.results[0],
							contexts[0]
						);
					} else {
						this.handleForwardManyMessageResult(
							data.results,
							contexts
						);
					}
				},
				error: e => {
					this.handleMutationError(e, contexts, 'all');
				},
			});
	}

	private handleForwardOneMessageResult(
		result: MessengerForwardMessageResult,
		context: SendingMessageContext
	): void {
		const error = result.messageError;
		if (!error) {
			this.showSnackbar(SnackbarDefinitionForwardOneMessageSuccess);
		} else {
			this.handleDomainError(error, [context], 'all');
		}
	}

	private handleForwardManyMessageResult(
		results: readonly MessengerForwardMessageResult[],
		contexts: SendingMessageContext[]
	): void {
		const numSuccessful = results.filter(it => !it.messageError).length;
		if (numSuccessful === 0) {
			this.showGenericErrorSnackbar();
		} else {
			this.showSnackbar(
				createForwardManyMessageSuccessSnackbar(
					numSuccessful,
					results.length
				)
			);
		}

		results.forEach((r, index) => {
			if (r.messageError) {
				this.handleDomainError(
					r.messageError,
					[contexts[index]],
					'none'
				);
			}
		});
	}

	@action.bound
	private addSendingMessage(
		messageType: 'text' | 'forward' | 'quote',
		clientConversation: ClientConversationState,
		text: string,
		retry: () => void,
		nestedMessage?: ConversationMessageFragment
	): SendingMessageContext {
		const conversation =
			this.messengerConversationService.getCachedOverviewConversation(
				clientConversation.conversationId
			);
		const message = this.sendingMessageFactory.createMessage(
			clientConversation.conversationId,
			text,
			id => {
				clientConversation.sendingMessagesState.disableRetry(id);
				withDebugDelay(() => retry());
			},
			messageType,
			nestedMessage
		);

		if (conversation && !conversation.otherParticipants[0].isAppBot) {
			clientConversation.sendingMessagesState.add(message);
		}

		return {
			conversation: clientConversation,
			messageCorrelationId: message.messageCorrelationId,
		};
	}

	private handleSendMessageResponse(
		result: MessengerSendMessageResponse,
		context: SendingMessageContext
	): void {
		if (result.error) {
			this.handleDomainError(result.error, [context], 'non-retryable');
		}
	}

	private handleMutationError(
		e: GraphQlOperationError<MessengerSendMessageError>,
		contexts: SendingMessageContext[],
		shownSnackbars: 'all' | 'non-retryable' | 'none'
	): void {
		if (e.kind === 'DomainError' && e.error) {
			this.handleDomainError(e.error, contexts, shownSnackbars);
		} else {
			if (shownSnackbars === 'all') {
				this.showGenericErrorSnackbar();
			}
			contexts.forEach(c => this.enableRetry(c));
		}
	}

	private handleDomainError(
		error: MessengerSendMessageError,
		contexts: SendingMessageContext[],
		shownSnackbars: 'all' | 'non-retryable' | 'none'
	): void {
		const r = this.mapSendMessageError(error);
		if (
			shownSnackbars === 'all' ||
			(shownSnackbars === 'non-retryable' && !r.canBeRetried)
		) {
			r.showSnackbar();
		}

		if (r.canBeRetried) {
			contexts.forEach(context => this.enableRetry(context));
		} else {
			contexts.forEach(context =>
				context.conversation.sendingMessagesState.removeByMessageCorrelationId(
					context.messageCorrelationId
				)
			);
		}
	}

	private enableRetry(context: SendingMessageContext): void {
		const storage = context.conversation.sendingMessagesState;
		storage.enableRetry(context.messageCorrelationId);
		if (
			this.messengerService.getOpenConversationId() !==
			context.conversation.conversationId
		) {
			storage.markAsUnread(context.messageCorrelationId);
		}
	}

	private mapSendMessageError(error: MessengerSendMessageError): {
		showSnackbar: () => void;
		canBeRetried: boolean;
	} {
		console.log('Error is', error);
		switch (error.type) {
			case MessengerSendMessageErrorType.PostboxIsFull:
				return this.nonRetryableErroSnackbar(
					SnackbarDefinitionSendMessagePostboxIsFull
				);
			case MessengerSendMessageErrorType.IsModerator:
				return this.nonRetryableErroSnackbar(
					SnackbarDefinitionSendMessageIsModerator
				);
			case MessengerSendMessageErrorType.ContactFilter:
				return this.nonRetryableErroSnackbar(
					SnackbarDefinitionSendMessageContactFilter
				);
			case MessengerSendMessageErrorType.InvalidMessage:
			case MessengerSendMessageErrorType.InvalidUser:
				return {
					showSnackbar: () => this.showGenericErrorSnackbar(),
					canBeRetried: false,
				};
			case MessengerSendMessageErrorType.ImagesBlockedPingPongDepth:
				return this.nonRetryableErroSnackbar(
					SnackbarDefinitionImageErrorBlockedPingPong
				);
			case MessengerSendMessageErrorType.ImagesSnapsNotAllowed:
				return this.nonRetryableErroSnackbar(
					SnackbarDefinitionImageErrorNotAllowed
				);
			case MessengerSendMessageErrorType.ImagesBlocked:
				return this.nonRetryableErroSnackbar(
					SnackbarDefinitionImageErrorBlocked
				);
			case MessengerSendMessageErrorType.ImagesSnapServerUnavailable:
				return this.nonRetryableErroSnackbar(
					SnackbarDefinitionImageErrorUnavailable
				);
			case MessengerSendMessageErrorType.TooManyMessagesWithoutPong:
				return this.nonRetryableErroSnackbar(
					SnackbarDefinitionTooManyMessagesWithoutPong
				);
			case MessengerSendMessageErrorType.Failed:
			default:
				if (error.filterReason === FilterReason.NoProfilePicture) {
					return {
						showSnackbar: () =>
							this.getProfileNavigationService().then(service =>
								this.showSnackbar(
									SnackbarDefinitionSendMessageFilteredByNoProfilePic(
										service.showCurrentUserProfile
									)
								)
							),
						canBeRetried: false,
					};
				} else {
					return {
						showSnackbar: () => this.showGenericErrorSnackbar(),
						canBeRetried: true,
					};
				}
		}
	}

	private showGenericErrorSnackbar(): void {
		this.snackbarService.showGenericError();
	}

	private showSnackbar(snackbar: SnackbarData): void {
		this.snackbarService.showSnackbar(snackbar);
	}

	private nonRetryableErroSnackbar = (snackbar: SnackbarData) => {
		return {
			showSnackbar: () => this.showSnackbar(snackbar),
			canBeRetried: false,
		};
	};
}

function withDebugDelay(fn: () => void): void {
	const delay = 0;
	if (import.meta.env.MODE === 'development' && delay > 0) {
		setTimeout(fn, delay);
	} else {
		fn();
	}
}

// tslint:disable-next-line:max-file-line-count
